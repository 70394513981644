/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import { Icon, theme } from '@zeta/ui/src'

/* Styled */
import {
  Wrapper,
  Row,
  Col,
  InfosCol,
  InfoGroup,
  TotalListedWrapper,
  TotalListedIconWrapper,
  TotalListedInfoWrapper,
  TotalListedNumber,
  TotalListedText,
  SocialWrapper,
  SocialIconWrapper,
  SocialInfoWrapper,
  SocialLabel,
  SocialText,
  Label,
  Info,
  InfoIconWrapper,
  InfoTextWrapper,
  InfoTotal,
  InfoText
} from './styled'

const StatisticsCard = ({ subjectType, totalListed, social, web, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <Row>
        {/* Total */}
        <Col>
          <Label>{subjectType === 'people' ? 'Pessoas' : 'Escritórios'}</Label>

          <TotalListedWrapper>
            <TotalListedIconWrapper subjectType={subjectType}>
              <Icon
                icon={
                  subjectType === 'people'
                    ? 'people-outline'
                    : 'briefcase-outline'
                }
                size="xlarge"
                fill={
                  subjectType === 'people'
                    ? theme.colors.brand.primary.medium
                    : theme.colors.brand.secondary.medium
                }
              />
            </TotalListedIconWrapper>

            <TotalListedInfoWrapper>
              <TotalListedNumber subjectType={subjectType}>
                {totalListed}
              </TotalListedNumber>

              <TotalListedText>
                {subjectType === 'people' ? 'pessoas' : 'escritórios'}
              </TotalListedText>
            </TotalListedInfoWrapper>
          </TotalListedWrapper>
        </Col>

        {/* Redes Sociais */}
        <InfosCol>
          <InfoGroup>
            <Label>Redes sociais</Label>

            <Info>
              <InfoIconWrapper subjectType={subjectType}>
                <Icon
                  icon="share-outline"
                  size="medium"
                  fill={
                    subjectType === 'people'
                      ? theme.colors.brand.primary.medium
                      : theme.colors.brand.secondary.medium
                  }
                />
              </InfoIconWrapper>

              <InfoTextWrapper>
                <InfoTotal subjectType={subjectType}>
                  {social.totalFound}
                </InfoTotal>{' '}
                <InfoText>redes sociais identificadas</InfoText>
              </InfoTextWrapper>
            </Info>

            <Info>
              <InfoIconWrapper subjectType={subjectType}>
                <Icon
                  icon="alert-triangle-outline"
                  size="medium"
                  fill={
                    subjectType === 'people'
                      ? theme.colors.brand.primary.medium
                      : theme.colors.brand.secondary.medium
                  }
                />
              </InfoIconWrapper>

              <InfoTextWrapper>
                <InfoTotal subjectType={subjectType}>
                  {social.totalHits}
                </InfoTotal>{' '}
                <InfoText>menções de risco identificadas</InfoText>
              </InfoTextWrapper>
            </Info>
          </InfoGroup>

          {/* Sites */}
          {web && (
            <InfoGroup>
              <Label>Websites</Label>

              <Info>
                <InfoIconWrapper subjectType={subjectType}>
                  <Icon
                    icon="browser-outline"
                    size="medium"
                    fill={
                      subjectType === 'people'
                        ? theme.colors.brand.primary.medium
                        : theme.colors.brand.secondary.medium
                    }
                  />
                </InfoIconWrapper>

                <InfoTextWrapper>
                  <InfoTotal subjectType={subjectType}>
                    {web.totalFound}
                  </InfoTotal>{' '}
                  <InfoText>websites identificados</InfoText>
                </InfoTextWrapper>
              </Info>

              <Info>
                <InfoIconWrapper subjectType={subjectType}>
                  <Icon
                    icon="alert-triangle-outline"
                    size="medium"
                    fill={
                      subjectType === 'people'
                        ? theme.colors.brand.primary.medium
                        : theme.colors.brand.secondary.medium
                    }
                  />
                </InfoIconWrapper>

                <InfoTextWrapper>
                  <InfoTotal subjectType={subjectType}>
                    {web.totalHits}
                  </InfoTotal>{' '}
                  <InfoText>menções de risco identificadas</InfoText>
                </InfoTextWrapper>
              </Info>
            </InfoGroup>
          )}
        </InfosCol>
      </Row>

      <Row>
        {/* Facebook */}
        <Col>
          <SocialWrapper>
            <SocialIconWrapper subjectType={subjectType}>
              <Icon
                icon="fa-facebook"
                size="medium"
                fill={theme.colors.surface.secondary.lightness}
              />
            </SocialIconWrapper>

            <SocialInfoWrapper>
              <SocialLabel>Facebook</SocialLabel>

              <SocialText>
                <strong>{social.facebook.totalFound}</strong> perfis
                identificados
              </SocialText>

              {social.facebook.totalHits >= 0 && (
                <SocialText>
                  <strong>{social.facebook.totalHits}</strong> menções de risco
                </SocialText>
              )}
            </SocialInfoWrapper>
          </SocialWrapper>
        </Col>

        {/* Linkedin */}
        <Col>
          <SocialWrapper>
            <SocialIconWrapper subjectType={subjectType}>
              <Icon
                icon="fa-linkedin"
                size="medium"
                fill={theme.colors.surface.secondary.lightness}
              />
            </SocialIconWrapper>

            <SocialInfoWrapper>
              <SocialLabel>Linkedin</SocialLabel>

              <SocialText>
                <strong>{social.linkedin.totalFound}</strong> perfis
                identificados
              </SocialText>

              {social.linkedin.totalHits >= 0 && (
                <SocialText>
                  <strong>{social.linkedin.totalHits}</strong> menções de risco
                </SocialText>
              )}
            </SocialInfoWrapper>
          </SocialWrapper>
        </Col>

        {/* Instagram */}
        <Col>
          <SocialWrapper>
            <SocialIconWrapper subjectType={subjectType}>
              <Icon
                icon="fa-instagram"
                size="medium"
                fill={theme.colors.surface.secondary.lightness}
              />
            </SocialIconWrapper>

            <SocialInfoWrapper>
              <SocialLabel>Instagram</SocialLabel>

              <SocialText>
                <strong>{social.instagram.totalFound}</strong> perfis
                identificados
              </SocialText>

              {social.instagram.totalHits >= 0 && (
                <SocialText>
                  <strong>{social.instagram.totalHits}</strong> menções de risco
                </SocialText>
              )}
            </SocialInfoWrapper>
          </SocialWrapper>
        </Col>

        {/* Twitter */}
        <Col>
          <SocialWrapper>
            <SocialIconWrapper subjectType={subjectType}>
              <Icon
                icon="fa-twitter"
                size="medium"
                fill={theme.colors.surface.secondary.lightness}
              />
            </SocialIconWrapper>

            <SocialInfoWrapper>
              <SocialLabel>Twitter</SocialLabel>

              <SocialText>
                <strong>{social.twitter.totalFound}</strong> perfis
                identificados
              </SocialText>

              {social.twitter.totalHits >= 0 && (
                <SocialText>
                  <strong>{social.twitter.totalHits}</strong> menções de risco
                </SocialText>
              )}
            </SocialInfoWrapper>
          </SocialWrapper>
        </Col>

        {/* Youtube */}
        <Col>
          <SocialWrapper>
            <SocialIconWrapper subjectType={subjectType}>
              <Icon
                icon="fa-youtube"
                size="medium"
                fill={theme.colors.surface.secondary.lightness}
              />
            </SocialIconWrapper>

            <SocialInfoWrapper>
              <SocialLabel>Youtube</SocialLabel>

              <SocialText>
                <strong>{social.youtube.totalFound}</strong> perfis
                identificados
              </SocialText>

              {social.youtube.totalHits >= 0 && (
                <SocialText>
                  <strong>{social.youtube.totalHits}</strong> menções de risco
                </SocialText>
              )}
            </SocialInfoWrapper>
          </SocialWrapper>
        </Col>
      </Row>
    </Wrapper>
  )
}

StatisticsCard.propTypes = {
  subjectType: PropTypes.oneOf(['people', 'company']),
  totalListed: PropTypes.number.isRequired,
  social: PropTypes.shape({
    totalFound: PropTypes.number,
    totalHits: PropTypes.number,
    facebook: PropTypes.shape({
      totalFound: PropTypes.number,
      totalHits: PropTypes.number
    }),
    linkedin: PropTypes.shape({
      totalFound: PropTypes.number,
      totalHits: PropTypes.number
    }),
    instagram: PropTypes.shape({
      totalFound: PropTypes.number,
      totalHits: PropTypes.number
    }),
    twitter: PropTypes.shape({
      totalFound: PropTypes.number,
      totalHits: PropTypes.number
    }),
    youtube: PropTypes.shape({
      totalFound: PropTypes.number,
      totalHits: PropTypes.number
    })
  }),
  web: PropTypes.shape({
    totalFound: PropTypes.number,
    totalHits: PropTypes.number
  })
}

export default StatisticsCard
