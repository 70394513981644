import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.div`
  ${defaultProps}
`

export const Label = styled.div`
  font-size: 0.75rem;
  color: ${({ theme: { colors } }) => colors.brand.tertiary.light};
  margin-bottom: ${({ theme: { spacing } }) => spacing.medium};
`

export const ToggleButton = styled.button`
  font-size: 0.75rem;
  margin-bottom: ${({ theme: { spacing } }) => spacing.small};

  ${({ select, theme: { colors } }) =>
    select && `color: ${colors.brand.primary.medium};`}
  ${({ clear, theme: { colors } }) =>
    clear && `color: ${colors.brand.tertiary.lightness};`}
`

export const ToggleButtonSeparator = styled.span`
  font-size: 0.75rem;
  color: ${({ theme: { colors } }) => colors.brand.tertiary.lightness};
  margin: 0 ${({ theme: { spacing } }) => spacing.small};
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
