import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;

  svg {
    width: ${({ theme: { spacing } }) => spacing.medium};
    height: ${({ theme: { spacing } }) => spacing.medium};
    fill: ${({ fill, theme: { colors } }) => fill || colors.type.medium};
    margin-right: ${({ theme: { spacing } }) => spacing.xsmall};
    transform: translateY(-2px);
  }

  > span {
    width: calc(100% - 20px);
  }

  ${defaultProps}
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
