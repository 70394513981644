/* React */
import React from 'react'

/* Components */
import { Row, Col } from 'styled-bootstrap-grid'

import { CustomBlock } from '@zeta/ui/src'

import Selection from 'components/filters/Selection'

/* Constants */
import { selectionOptions, visibleLabels } from 'constants/filters'

const BiosFilters = ({ ...props }) => {
  return (
    <CustomBlock mt="xlarge">
      <Row>
        <Col lg={4}>
          <Selection
            display="group"
            selectionType="checkbox"
            label={visibleLabels['linkedin_bios_status']}
            name="linkedin_bios_status"
            items={selectionOptions['linkedin_bios_status']}
            {...props}
            mb="xlarge"
          />
        </Col>

        <Col lg={4}>
          <Selection
            display="group"
            selectionType="checkbox"
            label={visibleLabels['linkedin_experience_status']}
            name="linkedin_experience_status"
            items={selectionOptions['linkedin_experience_status']}
            {...props}
            mb="xlarge"
          />
        </Col>

        <Col lg={4}>
          <Selection
            display="group"
            selectionType="checkbox"
            label={visibleLabels['instagram_bios_status']}
            name="instagram_bios_status"
            items={selectionOptions['instagram_bios_status']}
            {...props}
            mb="xlarge"
          />
        </Col>

        <Col lg={4}>
          <Selection
            display="group"
            selectionType="checkbox"
            label={visibleLabels['facebook_bios_status']}
            name="facebook_bios_status"
            items={selectionOptions['facebook_bios_status']}
            {...props}
            mb="xlarge"
          />
        </Col>

        <Col lg={4}>
          <Selection
            display="group"
            selectionType="checkbox"
            label={visibleLabels['twitter_bios_status']}
            name="twitter_bios_status"
            items={selectionOptions['twitter_bios_status']}
            {...props}
            mb="xlarge"
          />
        </Col>

        <Col lg={4}>
          <Selection
            display="group"
            selectionType="checkbox"
            label={visibleLabels['youtube_bios_status']}
            name="youtube_bios_status"
            items={selectionOptions['youtube_bios_status']}
            {...props}
            mb="xlarge"
          />
        </Col>
      </Row>
    </CustomBlock>
  )
}

export default BiosFilters
