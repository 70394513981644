import { call, put, takeLatest } from 'redux-saga/effects'

/* Constants */
import {
  PUBLICATIONS_REQUESTED,
  SET_PUBLICATIONS,
  PATCH_OBSERVATION_REQUESTED,
  OBSERVATION_PATCHED
} from './action-types'

/* Helpers */
import {
  mergePublications,
  mergeHits
} from 'components/pages/Individual/helpers'
import { removeArchivedHits, removeSocialsArchivedHits } from './helpers'
import {
  getSubjectPublications,
  getSubjectArticles,
  getSubjectTextSites,
  getSubjectBiosHits,
  editObservation
} from 'api'

/* Workers */
function* getAndSetPublicationsWorker(action) {
  try {
    const { subjectId } = action.reqParams

    /* redes sociais */
    const postsPayload = yield call(getSubjectPublications, { subjectId })
    const biosPayload = yield call(getSubjectBiosHits, { subjectId })

    let articlesPayload = { data: [] }
    if (action.subjectType === 'people')
      articlesPayload = yield call(getSubjectArticles, { subjectId })

    const publicationsBySocialNetwork = {
      ...removeSocialsArchivedHits(postsPayload.data[0]),
      linkedinArticles: removeArchivedHits(articlesPayload.data)
    }

    const mergedHits = mergeHits(mergePublications(publicationsBySocialNetwork))

    const biosBySocialNetwork = {
      ...removeSocialsArchivedHits(biosPayload.data)
    }

    const biosMergedHits = mergeHits(mergePublications(biosBySocialNetwork))

    /* site */
    let textSites
    let textSitesMergedHits

    if (action.subjectType === 'company') {
      const textSitesPayload = yield call(getSubjectTextSites, { subjectId })
      textSites = removeArchivedHits(textSitesPayload.data)
      textSitesMergedHits = mergeHits(textSites)
    }

    yield put({
      type: SET_PUBLICATIONS,
      publicationsBySocialNetwork,
      mergedHits,
      biosBySocialNetwork,
      biosMergedHits,
      textSites,
      textSitesMergedHits
    })
  } catch (error) {
    console.log(error)
  }
}

function* editObservationWorker(action) {
  try {
    const { observationId, status, notes } = action.reqParams
    const payload = yield call(editObservation, {
      observationId,
      status,
      notes
    })

    yield put({
      type: OBSERVATION_PATCHED,
      payload,
      observationId,
      social: action.social
    })
  } catch (error) {
    console.log(error)
  }
}

/* Watchers */
export function* getAndSetPublicationsWatcher() {
  yield takeLatest(PUBLICATIONS_REQUESTED, getAndSetPublicationsWorker)
}

export function* editObservationWatcher() {
  yield takeLatest(PATCH_OBSERVATION_REQUESTED, editObservationWorker)
}
