import styled from 'styled-components'

const ContentWrapper = styled.main`
  width: 100%;
  min-height: 100vh;
  padding-top: ${({ theme: { spacing } }) => spacing.medium};
  padding-bottom: 214px;
  position: relative;

  @media (min-width: 1200px) {
    padding-bottom: 150px;
  }

  @media (min-width: 1200px) {
    ${({ withSidebar }) =>
      withSidebar ? 'width: calc(100% - 392px);' : 'width: calc(100% - 88px);'}
  }
`

export default ContentWrapper
