import styled from 'styled-components'

import { defaultProps, defaultPropsPropTypes } from '@zeta/ui/src'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  ${defaultProps}
`

export const Item = styled.button`
  display: flex;
  align-items: center;
  outline: 0;
  ${({ isActive, theme: { colors } }) =>
    isActive && `background-color: ${colors.brand.primary.medium}`};
  padding: ${({ theme: { spacing } }) =>
    `${spacing.smallest} ${spacing.xsmall}`};
  border-radius: ${({ theme: { radius } }) => radius.smallest};
  margin-bottom: ${({ theme: { radius } }) => radius.small};

  &:not(:last-child) {
    margin-right: ${({ theme: { radius } }) => radius.medium};
  }

  svg {
    /* width: ${({ theme: { spacing } }) => spacing.medium};
    height: ${({ theme: { spacing } }) => spacing.medium};
    fill: ${({ isActive, theme: { colors } }) =>
      isActive
        ? `${colors.surface.secondary.lightness}`
        : `${colors.brand.primary.medium}`}; */
    transform: translateY(-1px);
  }
`

export const Text = styled.div`
  font-size: 0.75rem;
  ${({ isActive, theme: { colors } }) =>
    isActive && `color: ${colors.surface.secondary.lightness}`};
  margin-right: ${({ theme: { spacing } }) => spacing.small};
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
