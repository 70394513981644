import cookie from 'react-cookies'
import TagManager from 'react-gtm-module'
import axios from 'axios'
import { initAmplitude } from '@zeta/helpers'

import {
  apiDomain,
  authCookie,
  amplitudeConfig,
  gtmId,
  satismeterConfig
} from '../constants/config'

export const axiosInstance = axios.create({ baseURL: apiDomain })

/**
 *
 * Retorna os headers das requisições que necessitam do token
 * @param {String} token
 * @return {Object}
 *
 */
const getHeaders = () => ({
  headers: {
    'Content-Type': 'application/json'
  }
})

/**
 *
 * Login
 * @param {String} email
 * @param {String} password
 * @return {Promise}
 *
 */
export const signIn = ({ email, password }) => {
  const body = { user: { email, password } }

  return axiosInstance.post('/login', body, getHeaders())
}

/**
 *
 * Retorna lista de usuários (não admins)
 * @return {Promise}
 *
 */
export const getUsers = () => axiosInstance.get('/users', getHeaders())

/**
 *
 * Cria um novo usuário (sem senha, envia e-mail com link para o usuário definir a senha)
 * @param {String} email
 * @param {String} firstName
 * @param {String} lastName
 * @return {Promise}
 *
 */
export const createUser = ({ email, firstName, lastName }) =>
  axiosInstance.post(
    '/signup',
    {
      user: {
        email: email,
        first_name: firstName,
        last_name: lastName
      }
    },
    getHeaders()
  )

/**
 *
 * Reenvia e-mail para novo usuário definir a senha
 * @param {String} email
 * @return {Promise}
 *
 */
export const resendCreateUser = ({ email }) =>
  axiosInstance.post('/resend_email', { user: { email } }, getHeaders())

/**
 *
 * Deleta um usuário
 * @param {String} email
 * @return {Promise}
 *
 */
export const deleteUser = ({ email }) =>
  axiosInstance.delete('/signup', {
    data: { email },
    ...getHeaders()
  })

/**
 *
 * Atualiza nome e sobrenome de um usuário
 * @param {String} firstName
 * @param {String} lastName
 * @return {Promise}
 *
 */
export const updateUserInfo = ({ firstName, lastName }) =>
  axiosInstance.put(
    '/user',
    {
      user: {
        first_name: firstName,
        last_name: lastName
      }
    },
    getHeaders()
  )

/**
 *
 * Envia e-mail para redefinição de senha
 * @param {String} email
 * @return {Promise}
 *
 */
export const requestPasswordReset = ({ email }) =>
  axiosInstance.post('/forgot_password', { user: { email } })

/**
 *
 * Define a senha do usuário (primeiro acesso / redefinição de senha)
 * @param {String} token
 * @param {String} password
 * @param {String} confirmPassword
 * @return {Promise}
 *
 */
export const setPassword = ({ token, password, confirmPassword }) =>
  axiosInstance.post('/login_by_token', {
    user: {
      token,
      password,
      password_confirmation: confirmPassword
    }
  })

/**
 *
 * Redefine a senha do usuário ativo (dentro do painel de configurações)
 * @param {String} password
 * @param {String} newPassword
 * @param {String} confirmNewPassword
 * @return {Promise}
 *
 */
export const resetPassword = ({ password, newPassword, confirmNewPassword }) =>
  axiosInstance.post(
    '/change_password',
    {
      user: {
        old_pwd: password,
        pwd: newPassword,
        pwd_confirmation: confirmNewPassword
      }
    },
    getHeaders()
  )

/**
 *
 * Verifica se há um cookie de autenticação salvo, e em caso positivo, retorna os
 * dados salvos e inicializa o Amplitude, o Google Tag Manager e salva o token na instance do Axios
 * @return {Object | undefined} { auth: authCookiePayload }
 *
 */
export const getInitialAuthData = () => {
  const authCookiePayload = cookie.load(authCookie)

  if (authCookiePayload && authCookiePayload.token) {
    if (authCookiePayload.email !== 'devops@refinariadedados.com.br') {
      /* Amplitude */
      if (amplitudeConfig.key) {
        initAmplitude({
          key: amplitudeConfig.key,
          userId: authCookiePayload.email,
          userProperties: {
            Cliente: amplitudeConfig.company,
            'Permissão do usuário': authCookiePayload.permission
          }
        })
      }

      /* GTM */
      if (gtmId) {
        TagManager.initialize({ gtmId })
      }
    }

    /* Satismeter */
    if (satismeterConfig.writeKey) {
      ;(function () {
        window.satismeter =
          window.satismeter ||
          function () {
            ;(window.satismeter.q = window.satismeter.q || []).push(arguments)
          }
        window.satismeter.l = 1 * new Date()
        var script = document.createElement('script')
        var parent = document.getElementsByTagName('script')[0].parentNode
        script.async = 1
        script.src = 'https://app.satismeter.com/satismeter.js'
        parent.appendChild(script)
      })()

      window.satismeter({
        writeKey: satismeterConfig.writeKey,
        userId: authCookiePayload.email
      })
    }

    /* Salva o token na instance do Axios */
    axiosInstance.defaults.headers.common['Authorization'] =
      authCookiePayload.token

    return {
      user: authCookiePayload
    }
  }

  return undefined
}
