import styled from 'styled-components'

import { defaultProps, defaultPropsPropTypes } from '@zeta/ui/src'

export const Wrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  padding: ${({ theme: { spacing } }) => `${spacing.medium} ${spacing.small}`};
  border-radius: ${({ theme: { radius } }) => radius.smallest};

  ${defaultProps}
`

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const Col = styled.div`
  padding: ${({ theme: { spacing } }) => `0 ${spacing.small}`};
  width: 100%;

  @media (min-width: 768px) {
    width: 50%;
  }

  @media (min-width: 992px) {
    width: 25%;
  }
`

export const InfosCol = styled.div`
  padding: ${({ theme: { spacing } }) => `0 ${spacing.small}`};
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 991px) {
    width: 100%;
    margin-top: ${({ theme: { spacing } }) => spacing.medium};
  }

  @media (min-width: 992px) {
    width: 50%;
  }
`

export const InfoGroup = styled.div`
  width: 100%;

  @media (max-width: 767px) {
    &:last-child:not(:first-child) {
      margin-top: ${({ theme: { spacing } }) => spacing.medium};
    }
  }

  @media (min-width: 768px) {
    width: 50%;

    &:first-child {
      padding-right: ${({ theme: { spacing } }) => spacing.small};
    }
    &:last-child {
      padding-left: ${({ theme: { spacing } }) => spacing.small};
    }
  }
`

export const Label = styled.div`
  font-weight: 700;
  margin-bottom: ${({ theme: { spacing } }) => spacing.medium};
`

export const TotalListedWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const TotalListedIconWrapper = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme: { spacing } }) => spacing.medium};

  ${({ subjectType, theme: { colors } }) =>
    subjectType === 'people' &&
    `
      background-color: ${colors.brand.primary.lightness};
    `}

  ${({ subjectType, theme: { colors } }) =>
    subjectType === 'company' &&
    `
      background-color: ${colors.brand.secondary.lightness};
    `}
`

export const TotalListedInfoWrapper = styled.div`
  width: calc(100% - ${({ theme: { spacing } }) => spacing.largest});
`

export const TotalListedNumber = styled.div`
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;

  ${({ subjectType, theme: { colors } }) =>
    subjectType === 'people' &&
    `
      color: ${colors.brand.primary.medium};
    `}

  ${({ subjectType, theme: { colors } }) =>
    subjectType === 'company' &&
    `
      color: ${colors.brand.secondary.medium};
    `}
`

export const TotalListedText = styled.div`
  line-height: 1;
`

export const Info = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`

export const InfoIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme: { spacing } }) => spacing.small};
`

export const InfoTextWrapper = styled.div`
  width: calc(100% - ${({ theme: { spacing } }) => spacing.large});
`

export const InfoTotal = styled.span`
  font-weight: 700;

  ${({ subjectType, theme: { colors } }) =>
    subjectType === 'people' &&
    `
      color: ${colors.brand.primary.medium};
    `}

  ${({ subjectType, theme: { colors } }) =>
    subjectType === 'company' &&
    `
      color: ${colors.brand.secondary.medium};
    `}
`

export const InfoText = styled.span`
  font-size: 0.875rem;
`

export const SocialWrapper = styled.div`
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  padding: ${({ theme: { spacing } }) => spacing.medium};
  border-radius: ${({ theme: { radius } }) => radius.smallest};
  display: flex;
  align-items: center;
  margin-top: ${({ theme: { spacing } }) => spacing.medium};
`

export const SocialIconWrapper = styled.div`
  width: ${({ theme: { spacing } }) => spacing.xlarge};
  height: ${({ theme: { spacing } }) => spacing.xlarge};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme: { spacing } }) => spacing.large};

  ${({ subjectType, theme: { colors } }) =>
    subjectType === 'people' &&
    `
      background-color: ${colors.brand.primary.medium};
    `}

  ${({ subjectType, theme: { colors } }) =>
    subjectType === 'company' &&
    `
      background-color: ${colors.brand.secondary.medium};
    `}
`

export const SocialInfoWrapper = styled.div`
  width: calc(100% - 56px);
`

export const SocialLabel = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: ${({ theme: { spacing } }) => spacing.xsmall};
`

export const SocialText = styled.div`
  font-size: 0.875rem;
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
