import { css } from 'styled-components'
import PropTypes from 'prop-types'

const defaultProps = css`
  ${({ override }) => override && override}

  ${({ m, theme: { spacing } }) => m && `margin: ${spacing[m] || m + 'px'};`}

  ${({ mt, theme: { spacing } }) =>
    mt && `margin-top: ${spacing[mt] || mt + 'px'};`}

  ${({ mr, theme: { spacing } }) =>
    mr && `margin-right: ${spacing[mr] || mr + 'px'};`}

  ${({ mb, theme: { spacing } }) =>
    mb && `margin-bottom: ${spacing[mb] || mb + 'px'};`}

  ${({ ml, theme: { spacing } }) =>
    ml && `margin-left: ${spacing[ml] || ml + 'px'};`}

  ${({ mx, theme: { spacing } }) =>
    mx &&
    `
      margin-left: ${spacing[mx] || mx + 'px'};
      margin-right: ${spacing[mx] || mx + 'px'};
    `}

  ${({ my, theme: { spacing } }) =>
    my &&
    `
      margin-top: ${spacing[my] || my + 'px'};
      margin-bottom: ${spacing[my] || my + 'px'};
    `}

  ${({ p, theme: { spacing } }) => p && `padding: ${spacing[p] || p + 'px'};`}

  ${({ pt, theme: { spacing } }) =>
    pt && `padding-top: ${spacing[pt] || pt + 'px'};`}

  ${({ pr, theme: { spacing } }) =>
    pr && `padding-right: ${spacing[pr] || pr + 'px'};`}

  ${({ pb, theme: { spacing } }) =>
    pb && `padding-bottom: ${spacing[pb] || pb + 'px'};`}

  ${({ pl, theme: { spacing } }) =>
    pl && `padding-left: ${spacing[pl] || pl + 'px'};`}

  ${({ px, theme: { spacing } }) =>
    px &&
    `
      padding-left: ${spacing[px] || px + 'px'};
      padding-right: ${spacing[px] || px + 'px'};
    `}

  ${({ py, theme: { spacing } }) =>
    py &&
    `
      padding-top: ${spacing[py] || py + 'px'};
      padding-bottom: ${spacing[py] || py + 'px'};
    `}
`

export const defaultPropsPropTypes = {
  override: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  m: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ml: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mx: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  my: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  p: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  px: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  py: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

export default defaultProps
