/* React */
import React from 'react'

const SVGLogoFooter = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      viewBox='0 0 32 32'
    >
      <defs>
        <path id='a' d='M.081.114h11.913V23.94H.08z' />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <g transform='translate(20)'>
          <mask id='b' fill='#fff'>
            <use xlinkHref='#a' />
          </mask>
          <path
            fill='#DDE9F1'
            d='M.081.114v4h7.94v7.942H.08v11.883h11.913V.114z'
            mask='url(#b)'
          />
        </g>
        <path
          fill='#DAE4ED'
          d='M16 20a4 4 0 1 0-8 0 4 4 0 0 0 8 0M8 12h12V4H8z'
        />
        <path
          fill='#DAE4ED'
          d='M5.076 27.069V1H2.953v26.069H0v2.057h2.953V32h2.123v-2.874H32v-2.057z'
        />
      </g>
    </svg>
  )
}

export default SVGLogoFooter
