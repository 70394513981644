import styled from 'styled-components'

import Alert from '../Alert'

export const Background = styled.div`
  background-color: rgba(0, 0, 0, 0.4);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
`

export const Wrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: ${({ bodyWidth }) => (bodyWidth ? bodyWidth + 'px' : '632px')};
  max-width: calc(100% - ${({ theme: { spacing } }) => spacing.medium} * 2);
  max-height: calc(100vh - ${({ theme: { spacing } }) => spacing.medium} * 2);
  overflow-y: auto;
`

export const StyledAlert = styled(Alert)`
  box-shadow: 8px 8px 40px 0 rgba(0, 0, 0, 0.5);
  margin-bottom: ${({ theme: { spacing } }) => spacing.small};
`

export const Body = styled.div`
  box-shadow: 8px 8px 40px 0 rgba(0, 0, 0, 0.5);
  background-color: ${({ theme: { colors } }) =>
    colors.surface.secondary.lightness};
  border-radius: ${({ theme: { radius } }) => radius.xsmall};
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme: { spacing } }) => spacing.medium};
  border-bottom: 1px solid
    ${({ theme: { colors } }) => colors.surface.primary.dark};

  @media (min-width: 576px) {
    padding: ${({ theme: { spacing } }) => spacing.large};
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: ${({ theme: { spacing } }) => spacing.medium};
  }
`

export const Title = styled.div`
  font-weight: 700;
  width: calc(100% - 40px);
`

export const ModalContent = styled.div`
  padding: ${({ theme: { spacing } }) => spacing.medium};

  @media (min-width: 576px) {
    padding: ${({ theme: { spacing } }) => spacing.large};
  }
`

export const ModalActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  background-color: ${({ theme: { colors } }) => colors.surface.primary.dark};
  padding: ${({ theme: { spacing } }) => spacing.medium};
  border-radius: ${({ theme: { radius } }) =>
    `0 0 ${radius.xsmall} ${radius.xsmall}`};

  @media (min-width: 576px) {
    padding: ${({ theme: { spacing } }) => spacing.large};
  }

  button:not(:first-child) {
    margin-left: ${({ theme: { spacing } }) => spacing.medium};
  }
`
