/* Types */
import {
  BOARD_REQUESTED,
  BOARD_SUCCEEDED,
  SET_BOARD_INFO,
  SET_FILTERS,
  SET_SAVE_REPORT_MODAL,
  MAIN_DATA_REQUESTED,
  ARCHIVED_REQUESTED,
  SUBJECT_ARCHIVED,
  SUBJECT_UNARCHIVED,
  SUBJECT_UPDATED,
  PATCH_TERM_RISK_REQUESTED,
  SHELVE_TERM_REQUESTED,
  CHART_REQUESTED,
  SET_LIST_PAGE,
  SET_SEARCH_QUERY,
  SET_ACTIVE_UNIT,
  RESET_PROPS,
  RESET_STATE
} from './action-types'

export const getAndSetBoard = ({ boardType, boardId }) => ({
  type: BOARD_REQUESTED,
  boardType,
  boardId,
  params: { boardId }
})

export const setBoard = ({ boardType, payload }) => ({
  type: BOARD_SUCCEEDED,
  boardType,
  payload
})

export const setBoardInfo = ({ payload }) => ({
  type: SET_BOARD_INFO,
  payload
})

export const getAndSetChart = () => ({
  type: CHART_REQUESTED
})

export const getAndSetMainData = () => ({
  type: MAIN_DATA_REQUESTED
})

export const getAndSetArchived = () => ({
  type: ARCHIVED_REQUESTED
})

export const archiveSubjectAndObservations = ({
  subjectId,
  subjectType,
  relatesIds,
  archivedAlreadyRequested
}) => ({
  type: SUBJECT_ARCHIVED,
  subjectId,
  subjectType,
  relatesIds,
  archivedAlreadyRequested
})

export const unarchiveSubjectAndObservations = ({
  subjectId,
  subjectType,
  relatesIds
}) => ({
  type: SUBJECT_UNARCHIVED,
  subjectId,
  subjectType,
  relatesIds
})

export const updateSubject = ({
  subjectId,
  subjectType,
  activeUnit,
  values
}) => ({
  type: SUBJECT_UPDATED,
  subjectId,
  subjectType,
  activeUnit,
  values
})

export const setFilters = (filters) => ({
  type: SET_FILTERS,
  filters
})

export const setListPage = (page, subjectType) => ({
  type: SET_LIST_PAGE,
  page,
  subjectType
})

export const setSearchQuery = (searchQuery, subjectType) => ({
  type: SET_SEARCH_QUERY,
  searchQuery,
  subjectType
})

export const setSaveBoardModal = ({
  isOpen,
  proceedFn,
  enableProceedWithoutSaving
}) => ({
  type: SET_SAVE_REPORT_MODAL,
  isOpen,
  proceedFn,
  enableProceedWithoutSaving
})

export const editTermRisk = ({ termId, risk, callback }) => ({
  type: PATCH_TERM_RISK_REQUESTED,
  reqParams: { termId, risk },
  callback
})

export const shelveTerm = ({ termId, archived, callback }) => ({
  type: SHELVE_TERM_REQUESTED,
  reqParams: { termId, archived },
  callback
})

export const setActiveUnit = (activeUnit) => ({
  type: SET_ACTIVE_UNIT,
  activeUnit
})

/* Reseta uma ou mais prop's > array de string's */
export const resetProps = (props) => ({
  type: RESET_PROPS,
  props
})

/* Reseta tudo para o state inicial */
export const resetState = () => ({ type: RESET_STATE })
