/* React */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'

/* Components */
import { CustomText, Pagination, defaultAvatar } from '@zeta/ui/src'

import ListItem, { getInfos } from 'components/ListItem'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'
import { path, equals } from 'ramda'
import { getPathPrefix, logAmplitudeEvent } from '@zeta/helpers'
import {
  getPicture,
  paginateItems,
  formatSubjectsList,
  filterObservations
} from 'helpers'
import { filterSubjectsBySearchQuery, filterSubjectsToList } from '../helpers'

/* Action Creators */
import { setListPage } from 'store/reducers/data/action-creators'

/* Selectors */
const subjectsToListSelector = createSelector(
  (state) => state.data.observations,
  (state) => state.data.subjects,
  (state) => state.data.filters,
  (state) => state.data.searchQuery,
  (state) => state.data.activeUnit,
  (_, subjectType) => subjectType,
  (observations, subjects, filters, searchQuery, activeUnit, subjectType) => {
    if (path([activeUnit, subjectType], searchQuery)) {
      return filterSubjectsBySearchQuery(
        formatSubjectsList(
          path([activeUnit, subjectType], observations) || [],
          path([activeUnit, subjectType], subjects) || []
        ),
        searchQuery[activeUnit][subjectType]
      )
    }

    if (filters && !equals(filters, {})) {
      const filteredObservations = filterObservations(
        path([activeUnit, subjectType], observations) || [],
        filters,
        path([activeUnit, subjectType], subjects) || []
      )

      return filterSubjectsToList(
        filteredObservations,
        formatSubjectsList(
          filteredObservations,
          path([activeUnit, subjectType], subjects) || []
        )
      )
    }

    return filterSubjectsToList(
      path([activeUnit, subjectType], observations) || [],
      formatSubjectsList(
        path([activeUnit, subjectType], observations) || [],
        path([activeUnit, subjectType], subjects) || []
      )
    )
  }
)

// const paginatedItemsSelector = createSelector(
//   (state, subjectType) => subjectsToListSelector(state, subjectType),
//   state => state.data.listPage,
//   state => state.data.activeUnit,
//   (_, subjectType) => subjectType,
//   (_, __, perPage) => perPage,
//   (subjectsToList, listPage, activeUnit, subjectType, perPage) =>
//     paginateItems(subjectsToList, listPage[activeUnit][subjectType], perPage)
// )

const ListItems = ({
  /* router */
  history,
  computedMatch,
  /* props */
  subjectType
}) => {
  const dispatch = useDispatch()

  const listPage = useSelector((state) => state.data.listPage)
  const activeUnit = useSelector((state) => state.data.activeUnit)
  const subjectsToList = useSelector((state) =>
    subjectsToListSelector(state, subjectType)
  )
  // const paginatedItems = useSelector(state => paginatedItemsSelector(state, perPage))

  const perPage = 15

  return (
    <>
      {paginateItems(
        subjectsToList,
        listPage[activeUnit][subjectType],
        perPage
      ).map((subject) => (
        <ListItem
          profile={{
            name: subject.name,
            thumbnail:
              getPicture(subject.registration_data.linkedin_image) ||
              getPicture(subject.registration_data.twitter_image) ||
              defaultAvatar
          }}
          risks={{
            1: {
              total: path(['1', 'total'], subject) || 0,
              facebook: path(['1', 'Facebook'], subject) || false,
              twitter: path(['1', 'Twitter'], subject) || false,
              linkedin: path(['1', 'Linkedin'], subject) || false,
              instagram: path(['1', 'Instagram'], subject) || false,
              youtube: path(['1', 'Youtube'], subject) || false,
              site: path(['1', 'Site'], subject) || false
            },
            2: {
              total: path(['2', 'total'], subject) || 0,
              facebook: path(['2', 'Facebook'], subject) || false,
              twitter: path(['2', 'Twitter'], subject) || false,
              linkedin: path(['2', 'Linkedin'], subject) || false,
              instagram: path(['2', 'Instagram'], subject) || false,
              youtube: path(['2', 'Youtube'], subject) || false,
              site: path(['2', 'Site'], subject) || false
            },
            3: {
              total: path(['3', 'total'], subject) || 0,
              facebook: path(['3', 'Facebook'], subject) || false,
              twitter: path(['3', 'Twitter'], subject) || false,
              linkedin: path(['3', 'Linkedin'], subject) || false,
              instagram: path(['3', 'Instagram'], subject) || false,
              youtube: path(['3', 'Youtube'], subject) || false,
              site: path(['3', 'Site'], subject) || false
            }
          }}
          infos={() => getInfos(subjectType, subject)}
          onClick={() => {
            history.push(
              `${getPathPrefix(computedMatch)}/${subjectType}/individual/${
                subject.id
              }`
            )

            logAmplitudeEvent({
              event: 'Clique em perfil',
              eventProperties: {
                'ID do perfil': subject.id,
                Nome: subject.name,
                Base: subjectType,
                Local: 'Listagem'
              }
            })
          }}
          key={uuidv4()}
        />
      ))}

      {subjectsToList.length > 0 && (
        <Pagination
          page={listPage[activeUnit][subjectType]}
          setPageFn={(page) => dispatch(setListPage(page, subjectType))}
          perPage={perPage}
          totalItems={subjectsToList.length}
          scrollAnchor="listItems"
          my="xlarge"
        />
      )}

      {subjectsToList.length === 0 && (
        <CustomText size="0.875rem">Nenhum perfil a ser exibido.</CustomText>
      )}
    </>
  )
}

export default ListItems
