/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Styled */
import { Wrapper, Item, Text } from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'

const TabsAlt = ({ items, ...rest }) => {
  return (
    <Wrapper {...rest}>
      {items.map((item) =>
        item ? (
          <Item
            type='button'
            onClick={item.onClickFn}
            isActive={item.isActive}
            key={uuidv4()}
          >
            {item.icon}

            <Text isActive={item.isActive}>{item.text}</Text>
          </Item>
        ) : null
      )}
    </Wrapper>
  )
}

TabsAlt.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClickFn: PropTypes.func.isRequired,
      isActive: PropTypes.bool.isRequired
    })
  )
}

export default TabsAlt
