/* React */
import React from 'react'

/* Components */
import Flatpickr from 'react-flatpickr'

/* Styled */
import { Wrapper, InputGroup, Label } from './styled'

const DateRange = ({ values, setFilterValue, startKey, endKey, locale }) => {
  return (
    <Wrapper>
      <InputGroup>
        <Label>Data inicial:</Label>

        <Flatpickr
          value={values[startKey]}
          onChange={(date) => setFilterValue(startKey, date[0])}
          options={{
            dateFormat: 'd/m/Y',
            maxDate: values[endKey],
            locale
          }}
        />
      </InputGroup>

      <InputGroup>
        <Label>Data final:</Label>

        <Flatpickr
          value={values[endKey]}
          onChange={(date) => setFilterValue(endKey, date[0])}
          options={{
            dateFormat: 'd/m/Y',
            minDate: values[startKey],
            locale
          }}
        />
      </InputGroup>
    </Wrapper>
  )
}

export default DateRange
