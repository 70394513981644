import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-bottom: ${({ theme: { spacing } }) => spacing.medium};
`

export const Label = styled.label`
  display: block;
  font-weight: 700;
  margin-bottom: ${({ theme: { spacing } }) => spacing.xsmall};
`

export const InputWrapper = styled.div`
  position: relative;

  svg {
    fill: ${({ theme: { colors } }) => colors.brand.primary.medium};
    width: 15px;
    position: absolute;
    left: ${({ theme: { spacing } }) => spacing.small};
    top: calc(50% - 1px);
    transform: translateY(-50%);
  }
`

export const Input = styled.input`
  width: 100%;
  border: 0;
  font-size: 0.75rem;
  line-height: 1.5rem;
  border-radius: ${({ theme: { radius } }) => radius.xsmall};
  padding: ${({ theme: { spacing } }) => spacing.small};
  ${({ hasIcon, theme: { spacing } }) => hasIcon && `padding-left: calc(${spacing.medium} + 15px);`}
  background-color: ${({ theme: { colors } }) => colors.surface.primary.light};
  border-bottom: 2px solid;
  border-bottom-color: ${({ theme: { colors } }) => colors.surface.primary.medium};
  color: ${({ theme: { colors } }) => colors.brand.tertiary.medium};

  &:focus {
    outline: 0;
    border-bottom-color: ${({ theme: { colors } }) => colors.brand.primary.medium};
  }

  ${props => props.hasError && `
    border-bottom-color: ${props.theme.colors.feedback.red.darkness} !important;
    + svg {
      fill: ${props.theme.colors.feedback.red.darkness};
    }
  `}
`

export const ErrorMessage = styled.div`
  font-size: 0.75rem;
  color: ${({ theme: { colors } }) => colors.feedback.red.darkness};
`

export const SuccessMessage = styled.div`
  font-size: 0.875rem;
  color: ${({ theme: { colors } }) => colors.feedback.green.darkness};
`
