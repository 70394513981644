import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.table`
  width: 100%;
  text-align: left;
  color: ${({ theme: { colors } }) => colors.type.medium};
  border-collapse: collapse;
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  border-bottom: 0;

  ${defaultProps}
`

export const HeaderRow = styled.tr``

export const HeaderCell = styled.th`
  font-size: 0.75rem;
  font-weight: 400;
  text-transform: uppercase;
  background-color: ${({ theme: { colors } }) => colors.surface.primary.medium};
  height: 48px;
  padding: 0 ${({ theme: { spacing } }) => spacing.medium};
  white-space: nowrap;

  ${({ stickyHeader }) => stickyHeader && `
    position: sticky;
    top: -1px;
    z-index: 1;
  `}

  &:first-child {
    ${({ stickyFirstCell, theme }) => stickyFirstCell
      ? `
          left: -1px;
          z-index: 2;
          background-color: ${theme.colors.surface.primary.dark};
        `
      : ''}
  }
`

export const HeaderCellBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

  ${({ isActive, theme: { colors } }) =>
    isActive &&
    `
      color: ${colors.brand.primary.medium};
      font-weight: 700;
    `}
`

export const BodyRow = styled.tr`
  ${({ onClick, theme: { colors } }) =>
    onClick &&
    `
    cursor: pointer;

    &:hover {
      ${BodyCell} {
        background-color: ${colors.brand.primary.medium};
        color: ${colors.surface.secondary.lightness};
        transition: all 0.3s;
      }
    }
  `}
`

export const BodyCell = styled.td`
  font-size: 0.875rem;
  padding: ${({ theme: { spacing } }) => `${spacing.small} ${spacing.medium}`};
  background-color: ${({ theme: { colors } }) =>
    colors.surface.secondary.lightness};
  border-bottom: 1px solid
    ${({ theme: { colors } }) => colors.surface.primary.dark};

  ${({ isActive }) => isActive && 'font-weight: 700;'}
  ${({ noWrap }) => noWrap && 'white-space: nowrap;'}

  &:first-child {
    ${({ stickyFirstCell, theme }) => stickyFirstCell
    ? `
        position: sticky;
        left: -1px;
        background-color: ${theme.colors.lightGray['100']};
      `
    : ''}
  }
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
