import styled from 'styled-components'

import { Card } from '@zeta/ui/src'

export const Wrapper = styled.div`
  width: 304px;
  min-height: 100vh;
  padding: ${({ theme: { spacing } }) => `${spacing.large} ${spacing.medium}`};
  background-color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  margin-left: 88px;
  border-right: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};

  @media (max-width: 1199px) {
    display: none;
  }
`

export const ButtonCard = styled(Card)`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > button {
    margin-left: ${({ theme: { spacing } }) => spacing.medium};
  }
`
