/* React */
import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* Components */
import { Formik, Form } from 'formik' // Field

import {
  Alert,
  Icon,
  // FieldGroup,
  DropdownSelect,
  Button,
  FlexJustifyEnd
} from '@zeta/ui/src'

import TermEditionModal from './TermEditionModal'

/* Styled */
import {
  Wrapper,
  Header,
  Content,
  CurrentValueGroup,
  CurrentValueLabel,
  CurrentValue,
  FieldsRow
} from './styled'

const getVisibleRisk = risk => {
  if (risk === 1 || risk === '1') return 'Baixo risco'
  if (risk === 2 || risk === '2') return 'Médio risco'
  if (risk === 3 || risk === '3') return 'Alto risco'
}

const EditTermForm = ({
  /* props */
  setIsEditFormOpen,
  term,
  reload,
  setReload
}) => {
  const [isTermEditionModalVisible, setIsTermEditionModalVisible] = useState(false)
  const [newTerm, setNewTerm] = useState({ ...term })

  return (
    <>
      <Alert mb='small'>
        Alterar o termo <strong>não</strong> influenciará em coletas e classificações já feitas. Na próxima coleta, as novas informações passam a ser atualizadas na interface.<br />
        Para que as alterações feitas entrem em vigor na próxima coleta, faça as revisões necessárias <strong>até o primeiro dia do próximo mês</strong>.
      </Alert>

      <Wrapper>
        <Header>Alterações do termo</Header>

        <Content>
          <FieldsRow>
            {/* <CurrentValueGroup>
              <CurrentValueLabel>Termo atual:</CurrentValueLabel>
              <CurrentValue>{term.term}</CurrentValue>
            </CurrentValueGroup> */}

            <CurrentValueGroup>
              <CurrentValueLabel>Grau de risco atual:</CurrentValueLabel>
              <CurrentValue>{getVisibleRisk(term.risk)}</CurrentValue>
            </CurrentValueGroup>
          </FieldsRow>

          <Formik
            initialValues={{ risk: '' }}
            onSubmit={values => {
              setNewTerm({ ...newTerm, risk: values.risk })
              setIsTermEditionModalVisible(true)
            }}
          >
            {props => {
              const { values, setFieldValue, handleSubmit } = props

              return (
                <Form onSubmit={handleSubmit}>
                  <FieldsRow>
                    {/* <Field
                      inputElement='input'
                      type='text'
                      name='term'
                      label='Edição do termo:'
                      placeholder='Digite o termo'
                      component={FieldGroup}
                      mb='large'
                    /> */}

                    <DropdownSelect
                      items={[
                        {
                          text: 'Baixo risco',
                          fn: () => setFieldValue('risk', 1)
                        },
                        {
                          text: 'Médio risco',
                          fn: () => setFieldValue('risk', 2)
                        },
                        {
                          text: 'Alto risco',
                          fn: () => setFieldValue('risk', 3)
                        }
                      ]}
                      activeIndex={values.status - 1}
                      label='Edição do grau de risco:'
                      displayText='Selecione o risco'
                      mb='large'
                    />
                  </FieldsRow>

                  <FlexJustifyEnd mx='large'>
                    <Button
                      onClick={() => setIsEditFormOpen(false)}
                      red
                    >
                      <Icon icon='close-outline' />
                      <span>Cancelar</span>
                    </Button>

                    <Button
                      type='submit'
                      disabled={!values.risk || values.risk === term.risk}
                      ml='medium'
                    >
                      <Icon icon='save-outline' />
                      <span>Salvar</span>
                    </Button>
                  </FlexJustifyEnd>
                </Form>
              )
            }}
          </Formik>
        </Content>
      </Wrapper>

      <TermEditionModal
        isTermEditionModalVisible={isTermEditionModalVisible}
        setIsTermEditionModalVisible={setIsTermEditionModalVisible}
        term={term}
        newTerm={newTerm}
        setIsEditFormOpen={setIsEditFormOpen}
        reload={reload}
        setReload={setReload}
      />
    </>
  )
}

EditTermForm.propTypes = {
  setIsEditFormOpen: PropTypes.func.isRequired,
  term: PropTypes.shape({
    term: PropTypes.string,
    risk: PropTypes.oneOf([1, 2, 3, '1', '2', '3']),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired,
  reload: PropTypes.bool,
  setReload: PropTypes.func
}

export default EditTermForm
