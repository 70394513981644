import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.button.attrs(() => ({
  type: 'button'
}))`
  display: flex;
  align-items: center;
  color: ${({ theme: { colors } }) => colors.brand.primary.medium};
  font-size: 0.875rem;

  span {
    margin-left: 3px;
    transform: translateY(1px);
  }

  ${({ disabled, theme }) =>
    disabled &&
    `
    cursor: not-allowed;

    svg {
      fill: ${theme.colors.surface.primary.darkness};
    }
  `}

  ${defaultProps}
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
