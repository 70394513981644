/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Styled */
import { Wrapper, Item, Text, Count } from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'

const Tabs = ({ items, ...rest }) => {
  return (
    <Wrapper {...rest}>
      {items.map((item) =>
        item ? (
          <Item
            type='button'
            onClick={item.onClickFn}
            isActive={item.isActive}
            key={uuidv4()}
          >
            {item.icon}

            <Text isActive={item.isActive}>{item.text}</Text>

            {item.count && item.count >= 0 && <Count>{item.count}</Count>}
          </Item>
        ) : null
      )}
    </Wrapper>
  )
}

Tabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.node,
      text: PropTypes.string.isRequired,
      count: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      onClickFn: PropTypes.func.isRequired,
      isActive: PropTypes.bool.isRequired
    })
  )
}

export default Tabs
