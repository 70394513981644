/* React */
import React from 'react'
import { useSelector } from 'react-redux'

/* Components */
import { SVGLogoIcon, Icon } from '@zeta/ui/src'

import LinkWithSaveBoard from 'components/routeHelpers/LinkWithSaveBoard'

/* Styled */
import {
  LogoWrapper,
  Wrapper,
  NavWrapper,
  ItemWrapper,
  MobileLinkWrapper,
  LogoutLinkWrapper
} from './styled'

/* Constants */
// import { features } from 'constants/config'
import { paths } from '@zeta/user/src/constants/config'

const Menu = ({ setActiveTab }) => {
  const board = useSelector((state) => state.data.board)
  // const userPermission = useSelector(state => state.user.permission)

  return (
    <Wrapper>
      <LogoWrapper>
        <SVGLogoIcon />
      </LogoWrapper>

      <NavWrapper>
        {/* Painéis */}
        {/* {(userPermission === 'admin' || userPermission === 'moderator') && */}
        <LinkWithSaveBoard component={ItemWrapper} to={'/paineis'}>
          <Icon icon={'grid-outline'} />
          <p>Painéis</p>
        </LinkWithSaveBoard>

        {/* Dicionários */}
        {/* {(userPermission === 'admin' || userPermission === 'moderator') && */}
        <LinkWithSaveBoard component={ItemWrapper} to={'/dicionarios'}>
          <Icon icon={'message-circle-outline'} />
          <p>Dicionários</p>
        </LinkWithSaveBoard>

        {/* Configurações */}
        <LinkWithSaveBoard
          component={ItemWrapper}
          to={paths.userConfigurations}
        >
          <Icon icon={'settings-outline'} />
          <p>Configurações</p>
        </LinkWithSaveBoard>

        {/* Filtros (apenas mobile e apenas quando houver um "board") */}
        {board && (
          <MobileLinkWrapper>
            <ItemWrapper
              type={'button'}
              onClick={() => setActiveTab('filters')}
            >
              <Icon icon={'options-2-outline'} />
              <p>Filtros</p>
            </ItemWrapper>
          </MobileLinkWrapper>
        )}

        {/* Sair */}
        <LogoutLinkWrapper>
          <LinkWithSaveBoard component={ItemWrapper} to={paths.signOut}>
            <Icon icon={'log-out-outline'} />
            <p>Sair</p>
          </LinkWithSaveBoard>
        </LogoutLinkWrapper>
      </NavWrapper>
    </Wrapper>
  )
}

export default Menu
