import styled from 'styled-components'

import { defaultProps, defaultPropsPropTypes } from '@zeta/ui/src'

const BoardStatusBadge = styled.div`
  padding: ${({ theme: { spacing } }) => `${spacing.xsmall} ${spacing.medium}`};
  border-radius: ${({ theme: { radius } }) => radius.xsmall};
  font-size: 0.75rem;
  font-weight: 700;
  display: inline-flex;

  ${({ status, theme: { colors } }) =>
    status === 1 &&
    `
      background-color: ${colors.brand.primary.lightness};
      color: ${colors.brand.primary.dark};
    `}

  ${({ status, theme: { colors } }) =>
    status === 2 &&
    `
      background-color: ${colors.brand.secondary.lightness};
      color: ${colors.brand.secondary.dark};
    `}

  ${defaultProps}
`

BoardStatusBadge.propTypes = {
  ...defaultPropsPropTypes
}

export default BoardStatusBadge
