const helpers = {
  /**
   *
   * Remove hits de termos arquivados das publicações, e se a publicação não tiver nenhum hit de termo ativo, remove a publicação também
   * @param {Array[Object]} publications
   * @return {Array[Object]}
   *
   */
  removeArchivedHits: publications =>
    publications.reduce((acc, publication) => {
      const hits = publication.hit.reduce((acc, hit) => !hit.word.archived ? acc.concat(hit) : acc, [])

      const updatedPublication = {
        ...publication,
        hit: hits
      }

      return hits.length === 0 ? acc : acc.concat(updatedPublication)
    }, []),

  /**
   *
   * Executa a função removeArchivedHits para cada array de publications de cada rede social
   * @param {Object} socialsPublications
   * @return {Object}
   *
   */
  removeSocialsArchivedHits: socialsPublications =>
    Object.keys(socialsPublications).reduce((acc, social) => {
      return { ...acc, [social]: removeArchivedHits(socialsPublications[social]) }
    }, {})
}

export const {
  removeArchivedHits,
  removeSocialsArchivedHits
} = helpers

export default helpers
