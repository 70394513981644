import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.table`
  width: 100%;
  font-size: 0.75rem;
  color: ${({ theme: { colors } }) => colors.type.medium};
  border-collapse: collapse;

  ${defaultProps}
`

export const HeaderRow = styled.tr``

export const HeaderCell = styled.th`
  font-weight: 700;
  background-color: ${({ theme: { colors } }) =>
    colors.brand.primary.lightness};
  padding: ${({ theme: { spacing } }) => spacing.small};
  white-space: nowrap;
  text-align: ${({ textAlign }) => textAlign || 'left'};
`

export const BodyCell = styled.td`
  padding: ${({ theme: { spacing } }) => spacing.small};
  ${({ colSpan, theme: { spacing } }) =>
    colSpan > 1 && `padding-top: ${spacing.medium}`};
  background-color: ${({ theme: { colors } }) =>
    colors.surface.secondary.lightness};
  border-top: ${({ colSpan, theme: { colors } }) =>
    colSpan > 1 ? 'none' : `1px solid ${colors.surface.primary.darkness}`};
  text-align: ${({ textAlign }) => textAlign || 'left'};
`

export const BodyRow = styled.tr`
  list-style-position: inside;

  a {
    color: ${({ theme: { colors } }) => colors.brand.primary.medium};
    text-decoration: underline;
  }

  &:first-child {
    ${BodyCell} {
      border-top: none;
    }
  }
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
