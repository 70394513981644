import styled from 'styled-components'
// import PropTypes from 'prop-types'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 0.75rem;
  font-weight: 700;
  padding: ${({ theme: { spacing } }) => `0 ${spacing.medium}`};
  height: 20px;
  line-height: 20px;
  border-radius: ${({ theme: { spacing } }) => spacing.medium};
  white-space: nowrap;

  ${({ bg, theme: { colors } }) =>
    `background-color: ${bg || colors.brand.primary.medium};`}
  ${({ color, theme: { colors } }) =>
    `color: ${color || colors.surface.secondary.lightness};`}

  /* button {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-1px);

    &:first-child {
      margin-right: ${({ theme: { spacing } }) => spacing.xsmall};
    }
    &:last-child {
      margin-right: ${({ theme: { spacing } }) => spacing.xsmall};
    }
    &:only-child {
      margin: 0;
    }
  }

  svg {
    width: ${({ theme: { spacing } }) => spacing.medium};
    height: ${({ theme: { spacing } }) => spacing.medium};
    ${({ color }) => color && `fill: ${color};`}

    &:first-child {
      margin-right: ${({ theme: { spacing } }) => spacing.xsmall};
    }
    &:last-child {
      margin-right: ${({ theme: { spacing } }) => spacing.xsmall};
    }
    &:only-child {
      margin: 0;
    }
  } */

  ${defaultProps}
`

Wrapper.propTypes = {
  // blue: PropTypes.bool,
  // purple: PropTypes.bool,
  // light: PropTypes.bool,
  // square: PropTypes.bool,
  // withBorder: PropTypes.bool,
  ...defaultPropsPropTypes
}

// export default Chip

// ${ ({ square }) => square && 'border-radius: 0;' }

// ${
//   ({ withBorder, theme: { colors } }) =>
//     withBorder && `border: 1px solid ${colors.surface.primary.dark};`
// }
// ${
//   ({ blue, theme: { colors } }) =>
//     blue && `background-color: ${colors.brand.primary.medium};`
// }

// ${
//   ({ purple, theme: { colors } }) =>
//     purple && `background-color: ${colors.brand.secondary.medium};`
// }

// ${
//   ({ red, theme: { colors } }) =>
//     red && `background-color: ${colors.feedback.red.dark};`
// }

// ${
//   ({ green, theme: { colors } }) =>
//     green && `background-color: ${colors.feedback.green.dark};`
// }

// ${
//   ({ blue, purple, red, green, theme: { colors } }) =>
//     (blue || purple || red || green) && `color: ${colors.surface.secondary.lightness};`
// }

// ${ ({ light, theme: { colors } }) => light && `color: ${colors.surface.primary.darkness};` }
