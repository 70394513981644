/* React */
import React from 'react'

/* Styled */
import { Wrapper } from './styled'

const CustomBlock = ({ children, ...rest }) => {
  return <Wrapper {...rest}>{children}</Wrapper>
}

export default CustomBlock
