import { update } from 'ramda'

/* Constants */
import {
  PUBLICATIONS_REQUESTED,
  SET_PUBLICATIONS,
  SET_HIT_TO_CLASSIFY,
  PATCH_OBSERVATION_REQUESTED,
  OBSERVATION_PATCHED,
  SET_VIEW_FILTERED_PUBLICATIONS,
  RESET_INDIVIDUAL
} from './action-types'

export const initialState = {
  isLoadingPublications: false,
  hasPublications: false,
  publicationsBySocialNetwork: null,
  mergedHits: null,
  biosBySocialNetwork: null,
  biosMergedHits: null,
  hasTextSites: false,
  textSites: null,
  textSitesMergedHits: null,
  isPatchingObservation: false,
  hitToClassify: null,
  viewFilteredPublications: true
}

const getObservableProp = (observableType) => {
  if (observableType === 'Publication' || observableType === 'Article')
    return 'publicationsBySocialNetwork'
  if (observableType === 'Bio' || observableType === 'Experience')
    return 'biosBySocialNetwork'
}

const getObservableMergedHitsProp = (observableType) => {
  if (observableType === 'Publication' || observableType === 'Article')
    return 'mergedHits'
  if (observableType === 'Bio' || observableType === 'Experience')
    return 'biosMergedHits'
}

const getUpdatedHits = (state, action) => {
  if (action.payload.data.observable_type === 'TextSite') {
    const i = state.textSitesMergedHits.findIndex(
      (hit) => hit.observation.id === action.observationId
    )

    return {
      textSitesMergedHits: update(
        i,
        { ...state.textSitesMergedHits[i], observation: action.payload.data },
        state.textSitesMergedHits
      )
    }
  }

  const observableMergedHitsProp = getObservableMergedHitsProp(
    action.payload.data.observable_type
  )

  const i = state[observableMergedHitsProp].findIndex(
    (hit) => hit.observation.id === action.observationId
  )

  return {
    [observableMergedHitsProp]: update(
      i,
      {
        ...state[observableMergedHitsProp][i],
        observation: action.payload.data
      },
      state[observableMergedHitsProp]
    )
  }
}

const getUpdatedPublications = (state, action) => {
  if (action.social === 'sites') {
    const textSiteIndex = state.textSites.findIndex((textSite) =>
      textSite.hit.some((hit) => hit.observation.id === action.observationId)
    )
    const textSite = state.textSites[textSiteIndex]

    const hitIndex = textSite.hit.findIndex(
      (hit) => hit.observation.id === action.observationId
    )

    const updatedTextSite = {
      ...textSite,
      hit: update(
        hitIndex,
        { ...textSite.hit[hitIndex], observation: action.payload.data },
        textSite.hit
      )
    }

    const updatedTextSites = update(
      textSiteIndex,
      updatedTextSite,
      state.textSites
    )

    return {
      textSites: updatedTextSites
    }
  }

  const observableProp = getObservableProp(action.payload.data.observable_type)

  const publicationIndex = state[observableProp][
    action.social
  ].findIndex((publication) =>
    publication.hit.some((hit) => hit.observation.id === action.observationId)
  )
  const publication = state[observableProp][action.social][publicationIndex]

  const hitIndex = publication.hit.findIndex(
    (hit) => hit.observation.id === action.observationId
  )

  const updatedPublication = {
    ...publication,
    hit: update(
      hitIndex,
      { ...publication.hit[hitIndex], observation: action.payload.data },
      publication.hit
    )
  }

  const updatedPublications = update(
    publicationIndex,
    updatedPublication,
    state[observableProp][action.social]
  )

  return {
    [observableProp]: {
      ...state[observableProp],
      [action.social]: updatedPublications
    }
  }
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PUBLICATIONS_REQUESTED:
      return {
        ...state,
        isLoadingPublications: action.isLoadingPublications
      }

    case SET_PUBLICATIONS:
      return {
        ...state,
        isLoadingPublications: false,
        hasPublications:
          action.mergedHits.length > 0 || action.biosMergedHits.length > 0,
        publicationsBySocialNetwork: action.publicationsBySocialNetwork,
        mergedHits: action.mergedHits,
        biosBySocialNetwork: action.biosBySocialNetwork,
        biosMergedHits: action.biosMergedHits,
        hasTextSites:
          action.textSitesMergedHits && action.textSitesMergedHits.length > 0,
        textSites: action.textSites,
        textSitesMergedHits: action.textSitesMergedHits
      }

    case SET_HIT_TO_CLASSIFY:
      return {
        ...state,
        hitToClassify: action.hitToClassify
      }

    case PATCH_OBSERVATION_REQUESTED:
      return {
        ...state,
        isPatchingObservation: true
      }

    case OBSERVATION_PATCHED:
      return {
        ...state,
        isPatchingObservation: false,
        hitToClassify: null,
        ...getUpdatedHits(state, action),
        ...getUpdatedPublications(state, action)
      }

    case SET_VIEW_FILTERED_PUBLICATIONS:
      return {
        ...state,
        viewFilteredPublications: action.bool
      }

    case RESET_INDIVIDUAL:
      return initialState

    default:
      return state
  }
}
