/* React */
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'

/* Components */
import { Redirect } from 'react-router-dom'

/* Action Creators */
import { signOut } from '../../store/reducers/user/action-creators'

const SignOut = ({ to }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(signOut())
  }, [])

  return <Redirect to={to} />
}

export default SignOut
