/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Styled */
import { Wrapper } from './styled'

const CustomText = ({ size, weight, children, ...rest }) => {
  return (
    <Wrapper size={size} weight={weight} {...rest}>
      {children}
    </Wrapper>
  )
}

CustomText.propTypes = {
  size: PropTypes.string,
  weight: PropTypes.oneOf(['400', '700']),
  color: PropTypes.string,
  align: PropTypes.oneOf(['left', 'center', 'right'])
}

CustomText.defaultProps = {
  size: '1rem',
  weight: '400'
}

export default CustomText
