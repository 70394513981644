import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.div`
  > svg {
    width: ${({ size, theme: { spacing } }) => spacing[size] || size + 'px'};
  }

  ${defaultProps}
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
