/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Recharts */
import {
  LineChart as RechartsLineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'

import CustomTooltip from './CustomTooltip'

/* Styled */
import { Wrapper } from './styled'

/* Helpers */
import { getColor } from './helpers'
import { v4 as uuidv4 } from 'uuid'

const LineChart = ({
  data,
  chartWidth,
  chartHeight,
  tickCount,
  margin,
  colors,
  allowDecimals,
  ...rest
}) => {
  return (
    <Wrapper {...rest}>
      <ResponsiveContainer width={chartWidth || '100%'} height={chartHeight}>
        <RechartsLineChart data={data} margin={margin} {...rest}>
          <Tooltip content={<CustomTooltip />} />
          <Legend iconType='circle' iconSize={8} />

          <CartesianGrid />

          <XAxis type='category' dataKey='name' />
          <YAxis
            type='number'
            tickCount={tickCount}
            allowDecimals={allowDecimals}
          />

          {Object.keys(data[0])
            .slice(1)
            .map((key, i) => (
              <Line
                dataKey={key}
                stroke={getColor(i, colors)}
                strokeWidth={2}
                key={uuidv4()}
                isAnimationActive={false}
              />
            ))}
        </RechartsLineChart>
      </ResponsiveContainer>
    </Wrapper>
  )
}

LineChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  chartWidth: PropTypes.number,
  chartHeight: PropTypes.number,
  tickCount: PropTypes.number,
  margin: PropTypes.object,
  colors: PropTypes.array
}

LineChart.defaultProps = {
  // data: [
  //   { name: '21/09', 'Item 1': 500, 'Item 2': 200, 'Item 3': 300 },
  //   { name: '22/09', 'Item 1': 100, 'Item 2': 400, 'Item 3': 500 },
  //   { name: '23/09', 'Item 1': 200, 'Item 2': 600, 'Item 3': 200 },
  //   { name: '24/09', 'Item 1': 500, 'Item 2': 200, 'Item 3': 300 },
  //   { name: '25/09', 'Item 1': 500, 'Item 2': 400, 'Item 3': 100 },
  //   { name: '29/09', 'Item 1': 200, 'Item 2': 800, 'Item 3': 0 }
  // ],
  chartHeight: 250
}

export default LineChart
