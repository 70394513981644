import styled from 'styled-components'

import { defaultProps, defaultPropsPropTypes } from '@zeta/ui/src'

export const Wrapper = styled.div`
  display: inline-flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1;
  padding: ${({ theme: { spacing } }) => `0 ${spacing.medium}`};
  height: ${({ theme: { spacing } }) => spacing.large};
  white-space: nowrap;
  border-radius: ${({ theme: { spacing } }) => spacing.medium};
  background-color: ${({ risk, theme: { colors } }) =>
    colors.risk[risk].medium};
  color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};

  ${({ small }) => small && 'font-size: 0.75rem;'}

  ${defaultProps}
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
