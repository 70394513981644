/* Paths das rotas */
export const paths = {
  signIn: '/entrar',
  signOut: '/sair',
  forgotPassword: '/esqueci-minha-senha',
  setPassword: '/definir-senha',
  userConfigurations: '/configuracoes-usuario',
  initial: '/configuracoes-usuario'
}

/* API */
export const apiDomain = process.env.REACT_APP_API_DOMAIN

/* Amplitude */
export const amplitudeConfig = {
  key: process.env.REACT_APP_AMPLITUDE_KEY,
  company: process.env.REACT_APP_CLIENT
}

/* GTM */
export const gtmId = process.env.REACT_APP_GTM_KEY

/* Satismeter */
export const satismeterConfig = {
  writeKey: process.env.REACT_APP_SATISMETER_KEY
}

/* Auth */
export const authCookie = 'ORION_JWT'
