/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import Icon from '../Icon'

/* Styled */
import { Wrapper, Text } from './styled'

/* Constanst */
import theme from '../../theme/theme'

const Warning = ({ children, ...rest }) => (
  <Wrapper {...rest}>
    <Icon
      icon='info-outline'
      size='medium'
      fill={theme.colors.brand.primary.medium}
    />
    <Text>{children}</Text>
  </Wrapper>
)

Warning.propTypes = {
  children: PropTypes.string.isRequired
}

export default Warning
