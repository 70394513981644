/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import Icon from '../Icon'

/* Styled */
import { Form, Input, Submit } from './styled'

/* Constants */
import theme from '../../theme/theme'

const SearchForm = ({
  onSubmit,
  onChange,
  value,
  placeholder,
  bg,
  border,
  isDisabled,
  // clearAfterSubmit,
  ...rest
}) => {
  // const [value, setValue] = useState('')

  return (
    <Form
      // onSubmit={(e) => {
      //   e.preventDefault()
      //   onSubmit(value.trim())
      //   if (clearAfterSubmit) setValue('')
      // }}
      onSubmit={(e) => {
        e.preventDefault()
        if (onSubmit) onSubmit()
      }}
      bg={bg}
      border={border}
      {...rest}
    >
      <Input
        type='text'
        value={value}
        // onChange={(e) => setValue(e.target.value)}
        onChange={onChange}
        placeholder={placeholder}
      />

      <Submit type='submit' disabled={isDisabled || value === ''}>
        <Icon
          icon='search-outline'
          size={20}
          fill={theme.colors.brand.primary.medium}
        />
      </Submit>
    </Form>
  )
}

SearchForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  bg: PropTypes.string,
  isDisabled: PropTypes.bool
  // clearAfterSubmit: PropTypes.bool
}

export default SearchForm
