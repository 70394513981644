/* React */
import React from 'react'
import { useSelector } from 'react-redux'

/* Styled */
import { Wrapper } from './styled'

/* Parts */
import SelectedFilter from './SelectedFilter'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'
import { formatDate } from '@zeta/helpers'

/* Constants */
import {
  visibleLabels,
  selectionOptions,
  observationsFilterProps
} from 'constants/filters'

const SelectedFilters = () => {
  const filters = useSelector((state) => state.data.filters)

  return (
    <Wrapper>
      {Object.keys(filters)
        .filter((prop) =>
          observationsFilterProps.some(
            (observationFilterProp) => observationFilterProp === prop
          )
        )
        .map((key) => {
          if (Array.isArray(filters[key])) {
            return filters[key].map((filterValue) => (
              <SelectedFilter
                name={visibleLabels[key]}
                value={
                  selectionOptions[key]
                    ? selectionOptions[key].find(
                        (filterItem) => filterItem.dbValue == filterValue
                      ).visibleValue
                    : filterValue
                }
                key={uuidv4()}
              />
            ))
          }

          return (
            <SelectedFilter
              name={visibleLabels[key]}
              value={`${
                filters[key].start
                  ? 'maior que ' + formatDate(filters[key].start)
                  : ''
              }${filters[key].start && filters[key].end ? ' e ' : ''}${
                filters[key].end
                  ? 'menor que ' + formatDate(filters[key].end)
                  : ''
              }`}
              key={uuidv4()}
            />
          )
        })}
    </Wrapper>
  )
}

export default SelectedFilters
