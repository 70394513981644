/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import {
  CustomBlock,
  CustomText,
  Icon,
  FlexSpaceBetween,
  theme
} from '@zeta/ui/src'

import RiskBadge from 'components/RiskBadge'

/* Styled */
import {
  Wrapper,
  Info,
  IconWrapper,
  InfoTextWrapper,
  InfoTotal,
  InfoText,
  PositiveInfosBlock,
  PositiveInfosRow
} from './styled'

const RiskCard = ({
  risk,
  totalListed,
  totalListedLabel,
  totalPosts,
  totalPostsLabel,
  totalHits,
  totalHitsStatus0,
  totalHitsStatus1,
  totalHitsStatus2,
  totalHitsStatus3,
  totalHitsStatus4,
  totalHitsStatus5,
  totalHitsStatus6,
  totalHitsStatus7,
  hideHeader,
  ...rest
}) => {
  return (
    <Wrapper {...rest}>
      {!hideHeader &&
        <FlexSpaceBetween
          p='medium'
          override={`
          border-bottom: 1px solid ${theme.colors.surface.primary.dark};
        `}
        >
          {totalListed >= 0 && (
            <CustomText weight='700' pr='small' data-testid='headline'>
              Visão geral de
            </CustomText>
          )}

          <RiskBadge risk={risk} data-testid='riskBadge' />
        </FlexSpaceBetween>}

      <CustomBlock p='medium' pb='xsmall'>
        <Info>
          {totalListed >= 0 && (
            <IconWrapper risk={risk} data-testid='totalListedIcon'>
              <Icon icon='people-outline' />
            </IconWrapper>
          )}

          {totalPosts >= 0 && (
            <IconWrapper risk={risk} data-testid='totalPostsIcon'>
              <Icon icon='layout-outline' />
            </IconWrapper>
          )}

          <InfoTextWrapper>
            <InfoTotal risk={risk} data-testid='total'>
              {totalListed >= 0 ? totalListed : ''}
              {totalPosts >= 0 ? totalPosts : ''}
            </InfoTotal>{' '}
            <InfoText data-testid='totalLabel'>
              {totalListedLabel || totalPostsLabel}
            </InfoText>
          </InfoTextWrapper>
        </Info>

        <Info>
          <IconWrapper risk={risk}>
            <Icon icon='alert-triangle-outline' />
          </IconWrapper>

          <InfoTextWrapper>
            <InfoTotal risk={risk}>{totalHits}</InfoTotal>{' '}
            <InfoText>menções</InfoText>
          </InfoTextWrapper>
        </Info>

        <span data-testid='observationInfo'>
          <Info>
            <IconWrapper risk={risk}>
              <Icon icon='edit-outline' />
            </IconWrapper>

            <InfoTextWrapper>
              <InfoTotal risk={risk} data-testid='totalUnclassified'>
                {totalHitsStatus0}
              </InfoTotal>{' '}
              <InfoText>
                  menções <strong>não classificadas</strong>
              </InfoText>
            </InfoTextWrapper>
          </Info>

          <Info>
            <IconWrapper risk={risk}>
              <Icon icon='search-outline' />
            </IconWrapper>

            <InfoTextWrapper>
              <InfoTotal risk={risk}>
                {totalHitsStatus6}
              </InfoTotal>{' '}
              <InfoText>
                  menções <strong>em investigação</strong>
              </InfoText>
            </InfoTextWrapper>
          </Info>

          <Info>
            <IconWrapper risk={risk}>
              <Icon icon='close-circle-outline' />
            </IconWrapper>

            <InfoTextWrapper>
              <InfoTotal risk={risk}>{totalHitsStatus1}</InfoTotal>{' '}
              <InfoText>
                  menções <strong>improcedentes</strong>
              </InfoText>
            </InfoTextWrapper>
          </Info>

          <Info>
            <IconWrapper risk={risk}>
              <Icon icon='slash-outline' />
            </IconWrapper>

            <InfoTextWrapper>
              <InfoTotal risk={risk}>{totalHitsStatus7}</InfoTotal>{' '}
              <InfoText>
                  menções <strong>inconclusivas</strong>
              </InfoText>
            </InfoTextWrapper>
          </Info>

          <Info>
            <IconWrapper risk={risk}>
              <Icon icon='flag-outline' />
            </IconWrapper>

            <InfoTextWrapper>
              <InfoTotal risk={risk}>
                {totalHitsStatus2 + totalHitsStatus3 + totalHitsStatus4 + totalHitsStatus5}
              </InfoTotal>{' '}
              <InfoText>
                  menções <strong>procedentes</strong>
              </InfoText>
            </InfoTextWrapper>
          </Info>
        </span>
      </CustomBlock>

      <PositiveInfosBlock risk={risk} data-testid='positiveInfosBlock'>
        <CustomText
          size='0.75rem'
          weight='700'
          color={theme.colors.surface.secondary.lightness}
          mb='medium'
        >
          Classificação atual das menções procedentes
        </CustomText>

        <PositiveInfosRow>
          <Info>
            <IconWrapper risk={risk}>
              <Icon icon='flag-outline' />
            </IconWrapper>

            <InfoTextWrapper>
              <InfoTotal risk={risk}>{totalHitsStatus3}</InfoTotal>{' '}
              <InfoText>advertências</InfoText>
            </InfoTextWrapper>
          </Info>

          <Info>
            <IconWrapper risk={risk}>
              <Icon icon='flag-outline' />
            </IconWrapper>

            <InfoTextWrapper>
              <InfoTotal risk={risk}>{totalHitsStatus4}</InfoTotal>{' '}
              <InfoText>multas</InfoText>
            </InfoTextWrapper>
          </Info>
        </PositiveInfosRow>

        <PositiveInfosRow>
          <Info>
            <IconWrapper risk={risk}>
              <Icon icon='flag-outline' />
            </IconWrapper>

            <InfoTextWrapper>
              <InfoTotal risk={risk}>{totalHitsStatus5}</InfoTotal>{' '}
              <InfoText>orientações</InfoText>
            </InfoTextWrapper>
          </Info>

          <Info>
            <IconWrapper risk={risk}>
              <Icon icon='flag-outline' />
            </IconWrapper>

            <InfoTextWrapper>
              <InfoTotal risk={risk}>{totalHitsStatus2}</InfoTotal>{' '}
              <InfoText>nenhuma ação</InfoText>
            </InfoTextWrapper>
          </Info>
        </PositiveInfosRow>
      </PositiveInfosBlock>
    </Wrapper>
  )
}

RiskCard.propTypes = {
  risk: PropTypes.oneOf([1, 2, 3, '1', '2', '3', 'site']).isRequired,
  totalListed: PropTypes.number,
  totalListedLabel: PropTypes.string,
  totalPosts: PropTypes.number,
  totalPostsLabel: PropTypes.string,
  totalHits: PropTypes.number.isRequired,
  totalHitsStatus0: PropTypes.number,
  totalHitsStatus1: PropTypes.number,
  totalHitsStatus2: PropTypes.number,
  totalHitsStatus3: PropTypes.number,
  totalHitsStatus4: PropTypes.number,
  totalHitsStatus5: PropTypes.number,
  totalHitsStatus6: PropTypes.number,
  totalHitsStatus7: PropTypes.number,
  hideHeader: PropTypes.bool
}

export default RiskCard
