import styled from 'styled-components'

import { Icon } from '@zeta/ui/src'

export const Wrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  color: ${({ theme: { colors } }) => colors.brand.tertiary.light};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  padding: ${({ theme: { spacing } }) => spacing.medium};
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    padding-bottom: 0;
  }

  &:not(:first-child) {
    margin-top: ${({ theme: { spacing } }) => spacing.small};
  }
`

export const Thumbnail = styled.img`
  width: 104px;
  height: 104px;

  @media (min-width: 576px) {
    margin-right: ${({ theme: { spacing } }) => spacing.medium};
  }
`

export const SocialItem = styled.div`
  display: flex;
  padding: ${({ theme: { spacing } }) =>
    `0 ${spacing.medium} ${spacing.medium} ${spacing.medium}`};

  @media (max-width: 575px) {
    padding-right: 0;
  }

  @media (min-width: 576px) and (max-width: 991px) {
    padding-right: ${({ theme: { spacing } }) => spacing.xlarge};
  }

  @media (max-width: 991px) {
    padding-left: 0;
  }

  @media (min-width: 992px) {
    width: 33.333%;
  }
`

export const SocialIconWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.brand.primary.medium};
  width: ${({ theme: { spacing } }) => spacing.large};
  height: ${({ theme: { spacing } }) => spacing.large};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme: { spacing } }) => spacing.small};
`

export const SocialIcon = styled(Icon)`
  > svg {
    width: ${({ theme: { spacing } }) => spacing.medium};
    height: ${({ theme: { spacing } }) => spacing.medium};
    fill: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  }
`

export const ConnectionsWrapper = styled.div`
  width: calc(100% - ${({ theme: { spacing } }) => spacing.xlarge});
`

export const Connections = styled.div`
  font-weight: 700;
  margin-bottom: ${({ theme: { spacing } }) => spacing.xsmall};
`

export const ProfileLink = styled.a`
  font-size: 0.875rem;
  color: ${({ theme: { colors } }) => colors.brand.primary.medium};
  display: flex;
  align-items: center;
`

export const ProfileLinkIcon = styled(Icon)`
  > svg {
    width: 12px;
    height: 12px;
    fill: ${({ theme: { colors } }) => colors.brand.primary.medium};
    margin-left: ${({ theme: { spacing } }) => spacing.xsmall};
    transform: translateY(-1px);
  }
`

export const OfficeButton = styled.button.attrs(() => ({
  type: 'button'
}))`
  text-align: left;
`
