import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.brand.primary.lightness};
  border: 1px solid ${({ theme: { colors } }) => colors.brand.primary.dark};
  border-radius: ${({ theme: { radius } }) => radius.xsmall};
  margin-bottom: ${({ theme: { spacing } }) => spacing.xlarge};
`

export const Header = styled.div`
  font-weight: 700;
  border-bottom: 1px solid ${({ theme: { colors } }) => colors.brand.primary.dark};
  padding: ${({ theme: { spacing } }) => spacing.large};
`

export const Content = styled.div`
  padding: ${({ theme: { spacing } }) => `${spacing.large} 0`};
`

export const CurrentValueGroup = styled.div`
  margin-bottom: ${({ theme: { spacing } }) => spacing.large};
`

export const CurrentValueLabel = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: ${({ theme: { spacing } }) => spacing.xsmall};
`

export const CurrentValue = styled.div`
  width: 100%;
  height: 40px;
  line-height: 40px;
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark}};
  background-color: ${({ theme: { colors } }) => colors.type.lightness};
  padding: ${({ theme: { spacing } }) => `0 ${spacing.small}`};
  font-size: 0.75rem;
`

export const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    margin-left: ${({ theme: { spacing } }) => spacing.large};
    margin-right: ${({ theme: { spacing } }) => spacing.large};
    width: calc(100% - ${({ theme: { spacing } }) => spacing.large} * 2);

    /* @media (min-width: 768px) {
      width: calc(50% - ${({ theme: { spacing } }) => spacing.large} * 2);
    } */
  }
`
