/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import {
  HorizontalScroll,
  CustomText
} from '@zeta/ui/src'

import RiskBadge from 'components/RiskBadge'

/* Styled */
import { Wrapper, Header, Table } from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'

const TermsRankingTable = ({ risk, terms, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <Header>
        <CustomText weight='700'>
          Ranking dos termos de
        </CustomText>

        <RiskBadge risk={risk} />
      </Header>

      {terms.length > 0 ? (
        <HorizontalScroll>
          <Table risk={risk}>
            <thead>
              <tr>
                <th>Palavra</th>
                <th>Quantidade</th>
              </tr>
            </thead>

            <tbody>
              {terms.map((term) => (
                <tr key={uuidv4()}>
                  <td>{term.term}</td>
                  <td>{term.count}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </HorizontalScroll>
      ) : (
        <CustomText size='0.75rem' p='medium'>
            Nenhum termo identificado.
        </CustomText>
      )}
    </Wrapper>
  )
}

TermsRankingTable.propTypes = {
  risk: PropTypes.oneOf([1, 2, 3, '1', '2', '3']).isRequired,
  terms: PropTypes.arrayOf(
    PropTypes.shape({
      term: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired
    })
  )
}

export default TermsRankingTable
