import { axiosInstance } from '@zeta/user/src/api'

import store from 'store'
import { signOut } from '@zeta/user/src/store/reducers/user/action-creators'

/* Configura o erro de token expirado */
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (axiosInstance.defaults.headers.common['Authorization']) {
      if (error.response && error.response.status === 401) {
        alert('Sessão expirada. Faça o login novamente.')

        store.dispatch(signOut())
      } else {
        console.log(error)
      }
    }

    return Promise.reject(error)
  }
)

const getHeaders = () => ({
  headers: {
    'Content-Type': 'application/json'
  }
})

/***** BOARDS *****/

/**
 *
 * Retorna o index de boards
 * @return {Promise}
 *
 */
export const getBoardsList = () => axiosInstance.get('/reports', getHeaders())

/**
 *
 * Retorna os dados de um board
 * @param {Number} boardId
 * @return {Promise}
 *
 */
export const getBoard = ({ boardId }) =>
  axiosInstance.get(`/reports/${boardId}`, getHeaders())

/**
 *
 * Cria um board
 * @param {String} name
 * @param {Object} configBody {filters, etc.}
 * @return {Promise}
 *
 */
export const createBoard = ({
  name,
  // description,
  configBody
}) => {
  const body = {
    name,
    // description,
    config_body: configBody
  }

  return axiosInstance.post('/reports', body, getHeaders())
}

/**
 *
 * Salva um board
 * @param {Number} boardId
 * @param {Object} configBody {filters, etc.}
 * @return {Promise}
 *
 */
export const saveBoard = ({ boardId, name, configBody }) => {
  const body = {
    name,
    config_body: configBody
  }

  return axiosInstance.put(`/reports/${boardId}`, body, getHeaders())
}

/**
 *
 * Edita o nome de um board
 * @param {Number} boardId
 * @param {String} name
 * @return {Promise}
 *
 */
export const editBoard = ({
  boardId,
  name
  // description
}) => {
  const body = {
    name
    // description
  }

  return axiosInstance.put(`/reports/${boardId}`, body, getHeaders())
}

/**
 *
 * Deleta um board
 * @param {Number} boardId
 * @return {Promise}
 *
 */
export const deleteBoard = ({ boardId }) =>
  axiosInstance.delete(`/reports/${boardId}`, getHeaders())

/**
 *
 * Retorna o index de usuários de acordo com a permissão passada como parâmetro
 * @param {String} permission 'admin' / 'moderator' / 'standard' / 'editor'
 * @return {Promise}
 *
 */
// const getUsers = ({ permission }) =>
//   axiosInstance.get(`/permissions?permission=${permission}`, getHeaders())

/***** DICIONÁRIOS *****/

/**
 *
 * Retorna a lista de dicionários
 * @return {Promise}
 *
 */
export const getDictionaries = () =>
  axiosInstance.get('/dictionaries', getHeaders())

/**
 *
 * Retorna as informações de um dicionário
 * @param {Number} dictionaryId
 * @return {Promise}
 *
 */
export const getDictionaryInfo = ({ dictionaryId }) =>
  axiosInstance.get(`/dictionaries/${dictionaryId}`, getHeaders())

/**
 *
 * Retorna as informações relativas a observations de um dicionário
 * @param {Number} dictionaryId
 * @param {String} parameter // 'risk' / 'date'
 * @param {String} startDate // aaaa-mm-dd
 * @param {String} endDate // aaaa-mm-dd
 * @return {Promise}
 *
 */
export const getDictionaryObsData = ({
  dictionaryId,
  parameter,
  startDate,
  endDate,
  isDatePost
}) => {
  const params = `${startDate || endDate ? '?' : ''}${
    startDate ? `start_date=${startDate}` : ''
  }${startDate && endDate ? '&' : ''}${endDate ? `end_date=${endDate}` : ''}`

  if (parameter === 'risk') {
    return axiosInstance.get(
      `/dictionaries/${dictionaryId}/volumetry${
        isDatePost ? '_date_post' : ''
      }${params}`,
      getHeaders()
    )
  }

  if (parameter === 'date') {
    return axiosInstance.get(
      `/dictionaries/${dictionaryId}/period${
        isDatePost ? '_date_post' : ''
      }${params}`,
      getHeaders()
    )
  }
}

/**
 *
 * Retorna o csv de um dicionário
 * @param {Number} dictionaryId
 * @param {Boolean} archived
 * @return {Promise}
 *
 */
export const getDictionaryBlob = ({ dictionaryId, archived }) =>
  axiosInstance({
    method: 'get',
    url: `/dictionaries/${dictionaryId}/export?archived=${
      archived ? 'true' : 'false'
    }`,
    responseType: 'blob',
    APIContentType: 'text/csv',
    headers: {
      Authorization: store.getState().user.token
    }
  })

/**
 *
 * Retorna a lista de termos de um dicionário
 * @param {Number} dictionaryId
 * @param {Object} params
 * @return {Promise}
 *
 */
export const getTerms = ({ dictionaryId, params = {} }) => {
  const formattedParams = `?${params.page ? `page=${params.page}` : 'page=1'}${
    params.perPage ? `&per_page=${params.perPage}` : '&per_page=15'
  }${params.findWord ? `&find_word=${params.findWord}` : ''}${
    params.orderBy ? `&order_by=${params.orderBy}` : ''
  }${params.dscOrder ? `&dsc_order=${params.dscOrder}` : ''}${
    params.archived ? `&archived=${params.archived}` : ''
  }`

  return axiosInstance.get(
    `/dictionaries/${dictionaryId}/words${formattedParams}`,
    getHeaders()
  )
}

/**
 *
 * Cria um termo em um dicionário
 * @param {Number} dictionaryId
 * @param {String} term
 * @param {Number} risk
 * @return {Promise}
 *
 */
export const createTerm = ({ dictionaryId, term, risk }) => {
  const body = {
    word: {
      term,
      risk,
      dictionary_id: dictionaryId
    }
  }

  return axiosInstance.post('/words', body, getHeaders())
}

/**
 *
 * Edita um termo em um dicionário
 * @param {Number} termId
 * @param {String} term
 * @param {Number} risk
 * @param {Boolean} archived
 * @return {Promise}
 *
 */
export const editTerm = ({ termId, term, risk, archived }) => {
  const body = {
    word: {
      term,
      risk,
      archived
    }
  }

  return axiosInstance.put(`/words/${termId}`, body, getHeaders())
}

/**
 *
 * Retorna um termo
 * @param {Number} termId
 * @return {Promise}
 *
 */
export const getTerm = ({ termId }) =>
  axiosInstance.get(`/words/${termId}`, getHeaders())

/***** SUBJECT *****/

/**
 *
 * Retorna um subject
 * @param {Number} subjectId
 * @return {Promise}
 *
 */
export const getSubject = ({ subjectId }) =>
  axiosInstance.get(`/subjects/${subjectId}`, getHeaders())

/**
 *
 * Retorna as publicações de um subject
 * @param {Number} subjectId
 * @param {String} socialMedia 'all' (default) / 'facebook' / 'instagram' / 'linkedin' / 'twitter' / 'site'
 * @return {Promise}
 *
 */
export const getSubjectPublications = ({ subjectId, socialMedia = 'all' }) =>
  axiosInstance.get(
    `/subjects/${subjectId}/publications?social_media=${socialMedia}`,
    getHeaders()
  )

/**
 *
 * Retorna os hits de bios e experiências de um subject
 * @param {Number} subjectId
 * @return {Promise}
 *
 */
export const getSubjectBiosHits = ({ subjectId }) =>
  axiosInstance.get(
    `/subjects/${subjectId}/bios_experiences_hits`,
    getHeaders()
  )

/**
 *
 * Retorna os artigos (linkedin) de um subject
 * @param {Number} subjectId
 * @return {Promise}
 *
 */
export const getSubjectArticles = ({ subjectId }) =>
  axiosInstance.get(`/subjects/${subjectId}/articles`, getHeaders())

/**
 *
 * Retorna os textos de site de um subject
 * @param {Number} subjectId
 * @return {Promise}
 *
 */
export const getSubjectTextSites = ({ subjectId }) =>
  axiosInstance.get(`/subjects/${subjectId}/sites`, getHeaders())

/**
 *
 * Retorna as bios e experiências de um subject
 * @param {Number} subjectId
 * @return {Promise}
 *
 */
export const getSubjectBios = ({ subjectId }) =>
  axiosInstance.get(`/subjects/${subjectId}/bios_experiences`, getHeaders())

/**
 *
 * Edita o status de uma bio de um subject
 * @param {Number} biosId
 * @param {Number} status
 * @return {Promise}
 *
 */
export const classifySubjectBios = ({ biosId, status }) =>
  axiosInstance.put(`/bios/${biosId}?status=${status}`, getHeaders())

/**
 *
 * Edita o status de uma experiência de um subject
 * @param {Number} experienceId
 * @param {Number} status
 * @return {Promise}
 *
 */
export const classifySubjectExperience = ({ experienceId, status }) =>
  axiosInstance.put(
    `/experiences/${experienceId}?status=${status}`,
    getHeaders()
  )

/**
 *
 * Edita um subject
 * @param {Number} subjectId
 * @return {Promise}
 *
 */
export const updateSubject = ({ subjectId, values }) =>
  axiosInstance.put(`/subjects/${subjectId}`, values, getHeaders())

/**
 *
 * Arquiva um subject
 * @param {Number} subjectId
 * @return {Promise}
 *
 */
export const archiveSubject = ({ subjectId }) =>
  axiosInstance.put(`/subjects/${subjectId}`, { archived: true }, getHeaders())

/**
 *
 * Desarquiva um subject
 * @param {Number} subjectId
 * @return {Promise}
 *
 */
export const unarchiveSubject = ({ subjectId }) =>
  axiosInstance.put(`/subjects/${subjectId}`, { archived: false }, getHeaders())

/**
 *
 * Reporta erros de um subject
 * @param {Number} subjectId
 * @param {Object} mistake
 * @param {Number} mistakeId
 * @return {Promise}
 *
 */
export const reportMistakes = ({ subjectId, mistake, mistakeId }) => {
  const body = {
    mistake: {
      ...mistake,
      subject_id: subjectId
    }
  }

  return mistakeId
    ? axiosInstance.patch(`/mistakes/${mistakeId}`, body, getHeaders())
    : axiosInstance.post('/mistakes', body, getHeaders())
}

/***** OBSERVATIONS *****/

/**
 *
 * Edita uma observation
 * @param {Number} observationId
 * @param {Number} status
 * @param {String} notes
 * @return {Promise}
 *
 */
export const editObservation = ({ observationId, status, notes }) => {
  const body = {
    status,
    notes
  }

  return axiosInstance.patch(
    `/observations/${observationId}`,
    body,
    getHeaders()
  )
}

/**
 *
 * Retorna as observations baseadas em uma query
 * @param {Object} query
 * @return {Promise}
 *
 */
export const queryObservations = ({ query }) => {
  const body = {
    query
  }

  return axiosInstance.post('/query_observations', body, getHeaders())
}

/**
 *
 * Retorna as observations arquivadas baseadas em uma query
 * @param {Object} query
 * @return {Promise}
 *
 */
export const queryArchivedObservations = ({ query }) => {
  const body = {
    query
  }

  return axiosInstance.post(
    '/query_observations?subject_archived=true',
    body,
    getHeaders()
  )
}

/***** SUBJECTS *****/

/**
 *
 * Retorna a lista de subjects
 * @return {Promise}
 *
 */
export const getSubjects = () =>
  axiosInstance.get('/subjects?per_page=999999999999999', getHeaders())

/**
 *
 * Retorna a lista de subjects arquivados
 * @return {Promise}
 *
 */
export const getArchivedSubjects = () =>
  axiosInstance.get(
    '/subjects?per_page=999999999999999&archived=true',
    getHeaders()
  )

/***** OUTROS *****/

/**
 *
 * Retorna o chart
 * @return {Promise}
 *
 */
export const getChart = () => axiosInstance.get('/chart', getHeaders())

/**
 *
 * Retorna o csv de hits
 * @return {Promise}
 *
 */
export const getExportHitsBlob = () =>
  axiosInstance({
    method: 'get',
    url: '/export_hits',
    responseType: 'blob',
    APIContentType: 'text/csv',
    headers: {
      Authorization: store.getState().user.token
    }
  })
