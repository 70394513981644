import {
  SIGN_IN_REQUESTED,
  SIGN_IN_SUCCEEDED,
  SIGN_IN_FAILED,
  SIGN_OUT,
  UPDATE_USER_INFO_REQUESTED,
  UPDATE_USER_INFO_SUCCEEDED
} from './action-types'

export const initialState = {
  token: null,
  isLoading: false,
  error: null,
  isUpdatingUserInfo: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN_REQUESTED:
      return {
        ...state,
        isLoading: true
      }

    case SIGN_IN_SUCCEEDED:
      return {
        ...action.userData,
        isLoading: false,
        error: null
      }

    case SIGN_IN_FAILED:
      return {
        ...initialState,
        error: action.error
      }

    case SIGN_OUT:
      return initialState

    case UPDATE_USER_INFO_REQUESTED:
      return {
        ...state,
        isUpdatingUserInfo: true
      }

    case UPDATE_USER_INFO_SUCCEEDED:
      return {
        ...state,
        first_name: action.firstName,
        last_name: action.lastName,
        isUpdatingUserInfo: false
      }

    default:
      return state
  }
}
