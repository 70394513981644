import styled from 'styled-components'

import { defaultProps, defaultPropsPropTypes } from '@zeta/ui/src'

export const Wrapper = styled.button.attrs(() => ({
  type: 'button'
}))`
  background-color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  border-radius: ${({ theme: { radius } }) => radius.xsmall};
  padding: ${({ theme: { spacing } }) => spacing.medium};
  width: 100%;
  text-align: left;

  &:hover {
    border-color: ${({ theme: { colors } }) => colors.brand.primary.medium};
    transition: all .3s;
  }

  ${defaultProps}
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
