/* React */
import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'

/* Components */
import Icon from '../Icon'
import DropdownBtns from '../DropdownBtns'
import { scroller } from '../helpers/ScrollAnchor'

/* Styled */
import { Wrapper, PaginationCount, Pages, ActivePage } from './styled'

/* Constants */
import theme from '../../theme/theme'

const getItemsRange = (page, perPage, totalItems) => {
  const start = page * perPage - (perPage - 1)
  const end = page * perPage > totalItems ? totalItems : page * perPage

  return `${start} - ${end}`
}

const Pagination = ({
  page,
  setPageFn,
  perPage,
  setPerPageFn,
  perPageItems,
  totalItems,
  scrollAnchor,
  containerId,
  ...rest
}) => {
  const [showPerPageDropdown, setShowPerPageDropdown] = useState(false)

  const totalPages = Math.ceil(totalItems / perPage)

  const scrollToAnchor = () => {
    if (scrollAnchor) {
      scroller.scrollTo(scrollAnchor, {
        duration: 500,
        smooth: true,
        offset: -16,
        containerId
      })
    }
  }

  const changePerPage = (perPage) => {
    scrollToAnchor()
    setShowPerPageDropdown(false)

    setTimeout(() => {
      setPerPageFn(perPage)
      // setPageFn(1)
    }, 500)
  }

  return (
    <Wrapper {...rest}>
      {setPerPageFn && (
        <PaginationCount>
          <span>
            Itens por página: <strong>{perPage}</strong>
          </span>

          <button
            type='button'
            onClick={() => setShowPerPageDropdown(!showPerPageDropdown)}
          >
            {showPerPageDropdown ? (
              <Icon
                icon='arrow-ios-upward-outline'
                size='medium'
                fill={theme.colors.brand.primary.medium}
              />
            ) : (
              <Icon
                icon='arrow-ios-downward-outline'
                size='medium'
                fill={theme.colors.brand.primary.medium}
              />
            )}
          </button>

          {showPerPageDropdown && (
            <DropdownBtns
              items={perPageItems.map((item) => ({
                text: item,
                onClickFn: () => changePerPage(item)
              }))}
              override={`
                  right: 0;
                  top: 100%;
                `}
            />
          )}
        </PaginationCount>
      )}

      <PaginationCount>
        <strong>
          {getItemsRange(page, perPage, totalItems)} de {totalItems}
        </strong>
      </PaginationCount>

      <Pages>
        {page > 1 && (
          <Fragment>
            <button
              type='button'
              onClick={() => {
                scrollToAnchor()
                setTimeout(() => setPageFn(page - 1), 500)
              }}
            >
              <Icon
                icon='arrow-back-outline'
                size='medium'
                fill={theme.colors.brand.primary.medium}
              />
            </button>

            <button
              type='button'
              onClick={() => {
                scrollToAnchor()
                setTimeout(() => setPageFn(1), 500)
              }}
            >
              1
            </button>
          </Fragment>
        )}

        {page > 3 && <div>...</div>}

        {page > 2 && (
          <button
            type='button'
            onClick={() => {
              scrollToAnchor()
              setTimeout(() => setPageFn(page - 1), 500)
            }}
          >
            {page - 1}
          </button>
        )}

        {totalPages > 1 && <ActivePage>{page}</ActivePage>}

        {page < totalPages - 1 && (
          <button
            type='button'
            onClick={() => {
              scrollToAnchor()
              setTimeout(() => setPageFn(page + 1), 500)
            }}
          >
            {page + 1}
          </button>
        )}

        {page < totalPages - 2 && <div>...</div>}

        {page < totalPages && (
          <Fragment>
            <button
              type='button'
              onClick={() => {
                scrollToAnchor()
                setTimeout(() => setPageFn(totalPages), 500)
              }}
            >
              {totalPages}
            </button>

            <button
              type='button'
              onClick={() => {
                scrollToAnchor()
                setTimeout(() => setPageFn(page + 1), 500)
              }}
            >
              <Icon
                icon='arrow-forward-outline'
                size='medium'
                fill={theme.colors.brand.primary.medium}
              />
            </button>
          </Fragment>
        )}
      </Pages>
    </Wrapper>
  )
}

Pagination.propTypes = {
  page: PropTypes.number.isRequired,
  setPageFn: PropTypes.func.isRequired,
  perPage: PropTypes.number.isRequired,
  setPerPageFn: PropTypes.func,
  perPageItems: PropTypes.arrayOf(PropTypes.number), // obrigatório caso seja passado setPerPageFn
  totalItems: PropTypes.number.isRequired,
  scrollAnchor: PropTypes.string
}

export default Pagination
