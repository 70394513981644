import styled from 'styled-components'

export const Hide575 = styled.span`
  @media (max-width: 575px) {
    display: none;
  }
`

export const Hide576 = styled.span`
  @media (min-width: 576px) {
    display: none;
  }
`

export const Hide767 = styled.span`
  @media (max-width: 767px) {
    display: none;
  }
`

export const Hide768 = styled.span`
  @media (min-width: 768px) {
    display: none;
  }
`

export const Hide991 = styled.span`
  @media (max-width: 991px) {
    display: none;
  }
`

export const Hide992 = styled.span`
  @media (min-width: 992px) {
    display: none;
  }
`

export const Hide1199 = styled.span`
  @media (max-width: 1199px) {
    display: none;
  }
`

export const Hide1200 = styled.span`
  @media (min-width: 1200px) {
    display: none;
  }
`
