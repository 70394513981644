/* React */
import React, { useState, useEffect } from 'react'

/* Components */
import {
  Icon,
  ButtonAction,
  CustomBlock,
  Button,
  DropdownBtns,
  theme
} from '@zeta/ui/src'

/* Parts */
import ReportErrorModal from '../ReportErrorModal'
import ArchiveModal from '../ArchiveModal'

/* Styled */
import { Wrapper } from './styled'

/* Helpers */
import { getPathPrefix } from '@zeta/helpers'
import { getSubjectType } from 'helpers'

const ActionsBar = ({
  /* router */
  history,
  computedMatch,
  /* props */
  individual,
  setIndividual
}) => {
  const [isErrorModalVisible, setIsErrorModalVisible] = useState(false)
  const [mistake, setMistake] = useState(null)

  const [isEditDropdownOpen, setIsEditDropdownOpen] = useState(false)
  const [isArchiveModalVisible, setIsArchiveModalVisible] = useState(false)

  const subjectType = getSubjectType(computedMatch.url)

  useEffect(() => {
    if (individual.mistakes.length > 0)
      setMistake(individual.mistakes[individual.mistakes.length - 1])
  }, [])

  const errorModalProps = { isErrorModalVisible, setIsErrorModalVisible }
  const mistakeProps = { mistake, setMistake }
  const archiveModalProps = {
    isArchiveModalVisible,
    setIsArchiveModalVisible,
    individual,
    setIndividual
  }

  return (
    <>
      <Wrapper>
        <div>
          <ButtonAction onClick={() => history.goBack()} mr="medium">
            <Icon icon="arrow-ios-back-outline" />
            <span>Voltar</span>
          </ButtonAction>

          <ButtonAction
            onClick={() =>
              history.push(
                `${getPathPrefix(computedMatch)}/${subjectType}/individual`
              )
            }
            mr="medium"
          >
            <span>Ir para a lista de perfis</span>
          </ButtonAction>
        </div>

        <div>
          <ButtonAction
            onClick={() => setIsErrorModalVisible(true)}
            mr="medium"
          >
            <Icon icon="alert-circle-outline" />
            <span>Reportar erro</span>
          </ButtonAction>

          <CustomBlock
            override={`
              display: inline-block;
              position: relative;

              @media (max-width: 767px) {
                margin-top: ${theme.spacing.medium};
              }
            `}
          >
            <Button onClick={() => setIsEditDropdownOpen(!isEditDropdownOpen)}>
              <span>Editar</span>
              <Icon icon="arrow-ios-downward-outline" />
            </Button>

            {isEditDropdownOpen && (
              <DropdownBtns
                items={[
                  {
                    text: individual.subject.archived
                      ? 'Desarquivar perfil'
                      : 'Arquivar perfil',
                    onClickFn: () => {
                      setIsEditDropdownOpen(false)
                      setIsArchiveModalVisible(true)
                    }
                  }
                ]}
                override="right: 0;"
              />
            )}
          </CustomBlock>
        </div>
      </Wrapper>

      {/* Modal "Reportar Erro" */}
      <ReportErrorModal
        subjectId={individual.subject.id}
        subjectType={subjectType}
        {...errorModalProps}
        {...mistakeProps}
      />

      {/* Modal "Arquivar perfil" */}
      <ArchiveModal {...archiveModalProps} />
    </>
  )
}

export default ActionsBar
