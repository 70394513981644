export default {
  colors: {
    lightGray: {
      100: '#f7f9fa'
    },
    yellow: {
      50: '#FFF4CC',
      100: '#FFDD66',
      200: '#FFC700',
      300: '#CC9F00'
    },
    risk: {
      1: {
        50: '#FAEDD1',
        100: '#F0C15C',
        200: '#EAA81B',
        300: '#BF8812',
        400: '#91670D',
        lightness: '#FAEDD1',
        light: '#f0c15c',
        medium: '#EAA81B'
      },
      2: {
        50: '#FFE1CC',
        100: '#FE7A1B',
        200: '#C95501',
        300: '#984001',
        400: '#652B01',
        lightness: '#FFE1CC',
        light: '#fe7a1b',
        medium: '#C95501',
        darkness: '#652B01'
      },
      3: {
        50: '#FFCCCC',
        100: '#FF0000',
        200: '#7D0000',
        300: '#4D0000',
        400: '#330000',
        lightness: '#FFCCCC',
        light: '#ff0000',
        medium: '#7D0000'
      },
      site: {
        50: '#C4B0C3',
        100: '#7D5E7B',
        200: '#634061',
        300: '#4A2948',
        400: '#2B182A',
        lightness: '#C4B0C3',
        medium: '#634061'
      }
    },
    brand: {
      primary: {
        lightness: '#CCE7FF',
        light: '#54B0FF',
        medium: '#389CF2',
        dark: '#1C82D9',
        darkness: '#104C7E'
      },
      secondary: {
        lightness: '#C4B0C3',
        light: '#7D5E7B',
        medium: '#634061',
        dark: '#4A2948',
        darkness: '#2B182A',
        extra: '#ad4b7a'
      },
      tertiary: {
        lightness: '#788187',
        light: '#393d40',
        medium: '#2E3133',
        dark: '#222426',
        darkness: '#0A0A0B'
      }
    },
    surface: {
      primary: {
        lightness: '#F5FAFF',
        light: '#EBF5FF',
        medium: '#E3EEF7',
        dark: '#DAE4ED',
        darkness: '#CCD9E6'
      },
      secondary: {
        lightness: '#FFFFFF',
        light: '#F2F2F3',
        medium: '#E4E6E7',
        dark: '#D7D9DB',
        darkness: '#C9CDCF'
      }
    },
    type: {
      lightness: '#F1F2F3',
      light: '#DCDEE0',
      medium: '#3D4043',
      dark: '#313335',
      darkness: '#242728'
    },
    feedback: {
      green: {
        lightness: '#e9fcf2',
        light: '#a6f2cc',
        medium: '#21d97c',
        dark: '#0abf64',
        darkness: '#00994c'
      },
      red: {
        lightness: '#ffe9e5',
        light: '#ffc6bd',
        medium: '#ff6f57',
        dark: '#f8573c',
        darkness: '#de3a1f'
      },
      yellow: {
        lightness: '#fffbeb',
        light: '#fff4cc',
        medium: '#ffc700',
        dark: '#ffdd66',
        darkness: '#cc9f00'
      }
    }
  },
  radius: {
    smallest: '2px',
    xsmall: '4px',
    small: '8px',
    medium: '16px',
    large: '24px',
    xlarge: '32px',
    largest: '64px'
  },
  spacing: {
    smallest: '2px',
    xsmall: '4px',
    small: '8px',
    medium: '16px',
    large: '24px',
    xlarge: '32px',
    largest: '64px'
  },
  boxShadow: {
    rb: '2px 2px 4px rgba(0, 0, 0, 0.2)'
  }
}
