import styled from 'styled-components'

export const TooltipWrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  padding: ${({ theme: { spacing } }) => spacing.small};
`

export const TooltipName = styled.div`
  font-size: 0.8125rem;
  font-weight: 700;
  color: ${({ fill }) => fill};
`

export const TooltipValue = styled.div`
  font-size: 0.75rem;
  margin-top: 4px;

  ${({ fill }) => fill && `
    strong {
      color: ${fill};
    }
  `};
`
