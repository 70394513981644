/* React */
import React, { useState, useEffect } from 'react'

/* Components */
import { Row, Col } from 'styled-bootstrap-grid'

import {
  Card,
  Button,
  Icon,
  CustomText,
  FlexCentered,
  LoadingSpinner,
  theme
} from '@zeta/ui/src'

import { User, UserName, UserEmail, UserStatus, RemoveBtn } from './styled'

import ExcludeModal from './ExcludeModal'
import AddModal from './AddModal'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'
import { getUsers, resendCreateUser } from '../../api'

/* Constants */
const EXCLUDE_MODAL = 'EXCLUDE_MODAL'
const ADD_MODAL = 'ADD_MODAL'

const visibleStatus = {
  active: 'Ativo',
  inactive: 'Aguardando ativação'
}

export const ManageUsers = () => {
  const [isRequesting, setIsRequesting] = useState(false)
  const [users, setUsers] = useState(null)
  const [modal, setModal] = useState(null)
  const [userToExclude, setUserToExclude] = useState(null)
  const [resendingCreateUser, setResendingCreateUser] = useState({})
  const [resentCreateUser, setResentCreateUser] = useState({})

  useEffect(() => {
    setIsRequesting(true)

    getUsers().then((res) => {
      setUsers(res.data)
      setIsRequesting(false)
    })
  }, [])

  const handleResendCreateUser = (email) => {
    setResendingCreateUser({
      ...resendingCreateUser,
      [email]: true
    })

    resendCreateUser({ email }).then(() => {
      setResentCreateUser({
        ...resentCreateUser,
        [email]: true
      })
    })
  }

  return (
    <>
      <Row>
        <Col md={6} mdOffset={3}>
          <Card mb="xlarge">
            <CustomText
              size="0.875rem"
              weight="700"
              mb="medium"
              override={`
                color: ${theme.colors.brand.primary.medium};
                text-transform: uppercase;
              `}
            >
              Usuários
            </CustomText>

            <div>
              {isRequesting && (
                <FlexCentered>
                  <LoadingSpinner size="40" />
                </FlexCentered>
              )}

              {users &&
                users
                  .filter((user) => user.permission !== 'admin')
                  .map((user) => (
                    <User key={uuidv4()}>
                      <div>
                        <UserName>
                          {user.first_name} {user.last_name}
                        </UserName>

                        <UserEmail>{user.email}</UserEmail>

                        <UserStatus>
                          Status: {visibleStatus[user.status]}
                        </UserStatus>

                        {user.status === 'inactive' &&
                          !resentCreateUser[user.email] &&
                          !resendingCreateUser[user.email] && (
                            <Button
                              onClick={() => handleResendCreateUser(user.email)}
                              small
                              mt="xsmall"
                              override={`background-color: ${theme.colors.brand.secondary.medium};`}
                            >
                              enviar novo link
                            </Button>
                          )}

                        {resendingCreateUser[user.email] &&
                          !resentCreateUser[user.email] && (
                            <LoadingSpinner size="24" mt="xsmall" />
                          )}

                        {resentCreateUser[user.email] && (
                          <CustomText
                            size="0.875rem"
                            mt="xsmall"
                            override={`color: ${theme.colors.feedback.green.darkness};`}
                          >
                            novo link enviado com sucesso.
                          </CustomText>
                        )}
                      </div>

                      <RemoveBtn>
                        <Icon
                          icon="close-outline"
                          size="12"
                          fill={theme.colors.feedback.red.medium}
                          onClick={() => {
                            setModal(EXCLUDE_MODAL)
                            setUserToExclude({
                              email: user.email
                            })
                          }}
                        />
                      </RemoveBtn>
                    </User>
                  ))}
            </div>

            {users && (
              <Button fullWidth mt="medium" onClick={() => setModal(ADD_MODAL)}>
                <span>Adicionar novo usuário</span>
                <Icon icon="people-outline" />
              </Button>
            )}
          </Card>
        </Col>
      </Row>

      {modal === EXCLUDE_MODAL && (
        <ExcludeModal
          setModal={setModal}
          user={userToExclude}
          users={users}
          setUsers={setUsers}
        />
      )}

      {modal === ADD_MODAL && (
        <AddModal setModal={setModal} users={users} setUsers={setUsers} />
      )}
    </>
  )
}

export default ManageUsers
