/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Recharts */
import {
  PieChart as RechartsPieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'

import CustomTooltip from './CustomTooltip'

/* Styled */
import { Wrapper } from './styled'

/* Helpers */
import { getColor } from './helpers'
import { v4 as uuidv4 } from 'uuid'

const PieChart = ({
  data,
  chartWidth,
  chartHeight,
  innerRadius,
  outerRadius,
  colors,
  ...rest
}) => {
  return (
    <Wrapper {...rest}>
      <ResponsiveContainer width={chartWidth || '100%'} height={chartHeight}>
        <RechartsPieChart {...rest}>
          <Tooltip content={<CustomTooltip />} />
          <Legend iconType='circle' iconSize={8} />

          <Pie
            data={data}
            dataKey='quantidade'
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            paddingAngle={1}
            isAnimationActive={false}
          >
            {data.map((item, i) => (
              <Cell key={uuidv4()} fill={getColor(i, colors)} />
            ))}
          </Pie>
        </RechartsPieChart>
      </ResponsiveContainer>
    </Wrapper>
  )
}

PieChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  chartWidth: PropTypes.number,
  chartHeight: PropTypes.number,
  innerRadius: PropTypes.number,
  outerRadius: PropTypes.number
}

PieChart.defaultProps = {
  // data: [
  //   { name: 'Item 1', quantidade: 500, percentual: 20 },
  //   { name: 'Item 2', quantidade: 100, percentual: 30 },
  //   { name: 'Item 3', quantidade: 200, percentual: 10 }
  // ],
  chartHeight: 250,
  innerRadius: 30,
  outerRadius: 70
}

export default PieChart
