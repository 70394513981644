import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme: { colors } }) => colors.feedback.red.darkness};
  border-radius: ${({ theme: { spacing } }) => spacing.xsmall};
  background-color: ${({ theme: { colors } }) => colors.feedback.red.lightness};
  padding: ${({ theme: { spacing } }) => spacing.medium};

  svg {
    transform: translateY(-1px);
  }

  ${defaultProps}
`

export const Text = styled.div`
  font-size: 0.875rem;
  width: calc(100% - 40px);
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
