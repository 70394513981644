/* React */
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

/* Components */
import { Container, Row, Col } from 'styled-bootstrap-grid'

import {
  ButtonAction,
  CustomText,
  FlexSpaceBetween,
  FlexAlignCenter,
  TextWithIcon,
  Icon,
  Button,
  DropdownBtns,
  LineChart,
  CustomBlock,
  FlexCentered,
  LoadingSpinner,
  Warning,
  defaultAvatar,
  theme
} from '@zeta/ui/src'

import RiskBadge from 'components/RiskBadge'
import RiskCard from 'components/RiskCard'
import ListItem from 'components/ListItem'

import EditTermForm from './EditTermForm'
import ShelveTermModal from './ShelveTermModal'

import Menu from 'components/sidebar/Menu'
import ContentWrapper from 'components/ContentWrapper'
import Footer from 'components/Footer'

/* Styled */
import { StretchedCol, ChartCard } from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'
import { formatDate, logAmplitudeEvent } from '@zeta/helpers'
import { getSubjectType, getPicture } from 'helpers'
import { getTerm, getDictionaryInfo } from 'api'

/* Constants */
import { features } from 'constants/config'

export const DictionaryTermPage = ({
  /* router */
  history,
  computedMatch
}) => {
  const { termId } = computedMatch.params

  const subjectType = getSubjectType(computedMatch.url)

  const [isLoading, setIsLoading] = useState(false)
  const [termData, setTermData] = useState(null)
  const [dictionaryInfo, setDictionaryInfo] = useState(null)

  const [isEditDropdownOpen, setIsEditDropdownOpen] = useState(false)
  const [isEditFormOpen, setIsEditFormOpen] = useState(false)

  const [isShelveTermModalVisible, setIsShelveTermModalVisible] = useState(
    false
  )

  const [reload, setReload] = useState(false)

  useEffect(() => {
    let mounted = true

    setIsLoading(true)

    getTerm({ termId }).then((res) => {
      if (mounted) {
        setTermData(res.data)

        getDictionaryInfo({ dictionaryId: res.data.word.dictionary_id }).then(
          (res) => {
            setDictionaryInfo(res.data)
            setIsLoading(false)
          }
        )
      }
    })

    return () => {
      mounted = false
    }
  }, [reload])

  return (
    <>
      <Menu />

      <ContentWrapper>
        <Container>
          {!isLoading && termData && (
            <>
              <Row>
                <Col>
                  <ButtonAction
                    onClick={() =>
                      history.push(
                        `/dicionario/${subjectType}/${dictionaryInfo.dictionary.id}`
                      )
                    }
                    mt="largest"
                  >
                    <Icon icon="arrow-ios-back-outline" />
                    <span>Voltar para o dicionário</span>
                  </ButtonAction>

                  <FlexSpaceBetween
                    mt="largest"
                    mb="medium"
                    override={`
                      @media (max-width: 767px) {
                        flex-direction: column;
                        align-items: flex-start;
                      }
                    `}
                  >
                    <FlexAlignCenter>
                      <CustomText size="1.5rem" weight="700" mr="large">
                        {termData.word.term}
                      </CustomText>

                      <RiskBadge risk={termData.word.risk} />
                    </FlexAlignCenter>

                    <CustomBlock
                      override={`
                        display: inline-block;
                        position: relative;

                        @media (max-width: 767px) {
                          margin-top: ${theme.spacing.medium};
                        }
                      `}
                    >
                      <Button
                        onClick={() =>
                          setIsEditDropdownOpen(!isEditDropdownOpen)
                        }
                      >
                        <span>Editar</span>
                        <Icon icon="arrow-ios-downward-outline" />
                      </Button>

                      {isEditDropdownOpen && (
                        <DropdownBtns
                          items={[
                            {
                              text: 'Editar risco',
                              onClickFn: () => {
                                setIsEditDropdownOpen(false)
                                setIsEditFormOpen(true)
                              }
                            },
                            {
                              text: termData.word.archived
                                ? 'Desarquivar termo'
                                : 'Arquivar termo',
                              onClickFn: () => {
                                setIsEditDropdownOpen(false)
                                setIsShelveTermModalVisible(true)
                              }
                            }
                          ]}
                          override="right: 0;"
                        />
                      )}
                    </CustomBlock>
                  </FlexSpaceBetween>

                  <FlexAlignCenter
                    mb="medium"
                    override={`
                      @media (max-width: 767px) {
                        flex-direction: column;
                        align-items: flex-start;
                      }
                    `}
                  >
                    <TextWithIcon mb="medium" mr="large">
                      <Icon icon="message-circle-outline" />
                      <span>
                        Faz parte do dicionário:{' '}
                        <strong>{dictionaryInfo.dictionary.name}</strong>
                      </span>
                    </TextWithIcon>

                    <TextWithIcon mb="medium" mr="large">
                      <Icon icon="calendar-outline" />
                      <span>
                        Adicionado em:{' '}
                        <strong>{formatDate(termData.word.created_at)}</strong>
                      </span>
                    </TextWithIcon>

                    <TextWithIcon mb="medium">
                      <Icon icon="calendar-outline" />
                      <span>
                        {termData.word.archived
                          ? 'Arquivado em: '
                          : 'Atualizado em: '}
                        <strong>{formatDate(termData.word.updated_at)}</strong>
                      </span>
                    </TextWithIcon>
                  </FlexAlignCenter>

                  {isEditFormOpen && (
                    <EditTermForm
                      term={{
                        term: termData.word.term,
                        risk: termData.word.risk,
                        id: termId
                      }}
                      setIsEditFormOpen={setIsEditFormOpen}
                      reload={reload}
                      setReload={setReload}
                    />
                  )}
                </Col>
              </Row>

              {termData.chart.individuals_count > 0 ? (
                <>
                  <Row alignItems="stretch">
                    <StretchedCol lg={4}>
                      <RiskCard
                        risk={termData.word.risk}
                        totalListed={termData.chart.individuals_count}
                        totalListedLabel="perfis"
                        totalHits={termData.chart.mentions}
                        totalHitsStatus0={termData.chart.status.status0}
                        totalHitsStatus1={termData.chart.status.status1}
                        totalHitsStatus2={termData.chart.status.status2}
                        totalHitsStatus3={termData.chart.status.status3}
                        totalHitsStatus4={termData.chart.status.status4}
                        totalHitsStatus5={termData.chart.status.status5}
                        totalHitsStatus6={termData.chart.status.status6}
                        totalHitsStatus7={termData.chart.status.status7}
                        hideHeader
                      />
                    </StretchedCol>

                    <StretchedCol lg={8}>
                      <ChartCard>
                        <LineChart
                          data={Object.keys(
                            termData.chart.volumetry.date
                          ).reduce(
                            (acc, cur) =>
                              acc.concat({
                                name: cur,
                                Procedente:
                                  termData.chart.volumetry.date[cur].proceeding,
                                Improcedente:
                                  termData.chart.volumetry.date[cur].unfounded,
                                Investigação:
                                  termData.chart.volumetry.date[cur]
                                    .investigation,
                                Inconclusivo:
                                  termData.chart.volumetry.date[cur]
                                    .inconclusive,
                                'Não classificado':
                                  termData.chart.volumetry.date[cur]
                                    .unclassified
                              }),
                            []
                          )}
                          colors={[
                            theme.colors.brand.secondary.extra,
                            theme.colors.feedback.green.darkness,
                            theme.colors.brand.primary.light,
                            theme.colors.risk[2].darkness,
                            theme.colors.brand.primary.darkness
                          ]}
                          margin={{ top: 24, right: 12, left: -12, bottom: 0 }}
                          isResponsive
                        />
                      </ChartCard>
                    </StretchedCol>
                  </Row>

                  <Row>
                    <Col>
                      <CustomText
                        size="0.875rem"
                        weight="700"
                        mt="medium"
                        mb="large"
                      >
                        Perfis que mais mencionaram esse termo de risco
                      </CustomText>

                      <CustomBlock mb="xlarge">
                        {termData.chart.individuals.map((individual) => (
                          <ListItem
                            profile={{
                              name: individual.subject_name,
                              thumbnail:
                                getPicture(individual.pictures.linkedin) ||
                                getPicture(individual.pictures.twitter) ||
                                defaultAvatar
                            }}
                            risks={{
                              [termData.word.risk]: {
                                total: individual.mentions.quantity,
                                facebook: individual.mentions.facebook,
                                twitter: individual.mentions.twitter,
                                linkedin: individual.mentions.linkedin,
                                instagram: individual.mentions.instagram,
                                youtube: individual.mentions.youtube
                              }
                            }}
                            infos={() =>
                              subjectType === 'people' ? (
                                <>
                                  <TextWithIcon mb="small">
                                    <Icon icon="briefcase-outline" />
                                    <span>
                                      <strong>
                                        {features.occupationArea
                                          ? 'Área de ocupação'
                                          : 'Escritório'}
                                        :{' '}
                                      </strong>
                                      {features.occupationArea
                                        ? individual.occupation_area
                                        : individual.place}
                                    </span>
                                  </TextWithIcon>

                                  <TextWithIcon>
                                    <Icon icon="person-outline" />
                                    <span>
                                      <strong>CPF: </strong>
                                      {individual.document}
                                    </span>
                                  </TextWithIcon>
                                </>
                              ) : (
                                <>
                                  <TextWithIcon>
                                    <Icon icon="clipboard-outline" />
                                    <span>
                                      <strong>CNPJ: </strong>
                                      {individual.document}
                                    </span>
                                  </TextWithIcon>
                                </>
                              )
                            }
                            isTermPage
                            onClick={() => {
                              history.push(
                                `/dataset/${subjectType}/individual/${individual.subject_id}`
                              )

                              logAmplitudeEvent({
                                event: 'Clique em perfil',
                                eventProperties: {
                                  'ID do perfil': individual.subject_id,
                                  Nome: individual.subject_name,
                                  Base: subjectType,
                                  Local: 'Dicionário - Pág. do termo'
                                }
                              })
                            }}
                            key={uuidv4()}
                          />
                        ))}
                      </CustomBlock>
                    </Col>
                  </Row>
                </>
              ) : (
                <Warning>Este termo não foi mencionado até o momento.</Warning>
              )}
            </>
          )}

          {isLoading && (
            <Row>
              <Col>
                <FlexCentered>
                  <LoadingSpinner size="40" mt="largest" />
                </FlexCentered>
              </Col>
            </Row>
          )}
        </Container>

        {/* Footer */}
        <Footer />
      </ContentWrapper>

      {!isLoading && termData && (
        <ShelveTermModal
          isShelveTermModalVisible={isShelveTermModalVisible}
          setIsShelveTermModalVisible={setIsShelveTermModalVisible}
          termId={termId}
          archived={termData.word.archived}
          reload={reload}
          setReload={setReload}
        />
      )}
    </>
  )
}

DictionaryTermPage.propTypes = {
  history: PropTypes.object.isRequired,
  computedMatch: PropTypes.object.isRequired
}

export default withRouter(DictionaryTermPage)
