import styled from 'styled-components'

export const Wrapper = styled.div`
  margin-bottom: ${({ theme: { spacing } }) => spacing.xlarge};
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  border-bottom: 0;
  border-radius: ${({ theme: { radius } }) => `${radius.xsmall} ${radius.xsmall} 0 0`};
  padding: ${({ theme: { spacing } }) => `${spacing.medium} 0 0 ${spacing.medium}`};
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: ${({ theme: { colors } }) => colors.surface.primary.medium};
  border-radius: ${({ theme: { radius } }) => `0 0 ${radius.xsmall} ${radius.xsmall}`};
  padding: ${({ theme: { spacing } }) => spacing.medium};
`
