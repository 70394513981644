/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import { Icon, CustomBlock, CustomText, theme } from '@zeta/ui/src'

import BoardStatusBadge from 'components/BoardStatusBadge'

/* Styled */
import {
  Wrapper,
  Info,
  IconWrapper,
  InfoTextWrapper,
  InfoLabel,
  InfoText,
  DatasetGroup,
  DatasetGroupLabel,
  DatasetGroupInfo,
  DatasetGroupInfoTextWrapper,
  DatasetGroupInfoTotal,
  DatasetGroupInfoText
  // Divider
} from './styled'

const visibleStatus = {
  1: 'EM INVESTIGAÇÃO',
  2: 'FINALIZADO'
}

const BoardCard = ({
  status,
  id,
  name,
  start,
  end,
  owner,
  people,
  company,
  ...props
}) => {
  return (
    <Wrapper {...props} type="button">
      <BoardStatusBadge status={status} data-testid="boardStatusBadge">
        {visibleStatus[status]}
      </BoardStatusBadge>

      <CustomBlock my="large">
        <CustomText
          size="0.75rem"
          weight="700"
          color={theme.colors.brand.primary.medium}
        >
          #{id}
        </CustomText>

        <CustomText weight="700">{name}</CustomText>
      </CustomBlock>

      {start && end && (
        <Info>
          <IconWrapper blue>
            <Icon icon="calendar-outline" />
          </IconWrapper>

          <InfoTextWrapper>
            <InfoLabel>Período</InfoLabel>
            <InfoText>
              <span data-testid="startSpan">{start}</span>
              <Icon
                icon="arrow-forward-outline"
                size="medium"
                fill={theme.colors.brand.primary.medium}
                mx="medium"
              />
              <span data-testid="endSpan">{end}</span>
            </InfoText>
          </InfoTextWrapper>
        </Info>
      )}

      <Info>
        <IconWrapper blue>
          <Icon icon="calendar-outline" />
        </IconWrapper>

        <InfoTextWrapper>
          <InfoLabel>Criado por</InfoLabel>
          <InfoText>{owner}</InfoText>
        </InfoTextWrapper>
      </Info>

      {people && (
        <DatasetGroup blue data-testid="datasetGroupPeople">
          <DatasetGroupLabel>Pessoas</DatasetGroupLabel>

          <DatasetGroupInfo>
            <IconWrapper blue>
              <Icon icon="people-outline" />
            </IconWrapper>

            <DatasetGroupInfoTextWrapper>
              <DatasetGroupInfoTotal blue>
                {people.totalListed}
              </DatasetGroupInfoTotal>{' '}
              <DatasetGroupInfoText>pessoas neste painel</DatasetGroupInfoText>
            </DatasetGroupInfoTextWrapper>
          </DatasetGroupInfo>

          {/* <DatasetGroupInfo>
            <IconWrapper blue>
              <Icon icon='share-outline' />
            </IconWrapper>

            <DatasetGroupInfoTextWrapper>
              <DatasetGroupInfoTotal blue>
                {people.totalSocials}
              </DatasetGroupInfoTotal>{' '}
              <DatasetGroupInfoText>
                redes sociais identificadas
              </DatasetGroupInfoText>
            </DatasetGroupInfoTextWrapper>
          </DatasetGroupInfo> */}

          <DatasetGroupInfo>
            <IconWrapper blue>
              <Icon icon="alert-triangle-outline" />
            </IconWrapper>

            <DatasetGroupInfoTextWrapper>
              <DatasetGroupInfoTotal blue>
                {people.totalHits}
              </DatasetGroupInfoTotal>{' '}
              <DatasetGroupInfoText>
                menções de risco identificadas
              </DatasetGroupInfoText>
            </DatasetGroupInfoTextWrapper>
          </DatasetGroupInfo>
        </DatasetGroup>
      )}

      {company && (
        <DatasetGroup purple data-testid="datasetGroupCompany">
          <DatasetGroupLabel>Escritórios</DatasetGroupLabel>

          <DatasetGroupInfo>
            <IconWrapper purple>
              <Icon icon="briefcase-outline" />
            </IconWrapper>

            <DatasetGroupInfoTextWrapper>
              <DatasetGroupInfoTotal purple>
                {company.totalListed}
              </DatasetGroupInfoTotal>{' '}
              <DatasetGroupInfoText>
                escritórios neste painel
              </DatasetGroupInfoText>
            </DatasetGroupInfoTextWrapper>
          </DatasetGroupInfo>

          {/* <DatasetGroupInfo>
            <IconWrapper purple>
              <Icon icon='share-outline' />
            </IconWrapper>

            <DatasetGroupInfoTextWrapper>
              <DatasetGroupInfoTotal purple>
                {company.totalSocials}
              </DatasetGroupInfoTotal>{' '}
              <DatasetGroupInfoText>
                redes sociais identificadas
              </DatasetGroupInfoText>
            </DatasetGroupInfoTextWrapper>
          </DatasetGroupInfo> */}

          <DatasetGroupInfo>
            <IconWrapper purple>
              <Icon icon="alert-triangle-outline" />
            </IconWrapper>

            <DatasetGroupInfoTextWrapper>
              <DatasetGroupInfoTotal purple>
                {company.totalHits}
              </DatasetGroupInfoTotal>{' '}
              <DatasetGroupInfoText>
                menções de risco identificadas
              </DatasetGroupInfoText>
            </DatasetGroupInfoTextWrapper>
          </DatasetGroupInfo>

          {/* {company.totalSites >= 0 && (
            <span data-testid='sitesInfo'>
              <Divider />

              <DatasetGroupInfo>
                <IconWrapper purple>
                  <Icon icon='browser-outline' />
                </IconWrapper>

                <DatasetGroupInfoTextWrapper>
                  <DatasetGroupInfoTotal purple>
                    {company.totalSites}
                  </DatasetGroupInfoTotal>{' '}
                  <DatasetGroupInfoText>
                    sites identificados
                  </DatasetGroupInfoText>
                </DatasetGroupInfoTextWrapper>
              </DatasetGroupInfo>

              <DatasetGroupInfo>
                <IconWrapper purple>
                  <Icon icon='alert-triangle-outline' />
                </IconWrapper>

                <DatasetGroupInfoTextWrapper>
                  <DatasetGroupInfoTotal purple>
                    {company.totalRisksSites}
                  </DatasetGroupInfoTotal>{' '}
                  <DatasetGroupInfoText>
                    menções de risco identificadas
                  </DatasetGroupInfoText>
                </DatasetGroupInfoTextWrapper>
              </DatasetGroupInfo>
            </span>
          )} */}
        </DatasetGroup>
      )}
    </Wrapper>
  )
}

BoardCard.propTypes = {
  status: PropTypes.oneOf([1, 2]).isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  start: PropTypes.string,
  end: PropTypes.string,
  owner: PropTypes.string.isRequired,
  people: PropTypes.shape({
    totalListed: PropTypes.number,
    totalHits: PropTypes.number
  }),
  company: PropTypes.shape({
    totalListed: PropTypes.number,
    totalHits: PropTypes.number
  })
}

export default BoardCard
