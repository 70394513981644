/* React */
import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* Components */
import {
  CustomBlock,
  CustomText,
  TextWithIcon,
  Icon,
  Chip,
  FlexAlignCenter,
  defaultAvatar,
  theme
} from '@zeta/ui/src'

/* Styled */
import ReactTooltip from 'react-tooltip'

import {
  Wrapper,
  Thumbnail,
  SocialItem,
  SocialIconWrapper,
  SocialIcon,
  ConnectionsWrapper,
  Connections,
  ProfileLink,
  ProfileLinkIcon,
  OfficeButton
} from './styled'

import { StatusButton } from '../SocialTabs/TermsTable/styled'

import OuvidoriaModal from './OuvidoriaModal'

/* Helpers */
import { getPathPrefix } from '@zeta/helpers'
import { getPicture } from '../helpers'

/* Constants */
import { features } from 'constants/config'
import { visibleObservationStatus } from 'constants/global'

const socialsSuffix = {
  facebook: {
    people: 'amigos',
    company: 'seguidores'
  },
  linkedin: {
    people: 'conexões',
    company: 'seguidores'
  },
  instagram: {
    people: 'seguidores',
    company: 'seguidores'
  },
  twitter: {
    people: 'seguidores',
    company: 'seguidores'
  },
  youtube: {
    people: 'inscritos',
    company: 'inscritos'
  }
}

const ExternalLinkIcon = () => <ProfileLinkIcon icon="external-link-outline" />

const IndividualHeader = ({
  /* router */
  history,
  computedMatch,
  /* props */
  individual,
  setIndividual,
  subjectType,
  activeUnit
}) => {
  const [isOuvidoriaModalOpen, setIsOuvidoriaModalOpen] = useState(false)

  return (
    <>
      <Wrapper subjectType={subjectType}>
        <CustomBlock
          override={`
          display: flex;
          align-items: flex-start;

          @media (max-width: 575px) {
            flex-direction: column;
          }

          @media (max-width: 991px) {
            margin-bottom: ${theme.spacing.large}
          }

          @media (min-width: 992px) {
            width: calc(100% - 580px);
          }
        `}
        >
          <Thumbnail
            src={
              getPicture(individual.linkedin) ||
              getPicture(individual.twitter) ||
              defaultAvatar
            }
          />

          <CustomBlock
            override={`
            @media (max-width: 575px) {
              margin-top: ${theme.spacing.medium};
              width: 100%;
            }
          `}
          >
            <CustomText size="1.5rem" weight="700" mb="medium">
              {individual.subject.name}
            </CustomText>

            {individual.subject.archived && (
              <Chip bg={theme.colors.feedback.red.darkness} mb="medium">
                Perfil arquivado
              </Chip>
            )}

            {subjectType === 'people' ? (
              <>
                {individual.attached_to_id && (
                  <TextWithIcon mb="small">
                    <Icon icon="briefcase-outline" />
                    <OfficeButton
                      onClick={() =>
                        history.push(
                          `${getPathPrefix(computedMatch)}/company/individual/${
                            individual.attached_to_id
                          }`
                        )
                      }
                    >
                      <strong>Escritório:</strong>{' '}
                      {individual.subject.registration_data.office}
                    </OfficeButton>
                  </TextWithIcon>
                )}

                {features.actingArea &&
                  individual.subject.registration_data.acting_area && (
                    <TextWithIcon mb="small">
                      <Icon icon="briefcase-outline" />
                      <span>
                        <strong>Frente de atuação: </strong>
                        {individual.subject.registration_data.acting_area}
                      </span>
                    </TextWithIcon>
                  )}

                {features.occupationArea &&
                  individual.subject.registration_data.area && (
                    <TextWithIcon mb="small">
                      <Icon icon="briefcase-outline" />
                      <span>
                        <strong>Área de ocupação: </strong>
                        {individual.subject.registration_data.area}
                      </span>
                    </TextWithIcon>
                  )}

                <TextWithIcon>
                  <Icon icon="person-outline" />
                  <span>
                    <strong>CPF:</strong> {individual.subject.document}
                  </span>
                </TextWithIcon>
              </>
            ) : (
              <TextWithIcon>
                <Icon icon="clipboard-outline" />

                <span>
                  <strong>CNPJ:</strong> {individual.subject.document}
                </span>
              </TextWithIcon>
            )}

            {subjectType === 'company' && features.ouvidoria && (
              <FlexAlignCenter mt="medium">
                <Chip
                  bg={
                    individual.subject.ouvidoria
                      ? theme.colors.feedback.green.darkness
                      : theme.colors.feedback.red.darkness
                  }
                  mr="xsmall"
                >
                  <span>Ouvidoria</span>{' '}
                  <Icon
                    icon={
                      individual.subject.ouvidoria
                        ? 'checkmark-outline'
                        : 'close-outline'
                    }
                    fill={theme.colors.surface.secondary.lightness}
                    size="12"
                    ml="xsmall"
                  />
                </Chip>

                <StatusButton
                  type="button"
                  onClick={() => setIsOuvidoriaModalOpen(true)}
                >
                  <Icon
                    icon="edit-outline"
                    fill={theme.colors.brand.primary.medium}
                    size="medium"
                    mr="xsmall"
                  />
                  <span
                    style={{
                      fontSize: '0.875rem',
                      transform: 'translateY(1px)'
                    }}
                  >
                    {
                      visibleObservationStatus[
                        individual.subject.ouvidoria_status
                      ]
                    }
                  </span>
                </StatusButton>

                <CustomBlock ml="small" override="transform: translateY(2px);">
                  <ReactTooltip id="ouvidoriaNotes" />

                  <Chip
                    bg={
                      individual.subject.ouvidoria_notes
                        ? theme.colors.brand.primary.medium
                        : theme.colors.surface.primary.dark
                    }
                    data-tip={individual.subject.ouvidoria_notes || ''}
                    data-for="ouvidoriaNotes"
                  >
                    <Icon
                      icon="message-circle-outline"
                      size="medium"
                      fill={theme.colors.surface.secondary.lightness}
                    />
                  </Chip>
                </CustomBlock>
              </FlexAlignCenter>
            )}
          </CustomBlock>
        </CustomBlock>

        <CustomBlock
          override={`
          display: flex;
          flex-wrap: wrap;

          @media (max-width: 575px) {
            flex-direction: column;
            align-items: flex-start;
          }

          @media (min-width: 992px) {
            width: 580px;
            justify-content: flex-end;
          }
        `}
        >
          {individual.sites && individual.sites.length > 0 && (
            <SocialItem>
              <SocialIconWrapper>
                <SocialIcon icon="globe" />
              </SocialIconWrapper>

              <ConnectionsWrapper>
                <Connections>Site</Connections>

                <ProfileLink href={individual.sites[0].url} target="_blank">
                  <span>acessar link</span>

                  <ExternalLinkIcon />
                </ProfileLink>
              </ConnectionsWrapper>
            </SocialItem>
          )}

          {individual.facebook.length > 0 && (
            <SocialItem>
              <SocialIconWrapper>
                <SocialIcon icon="fa-facebook" />
              </SocialIconWrapper>

              <ConnectionsWrapper>
                <Connections>
                  {individual.facebook[0].connections}
                  {individual.facebook[0].connections === 'NA'
                    ? ''
                    : ` ${socialsSuffix.facebook[subjectType]}`}
                </Connections>

                <ProfileLink href={individual.facebook[0].url} target="_blank">
                  <span>acessar perfil</span>

                  <ExternalLinkIcon />
                </ProfileLink>
              </ConnectionsWrapper>
            </SocialItem>
          )}

          {individual.linkedin.length > 0 && (
            <SocialItem>
              <SocialIconWrapper>
                <SocialIcon icon="fa-linkedin" />
              </SocialIconWrapper>

              <ConnectionsWrapper>
                <Connections>
                  {individual.linkedin[0].connections}
                  {individual.linkedin[0].connections === 'NA'
                    ? ''
                    : ` ${socialsSuffix.linkedin[subjectType]}`}
                </Connections>

                <ProfileLink href={individual.linkedin[0].url} target="_blank">
                  <span>acessar perfil</span>

                  <ExternalLinkIcon />
                </ProfileLink>
              </ConnectionsWrapper>
            </SocialItem>
          )}

          {individual.instagram.length > 0 && (
            <SocialItem>
              <SocialIconWrapper>
                <SocialIcon icon="fa-instagram" />
              </SocialIconWrapper>

              <ConnectionsWrapper>
                <Connections>
                  {individual.instagram[0].connections}
                  {individual.instagram[0].connections === 'NA'
                    ? ''
                    : ` ${socialsSuffix.instagram[subjectType]}`}
                </Connections>

                <ProfileLink href={individual.instagram[0].url} target="_blank">
                  <span>acessar perfil</span>

                  <ExternalLinkIcon />
                </ProfileLink>
              </ConnectionsWrapper>
            </SocialItem>
          )}

          {individual.twitter.length > 0 && (
            <SocialItem>
              <SocialIconWrapper>
                <SocialIcon icon="fa-twitter" />
              </SocialIconWrapper>

              <ConnectionsWrapper>
                <Connections>
                  {individual.twitter[0].connections}
                  {individual.twitter[0].connections === 'NA'
                    ? ''
                    : ` ${socialsSuffix.twitter[subjectType]}`}
                </Connections>

                <ProfileLink href={individual.twitter[0].url} target="_blank">
                  <span>acessar perfil</span>

                  <ExternalLinkIcon />
                </ProfileLink>
              </ConnectionsWrapper>
            </SocialItem>
          )}

          {individual.youtube.length > 0 && (
            <SocialItem>
              <SocialIconWrapper>
                <SocialIcon icon="fa-youtube" />
              </SocialIconWrapper>

              <ConnectionsWrapper>
                <Connections>
                  {individual.youtube[0].connections}
                  {individual.youtube[0].connections === 'NA'
                    ? ''
                    : ` ${socialsSuffix.youtube[subjectType]}`}
                </Connections>

                <ProfileLink href={individual.youtube[0].url} target="_blank">
                  <span>acessar perfil</span>

                  <ExternalLinkIcon />
                </ProfileLink>
              </ConnectionsWrapper>
            </SocialItem>
          )}
        </CustomBlock>
      </Wrapper>

      {isOuvidoriaModalOpen && (
        <OuvidoriaModal
          individual={individual}
          setIndividual={setIndividual}
          subjectType={subjectType}
          activeUnit={activeUnit}
          setIsOuvidoriaModalOpen={setIsOuvidoriaModalOpen}
        />
      )}
    </>
  )
}

IndividualHeader.propTypes = {
  individual: PropTypes.object.isRequired,
  subjectType: PropTypes.oneOf(['people', 'company']).isRequired
}

export default IndividualHeader
