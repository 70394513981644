import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.div`
  background-color: ${({ theme: { colors } }) =>
    colors.surface.secondary.lightness};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  border-radius: ${({ theme: { radius } }) => radius.smallest};
  padding: ${({ theme: { spacing } }) => spacing.medium};
  width: 100%;
  text-align: left;
  page-break-inside: avoid;

  ${({ as, theme: { colors } }) =>
    as === 'button' &&
    `
      &:hover {
        border-color: ${colors.brand.primary.medium};
        transition: border-color 0.3s;
      }
  `}

  ${defaultProps}
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
