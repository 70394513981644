/* React */
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

/* Components */
import { LoadingSpinner, theme } from '@zeta/ui/src'

/* Parts */
import Sidebar from 'components/sidebar/Sidebar'
import SaveBoardModal from './SaveBoardModal'

/* Helpers */
import { getSubjectType } from 'helpers'

/* Constants */
import { subjectTypes } from 'constants/config'

/* Action Creators */
import {
  getAndSetBoard,
  setBoard,
  setSaveBoardModal,
  resetState
} from 'store/reducers/data/action-creators'

const BoardWrapper = ({ content: Content, computedMatch, ...props }) => {
  const dispatch = useDispatch()

  const board = useSelector((state) => state.data.board)
  const isLoadingMainData = useSelector((state) => state.data.isLoadingMainData)
  const isSaveBoardModalOpen = useSelector((state) => state.data.saveBoardModal)
    .isOpen
  const proceedFn = useSelector((state) => state.data.saveBoardModal).proceedFn
  const enableProceedWithoutSaving = useSelector(
    (state) => state.data.saveBoardModal
  ).enableProceedWithoutSaving

  const subjectType = getSubjectType(computedMatch.url)
  const subjectId = computedMatch.params.individualID
  const subjectProps = { subjectType, subjectId }

  useEffect(() => {
    return () => dispatch(resetState())
  }, [])

  useEffect(() => {
    if (!board) {
      const { boardId } = computedMatch.params

      if (boardId) {
        dispatch(getAndSetBoard({ boardType: 'board', boardId }))
        return
      }

      dispatch(setBoard({ boardType: 'dataSet' }))
    }
  }, [board])

  return board && !isLoadingMainData ? (
    <>
      <Sidebar />

      <Content
        {...props}
        key={subjectTypes[subjectType]}
        {...subjectProps}
        computedMatch={computedMatch}
      />

      <SaveBoardModal
        isModalOpen={isSaveBoardModalOpen}
        closeModal={() =>
          dispatch(
            setSaveBoardModal({
              isOpen: false,
              proceedFn: null,
              enableProceedWithoutSaving: false
            })
          )
        }
        isBoard={() => board.type === 'board'}
        proceedFn={(res) => proceedFn(res)}
        enableProceedWithoutSaving={enableProceedWithoutSaving}
      />
    </>
  ) : (
    <LoadingSpinner
      size="40"
      override={`margin: ${theme.spacing.xlarge} auto 0 auto;`}
    />
  )
}

export default BoardWrapper
