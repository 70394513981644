/* React */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

/* Components */
import {
  Modal,
  ModalContent,
  ModalActions,
  Button,
  LoadingSpinner,
  Icon,
  CustomText
} from '@zeta/ui/src'

/* Helpers */
import { deleteUser } from '../../../api'

/* Action Creators */
import { setUsers as setUsersInStore } from '../../../store/reducers/user/action-creators'

const ExcludeModal = ({ setModal, user, users, setUsers }) => {
  const dispatch = useDispatch()

  const [isRequesting, setIsRequesting] = useState(false)

  const handleDeleteUser = () => {
    setIsRequesting(true)

    const { email } = user

    deleteUser({ email }).then(() => {
      setIsRequesting(false)
      setModal(null)
      setUsers(users.filter((user) => user.email !== email))
      dispatch(
        setUsersInStore(
          users
            .filter((user) => user.email !== email)
            .map((user) => `${user.first_name} ${user.last_name}`)
        )
      )
    })
  }

  return (
    <Modal
      title="Excluir usuário"
      isVisible={true}
      closeFn={() => setModal(null)}
    >
      <ModalContent>
        <CustomText size="0.875rem">
          Confirma a exclusão do usuário <strong>{user.email}</strong>?
        </CustomText>
      </ModalContent>

      <ModalActions>
        <Button onClick={() => setModal(null)} red>
          <Icon icon="close-outline" />
          <span>Cancelar</span>
        </Button>

        <Button onClick={handleDeleteUser} isDisabled={isRequesting}>
          <Icon icon="checkmark-outline" />
          <span>Confirmar</span>
        </Button>

        {isRequesting && <LoadingSpinner size="xlarge" ml="small" />}
      </ModalActions>
    </Modal>
  )
}

export default ExcludeModal
