/* React */
import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

/* Components */
import { CustomBlock, SectionHeadline, defaultAvatar } from '@zeta/ui/src'

import ListItem, { getInfos } from 'components/ListItem'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'
import { path } from 'ramda'
import { getPathPrefix, logAmplitudeEvent } from '@zeta/helpers'
import { filterObservations, getPicture, formatSubjectsList } from 'helpers'
import { getSubjectsRanking } from '../helpers'

/* Selectors */
const subjectsRankingSelector = createSelector(
  (state) => state.data.observations,
  (state) => state.data.subjects,
  (state) => state.data.filters,
  (state) => state.data.activeUnit,
  (_, subjectType) => subjectType,
  (observations, subjects, filters, activeUnit, subjectType) =>
    getSubjectsRanking(
      filterObservations(
        path([activeUnit, subjectType], observations) || [],
        filters,
        path([activeUnit, subjectType], subjects) || []
      ),
      formatSubjectsList(
        filterObservations(
          path([activeUnit, subjectType], observations) || [],
          filters,
          path([activeUnit, subjectType], subjects) || []
        ),
        path([activeUnit, subjectType], subjects) || []
      )
    )
)

const SubjectsRanking = ({
  /* router */
  history,
  computedMatch,
  /* props */
  subjectType
}) => {
  const subjectsRanking = useSelector((state) =>
    subjectsRankingSelector(state, subjectType)
  )

  return (
    subjectsRanking.length > 0 && (
      <CustomBlock mb="xlarge">
        <SectionHeadline>Perfis com mais menções de risco</SectionHeadline>

        {subjectsRanking.map((subject) => (
          <ListItem
            profile={{
              name: subject.name,
              thumbnail:
                getPicture(subject.registration_data.linkedin_image) ||
                getPicture(subject.registration_data.twitter_image) ||
                defaultAvatar
            }}
            risks={{
              1: {
                total: path(['1', 'total'], subject) || 0,
                facebook: path(['1', 'Facebook'], subject) || false,
                twitter: path(['1', 'Twitter'], subject) || false,
                linkedin: path(['1', 'Linkedin'], subject) || false,
                instagram: path(['1', 'Instagram'], subject) || false,
                youtube: path(['1', 'Youtube'], subject) || false,
                site: path(['1', 'Site'], subject) || false
              },
              2: {
                total: path(['2', 'total'], subject) || 0,
                facebook: path(['2', 'Facebook'], subject) || false,
                twitter: path(['2', 'Twitter'], subject) || false,
                linkedin: path(['2', 'Linkedin'], subject) || false,
                instagram: path(['2', 'Instagram'], subject) || false,
                youtube: path(['2', 'Youtube'], subject) || false,
                site: path(['2', 'Site'], subject) || false
              },
              3: {
                total: path(['3', 'total'], subject) || 0,
                facebook: path(['3', 'Facebook'], subject) || false,
                twitter: path(['3', 'Twitter'], subject) || false,
                linkedin: path(['3', 'Linkedin'], subject) || false,
                instagram: path(['3', 'Instagram'], subject) || false,
                youtube: path(['3', 'Youtube'], subject) || false,
                site: path(['3', 'Site'], subject) || false
              }
            }}
            infos={() => getInfos(subjectType, subject)}
            onClick={() => {
              history.push(
                `${getPathPrefix(computedMatch)}/${subjectType}/individual/${
                  subject.id
                }`
              )

              logAmplitudeEvent({
                event: 'Clique em perfil',
                eventProperties: {
                  'ID do perfil': subject.id,
                  Nome: subject.name,
                  Base: subjectType,
                  Local: 'Análise Geral - Ranking'
                }
              })
            }}
            key={uuidv4()}
          />
        ))}
      </CustomBlock>
    )
  )
}

export default SubjectsRanking
