import styled from 'styled-components'
import PropTypes from 'prop-types'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme: { colors } }) => colors.surface.primary.dark};
  margin: ${({ theme: { spacing } }) => spacing.large} 0;

  ${({ fullWidth, theme: { spacing } }) =>
    fullWidth &&
    `
      width: calc(100% + ${spacing.xlarge});
      margin-left: -${spacing.medium};
      margin-right: -${spacing.medium};
    `}

  ${defaultProps}
`

Divider.propTypes = {
  fullWidth: PropTypes.bool,
  ...defaultPropsPropTypes
}

export default Divider
