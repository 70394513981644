/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Styled */
import { Wrapper, UnitSwitcherButton } from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'

const UnitSwitcher = ({ items, activeUnit, ...rest }) => (
  <Wrapper {...rest}>
    {items.map((item) => (
      <UnitSwitcherButton
        isActive={activeUnit === item.key}
        onClick={item.onClickFn}
        key={uuidv4()}
      >
        {item.text}
      </UnitSwitcherButton>
    ))}
  </Wrapper>
)

UnitSwitcher.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      onClickFn: PropTypes.func.isRequired
    })
  ).isRequired,
  activeUnit: PropTypes.string.isRequired
}

export default UnitSwitcher
