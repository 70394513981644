import styled from 'styled-components'

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;

  ${({ first, theme: { spacing } }) =>
    first && `margin-bottom: ${spacing.small}`}
`

export const Input = styled.input`
  width: ${({ theme: { spacing } }) => spacing.largest};
  height: ${({ theme: { spacing } }) => spacing.large};
  line-height: ${({ theme: { spacing } }) => spacing.large};
  font-size: 0.75rem;
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  border-radius: ${({ theme: { radius } }) => radius.smallest};
  padding-left: ${({ theme: { spacing } }) => spacing.xsmall};
`

export const Prefix = styled.span`
  font-size: 0.75rem;
  color: ${({ theme: { colors } }) => colors.brand.tertiary.lightness};
  margin-right: ${({ theme: { spacing } }) => spacing.small};

  &:last-child {
    margin-right: 0;
    margin-left: ${({ theme: { spacing } }) => spacing.small};
  }
`
