import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.div`
  position: relative;

  ${defaultProps}
`

export const Line = styled.div`
  background-color: ${({ theme: { colors } }) => colors.brand.primary.medium};

  @media (max-width: ${({ orientationBreakpoint }) =>
      `${orientationBreakpoint - 1}px`}) {
    width: 2px;
    height: 100%;
    position: absolute;
    left: 7px;
    top: 0;
  }

  @media (min-width: ${({ orientationBreakpoint }) =>
      `${orientationBreakpoint}px`}) {
    width: 100%;
    height: 2px;
    transform: ${({ theme: { spacing } }) =>
      'translateY(' + spacing.small + ')'};
  }
`

export const ItemsWrapper = styled.div`
  display: flex;

  @media (max-width: ${({ orientationBreakpoint }) =>
      `${orientationBreakpoint - 1}px`}) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (min-width: ${({ orientationBreakpoint }) =>
      `${orientationBreakpoint}px`}) {
    justify-content: space-around;
  }
`

export const Item = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: ${({ orientationBreakpoint }) =>
      `${orientationBreakpoint - 1}px`}) {
    &:not(:last-child) {
      margin-bottom: ${({ theme: { spacing } }) => spacing.large};
    }
  }

  @media (min-width: ${({ orientationBreakpoint }) =>
      `${orientationBreakpoint}px`}) {
    flex-direction: column;
  }

  ${({ isActive, theme: { colors } }) =>
    isActive &&
    `
    ${ItemCheck} {
      background-color: ${colors.brand.primary.medium};
    }

    ${ItemLabel} {
      font-weight: 700;
    }
  `};
`

export const ItemCheck = styled.div`
  width: ${({ theme: { spacing } }) => spacing.medium};
  height: ${({ theme: { spacing } }) => spacing.medium};
  border: 2px solid ${({ theme: { colors } }) => colors.brand.primary.medium};
  border-radius: 50%;
  background-color: ${({ itemCheckBg }) => itemCheckBg};
  z-index: 1;
`

export const ItemLabel = styled.div`
  font-size: 0.75rem;

  @media (max-width: ${({ orientationBreakpoint }) =>
      `${orientationBreakpoint - 1}px`}) {
    margin-left: ${({ theme: { spacing } }) => spacing.small};
    width: calc(100% - ${({ theme: { spacing } }) => spacing.large});
  }

  @media (min-width: ${({ orientationBreakpoint }) =>
      `${orientationBreakpoint}px`}) {
    margin-top: ${({ theme: { spacing } }) => spacing.small};
    text-align: center;
  }
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
