import styled from 'styled-components'

import { Col } from 'styled-bootstrap-grid'

export const StretchedCol = styled(Col)`
  margin-bottom: ${({ theme: { spacing } }) => spacing.xlarge};
`

export const ChartCard = styled.div`
  background-color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  border-radius: ${({ theme: { radius } }) => radius.smallest};
  padding: ${({ theme: { spacing } }) => spacing.medium};
  height: 100%;
  display: flex;
  align-items: center;

  > div {
    width: 100%;
  }
`
