import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  background-color: ${({ bg, theme: { colors } }) =>
    bg || colors.surface.secondary.lightness};
  ${({ border }) => border && `border: 1px solid ${border};`}
  padding-left: ${({ theme: { spacing } }) => spacing.medium};
  border-radius: ${({ theme: { radius } }) => radius.xsmall};

  ${defaultProps}
`

export const Input = styled.input`
  width: calc(100% - 40px);
  font-size: 0.875rem;
  color: ${({ theme: { colors } }) => colors.brand.tertiary.light};
  border: none;
  background: none;
  outline: 0;
`

export const Submit = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  outline: 0;

  ${({ disabled, theme: { colors } }) =>
    disabled &&
    `
      cursor: not-allowed;

      svg {
        fill: ${colors.surface.secondary.medium};
      }
    `}

  ${({ onSubmit }) => !onSubmit && `pointer-events: none;`}
`

Form.propTypes = {
  ...defaultPropsPropTypes
}
