/* React */
import React from 'react'
import { useSelector } from 'react-redux'

/* Styled */
import { Wrapper } from './styled'

/* Parts */
import SelectedFilter from './SelectedFilter'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'

const SelectedFilters = () => {
  const filters = useSelector(state => state.data.filters)

  return filters.occupation_area && filters.occupation_area.length > 0
    ? <Wrapper>
      {
        filters.occupation_area.map(filterValue => (
          <SelectedFilter
            value={filterValue}
            key={uuidv4()}
          />
        ))
      }
    </Wrapper>
    : null
}

export default SelectedFilters
