/* React */
import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'

/* Components */
import {
  Icon,
  theme
} from '@zeta/ui/src'

/* Styled */
import { Wrapper, Label } from '../styled'

import {
  Item,
  ItemCheckbox,
  ItemLabel,
  ExpandButton,
  CloudItem
} from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'
import { without, includes } from 'ramda'

export const isSelected = (selectedValue, value) => value && includes(selectedValue, value)

export const toggleSelection = (
  selectionType,
  selectedValue,
  value,
  setFilterValue,
  removeFilterValue,
  name
) => {
  if (selectionType === 'checkbox') {
    isSelected(selectedValue, value)
      ? value.length === 1
        ? removeFilterValue(name)
        : setFilterValue(name, without([selectedValue], value))
      : value
        ? setFilterValue(name, value.concat(Array.isArray(selectedValue) ? [...selectedValue] : selectedValue))
        : setFilterValue(name, [].concat(Array.isArray(selectedValue) ? [...selectedValue] : selectedValue))
  }

  if (selectionType === 'radio') {
    isSelected(selectedValue, value)
      ? removeFilterValue(name)
      : setFilterValue(name, [selectedValue])
  }
}

const Selection = ({
  selectionType,
  display,
  columnWidth,
  dropdownVisible,
  items,
  name,
  values,
  setFilterValue,
  removeFilterValue,
  label,
  // enableSelectAll,
  ...rest
}) => {
  const value = values[name]

  const [isOpen, setIsOpen] = useState(false)

  return (
    <Wrapper {...rest}>
      {label && <Label>{label}</Label>}

      {/* {enableSelectAll && selectionType === 'checkbox' && (
        <div>
          <ToggleButton
            type='button'
            onClick={() =>
              setFilterValue(
                name,
                items.map((item) => item.dbValue)
              )
            }
            select
          >
            Selecionar tudo
          </ToggleButton>

          <ToggleButtonSeparator>|</ToggleButtonSeparator>

          <ToggleButton
            type='button'
            onClick={() => removeFilterValue(name)}
            clear
          >
            Limpar
          </ToggleButton>
        </div>
      )} */}

      {(display === 'group' || display === 'column') && (
        <Fragment>
          {items.map((item) => (
            <Item
              key={uuidv4()}
              selected={isSelected(item.dbValue, value)}
              onClick={() =>
                toggleSelection(
                  selectionType,
                  item.dbValue,
                  value,
                  setFilterValue,
                  removeFilterValue,
                  name
                )
              }
              type='button'
              display={display}
              columnWidth={columnWidth}
            >
              <ItemCheckbox>
                <Icon icon='checkmark-outline' size={12} fill='transparent' />
              </ItemCheckbox>

              <ItemLabel>{item.visibleValue}</ItemLabel>
            </Item>
          ))}
        </Fragment>
      )}

      {display === 'dropdown' && (
        <Fragment>
          {items.slice(0, dropdownVisible).map((item) => (
            <Item
              key={uuidv4()}
              selected={isSelected(item.dbValue, value)}
              onClick={() =>
                toggleSelection(
                  selectionType,
                  item.dbValue,
                  value,
                  setFilterValue,
                  removeFilterValue,
                  name
                )
              }
              type='button'
            >
              <ItemCheckbox>
                <Icon icon='checkmark-outline' size={12} fill='transparent' />
              </ItemCheckbox>

              <ItemLabel>{item.visibleValue}</ItemLabel>
            </Item>
          ))}

          {isOpen &&
            items.slice(dropdownVisible).map((item) => (
              <Item
                key={uuidv4()}
                selected={isSelected(item.dbValue, value)}
                onClick={() =>
                  toggleSelection(
                    selectionType,
                    item.dbValue,
                    value,
                    setFilterValue,
                    removeFilterValue,
                    name
                  )
                }
                type='button'
              >
                <ItemCheckbox>
                  <Icon icon='checkmark-outline' size={12} fill='transparent' />
                </ItemCheckbox>

                <ItemLabel>{item.visibleValue}</ItemLabel>
              </Item>
            ))}

          <ExpandButton type='button' onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? 'fechar' : 'ver todas as opções'}

            <Icon
              icon={
                isOpen
                  ? 'arrow-ios-upward-outline'
                  : 'arrow-ios-downward-outline'
              }
              size='medium'
              fill={theme.colors.surface.primary.dark}
            />
          </ExpandButton>
        </Fragment>
      )}

      {display === 'cloud' && (
        <Fragment>
          {items.map((item) => (
            <Item
              key={uuidv4()}
              selected={isSelected(item.dbValue, value)}
              onClick={() =>
                toggleSelection(
                  selectionType,
                  item.dbValue,
                  value,
                  setFilterValue,
                  removeFilterValue,
                  name
                )
              }
              type='button'
              display='cloud'
            >
              <CloudItem>{item.visibleValue}</CloudItem>
            </Item>
          ))}
        </Fragment>
      )}
    </Wrapper>
  )
}

Selection.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  selectionType: PropTypes.oneOf(['checkbox', 'radio']).isRequired,
  // enableSelectAll: PropTypes.bool,
  display: PropTypes.oneOf(['group', 'dropdown', 'cloud', 'column']).isRequired,
  dropdownVisible: PropTypes.number, // se for display "dropdown"
  columnWidth: PropTypes.string, // se for display "column"
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  name: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired, // herdado de Filter
  setFilterValue: PropTypes.func.isRequired // herdado de Filter
}

export default Selection
