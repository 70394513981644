/* React */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'

/* Components */
import { Container, Row, Col } from 'styled-bootstrap-grid'

import {
  Button,
  LoadingSpinner,
  Tabs,
  HeadlineCard,
  Icon,
  CustomText,
  FlexCentered,
  FlexJustifyEnd,
  FlexAlignCenter
} from '@zeta/ui/src'

import ContentWrapper from 'components/ContentWrapper'
import Footer from 'components/Footer'

import StatisticsCard from './StatisticsCard'
import BoardCard from './BoardCard'

/* Parts */
import Menu from 'components/sidebar/Menu'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'
import { logAmplitudeEvent, formatDate } from '@zeta/helpers'
import { subjectTypes } from 'constants/config'
import { getBoardsList, getExportHitsBlob } from 'api'

/* Action Creators */
import { getAndSetChart } from 'store/reducers/data/action-creators'

export const BoardsList = ({
  /* router */
  history
}) => {
  const dispatch = useDispatch()

  const chart = useSelector((state) => state.data.chart)
  const isLoadingChart = useSelector((state) => state.data.isLoadingChart)

  const [activeStatusTab, setActiveStatusTab] = useState(1)
  const [isLoadingBoards, setIsLoadingBoards] = useState(false)
  const [boardsList, setBoardsList] = useState(null)
  const [isExportingHits, setIsExportingHits] = useState(false)

  useEffect(() => {
    let mounted = true

    // dispatch(resetState())

    /* chart */
    dispatch(getAndSetChart())

    /* boards */
    setIsLoadingBoards(true)

    getBoardsList().then((res) => {
      if (mounted) {
        setIsLoadingBoards(false)
        setBoardsList(res.data.reverse())
      }
    })

    return () => {
      mounted = false
    }
  }, [])

  // const deleteFn = boardId => {
  //   db
  //     .deleteBoard({ token, boardId })
  //     .then(res => console.log(res.data)) // atualizar boardsList
  //     .catch(error => db.handleAuthError(error, signOut))
  // }

  const hasBoard = () => !isLoadingBoards && boardsList && boardsList.length > 0

  return (
    <>
      <Menu />

      <ContentWrapper>
        <Container>
          <Row>
            <Col>
              <FlexJustifyEnd override="@media (min-width: 1200px) { margin-top: 48px; }">
                <FlexAlignCenter>
                  <Button
                    onClick={() => {
                      setIsExportingHits(true)

                      getExportHitsBlob().then((res) => {
                        setIsExportingHits(false)
                        const downloadUrl = window.URL.createObjectURL(res.data)
                        const tempLink = document.createElement('a')
                        tempLink.href = downloadUrl
                        tempLink.setAttribute('download', 'export-hits.csv')
                        tempLink.click()
                      })

                      logAmplitudeEvent({
                        event: 'Exportar CSV'
                      })
                    }}
                    isDisabled={isExportingHits}
                  >
                    <Icon icon="download-outline" />
                    <span>Exportar CSV</span>
                  </Button>

                  {isExportingHits && <LoadingSpinner size="32" ml="medium" />}
                </FlexAlignCenter>
              </FlexJustifyEnd>

              <Tabs
                items={[
                  { text: 'Visão geral', onClickFn: () => {}, isActive: true }
                ]}
                my="large"
              />

              {isLoadingChart && (
                <FlexCentered>
                  <LoadingSpinner size="40" mb="large" />
                </FlexCentered>
              )}

              {chart && !isLoadingChart && (
                <Row>
                  <Col>
                    {subjectTypes.people && chart.not_archived.private_person && (
                      <StatisticsCard
                        subjectType="people"
                        totalListed={chart.not_archived.private_person.subjects}
                        social={{
                          totalFound:
                            chart.not_archived.private_person.social_networks,
                          totalHits: chart.not_archived.private_person.hits,
                          facebook: {
                            totalFound:
                              chart.not_archived.private_person.facebooks
                                .social_network,
                            totalHits:
                              chart.not_archived.private_person.facebooks.hits
                          },
                          linkedin: {
                            totalFound:
                              chart.not_archived.private_person.linkedins
                                .social_network,
                            totalHits:
                              chart.not_archived.private_person.linkedins.hits
                          },
                          instagram: {
                            totalFound:
                              chart.not_archived.private_person.instagrams
                                .social_network,
                            totalHits:
                              chart.not_archived.private_person.instagrams.hits
                          },
                          twitter: {
                            totalFound:
                              chart.not_archived.private_person.twitters
                                .social_network,
                            totalHits:
                              chart.not_archived.private_person.twitters.hits
                          },
                          youtube: {
                            totalFound:
                              chart.not_archived.private_person.youtubes
                                .social_network,
                            totalHits:
                              chart.not_archived.private_person.youtubes.hits
                          }
                        }}
                        mb="medium"
                      />
                    )}

                    {subjectTypes.company && chart.not_archived.legal_entity && (
                      <StatisticsCard
                        subjectType="company"
                        totalListed={chart.not_archived.legal_entity.subjects}
                        social={{
                          totalFound:
                            chart.not_archived.legal_entity.social_networks,
                          totalHits: chart.not_archived.legal_entity.hits,
                          facebook: {
                            totalFound:
                              chart.not_archived.legal_entity.facebooks
                                .social_network,
                            totalHits:
                              chart.not_archived.legal_entity.facebooks.hits
                          },
                          linkedin: {
                            totalFound:
                              chart.not_archived.legal_entity.linkedins
                                .social_network,
                            totalHits:
                              chart.not_archived.legal_entity.linkedins.hits
                          },
                          instagram: {
                            totalFound:
                              chart.not_archived.legal_entity.instagrams
                                .social_network,
                            totalHits:
                              chart.not_archived.legal_entity.instagrams.hits
                          },
                          twitter: {
                            totalFound:
                              chart.not_archived.legal_entity.twitters
                                .social_network,
                            totalHits:
                              chart.not_archived.legal_entity.twitters.hits
                          },
                          youtube: {
                            totalFound:
                              chart.not_archived.legal_entity.youtubes
                                .social_network,
                            totalHits:
                              chart.not_archived.legal_entity.youtubes.hits
                          }
                        }}
                        web={{
                          totalFound:
                            chart.not_archived.legal_entity.sites
                              .social_network,
                          totalHits: chart.not_archived.legal_entity.sites.hits
                        }}
                        mb="xlarge"
                      />
                    )}
                  </Col>
                </Row>
              )}

              <HeadlineCard
                headline="Painéis"
                text={
                  <>
                    Os painéis são classificados em{' '}
                    <strong>Em Investigação</strong> e{' '}
                    <strong>Finalizados</strong>
                  </>
                }
                icon="clipboard-outline"
                mb="xlarge"
              >
                <Button
                  type={'button'}
                  onClick={() => {
                    history.push('/dataset/people/dashboard')

                    logAmplitudeEvent({
                      event: '[Paineis] Criar painel',
                      eventProperties: { Local: 'Página "Paineis"' }
                    })
                  }}
                >
                  <Icon icon="plus-circle-outline" />
                  <span>Novo painel</span>
                </Button>
              </HeadlineCard>

              {isLoadingBoards && (
                <FlexCentered>
                  <LoadingSpinner size="40" />
                </FlexCentered>
              )}

              {!isLoadingBoards && boardsList && boardsList.length === 0 && (
                <CustomText size="0.875rem" mb="xlarge">
                  Nenhum painel encontrado.
                </CustomText>
              )}

              {hasBoard() && (
                <Tabs
                  items={[
                    {
                      text: 'Em investigação',
                      onClickFn: () => {
                        setActiveStatusTab(1)
                      },
                      isActive: activeStatusTab === 1,
                      count:
                        boardsList.filter(
                          (board) =>
                            !board.config_body.status ||
                            board.config_body.status === 1
                        ).length || false
                    },
                    {
                      text: 'Finalizados',
                      onClickFn: () => {
                        setActiveStatusTab(2)
                      },
                      isActive: activeStatusTab === 2,
                      count:
                        boardsList.filter(
                          (board) => board.config_body.status === 2
                        ).length || false
                    }
                  ]}
                  mb="large"
                />
              )}

              <Row>
                {hasBoard() &&
                  boardsList
                    .filter((board) =>
                      activeStatusTab === 1
                        ? !board.config_body.status ||
                          board.config_body.status === 1
                        : board.config_body.status === 2
                    )
                    .map((board) => (
                      <Col lg={4} md={6} key={uuidv4()}>
                        <BoardCard
                          status={board.config_body.status || 1}
                          id={board.id}
                          name={board.name}
                          start={
                            board.config_body.filters.date_post
                              ? formatDate(
                                  board.config_body.filters.date_post.start ||
                                    'Não definido'
                                )
                              : null
                          }
                          end={
                            board.config_body.filters.date_post
                              ? formatDate(
                                  board.config_body.filters.date_post.end ||
                                    'Não definido'
                                )
                              : null
                          }
                          owner={board.user.email}
                          people={
                            subjectTypes.people && {
                              totalListed:
                                board.config_body.totals.people &&
                                board.config_body.totals.people.totalListed,
                              totalHits:
                                board.config_body.totals.people &&
                                board.config_body.totals.people.totalHits
                            }
                          }
                          company={
                            subjectTypes.company && {
                              totalListed:
                                board.config_body.totals.company &&
                                board.config_body.totals.company.totalListed,
                              totalHits:
                                board.config_body.totals.company &&
                                board.config_body.totals.company.totalHits
                            }
                          }
                          onClick={() => {
                            history.push(`/painel/${board.id}/people/dashboard`)

                            logAmplitudeEvent({
                              event: '[Paineis] Abrir painel existente',
                              eventProperties: { Local: 'Página "Paineis"' }
                            })
                          }}
                          mb="large"
                        />
                      </Col>
                    ))}
              </Row>

              {hasBoard() &&
                ((activeStatusTab === 1 &&
                  boardsList.filter(
                    (board) =>
                      !board.config_body.status ||
                      board.config_body.status === 1
                  ).length === 0) ||
                  (activeStatusTab === 2 &&
                    boardsList.filter((board) => board.config_body.status === 2)
                      .length === 0)) && (
                  <CustomText size="0.875rem">
                    Nenhum painel encontrado.
                  </CustomText>
                )}
            </Col>
          </Row>
        </Container>

        {/* Footer */}
        <Footer />
      </ContentWrapper>
    </>
  )
}

export default withRouter(BoardsList)
