const routerHelpers = {
  /**
   *
   * Retorna o prefixo da url, indicando se usuário está visualizando um board ou dataset
   * @param {Object} computedMatch {params, ...}
   * @return {String}
   *
   */
  getPathPrefix: (computedMatch) =>
    computedMatch.params.boardId
      ? `/painel/${computedMatch.params.boardId}`
      : '/dataset'
}

export const { getPathPrefix } = routerHelpers

export default routerHelpers
