/* React */
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

/* Components */
import {
  Modal,
  ModalContent,
  ModalActions,
  Button,
  LoadingSpinner,
  CustomText,
  Icon
} from '@zeta/ui/src'

/* Helpers */
import { unarchiveSubject, archiveSubject } from 'api'

/* Action Creators */
import {
  archiveSubjectAndObservations,
  unarchiveSubjectAndObservations
} from 'store/reducers/data/action-creators'

const ArchiveModal = ({
  isArchiveModalVisible,
  setIsArchiveModalVisible,
  individual,
  setIndividual
}) => {
  const dispatch = useDispatch()

  const archivedAlreadyRequested = useSelector(
    (state) => state.data.archivedAlreadyRequested
  )

  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleArchiveSubject = () => {
    setIsSubmitting(true)

    if (individual.subject.archived) {
      return unarchiveSubject({ subjectId: individual.subject.id }).then(() => {
        setIsSubmitting(false)
        setIsArchiveModalVisible(false)

        setIndividual({
          ...individual,
          subject: {
            ...individual.subject,
            archived: false
          }
        })

        dispatch(
          unarchiveSubjectAndObservations({
            subjectId: individual.subject.id,
            subjectType:
              individual.subject.subject_type === 'private_person'
                ? 'people'
                : 'company',
            relatesIds: individual.relates_id
          })
        )
      })
    }

    return archiveSubject({ subjectId: individual.subject.id }).then(() => {
      setIsSubmitting(false)
      setIsArchiveModalVisible(false)

      setIndividual({
        ...individual,
        subject: {
          ...individual.subject,
          archived: true
        }
      })

      dispatch(
        archiveSubjectAndObservations({
          subjectId: individual.subject.id,
          subjectType:
            individual.subject.subject_type === 'private_person'
              ? 'people'
              : 'company',
          relatesIds: individual.relates_id,
          archivedAlreadyRequested
        })
      )
    })
  }

  return (
    <Modal
      isVisible={isArchiveModalVisible}
      closeFn={() => setIsArchiveModalVisible(false)}
      title={
        individual.subject.archived ? 'Desarquivar perfil' : 'Arquivar perfil'
      }
    >
      <>
        <ModalContent>
          <CustomText size="0.875rem" mb="large">
            Você está certo(a) de que deseja{' '}
            {individual.subject.archived ? 'desarquivar' : 'arquivar'} esse
            perfil?
          </CustomText>
        </ModalContent>

        <ModalActions>
          <Button red onClick={() => setIsArchiveModalVisible(false)}>
            <Icon icon="close-outline" />
            <span>Cancelar</span>
          </Button>

          <Button onClick={handleArchiveSubject} isDisabled={isSubmitting}>
            <Icon icon="save-outline" />
            <span>Confirmar</span>
          </Button>

          {isSubmitting && <LoadingSpinner size={'xlarge'} ml={'small'} />}
        </ModalActions>
      </>
    </Modal>
  )
}

export default ArchiveModal
