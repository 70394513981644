/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import { Chip, defaultAvatar, theme } from '@zeta/ui/src'

/* Styled */
import {
  Wrapper,
  Table,
  NameTd,
  ThumbnailTd,
  ChipTd,
  Thumbnail
} from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'
import { logAmplitudeEvent } from '@zeta/helpers'

const EmployeesTable = ({ employees, onClickFn, computedMatch, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <Table>
        <tbody>
          {employees.map((employee) => (
            <Employee
              onClickFn={() => {
                onClickFn(employee.id)

                logAmplitudeEvent({
                  event: 'Clique em perfil',
                  eventProperties: {
                    'ID do perfil': employee.id,
                    'Nome': employee.name,
                    'Base': 'people',
                    'Local': `Listagem do escritório (${computedMatch.path})`
                  }
                })
              }}
              thumbnail={employee.thumbnail || defaultAvatar}
              name={employee.name}
              risks={employee.risks}
              key={uuidv4()}
            />
          ))}
        </tbody>
      </Table>
    </Wrapper>
  )
}

const Employee = ({ onClickFn, thumbnail, name, risks }) => {
  return (
    <tr onClick={onClickFn}>
      <ThumbnailTd>
        <Thumbnail src={thumbnail} />
      </ThumbnailTd>

      <NameTd>{name}</NameTd>

      {Object.keys(risks)
        .reverse()
        .map(
          (risk) => (
            <ChipTd key={uuidv4()}>
              <Chip
                bg={theme.colors.risk[risk].medium}
                color={theme.colors.surface.secondary.lightness}
              >
                {risks[risk]} menções
              </Chip>
            </ChipTd>
          )
          // risks[risk] > 0
          //   ? (
          //     <ChipTd key={uuidv4()}>
          //       <Chip
          //         bg={theme.colors.risk[risk].medium}
          //         color={theme.colors.surface.secondary.lightness}
          //       >
          //         {risks[risk]} menções
          //       </Chip>
          //     </ChipTd>
          //   )
          //   : <ChipTd key={uuidv4()}></ChipTd>
        )}
    </tr>
  )
}

EmployeesTable.propTypes = {
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      thumbnail: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      risks: PropTypes.shape({
        1: PropTypes.number.isRequired,
        2: PropTypes.number.isRequired,
        3: PropTypes.number.isRequired
      }),
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
    })
  ),
  onClickFn: PropTypes.func.isRequired
}

Employee.propTypes = {
  onClickFn: PropTypes.func.isRequired,
  thumbnail: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  risks: PropTypes.shape({
    1: PropTypes.number.isRequired,
    2: PropTypes.number.isRequired,
    3: PropTypes.number.isRequired
  })
}

export default EmployeesTable
