/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Styled */
import ClickAwayListener from 'react-click-away-listener'

import {
  Background,
  Wrapper,
  StyledAlert,
  Body,
  Header,
  Title,
  ModalContent,
  ModalActions
} from './styled'

/* Components */
import Icon from '../Icon'

/* Constants */
import theme from '../../theme/theme'

const Modal = ({ isVisible, closeFn, bodyWidth, title, children, alert }) =>
  isVisible && (
    <Background>
      <ClickAwayListener onClickAway={closeFn} mouseEvent='mousedown'>
        <Wrapper bodyWidth={bodyWidth}>
          {alert && <StyledAlert>{alert}</StyledAlert>}

          <Body>
            <Header>
              <Title>{title || ' '}</Title>

              <button type='button' onClick={closeFn}>
                <Icon
                  icon='close-outline'
                  size='large'
                  fill={theme.colors.brand.tertiary.light}
                />
              </button>
            </Header>

            {children}
          </Body>
        </Wrapper>
      </ClickAwayListener>
    </Background>
  )

Modal.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  closeFn: PropTypes.func.isRequired,
  title: PropTypes.string,
  alert: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  bodyWidth: PropTypes.number
}

export { ModalContent, ModalActions }

export default Modal
