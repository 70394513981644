/* React */
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

/* Components */
import {
  CustomText,
  TextWithIcon,
  Icon,
  FlexCentered,
  LoadingSpinner
} from '@zeta/ui/src'

/* Styled */
import {
  Totals,
  TotalsItem,
  TotalsItemIconWrapper,
  TotalsItemIcon,
  TotalsItemTextWrapper
} from './styled'

/* Helpers */
import { formatDate } from '@zeta/helpers'
import { getDictionaryInfo } from 'api'

export const Header = ({
  /* props */
  dictionaryId,
  reload
}) => {
  const [dictionaryInfo, setDictionaryInfo] = useState(null)
  const [isLoading, setIsLoading] = useState(null)

  useEffect(() => {
    let mounted = true

    setIsLoading(true)

    getDictionaryInfo({ dictionaryId }).then((res) => {
      if (mounted) {
        setDictionaryInfo(res.data)
        setIsLoading(false)
      }
    })

    return () => {
      mounted = false
    }
  }, [reload])

  return !isLoading && dictionaryInfo ? (
    <>
      <CustomText size="1.5rem" weight="700" mt="largest" mb="small">
        {dictionaryInfo.dictionary.name}
      </CustomText>

      <TextWithIcon mb="largest">
        <Icon icon="calendar-outline" />
        <span>
          Atualizado em:{' '}
          <strong>{formatDate(dictionaryInfo.dictionary.updated_at)}</strong>
        </span>
      </TextWithIcon>

      <Totals>
        <TotalsItem>
          <TotalsItemIconWrapper>
            <TotalsItemIcon icon="message-square-outline" />
          </TotalsItemIconWrapper>

          <TotalsItemTextWrapper>
            <span>{dictionaryInfo.quantity_term}</span>
            <span>termos únicos no dicionário</span>
          </TotalsItemTextWrapper>
        </TotalsItem>

        <TotalsItem>
          <TotalsItemIconWrapper>
            <TotalsItemIcon icon="message-square-outline" />
          </TotalsItemIconWrapper>

          <TotalsItemTextWrapper>
            <span>{dictionaryInfo.quantity_mentions}</span>
            <span>termos mencionados</span>
          </TotalsItemTextWrapper>
        </TotalsItem>

        <TotalsItem>
          <TotalsItemIconWrapper>
            <TotalsItemIcon icon="message-square-outline" />
          </TotalsItemIconWrapper>

          <TotalsItemTextWrapper>
            <span>{dictionaryInfo.term_classified}</span>
            <span>termos classificados</span>
          </TotalsItemTextWrapper>
        </TotalsItem>
      </Totals>
    </>
  ) : (
    <FlexCentered>
      <LoadingSpinner size="40" mt="largest" mb="xlarge" />
    </FlexCentered>
  )
}

Header.propTypes = {
  dictionaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired
}

export default withRouter(Header)
