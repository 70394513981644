/* React */
import React from 'react'

/* Components */
import Flatpickr from 'react-flatpickr'
import { Portuguese } from 'flatpickr/dist/l10n/pt.js'
import 'flatpickr/dist/themes/material_blue.css'

/* Styled */
import { Wrapper, InputGroup, Label, ClearButton } from './styled'

const DateRange = ({ values, setFilterValue, removeFilterValue, name }) => {
  return (
    <Wrapper>
      <InputGroup>
        <Label>Data inicial:</Label>

        <Flatpickr
          value={values[name] && values[name]['start']}
          onChange={(date) => setFilterValue(name, { ...values[name], start: date[0].toISOString().slice(0, 10) })}
          options={{
            dateFormat: 'Y-m-d',
            altInput: true,
            altFormat: 'd/m/Y',
            maxDate: values[name] && values[name]['end'],
            locale: Portuguese
          }}
        />
      </InputGroup>

      <InputGroup>
        <Label>Data final:</Label>

        <Flatpickr
          value={values[name] && values[name]['end']}
          onChange={(date) => setFilterValue(name, { ...values[name], end: date[0].toISOString().slice(0, 10) })}
          options={{
            dateFormat: 'Y-m-d',
            altInput: true,
            altFormat: 'd/m/Y',
            minDate: values[name] && values[name]['start'],
            locale: Portuguese
          }}
        />
      </InputGroup>

      <InputGroup>
        <ClearButton type='button' onClick={() => removeFilterValue(name)}>Limpar datas</ClearButton>
      </InputGroup>
    </Wrapper>
  )
}

export default DateRange
