import styled from 'styled-components'

// import { defaultProps, defaultPropsPropTypes } from '@zeta/ui/src'

export const Wrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  border-radius: ${({ theme: { radius } }) => radius.xsmall};
  margin-bottom: ${({ theme: { spacing } }) => spacing.xlarge};
`

export const IconWrapper = styled.div`
  width: ${({ theme: { spacing } }) => spacing.large};
  height: ${({ theme: { spacing } }) => spacing.large};
  border-radius: 50%;
  background-color: ${({ risk, theme: { colors } }) =>
    colors.risk[risk].lightness};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme: { spacing } }) => spacing.small};
  transform: translateY(-2px);

  svg {
    width: ${({ theme: { spacing } }) => spacing.medium};
    height: ${({ theme: { spacing } }) => spacing.medium};
    fill: ${({ risk, theme: { colors } }) => colors.risk[risk].medium};
  }
`

export const Info = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 12px;
`

export const InfoTextWrapper = styled.div`
  width: calc(100% - ${({ theme: { spacing } }) => spacing.large});
`

export const InfoTotal = styled.span`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${({ risk, theme: { colors } }) => colors.risk[risk].medium};

  @media (max-width: 575px) {
    display: block;
  }
`

export const InfoText = styled.span`
  font-size: 0.875rem;

  @media (max-width: 575px) {
    display: block;
  }
`

export const PositiveInfosBlock = styled.div`
  padding: ${({ theme: { spacing } }) => spacing.medium};
  padding-bottom: ${({ theme: { spacing } }) => spacing.xsmall};
  background-color: ${({ risk, theme: { colors } }) =>
    colors.risk[risk].medium};
  border-radius: ${({ theme: { radius } }) =>
    `0 0 ${radius.xsmall} ${radius.xsmall}`};

  ${InfoTextWrapper} {
    width: calc(100% - ${({ theme: { spacing } }) => spacing.xlarge});
  }

  ${IconWrapper} {
    background-color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  }

  ${InfoTotal}, ${InfoText} {
    color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  }
`

export const PositiveInfosRow = styled.div`
  display: flex;

  ${Info} {
    &:not(:only-child) {
      width: 50%;

      &:first-child {
        padding-right: ${({ theme: { spacing } }) => spacing.xsmall};
      }

      &:last-child {
        padding-left: ${({ theme: { spacing } }) => spacing.xsmall};
      }
    }
  }
`

// Wrapper.propTypes = {
//   ...defaultPropsPropTypes
// }
