/* React */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

/* Components */
import { Redirect } from 'react-router-dom'

import { Container, Row } from 'styled-bootstrap-grid'
import { Formik, Field, Form } from 'formik'

import {
  Card,
  Logo,
  LoadingSpinner,
  Button,
  FlexCentered,
  CustomText,
  Icon
} from '@zeta/ui/src'

import AuthWrapper from '../AuthWrapper'
import FieldGroup from '../FieldGroup'
import { ErrorMessage, SuccessMessage } from '../FieldGroup/styled'

/* Styled */
import { CardCol } from '../styled'

/* Helpers */
import { paths } from '../../constants/config'
import { requestPasswordReset } from '../../api'
import { validateEmail } from '../../helpers'

/* Constants */
const visibleError = {
  'User does not exist': 'Usuário inexistente.'
}

const ForgotPassword = ({ history }) => {
  const token = useSelector((state) => state.user.token)

  const [isRequesting, setIsRequesting] = useState(false)
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(false)

  const requestPasswordChange = (email) => {
    setIsRequesting(true)
    setSuccess(false)
    setError(null)

    requestPasswordReset({ email })
      .then(() => {
        setSuccess(true)
        setIsRequesting(false)
      })
      .catch((error) => {
        setError(error.response)
        setIsRequesting(false)
      })
  }

  return token ? (
    <Redirect to={paths.initial} />
  ) : (
    <AuthWrapper>
      <Container>
        <Row alignItems={'center'} justifyContent={'end'}>
          <CardCol md={6} lg={4}>
            <Card>
              <FlexCentered mb={'medium'}>
                <Logo size={'120'} />
              </FlexCentered>

              <Formik
                initialValues={{ email: '' }}
                onSubmit={(values) => requestPasswordChange(values.email)}
              >
                {({ values, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <CustomText size="0.875rem" mb="medium">
                      Para confirmar a sua identidade, te enviaremos um link por
                      e-mail para que você possa reconfigurar a sua senha.
                    </CustomText>

                    <Field
                      type="email"
                      name="email"
                      label="confirme seu e-mail"
                      placeholder="nome@empresa.com.br"
                      component={FieldGroup}
                      icon={() => <Icon icon={'email-outline'} />}
                      data-testid="emailInput"
                    />

                    {/* Erro */}
                    {error && error.status !== 401 && (
                      <FlexCentered mb="small">
                        <ErrorMessage>
                          {visibleError[error.data.msg]}
                        </ErrorMessage>
                      </FlexCentered>
                    )}

                    {/* Loading */}
                    {isRequesting && (
                      <FlexCentered>
                        <LoadingSpinner size={'40'} />
                      </FlexCentered>
                    )}

                    {/* Sucesso */}
                    {success && !isRequesting && (
                      <FlexCentered mb="small">
                        <SuccessMessage>
                          E-mail enviado. Acesse seu e-mail para configurar sua
                          nova senha.
                        </SuccessMessage>
                      </FlexCentered>
                    )}

                    {/* Enviar */}
                    <FlexCentered>
                      <Button
                        type="submit"
                        disabled={!validateEmail(values.email) || isRequesting}
                      >
                        Enviar link de redefinição de senha
                      </Button>
                    </FlexCentered>
                  </Form>
                )}
              </Formik>

              <FlexCentered mt="medium">
                <CustomText
                  as="button"
                  type="button"
                  onClick={() => history.push(paths.signIn)}
                  size="0.875rem"
                  override="text-decoration: underline;"
                >
                  voltar
                </CustomText>
              </FlexCentered>
            </Card>
          </CardCol>
        </Row>
      </Container>
    </AuthWrapper>
  )
}

export default ForgotPassword
