import React from 'react'
import PropTypes from 'prop-types'

import { Wrapper, Label, InputWrapper, Input, ErrorMessage } from './styled'

const FieldGroup = ({
  label,
  field,
  form: { touched, errors },
  icon: Icon,
  ...rest
}) => {
  const hasError = touched[field.name] && errors[field.name]
  const hasIcon = Icon

  return (
    <Wrapper>
      {label && <Label htmlFor={field.name}>{label}</Label>}
      <InputWrapper>
        <Input {...field} {...rest} hasError={hasError} hasIcon={hasIcon} />
        {hasIcon && <Icon />}
      </InputWrapper>
      {hasError && <ErrorMessage>{errors[field.name]}</ErrorMessage>}
    </Wrapper>
  )
}

FieldGroup.propTypes = {
  label: PropTypes.string,
  field: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func
  }).isRequired,
  form: PropTypes.shape({
    touched: PropTypes.object,
    errors: PropTypes.object
  }).isRequired
  // icon: PropTypes.object
}

export default FieldGroup
