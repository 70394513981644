/* React */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

/* Components */
import {
  Button,
  CustomBlock,
  CustomText,
  LoadingSpinner,
  FlexJustifyEnd,
  theme
} from '@zeta/ui/src'

import { Label, Input } from '../../styled'

/* Helpers */
import {
  validatePassword,
  hasUpperCaseLetter,
  hasLowerCaseLetter,
  hasNumber,
  hasSpecialCharacter,
  hasMinLength,
  getValidationColor
} from '../../../helpers'
import { resetPassword } from '../../../api'

/* Constants */
const visibleError = {
  'Wrong password': 'senha atual incorreta.',
  'Password mismatch':
    'as senhas não coincidem (nova senha / confirmar nova senha).'
}

export const ResetPassword = () => {
  const user = useSelector((state) => state.user)

  const [isRequesting, setIsRequesting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(null)

  const passwordInitialFields = {
    password: '',
    newPassword: '',
    confirmNewPassword: ''
  }

  const [passwordFields, setPasswordFields] = useState(passwordInitialFields)

  const handleResetPassword = (passwordFields) => {
    setIsRequesting(true)

    const { password, newPassword, confirmNewPassword } = passwordFields

    resetPassword({
      token: user.token,
      password,
      newPassword,
      confirmNewPassword
    })
      .then(() => {
        setIsRequesting(false)
        setSuccess(true)
        setPasswordFields(passwordInitialFields)

        setTimeout(() => setSuccess(false), 4000)
      })
      .catch((error) => {
        setIsRequesting(false)
        setError(error.response)

        setTimeout(() => setError(null), 4000)
      })
  }

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        handleResetPassword(passwordFields)
      }}
    >
      <CustomBlock my="medium">
        <Label for="password">Senha atual</Label>
        <Input
          id="password"
          type="password"
          value={passwordFields.password}
          onChange={(e) =>
            setPasswordFields({ ...passwordFields, password: e.target.value })
          }
        />
      </CustomBlock>

      <CustomBlock mb="medium">
        <Label for="newPassword">Nova senha</Label>

        <CustomText size="0.75rem" mb="xsmall">
          Sua senha deve conter:
        </CustomText>
        <CustomText
          size="0.75rem"
          mb="xsmall"
          override={`color: ${getValidationColor(
            hasUpperCaseLetter(passwordFields.newPassword)
          )};`}
        >
          &#9679; uma letra maiúscula
        </CustomText>
        <CustomText
          size="0.75rem"
          mb="xsmall"
          override={`color: ${getValidationColor(
            hasLowerCaseLetter(passwordFields.newPassword)
          )};`}
        >
          &#9679; uma letra minúscula
        </CustomText>
        <CustomText
          size="0.75rem"
          mb="xsmall"
          override={`color: ${getValidationColor(
            hasNumber(passwordFields.newPassword)
          )};`}
        >
          &#9679; um número
        </CustomText>
        <CustomText
          size="0.75rem"
          mb="xsmall"
          override={`color: ${getValidationColor(
            hasSpecialCharacter(passwordFields.newPassword)
          )};`}
        >
          &#9679; um caractere especial
        </CustomText>
        <CustomText
          size="0.75rem"
          mb="small"
          override={`color: ${getValidationColor(
            hasMinLength(passwordFields.newPassword)
          )};`}
        >
          &#9679; no mínimo 8 caracteres
        </CustomText>

        <Input
          id="newPassword"
          type="password"
          value={passwordFields.newPassword}
          onChange={(e) =>
            setPasswordFields({
              ...passwordFields,
              newPassword: e.target.value
            })
          }
        />
      </CustomBlock>

      <CustomBlock mb="xlarge">
        <Label for="confirmNewPassword">Confirmar nova senha</Label>
        <Input
          id="confirmNewPassword"
          type="password"
          value={passwordFields.confirmNewPassword}
          onChange={(e) =>
            setPasswordFields({
              ...passwordFields,
              confirmNewPassword: e.target.value
            })
          }
        />

        {passwordFields.newPassword !== '' &&
        passwordFields.newPassword !== passwordFields.confirmNewPassword ? (
          <CustomText
            size="0.75rem"
            mt="small"
            override={`color: ${theme.colors.feedback.red.darkness};`}
          >
            &#9679; as senhas não coincidem
          </CustomText>
        ) : null}
      </CustomBlock>

      {success && (
        <CustomText
          size="0.875rem"
          color={theme.colors.feedback.green.darkness}
          mb="small"
        >
          senha alterada com sucesso.
        </CustomText>
      )}
      {error && error.status !== 401 && (
        <CustomText
          size="0.875rem"
          color={theme.colors.feedback.red.darkness}
          mb="small"
        >
          {visibleError[error.data.msg]}
        </CustomText>
      )}

      <FlexJustifyEnd>
        <Button
          type="submit"
          isDisabled={
            passwordFields.password === '' ||
            !validatePassword(passwordFields.newPassword) ||
            passwordFields.newPassword !== passwordFields.confirmNewPassword ||
            passwordFields.password === passwordFields.newPassword ||
            isRequesting
          }
        >
          Alterar senha
        </Button>

        {isRequesting && <LoadingSpinner size="xlarge" ml="small" />}
      </FlexJustifyEnd>
    </form>
  )
}

export default ResetPassword
