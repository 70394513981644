import { uniq } from 'ramda'

const helpers = {
  /**
   *
   * Filtra um array de observations, retornando apenas as observations do source especificado
   * @param {Array[Object]} observations
   * @param {String} 'social' / 'web'
   * @return {Array[Object]}
   *
   */
  filterObservationsBySource: (observations, source) =>
    observations.filter((observation) => {
      if (source === 'social')
        return (
          observation.observable_type === 'Publication' ||
          observation.observable_type === 'Article' ||
          observation.observable_type === 'Bio' ||
          observation.observable_type === 'Experience'
        )
      if (source === 'web') return observation.observable_type === 'TextSite'
    }),

  /**
   *
   * Retorna um objeto dividindo as observations por seus respectivos riscos
   * @param {Array} observations
   * @return {Object} { '1': [...], '2': [...], '3': [...] }
   *
   */
  splitObservationsByRisk: (observations) =>
    observations.reduce(
      (acc, observation) => ({
        ...acc,
        [observation.risk]: acc[observation.risk]
          ? acc[observation.risk].concat(observation)
          : [].concat(observation)
      }),
      {}
    ),

  /**
   *
   * Filtra um array de observations, retornando apenas as observations do status especificado
   * @param {Array} observations array de observations de um risco específico
   * @param {Number} status 0 a 6
   * @return {Array}
   *
   */
  filterObservationsByStatus: (observations, status) =>
    observations.filter((observation) => observation.status == status),

  /**
   *
   * Formata o objeto que será passado para o componente RiskCard
   * @param {Array} observations array de observations de um risco específico
   * @return {Array}
   *
   */
  formatRiskCard: (observations = []) => ({
    totalListed: uniq(observations.map((observation) => observation.subject_id))
      .length,
    totalHits: observations.length,
    totalHitsStatus0: helpers.filterObservationsByStatus(observations, 0)
      .length,
    totalHitsStatus1: helpers.filterObservationsByStatus(observations, 1)
      .length,
    totalHitsStatus2: helpers.filterObservationsByStatus(observations, 2)
      .length,
    totalHitsStatus3: helpers.filterObservationsByStatus(observations, 3)
      .length,
    totalHitsStatus4: helpers.filterObservationsByStatus(observations, 4)
      .length,
    totalHitsStatus5: helpers.filterObservationsByStatus(observations, 5)
      .length,
    totalHitsStatus6: helpers.filterObservationsByStatus(observations, 6)
      .length,
    totalHitsStatus7: helpers.filterObservationsByStatus(observations, 7).length
  }),

  /**
   *
   * Cria um objeto com a contagem de uma prop das observations (ex: contar quantas vezes cada termo apareceu)
   * @param {Array} observations array de observations
   * @return {Object} { '123': 10, '456': 30, '789': 20 }
   *
   */
  countObservationsProp: (observations, prop) =>
    observations.reduce((acc, observation) => {
      return {
        ...acc,
        [observation[prop]]: (acc[observation[prop]] || 0) + 1
      }
    }, {}),

  /**
   *
   * Formata o ranking de termos mencionados que será passado para o componente TermsRankingTable
   * @param {Array} observations array de observations de um risco específico
   * @return {Array[Object]} [{ term: 'abc', count: 10 }, ...]
   *
   */
  formatTermsRankingTable: (observations = [], allTerms) => {
    const termsCount = countObservationsProp(observations, 'word_id')

    return Object.keys(termsCount)
      .map((termId) => ({
        term: allTerms.find((allTermsItem) => allTermsItem.id == termId).term,
        count: termsCount[termId]
      }))
      .sort((a, b) => b.count - a.count)
      .slice(0, 10)
  },

  /**
   *
   * Retorna o ranking de subjects (os 5 com mais menções de risco dentro das observations especificadas)
   * @param {Array[Object]} observations
   * @param {Array[Object]} allSubjects
   * @return {Array[Object]}
   *
   */
  getSubjectsRanking: (observations, allSubjects) => {
    const subjectsHitsCount = countObservationsProp(observations, 'subject_id')

    return Object.keys(subjectsHitsCount)
      .map((subjectId) => ({ subjectId, count: subjectsHitsCount[subjectId] }))
      .sort((a, b) => b.count - a.count)
      .slice(0, 5)
      .map((subject) =>
        allSubjects.find(
          (allSubjectsItem) => allSubjectsItem.id == subject.subjectId
        )
      )
  }
}

export const {
  filterObservationsBySource,
  splitObservationsByRisk,
  filterObservationsByStatus,
  formatRiskCard,
  countObservationsProp,
  formatTermsRankingTable,
  getSubjectsRanking
} = helpers

export default helpers
