/* Helpers */
import amplitude from 'amplitude-js'

/**
 *
 * Métodos relacionados a inicialização e log de eventos do Amplitude.
 *
 */
const amplitudeHelpers = {
  /**
   *
   * Inicializa o Amplitude
   * @param {String} key key do projeto no Amplitude
   * @param {String} userId id do usuário (utilizando e-mail atualmente)
   * @param {Object} userProperties { 'Cliente', 'Permissão do usuário' }
   * @return {Object} 
   *
   */
  initAmplitude: ({
    key,
    userId,
    userProperties
  }) => {
    const instance = amplitude.getInstance()

    instance.init(key)
    instance.setUserId(userId)
    instance.setUserProperties({ ...userProperties })

    return instance
  },

  /**
   *
   * Loga um evento no Amplitude
   * @param {String} event '[Categoria] Evento'
   * @param {Object} eventProperties { 'Termo', 'Status', 'Exemplo', ... }
   *
   */
  logAmplitudeEvent: ({
    event,
    eventProperties
  }) =>
    amplitude.getInstance()._isInitialized &&
    amplitude.getInstance().logEvent(event, { ...eventProperties })
}

export const {
  initAmplitude,
  logAmplitudeEvent
} = amplitudeHelpers

export default amplitudeHelpers
