/* React */
import React from 'react'
import { useDispatch } from 'react-redux'

/* Components */
import ReactTooltip from 'react-tooltip'

import { theme, HorizontalScroll, Chip, Icon } from '@zeta/ui/src'

import RiskBadge from 'components/RiskBadge'

/* Styled */
import { Table, StatusButton } from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'

/* Constants */
import { visibleObservationStatus } from 'constants/global'

/* Action Creators */
import { setHitToClassify } from 'store/reducers/individual/action-creators'

const TermsTable = ({ publication, social }) => {
  const dispatch = useDispatch()

  const hits = publication.hit

  const iconProps = { size: 'medium', mr: 'xsmall' }

  return (
    <>
      <HorizontalScroll>
        <Table>
          <thead>
            <tr>
              <th>Termo</th>
              <th>Risco</th>
              <th>Procedência</th>
              <th>Observação</th>
            </tr>
          </thead>

          <tbody>
            {hits.map((hit) => (
              <tr key={uuidv4()} data-testid="termRow">
                {/* Termo */}
                <td>{hit.word.term}</td>

                {/* Risco */}
                <td>
                  <RiskBadge risk={hit.word.risk} small />
                </td>

                {/* Procedência */}
                <td>
                  {hit.observation.status === 0 ? (
                    <StatusButton
                      type="button"
                      onClick={() =>
                        dispatch(setHitToClassify({ ...hit, social }))
                      }
                    >
                      <Icon
                        icon="edit-outline"
                        fill={theme.colors.brand.primary.medium}
                        {...iconProps}
                      />
                      <span>Não classificado</span>
                    </StatusButton>
                  ) : (
                    <Chip bg={theme.colors.risk[hit.word.risk].medium}>
                      <button
                        type="button"
                        onClick={() =>
                          dispatch(setHitToClassify({ ...hit, social }))
                        }
                      >
                        <Icon
                          icon="edit-outline"
                          fill={theme.colors.surface.secondary.lightness}
                          {...iconProps}
                        />
                      </button>

                      <span data-testid="statusSpan">
                        {visibleObservationStatus[hit.observation.status]}
                      </span>
                    </Chip>
                  )}
                </td>

                {/* Observação */}
                <td>
                  <ReactTooltip id="observationNotes" />

                  <Chip
                    bg={
                      hit.observation.notes
                        ? theme.colors.risk[hit.word.risk].medium
                        : theme.colors.surface.primary.dark
                    }
                    data-for="observationNotes"
                    data-tip={hit.observation.notes || ''}
                    data-testid="notesChip"
                  >
                    <Icon
                      icon="message-circle-outline"
                      size="medium"
                      fill={theme.colors.surface.secondary.lightness}
                    />
                  </Chip>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </HorizontalScroll>
    </>
  )
}

export default TermsTable
