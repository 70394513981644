/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Recharts */
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  LabelList
} from 'recharts'

import CustomTooltip from './CustomTooltip'

/* Styled */
import { Wrapper } from './styled'

/* Helpers */
import { getColor } from './helpers'
import { v4 as uuidv4 } from 'uuid'

const GroupBarChart = ({
  data,
  chartWidth,
  chartHeight,
  barGap,
  tickCount,
  margin,
  colors,
  allowDecimals,
  ...rest
}) => {
  return (
    <Wrapper {...rest}>
      <ResponsiveContainer width={chartWidth || '100%'} height={chartHeight}>
        <BarChart data={data} margin={margin} barGap={barGap} {...rest}>
          <Tooltip content={<CustomTooltip />} />
          <Legend iconType='square' iconSize={8} />

          <CartesianGrid vertical={false} />

          <XAxis type='category' dataKey='name' />
          <YAxis
            type='number'
            tickCount={tickCount}
            allowDecimals={allowDecimals}
          />

          {Object.keys(data[0])
            .slice(1)
            .map((key, i) => (
              <Bar
                dataKey={key}
                barSize={30}
                fill={getColor(i, colors)}
                isAnimationActive={false}
                key={uuidv4()}
              >
                <LabelList dataKey={key} position='top' />
              </Bar>
            ))}
        </BarChart>
      </ResponsiveContainer>
    </Wrapper>
  )
}

GroupBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  chartWidth: PropTypes.string,
  chartHeight: PropTypes.number,
  tickCount: PropTypes.number,
  margin: PropTypes.object,
  colors: PropTypes.array,
  barGap: PropTypes.number
}

GroupBarChart.defaultProps = {
  // data: [
  //   { name: 'Item 1', quantidade: 500, percentual: 30 },
  //   { name: 'Item 2', quantidade: 100, percentual: 10 },
  //   { name: 'Item 3', quantidade: 200, percentual: 20 },
  //   { name: 'Item 4', quantidade: 800, percentual: 50 },
  //   { name: 'Item 5', quantidade: 50, percentual: 38 }
  // ],
  chartHeight: 250,
  barGap: 0
}

export default GroupBarChart
