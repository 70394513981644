/* React */
import React from 'react'

/* Styled */
import { TooltipWrapper, TooltipName, TooltipValue } from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'

const CustomTooltip = ({ payload }) => {
  const getTotal = (payload) => payload.reduce((acc, cur) => acc + cur.value, 0)

  return payload[0] ? (
    payload.length > 1 ? (
      <TooltipWrapper>
        <TooltipName>{payload[0].payload.name}</TooltipName>

        {payload.map((item) => (
          <TooltipValue fill={item.color} key={uuidv4()}>
            <strong>{item.name}:</strong> {item.value} (
            {getTotal(payload) > 0
              ? ((item.value * 100) / getTotal(payload))
                  .toFixed(1)
                  .replace(/\.0/, '')
              : 0}
            %)
          </TooltipValue>
        ))}
      </TooltipWrapper>
    ) : (
      <TooltipWrapper>
        <TooltipName fill={payload[0].color || payload[0].payload.fill}>
          {payload[0].payload.name}
        </TooltipName>

        <TooltipValue>
          <strong>Quantidade:</strong> {payload[0].payload.quantidade}
        </TooltipValue>
        <TooltipValue>
          <strong>Percentual:</strong> {payload[0].payload.percentual}%
        </TooltipValue>
      </TooltipWrapper>
    )
  ) : null
}

export default CustomTooltip
