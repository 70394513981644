import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.div`
  .recharts-cartesian-grid-horizontal line,
  .recharts-cartesian-grid-vertical line,
  .recharts-cartesian-axis-tick-line,
  line.recharts-xAxis.xAxis,
  line.recharts-yAxis.yAxis {
    stroke: ${({ theme: { colors } }) => colors.surface.primary.dark};
  }

  .recharts-cartesian-axis-line {
    stroke: transparent;
  }

  .recharts-rectangle.recharts-tooltip-cursor {
    fill: ${({ theme: { colors } }) => colors.surface.secondary.light};
  }

  .recharts-default-tooltip {
    border-color: ${({ theme: { colors } }) =>
      colors.surface.primary.dark} !important;
    font-size: 0.75rem;
  }

  .recharts-tooltip-label {
    font-weight: 700;
  }

  .recharts-cartesian-axis-tick-value,
  .recharts-label {
    font-size: 0.75rem;
    fill: ${({ theme: { colors } }) => colors.type.medium};
  }

  .recharts-legend-item {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  .recharts-legend-item svg.recharts-surface {
    margin-right: ${({ theme: { spacing } }) => spacing.small} !important;
  }

  .recharts-legend-item-text {
    font-size: 0.875rem;
    color: ${({ theme: { colors } }) => colors.type.medium};
  }

  ${defaultProps}
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
