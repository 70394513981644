import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.div`
  display: flex;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  @media (min-width: 768px) {
    border-bottom: 1px solid
      ${({ theme: { colors } }) => colors.brand.primary.medium};
  }

  ${defaultProps}
`

export const Text = styled.div`
  font-weight: 700;
  line-height: 1;
  color: ${({ isActive, theme: { colors } }) =>
    isActive
      ? `${colors.brand.tertiary.light}`
      : `${colors.brand.tertiary.lightness}`};
`

export const Item = styled.button`
  display: flex;
  align-items: flex-end;
  outline: 0;
  padding-bottom: ${({ theme: { spacing } }) => spacing.small};
  border-bottom: 3px solid
    ${({ isActive, theme: { colors } }) =>
      isActive ? `${colors.brand.primary.medium}` : 'transparent'};

  @media (max-width: 767px) {
    ${({ isActive, theme: { colors } }) =>
      !isActive &&
      `
      border-bottom: 1px solid ${colors.brand.primary.medium};
    `}

    &:not(:last-child) {
      margin-bottom: ${({ theme: { spacing } }) => spacing.small};
    }
  }

  @media (min-width: 768px) {
    &:not(:last-child) {
      margin-right: ${({ theme: { spacing } }) => spacing.xlarge};
    }
  }

  &:hover {
    border-bottom-color: ${({ theme: { colors } }) =>
      colors.brand.primary.medium};
    transition: all 0.3s;

    svg {
      fill: ${({ theme: { colors } }) => colors.brand.primary.medium};
      transition: all 0.3s;
    }

    ${Text} {
      color: ${({ theme: { colors } }) => colors.brand.tertiary.light};
      transition: all 0.3s;
    }
  }

  > * {
    &:not(:last-child) {
      margin-right: ${({ theme: { spacing } }) => spacing.xsmall};
    }
  }

  svg {
    width: ${({ theme: { spacing } }) => spacing.medium};
    height: ${({ theme: { spacing } }) => spacing.medium};
    fill: ${({ isActive, theme: { colors } }) =>
      isActive
        ? `${colors.brand.primary.medium}`
        : `${colors.brand.tertiary.lightness}`};
    transform: translateY(-2px);
  }
`

export const Count = styled.div`
  background-color: ${({ theme: { colors } }) => colors.brand.primary.medium};
  color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  padding: ${({ theme: { spacing } }) => spacing.xsmall};
  border-radius: ${({ theme: { radius } }) => radius.xsmall};
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1;
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
