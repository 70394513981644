/* React */
import React from 'react'

/* Styled */
import { Overlay } from './styled'

/* Parts */
import Filters from './Filters'
// import Boards from './Boards'

const SidebarTabs = ({ activeTab, setActiveTab }) => {
  const closeTabAndGoToTop = () => {
    setActiveTab(null)
    window.scrollTo(0, 0)
  }

  return (
    <>
      {activeTab && <Overlay />}

      {activeTab === 'filters' && (
        <Filters closeTabAndGoToTop={closeTabAndGoToTop} />
      )}

      {/* {activeTab === 'boards' &&
        <Boards closeTabAndGoToTop={closeTabAndGoToTop} />} */}
    </>
  )
}

export default SidebarTabs
