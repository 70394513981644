import styled from 'styled-components'

export const CheckboxSelect = styled.div`
  width: ${({ theme: { spacing } }) => spacing.medium};
  height: ${({ theme: { spacing } }) => spacing.medium};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  border-radius: ${({ theme: { radius } }) => radius.smallest};
`

export const CheckboxLabel = styled.div`
  font-size: 0.75rem;
  color: ${({ theme: { colors } }) => colors.brand.tertiary.lightness};
  width: calc(100% - 24px);
  margin-left: ${({ theme: { spacing } }) => spacing.small};
  line-height: 1;
`

export const CheckboxWrapper = styled.button.attrs(() => ({
  type: 'button'
}))`
  display: flex;
  align-items: center;
  outline: 0;
  text-align: left;

  &:not(:last-child) {
    margin-bottom: ${({ theme: { spacing } }) => spacing.medium};
  }

  ${CheckboxSelect} {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ isSelected, theme: { colors } }) =>
    isSelected &&
    `
      ${CheckboxSelect} {
        background-color: ${colors.brand.primary.dark};
        border-color: ${colors.brand.primary.dark};

        svg {
          fill: ${colors.surface.secondary.lightness};
        }
      }

      ${CheckboxLabel} {
        color: ${colors.brand.tertiary.dark};
      }
    `}
`
