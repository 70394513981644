import styled from 'styled-components'

import { Icon } from '@zeta/ui/src'

export const Totals = styled.div`
  background-color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  border-radius: ${({ theme: { radius } }) => radius.xsmall};
  padding: ${({ theme: { spacing } }) => spacing.large} 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: ${({ theme: { spacing } }) => spacing.large};
`

export const TotalsItem = styled.div`
  display: flex;
  align-items: center;
  padding: 0 ${({ theme: { spacing } }) => spacing.large};

  @media (max-width: 991px) {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: ${({ theme: { spacing } }) => spacing.large};
    }
  }
`

export const TotalsItemIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme: { spacing } }) => spacing.large};
  height: ${({ theme: { spacing } }) => spacing.large};
  border-radius: 50%;
  background-color: ${({ theme: { colors } }) => colors.brand.primary.lightness};
  margin-right: ${({ theme: { spacing } }) => spacing.small};
`

export const TotalsItemIcon = styled(Icon)`
  > svg {
    width: ${({ theme: { spacing } }) => spacing.medium};
    height: ${({ theme: { spacing } }) => spacing.medium};
    fill: ${({ theme: { colors } }) => colors.brand.primary.medium};
  }
`

export const TotalsItemTextWrapper = styled.div`
  width: calc(100% - ${({ theme: { spacing } }) => spacing.xlarge});

  > span {
    &:first-child {
      font-size: 1.25rem;
      font-weight: 700;
      color: ${({ theme: { colors } }) => colors.brand.primary.medium};
      margin-right: ${({ theme: { spacing } }) => spacing.xsmall};
    }

    &:last-child {
      font-size: 0.875rem;
    }
  }
`
