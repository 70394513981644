import styled from 'styled-components'

import { defaultProps, defaultPropsPropTypes } from '@zeta/ui/src'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({ theme: { colors } }) => colors.surface.secondary.darkness};
  padding: ${({ theme: { spacing } }) => spacing.small};

  ${defaultProps}
`

export const Display = styled.div`
  font-size: 0.75rem;
  color: ${({ theme: { colors } }) => colors.brand.tertiary.lightness};
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
