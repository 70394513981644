/* React */
import React from 'react'

/* Components */
import { Row, Col } from 'styled-bootstrap-grid'

import { CustomBlock } from '@zeta/ui/src'

import Selection from 'components/filters/Selection'

/* Constants */
import { selectionOptions, visibleLabels } from 'constants/filters'

const OuvidoriaFilters = ({ ...props }) => {
  return (
    <CustomBlock mt="medium">
      <Row>
        <Col lg={4}>
          <Selection
            display="group"
            selectionType="checkbox"
            label={visibleLabels['ouvidoria']}
            name="ouvidoria"
            items={selectionOptions['ouvidoria']}
            {...props}
            mb="xlarge"
          />
        </Col>

        <Col lg={4}>
          <Selection
            display="group"
            selectionType="checkbox"
            label={visibleLabels['ouvidoria_status']}
            name="ouvidoria_status"
            items={selectionOptions['ouvidoria_status']}
            {...props}
            mb="xlarge"
          />
        </Col>
      </Row>
    </CustomBlock>
  )
}

export default OuvidoriaFilters
