import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.button.attrs(() => ({
  type: 'button'
}))`
  display: inline-flex;
  align-items: center;
  height: ${({ theme: { spacing } }) => spacing.large};
  padding: 0 12px;
  border-radius: ${({ theme: { radius } }) => radius.medium};
  background: ${({ bg, theme: { colors } }) =>
    bg || colors.brand.primary.medium};
  color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  font-size: 0.75rem;

  > span {
    &:not(:only-child) {
      &:first-child {
        margin-right: ${({ theme: { spacing } }) => spacing.xsmall};
        transform: translateY(1px);
      }
      &:last-child {
        margin-left: ${({ theme: { spacing } }) => spacing.xsmall};
      }
    }
  }

  svg {
    height: ${({ theme: { spacing } }) => spacing.medium};
    fill: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
    transform: translateY(-1px);
  }

  ${defaultProps}
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
