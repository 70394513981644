/* React */
import React from 'react'
import { useSelector } from 'react-redux'

/* Router */
import { Redirect } from 'react-router-dom'

/* Constants */
import { paths } from '../../constants/config'

const PrivateRoute = ({
  permissions,
  initialPath,
  component: Component,
  children,
  ...rest
}) => {
  const token = useSelector((state) => state.user.token)
  const userPermission = useSelector((state) => state.user.permission)

  /* caso usuário não esteja logado */
  if (!token) return <Redirect to={{ pathname: paths.signIn }} />

  /* caso usuário não tenha permissão de acesso à rota */
  const hasPermission = permissions.find(
    (permission) => permission === userPermission
  )

  if (!hasPermission) return <Redirect to={{ pathname: initialPath }} />

  /* caso nenhuma restrição se aplique */
  return Component ? <Component {...rest} /> : children
}

export default PrivateRoute
