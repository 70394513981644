/* Types */
import {
  SIGN_IN_REQUESTED,
  SIGN_OUT,
  UPDATE_USER_INFO_REQUESTED,
  SET_USERS
} from './action-types'

/* Helpers */
import cookie from 'react-cookies'

/* Constants */
import { authCookie } from '../../../constants/config'
import { axiosInstance } from '../../../api'

export const signIn = ({ email, password, history, initialPath }) => ({
  type: SIGN_IN_REQUESTED,
  email,
  password,
  history,
  initialPath
})

export const signOut = () => {
  axiosInstance.defaults.headers.common['Authorization'] = ''

  cookie.remove(authCookie, { path: '/' })

  return {
    type: SIGN_OUT
  }
}

export const updateUserInfo = ({ firstName, lastName }) => ({
  type: UPDATE_USER_INFO_REQUESTED,
  firstName,
  lastName
})

export const setUsers = (users) => ({
  type: SET_USERS,
  users
})
