import styled from 'styled-components'

import { defaultProps, defaultPropsPropTypes } from '@zeta/ui/src'

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  .flatpickr-input,
  .flatpickr-input + .form-control.input {
    height: ${({ theme: { spacing } }) => spacing.large};
    line-height: ${({ theme: { spacing } }) => spacing.large};
    border-radius: ${({ theme: { radius } }) => radius.smallest};
    padding-left: ${({ theme: { spacing } }) => spacing.xsmall};
    padding-right: ${({ theme: { spacing } }) => spacing.xsmall};
  }

  ${defaultProps}
`

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme: { spacing } }) => spacing.small};

  &:not(:last-child) {
    margin-right: ${({ theme: { spacing } }) => spacing.medium};
  }
`

export const Label = styled.div`
  font-size: 0.75rem;
  color: ${({ theme: { colors } }) => colors.brand.tertiary.light};
  margin-right: ${({ theme: { spacing } }) => spacing.small};
`

export const ClearButton = styled.button`
  font-size: 0.75rem;
  color: ${({ theme: { colors } }) => colors.brand.primary.medium};
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
