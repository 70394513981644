/* React */
import React from 'react'

/* Components */
import { Scrollbars } from 'react-custom-scrollbars'

const CustomScrollbars = ({ style, renderViewStyle, containerId, children }) => {
  return (
    <Scrollbars
      style={style}
      renderView={props => <div id={containerId} style={{ ...props.style, ...renderViewStyle }} />}
      renderTrackVertical={props => <div {...props} style={{ backgroundColor: '#eee', position: 'absolute', width: '6px', right: '0', top: '0', borderRadius: '4px', height: '100%', zIndex: '1', ...props.style }} />}
      renderTrackHorizontal={props => <div {...props} style={{ backgroundColor: '#eee', position: 'absolute', height: '6px', left: '0', bottom: '0', borderRadius: '4px', width: '100%', zIndex: '1', ...props.style }} />}
      hideTracksWhenNotNeeded
    >
      {children}
    </Scrollbars>
  )
}

export default CustomScrollbars
