import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.button.attrs(() => ({
  type: 'button'
}))`
  ${({ isSquare, theme: { spacing } }) =>
    isSquare ? `width: 40px;` : `padding: 0 ${spacing.medium};`}
  height: 40px;
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  background-color: ${({ theme: { colors } }) =>
    colors.surface.primary.lightness};
  color: ${({ theme: { colors } }) => colors.brand.primary.medium};
  font-size: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    &:not(:only-child) {
      &:first-child {
        margin-right: ${({ theme: { spacing } }) => spacing.xsmall};
      }
      &:last-child {
        margin-left: ${({ theme: { spacing } }) => spacing.xsmall};
      }
    }
  }

  svg {
    width: ${({ theme: { spacing } }) => spacing.medium};
    height: ${({ theme: { spacing } }) => spacing.medium};
    fill: ${({ theme: { colors } }) => colors.brand.primary.medium};
  }

  ${defaultProps}
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
