export const formatData = (data, reqParameter) => {
  if (reqParameter === 'risk') {
    return Object.keys(data)
      .map(riskKey => Object.keys(data[riskKey]).reduce((acc, statusKey) => acc + data[riskKey][statusKey], 0))
      .reduce((acc, cur) => acc + cur, 0) !== 0
      ? [
        { name: 'Procedentes', 'Risco alto': data.risk3.proceeding, 'Risco médio': data.risk2.proceeding, 'Risco baixo': data.risk1.proceeding },
        { name: 'Improcedentes', 'Risco alto': data.risk3.unfounded, 'Risco médio': data.risk2.unfounded, 'Risco baixo': data.risk1.unfounded },
        { name: 'Investigação', 'Risco alto': data.risk3.investigation, 'Risco médio': data.risk2.investigation, 'Risco baixo': data.risk1.investigation },
        { name: 'Inconclusivo', 'Risco alto': data.risk3.inconclusive, 'Risco médio': data.risk2.inconclusive, 'Risco baixo': data.risk1.inconclusive },
        { name: 'Não classificados', 'Risco alto': data.risk3.unclassified, 'Risco médio': data.risk2.unclassified, 'Risco baixo': data.risk1.unclassified }
      ]
      : []
  }

  if (reqParameter === 'date') {
    return Object.keys(data.date)
      .map(dateKey => Object.keys(data.date[dateKey]).reduce((acc, statusKey) => acc + data.date[dateKey][statusKey], 0))
      .reduce((acc, cur) => acc + cur, 0) !== 0
      ? Object.keys(data.date).reduce((acc, cur) =>
        acc.concat({
          name: cur,
          'Procedente': data.date[cur].proceeding,
          'Improcedente': data.date[cur].unfounded,
          'Investigação': data.date[cur].investigation,
          'Inconclusivo': data.date[cur].inconclusive,
          'Não classificado': data.date[cur].unclassified
        }), [])
      : []
  }
}

export const getMonthName = monthNumber => {
  switch (monthNumber) {
    case '01':
      return 'Jan'
    case '02':
      return 'Fev'
    case '03':
      return 'Mar'
    case '04':
      return 'Abr'
    case '05':
      return 'Mai'
    case '06':
      return 'Jun'
    case '07':
      return 'Jul'
    case '08':
      return 'Ago'
    case '09':
      return 'Set'
    case '10':
      return 'Out'
    case '11':
      return 'Nov'
    case '12':
      return 'Dez'
  }
}
