/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Styled */
import { Wrapper } from './styled'

/* Components */
import SVGLoadingSpinner from '../svg/SVGLoadingSpinner'

const LoadingSpinner = (props) => {
  return (
    <Wrapper {...props}>
      <SVGLoadingSpinner />
    </Wrapper>
  )
}

LoadingSpinner.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export default LoadingSpinner
