/* React */
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

/* Components */
import {
  SearchForm,
  CustomBlock,
  CustomText,
  Button,
  Icon
} from '@zeta/ui/src'

/* Helpers */
import { path } from 'ramda'
import { logAmplitudeEvent } from '@zeta/helpers'

/* Action Creators */
import { setSearchQuery } from 'store/reducers/data/action-creators'

const placeholder = {
  people: 'Busque por NOME ou CPF',
  company: 'Busque por NOME ou CNPJ'
}

export const ListSearch = ({
  subjectType
}) => {
  const dispatch = useDispatch()

  const searchQuery = useSelector(state => state.data.searchQuery)
  const activeUnit = useSelector(state => state.data.activeUnit)

  const [formValue, setFormValue] = useState('')

  return (
    <>
      <SearchForm
        onSubmit={() => {
          dispatch(setSearchQuery(formValue.trim(), subjectType))
          setFormValue('')

          logAmplitudeEvent({
            event: 'Busca aplicada',
            eventProperties: {
              'Busca': formValue.trim(),
              'Base': subjectType
            }
          })
        }}
        onChange={(e) => setFormValue(e.target.value)}
        value={formValue}
        placeholder={placeholder[subjectType]}
      />

      {path([activeUnit, subjectType], searchQuery) &&
        <CustomBlock
          override='display: flex; align-items: center;'
          mt='small'
        >
          <CustomText size='0.75rem' mr='medium'>
            Exibindo resultados de busca para <strong>"{searchQuery[activeUnit][subjectType]}"</strong>
          </CustomText>

          <Button
            onClick={() => {
              dispatch(setSearchQuery('', subjectType))
              setFormValue('')
            }}
            small
            red
          >
            <Icon icon='close-outline' />
            <span>Limpar busca</span>
          </Button>
        </CustomBlock>}
    </>
  )
}

export default ListSearch
