import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > svg {
    ${({ size, width, theme: { spacing } }) =>
      (size || width) &&
      `width: ${
        size ? spacing[size] || size + 'px' : spacing[width] || width + 'px'
      };`}
    ${({ size, height, theme: { spacing } }) =>
      (size || height) &&
      `height: ${
        size ? spacing[size] || size + 'px' : spacing[height] || height + 'px'
      };`}
    ${({ fill }) => fill && `fill: ${fill};`}
  }

  ${defaultProps}
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
