import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../../defaultProps'

export const Wrapper = styled.div`
  ${defaultProps}
`

export const Background = styled.circle`
  fill: none;
  stroke: ${({ theme: { colors } }) => colors.surface.secondary.medium};
`

export const Progress = styled.circle`
  fill: none;
  stroke: ${({ fill, theme: { colors } }) =>
    fill || colors.brand.primary.medium};
  stroke-linecap: butt;
  stroke-linejoin: butt;
`

export const Percentage = styled.text`
  font-size: 1.5rem;
  font-weight: 700;
  fill: ${({ theme: { colors } }) => colors.type.medium};
`

export const Text = styled.text`
  font-size: 0.75rem;
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
