/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Styled */
import { Wrapper } from './styled'

/* Icons */
import icons from '../../icons'

const Icon = ({ icon, ...rest }) => {
  return <Wrapper {...rest}>{icons[icon]}</Wrapper>
}

Icon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // size já aplica width e height ao mesmo tempo
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // caso não tenha aplicado size
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // caso não tenha aplicado size
  fill: PropTypes.string
}

export default Icon
