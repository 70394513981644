import styled from 'styled-components'

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead {
    th {
      font-size: 0.875rem;
      text-align: left;
      padding-bottom: ${({ theme: { spacing } }) => spacing.medium};
      padding-right: ${({ theme: { spacing } }) => spacing.medium};
      /* white-space: nowrap; */
    }
  }

  tbody {
    td {
      font-size: 0.875rem;
      padding-bottom: 12px;
      padding-right: ${({ theme: { spacing } }) => spacing.medium};
    }

    tr:last-child {
      td {
        padding-bottom: 0;
      }
    }
  }
`

export const StatusButton = styled.button`
  outline: 0;
  display: flex;
  align-items: center;
  color: ${({ theme: { colors } }) => colors.brand.primary.medium};
  white-space: nowrap;
`
