/* React */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

/* Components */
import { Row, Col } from 'styled-bootstrap-grid'

import {
  Card,
  Button,
  CustomBlock,
  CustomText,
  LoadingSpinner,
  FlexAlignCenter,
  FlexJustifyEnd,
  theme
} from '@zeta/ui/src'

import { TabButton, Label, Input } from '../styled'

import Profile from './Profile'
import ResetPassword from './ResetPassword'

/* Constants */
const PROFILE = 'PROFILE'
const RESET_PASSWORD = 'RESET_PASSWORD'

export const ManageProfile = () => {
  const [activeTab, setActiveTab] = useState(PROFILE)

  return (
    <>
      <Row>
        <Col md={6} mdOffset={3}>
          <Card mb='xlarge'>
            <FlexAlignCenter>
              <TabButton isActive={activeTab === PROFILE} onClick={() => setActiveTab(PROFILE)}>Perfil</TabButton>
              <TabButton isActive={activeTab === RESET_PASSWORD} onClick={() => setActiveTab(RESET_PASSWORD)}>Alterar senha</TabButton>
            </FlexAlignCenter>

            {activeTab === PROFILE && <Profile />}

            {activeTab === RESET_PASSWORD && <ResetPassword />}
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default ManageProfile
