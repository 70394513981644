/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import {
  CustomText,
  IconWrapper,
  Icon,
  FlexAlignCenter,
  theme
} from '@zeta/ui/src'

/* Styled */
import { Wrapper } from './styled'

const CustomIconWrapper = ({ children }) => (
  <IconWrapper
    size='large'
    iconSize='14px'
    bg={theme.colors.brand.primary.lightness}
    fill={theme.colors.brand.primary.medium}
    mr='small'
  >
    {children}
  </IconWrapper>
)

const Info = ({ children }) => (
  <CustomText
    size='0.875rem'
    override={`width: calc(100% - ${theme.spacing.xlarge});`}
  >
    {children}
  </CustomText>
)

const Total = ({ children }) => (
  <CustomText
    as='span'
    size='0.875rem'
    weight='700'
    color={theme.colors.brand.primary.medium}
  >
    {children}
  </CustomText>
)

const DictionaryItem = ({ id, name, totalTerms, updatedAt, ...rest }) => {
  return (
    <Wrapper {...rest}>
      <CustomText
        size='0.75rem'
        weight='700'
        color={theme.colors.brand.primary.medium}
      >
        Dicionário #{id}
      </CustomText>

      <CustomText weight='700'>{name}</CustomText>

      <FlexAlignCenter mt='medium'>
        <CustomIconWrapper>
          <Icon icon='message-square-outline' />
        </CustomIconWrapper>

        <Info>
          Quantidade de termos: <Total>{totalTerms}</Total>
        </Info>
      </FlexAlignCenter>

      <FlexAlignCenter mt='small'>
        <CustomIconWrapper>
          <Icon icon='calendar-outline' />
        </CustomIconWrapper>

        <Info>
          Última atualização: <Total>{updatedAt}</Total>
        </Info>
      </FlexAlignCenter>
    </Wrapper>
  )
}

DictionaryItem.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  totalTerms: PropTypes.number.isRequired,
  updatedAt: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export default DictionaryItem
