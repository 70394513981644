/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Styled */
import { Wrapper } from './styled'

const getVisibleRisk = (risk, small) => {
  if (risk === 1 || risk === '1') return `baixo${small ? '' : ' risco'}`
  if (risk === 2 || risk === '2') return `médio${small ? '' : ' risco'}`
  if (risk === 3 || risk === '3') return `alto${small ? '' : ' risco'}`
  if (risk === 'site') return 'site'
}

const RiskBadge = ({
  risk,
  small,
  ...rest
}) => {
  return (
    <Wrapper risk={risk} small={small} {...rest}>
      {getVisibleRisk(risk, small)}
    </Wrapper>
  )
}

RiskBadge.propTypes = {
  risk: PropTypes.oneOf([1, 2, 3, '1', '2', '3', 'site']).isRequired
}

export default RiskBadge
