/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import InputMask from 'react-input-mask'
import CustomBlock from '../../helpers/CustomBlock'

/* Styled */
import {
  Wrapper,
  Label,
  Legend,
  InputWrapper,
  Input,
  Textarea,
  RadioItem,
  Radio,
  RadioLabel,
  ErrorMessage
} from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'

const FieldGroup = ({
  inputElement,
  label,
  legend,
  inlineLegend,
  options,
  mask,
  error,
  field,
  form: { touched, errors, values },
  ...rest
}) => {
  const hasError = error || (touched[field.name] && errors[field.name])

  return (
    <Wrapper>
      {label && (
        <CustomBlock mb='xsmall'>
          <Label htmlFor={field.name}>
            {label}
            {inlineLegend && <Legend as='span'> {inlineLegend}</Legend>}
          </Label>

          {legend && <Legend>{legend}</Legend>}
        </CustomBlock>
      )}

      <InputWrapper
        override={
          inputElement === 'radio'
            ? `
                @media (min-width: 768px) {
                  flex-wrap: wrap;
                }

                @media (max-width: 767px) {
                  flex-direction: column;
                }
              `
            : null
        }
      >
        {inputElement === 'input' ? (
          mask ? (
            <InputMask mask={mask} {...field} {...rest}>
              {(inputProps) => <Input {...field} {...rest} {...inputProps} />}
            </InputMask>
          ) : (
            <Input {...field} {...rest} />
          )
        ) : null}

        {inputElement === 'textarea' && <Textarea {...field} {...rest} />}

        {inputElement === 'radio' &&
          options.map((option) => (
            <RadioItem
              isChecked={values[field.name] == option.value}
              key={uuidv4()}
            >
              <Radio
                {...field}
                {...rest}
                value={option.value}
                id={option.value}
                checked={values[field.name] == option.value}
              />
              <RadioLabel htmlFor={option.value}>{option.label}</RadioLabel>
            </RadioItem>
          ))}
      </InputWrapper>

      {hasError && <ErrorMessage>{error || errors[field.name]}</ErrorMessage>}
    </Wrapper>
  )
}

FieldGroup.propTypes = {
  inputElement: PropTypes.oneOf(['input', 'textarea', 'radio']).isRequired,
  type: PropTypes.string, // obrigatório caso inputElement seja "input"
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired
    })
  ), // obrigatório caso inputElement seja "radio"
  label: PropTypes.string,
  legend: PropTypes.string,
  inlineLegend: PropTypes.string,
  mask: PropTypes.string,
  error: PropTypes.string
}

export default FieldGroup
