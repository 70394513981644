import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  margin-top: ${({ theme: { spacing } }) => spacing.xlarge};
  margin-bottom: ${({ theme: { spacing } }) => spacing.large};

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (min-width: 768px) {
    align-items: center;
    justify-content: space-between;
  }

  > div {
    display: flex;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;

      > button {
        margin-bottom: ${({ theme: { spacing } }) => spacing.small};
      }
    }

    @media (min-width: 768px) {
      align-items: center;
    }
  }
`
