/* React */
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

/* Components */
import { Container, Row, Col } from 'styled-bootstrap-grid'

import {
  HeadlineCard,
  SectionHeadline,
  FlexCentered,
  LoadingSpinner
} from '@zeta/ui/src'

import DictionaryItem from './DictionaryItem'

import Menu from 'components/sidebar/Menu'
import ContentWrapper from 'components/ContentWrapper'
import Footer from 'components/Footer'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'
import { formatDate } from '@zeta/helpers'
import { subjectTypes } from 'constants/config'
import { getDictionaries } from 'api'

export const DictionariesList = ({
  /* router */
  history
}) => {
  const [dictionariesList, setDictionariesList] = useState(null)
  const [isLoadingDictionaries, setIsLoadingDictionaries] = useState(false)

  useEffect(() => {
    let mounted = true

    setIsLoadingDictionaries(true)

    getDictionaries().then((res) => {
      if (mounted) {
        setIsLoadingDictionaries(false)
        setDictionariesList(res.data)
      }
    })

    return () => {
      mounted = false
    }
  }, [])

  const hasDictionaries = () =>
    !isLoadingDictionaries && dictionariesList && dictionariesList.length > 0

  return (
    <>
      <Menu />

      <ContentWrapper>
        <Container>
          <Row>
            <Col>
              <HeadlineCard
                headline="Dicionários"
                icon="book-outline"
                mt="largest"
              />

              {isLoadingDictionaries && (
                <FlexCentered>
                  <LoadingSpinner size={'40'} mt="xlarge" />
                </FlexCentered>
              )}
            </Col>

            {!isLoadingDictionaries && subjectTypes.people && (
              <Col>
                <SectionHeadline mt="xlarge">Pessoas</SectionHeadline>
              </Col>
            )}

            {hasDictionaries() &&
              subjectTypes.people &&
              dictionariesList
                .filter(
                  (dictionary) => dictionary.subject_type === 'private_person'
                )
                .map((dictionary) => (
                  <Col lg={4} md={6} key={uuidv4()}>
                    <DictionaryItem
                      id={dictionary.id}
                      name={dictionary.name}
                      totalTerms={dictionary.total_words}
                      updatedAt={formatDate(dictionary.updated_at)}
                      onClick={() =>
                        history.push(`/dicionario/people/${dictionary.id}`)
                      }
                      mb="xlarge"
                    />
                  </Col>
                ))}

            {!isLoadingDictionaries && subjectTypes.company && (
              <Col>
                <SectionHeadline>Escritórios</SectionHeadline>
              </Col>
            )}

            {hasDictionaries() &&
              subjectTypes.company &&
              dictionariesList
                .filter(
                  (dictionary) => dictionary.subject_type === 'legal_entity'
                )
                .map((dictionary) => (
                  <Col lg={4} md={6} key={uuidv4()}>
                    <DictionaryItem
                      id={dictionary.id}
                      name={dictionary.name}
                      totalTerms={dictionary.total_words}
                      updatedAt={formatDate(dictionary.updated_at)}
                      onClick={() =>
                        history.push(`/dicionario/company/${dictionary.id}`)
                      }
                      mb="xlarge"
                    />
                  </Col>
                ))}
          </Row>
        </Container>

        {/* Footer */}
        <Footer />
      </ContentWrapper>
    </>
  )
}

DictionariesList.propTypes = {
  history: PropTypes.object.isRequired
}

export default withRouter(DictionariesList)
