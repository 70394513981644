/* React */
import React from 'react'

/* Components */
import {
  CustomText,
  SVGLogoFooter,
  theme
} from '@zeta/ui/src'

/* Styled */
import { Wrapper } from './styled'

const Footer = ({ ...rest }) => {
  return (
    <Wrapper {...rest}>
      <SVGLogoFooter />

      <CustomText
        size='0.75rem'
        color={theme.colors.surface.primary.darkness}
        align='center'
        mt='large'
      >
        dúvidas, dificuldades ou sugestões? nos escreva em{' '}
        <CustomText
          as='a'
          href='mailto:suporte@refinariadedados.com.br'
          size='0.75rem'
          weight='700'
          color={theme.colors.surface.primary.darkness}
        >
          suporte@refinariadedados.com.br
        </CustomText>
      </CustomText>
    </Wrapper>
  )
}

export default Footer
