/* React */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

/* Components */
import { HorizontalScroll, Icon, theme } from '@zeta/ui/src'

import RiskBadge from 'components/RiskBadge'

/* Styled */
import {
  Wrapper,
  Table,
  ExpandButton,
  SocialsIcons,
  SocialIcon
} from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'
import {
  mergeHits,
  mergePublications,
  getUniqTerms,
  formatSocialRanking,
  filterPublications
} from '../helpers'

/* Selectors */
const termsSelector = createSelector(
  (state) => state.individual.publicationsBySocialNetwork,
  (state) => state.individual.biosBySocialNetwork,
  (state) => state.individual.textSites,
  (state) => state.individual.viewFilteredPublications,
  (state) => state.data.filters,
  (_, source) => source,
  (
    publicationsBySocialNetwork,
    biosBySocialNetwork,
    textSites,
    viewFilteredPublications,
    filters,
    source
  ) => {
    const mergedHits = mergeHits(
      source === 'social'
        ? filterPublications(
            [
              ...mergePublications(publicationsBySocialNetwork),
              ...mergePublications(biosBySocialNetwork)
            ],
            viewFilteredPublications,
            filters
          )
        : filterPublications(textSites, viewFilteredPublications, filters)
    )

    return formatSocialRanking(getUniqTerms(mergedHits), mergedHits)
  }
)

const IndividualTermsTable = ({ source, initialShowCount = 10 }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const terms = useSelector((state) => termsSelector(state, source))

  const getTerms = () => (isExpanded ? terms : terms.slice(0, initialShowCount))

  return terms.length > 0 ? (
    <Wrapper>
      <HorizontalScroll>
        <Table>
          <thead>
            <tr>
              {terms[0].risk && <th>RISCO</th>}
              <th>TERMO</th>
              {terms[0].socials && source === 'social' && <th>APARECE EM</th>}
              <th>QUANTIDADE</th>
            </tr>
          </thead>

          <tbody>
            {getTerms().map((term) => (
              <tr key={uuidv4()}>
                {term.risk && (
                  <td>
                    <RiskBadge risk={term.risk} />
                  </td>
                )}

                <td>{term.term}</td>

                {term.socials && source === 'social' && (
                  <td>
                    <SocialsIcons>
                      {term.socials.facebook && (
                        <SocialIcon icon="fa-facebook" />
                      )}
                      {term.socials.twitter && <SocialIcon icon="fa-twitter" />}
                      {term.socials.linkedin && (
                        <SocialIcon icon="fa-linkedin" />
                      )}
                      {term.socials.instagram && (
                        <SocialIcon icon="fa-instagram" />
                      )}
                      {term.socials.youtube && <SocialIcon icon="fa-youtube" />}
                    </SocialsIcons>
                  </td>
                )}

                <td>{term.total}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </HorizontalScroll>

      {terms.length > initialShowCount && (
        <ExpandButton type="button" onClick={() => setIsExpanded(!isExpanded)}>
          <span>{isExpanded ? 'Ver menos' : 'Ver tudo'}</span>

          <Icon
            icon={
              isExpanded
                ? 'arrow-ios-upward-outline'
                : 'arrow-ios-downward-outline'
            }
            size="medium"
            fill={theme.colors.brand.primary.medium}
            ml="xsmall"
            override={'transform: translateY(-1px);'}
          />
        </ExpandButton>
      )}
    </Wrapper>
  ) : null
}

export default IndividualTermsTable
