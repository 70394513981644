/* React */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

/* Components */
import {
  Card,
  CustomText,
  ButtonAlt,
  Icon,
  LoadingSpinner,
  FlexSpaceBetween,
  FlexAlignCenter,
  FlexCentered,
  DropdownSelect,
  theme
} from '@zeta/ui/src'

/* Helpers */
import domtoimage from 'dom-to-image'
import { formatData, getMonthName } from './helpers'
import { getDictionaryObsData } from 'api'

/* Constants */
import dateRanges from 'constants/collectionDateRanges'

const Label = ({ children }) => (
  <CustomText
    size="0.75rem"
    color={theme.colors.brand.tertiary.lightness}
    mr="xsmall"
  >
    {children}
  </CustomText>
)

const DictionaryGraphCard = ({
  /* props */
  headline,
  dictionaryId,
  reqParameter,
  graph,
  id,
  isDatePost,
  ...rest
}) => {
  const [dateRange, setDateRange] = useState({ start: '', end: '' })
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let mounted = true

    setIsLoading(true)

    getDictionaryObsData({
      dictionaryId,
      parameter: reqParameter,
      startDate: dateRange.start,
      endDate: dateRange.end,
      isDatePost
    }).then((res) => {
      if (mounted) {
        setData(formatData(res.data, reqParameter))
        setIsLoading(false)
      }
    })

    return () => {
      mounted = false
    }
  }, [dateRange])

  return (
    <Card id={id} {...rest}>
      <FlexSpaceBetween mb="small">
        <CustomText size="0.875rem">{headline}</CustomText>

        <ButtonAlt
          onClick={async () => {
            const dataUrl = await domtoimage.toJpeg(document.getElementById(id))
            const link = document.createElement('a')
            link.download = `${headline}.jpeg`
            link.href = dataUrl
            link.click()
          }}
          isSquare
        >
          <Icon icon="download-outline" />
        </ButtonAlt>
      </FlexSpaceBetween>

      <FlexAlignCenter>
        <FlexAlignCenter mr="medium">
          <Label>De</Label>

          <DropdownSelect
            items={dateRanges.map((dateRangesItem) => ({
              text: `${dateRangesItem[0].split('-')[2]} de ${getMonthName(
                dateRangesItem[0].split('-')[1]
              )} de ${dateRangesItem[0].split('-')[0]}`,
              fn: () =>
                setDateRange({ ...dateRange, start: dateRangesItem[0] }),
              isDisabled: dateRange.end && dateRange.end < dateRangesItem[0]
            }))}
            displayText="Selecione"
            displayWidth={140}
            maxItemsVisible={6}
          />
        </FlexAlignCenter>

        <FlexAlignCenter>
          <Label>Até</Label>

          <DropdownSelect
            items={dateRanges.map((dateRangesItem) => ({
              text: `${dateRangesItem[1].split('-')[2]} de ${getMonthName(
                dateRangesItem[1].split('-')[1]
              )} de ${dateRangesItem[1].split('-')[0]}`,
              fn: () => setDateRange({ ...dateRange, end: dateRangesItem[1] }),
              isDisabled: dateRange.start && dateRange.start > dateRangesItem[1]
            }))}
            displayText="Selecione"
            displayWidth={140}
            maxItemsVisible={6}
            isDisabled={!dateRange.start}
          />
        </FlexAlignCenter>
      </FlexAlignCenter>

      {isLoading && (
        <FlexCentered>
          <LoadingSpinner size="40" my="large" />
        </FlexCentered>
      )}

      {data && data.length > 0 && !isLoading && graph(data)}

      {data && data.length === 0 && !isLoading && (
        <CustomText size="0.75rem" mt="medium">
          Nenhum dado a ser exibido para o período selecionado.
        </CustomText>
      )}
    </Card>
  )
}

DictionaryGraphCard.propTypes = {
  headline: PropTypes.string.isRequired,
  dictionaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  reqParameter: PropTypes.oneOf(['risk', 'date']).isRequired,
  graph: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isDatePost: PropTypes.bool
}

export default DictionaryGraphCard
