/* React */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

/* Components */
import { Row, Col } from 'styled-bootstrap-grid'

import { Tabs } from '@zeta/ui/src'

import ManageProfile from '../ManageProfile'
import ManageUsers from '../ManageUsers'

/* Constants */
const MANAGE_PROFILE = 'MANAGE_PROFILE'
const MANAGE_USERS = 'MANAGE_USERS'

/* Helpers */
const getTabs = (userPermission, activeTab, setActiveTab) => {
  if (userPermission === 'admin') {
    return [
      { text: 'Configurações de usuário', onClickFn: () => setActiveTab(MANAGE_PROFILE), isActive: activeTab === MANAGE_PROFILE },
      { text: 'Gestão de usuários', onClickFn: () => setActiveTab(MANAGE_USERS), isActive: activeTab === MANAGE_USERS }
    ]
  }

  if (userPermission === 'standard') {
    return [
      { text: 'Configurações de usuário', onClickFn: () => setActiveTab(MANAGE_PROFILE), isActive: activeTab === MANAGE_PROFILE }
    ]
  }

  return []
}

export const UserConfigurations = () => {
  const userPermission = useSelector(state => state.user.permission)

  const [activeTab, setActiveTab] = useState(MANAGE_PROFILE)

  return (
    <>
      <Row>
        <Col>
          <Tabs
            items={getTabs(userPermission, activeTab, setActiveTab)}
            override={`
              @media (min-width: 1200px) {
                margin-top: 48px;
                margin-bottom: 64px;
              }
              @media (max-width: 1199px) {
                margin-bottom: 24px;
              }
            `}
          />

          {activeTab === MANAGE_PROFILE && <ManageProfile />}

          {activeTab === MANAGE_USERS && <ManageUsers />}
        </Col>
      </Row>
    </>
  )
}

export default UserConfigurations
