/* React */
import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'

/* Components */
import { Container, Row, Col } from 'styled-bootstrap-grid'

import {
  FlexCentered,
  Tabs,
  SectionHeadline,
  CollapseAlt,
  LoadingSpinner,
  UnitSwitcher,
  CustomText
} from '@zeta/ui/src'

import ContentWrapper from 'components/ContentWrapper'
import Footer from 'components/Footer'

/* Parts */
import ActionsBar from './ActionsBar'
import IndividualHeader from './IndividualHeader'
import Bios from './Bios'
import EmployeesTable from './EmployeesTable'
import RiskCards from './RiskCards'
import IndividualTermsTable from './IndividualTermsTable'
import SocialTabs from './SocialTabs'
import TermModal from './TermModal'
import SelectedFilters from './SelectedFilters'

/* Helpers */
import { getPathPrefix } from '@zeta/helpers'
import { formatSubjectsList } from 'helpers'
import { formatEmployees } from './helpers'
import { getSubject } from 'api'

/* Constants */
import { observationsFilterProps } from 'constants/filters'

/* Action Creators */
import {
  getAndSetPublications,
  setViewFilteredPublications,
  resetIndividual
} from 'store/reducers/individual/action-creators'
import { getAndSetArchived } from 'store/reducers/data/action-creators'

const Individual = ({
  /* router */
  history,
  computedMatch,
  /* props */
  subjectType,
  subjectId
}) => {
  const isLoadingPublications = useSelector(
    (state) => state.individual.isLoadingPublications
  )
  const hasPublications = useSelector(
    (state) => state.individual.hasPublications
  )
  const hasTextSites = useSelector((state) => state.individual.hasTextSites)
  const viewFilteredPublications = useSelector(
    (state) => state.individual.viewFilteredPublications
  )
  const subjects = useSelector((state) => state.data.subjects)
  const isLoadingArchived = useSelector((state) => state.data.isLoadingArchived)
  const archivedAlreadyRequested = useSelector(
    (state) => state.data.archivedAlreadyRequested
  )
  const filters = useSelector((state) => state.data.filters)
  const observations = useSelector((state) => state.data.observations)

  const activeUnit = subjects.active[subjectType].find(
    (subject) => subject.id == subjectId
  )
    ? 'active'
    : 'archived'

  const dispatch = useDispatch()

  const [individual, setIndividual] = useState(null)
  const [isLoadingIndividual, setIsLoadingIndividual] = useState(false)

  useEffect(() => {
    let mounted = true

    setIsLoadingIndividual(true)

    getSubject({ subjectId }).then((res) => {
      if (mounted) {
        setIndividual(res.data)
        setIsLoadingIndividual(false)

        if (res.data.subject.archived && !archivedAlreadyRequested) {
          dispatch(getAndSetArchived())
        }
      }
    })

    dispatch(getAndSetPublications({ subjectId, subjectType }))

    return () => {
      mounted = false

      dispatch(resetIndividual())
    }
  }, [subjectType, subjectId])

  const mainInfoLoaded = () =>
    !isLoadingIndividual && !isLoadingPublications && !isLoadingArchived

  const routerProps = { history, computedMatch }
  const individualProps = { individual, setIndividual }

  return (
    <ContentWrapper withSidebar>
      <Container fluid>
        <Row>
          <Col>
            <Tabs
              items={[
                {
                  text: 'Análise de menções',
                  onClickFn: () =>
                    history.push(
                      `${getPathPrefix(computedMatch)}/${subjectType}/dashboard`
                    )
                },
                {
                  text: 'Perfis',
                  onClickFn: () =>
                    history.push(
                      `${getPathPrefix(
                        computedMatch
                      )}/${subjectType}/individual`
                    ),
                  isActive: true
                }
              ]}
            />

            {(isLoadingIndividual ||
              isLoadingPublications ||
              isLoadingArchived) && (
              <FlexCentered>
                <LoadingSpinner size="40" my="large" />
              </FlexCentered>
            )}
          </Col>

          {mainInfoLoaded() && individual && (
            <Col>
              <ActionsBar {...routerProps} {...individualProps} />

              <IndividualHeader
                individual={individual}
                setIndividual={setIndividual}
                subjectType={subjectType}
                activeUnit={activeUnit}
                {...routerProps}
              />

              <CollapseAlt title="Bios e Experiência">
                <Bios
                  subjectId={subjectId}
                  subjectType={subjectType}
                  activeUnit={activeUnit}
                />
              </CollapseAlt>

              {subjectType === 'company' &&
                individual.relates_id.length > 0 &&
                subjects &&
                ((individual.subject.archived && subjects.archived) ||
                  !individual.subject.archived) && (
                  <CollapseAlt title="Pessoas vinculadas a esta empresa">
                    <EmployeesTable
                      employees={formatEmployees(
                        individual.relates_id,
                        formatSubjectsList(
                          observations.active.people,
                          subjects.active.people
                        )
                      )}
                      onClickFn={(id) =>
                        history.push(
                          `${getPathPrefix(
                            computedMatch
                          )}/people/individual/${id}`
                        )
                      }
                      computedMatch={computedMatch}
                    />
                  </CollapseAlt>
                )}

              {filters &&
                Object.keys(filters).filter((prop) =>
                  observationsFilterProps.some(
                    (observationFilterProp) => observationFilterProp === prop
                  )
                ).length > 0 && (
                  <>
                    <UnitSwitcher
                      items={[
                        {
                          key: true,
                          text: 'Visualização com filtros',
                          onClickFn: () =>
                            dispatch(setViewFilteredPublications(true))
                        },
                        {
                          key: false,
                          text: 'Visualização sem filtros',
                          onClickFn: () =>
                            dispatch(setViewFilteredPublications(false))
                        }
                      ]}
                      activeUnit={viewFilteredPublications}
                      mt="xlarge"
                      mb="large"
                    />

                    <CustomText size="0.75rem" mb="small">
                      Filtros aplicados:
                    </CustomText>
                    <SelectedFilters />
                  </>
                )}
            </Col>
          )}

          {mainInfoLoaded() && hasPublications && (
            <>
              <Col>
                <SectionHeadline mt="xlarge">Redes Sociais</SectionHeadline>
              </Col>

              <RiskCards source="social" />

              <Col>
                <IndividualTermsTable source="social" />
              </Col>
            </>
          )}

          {mainInfoLoaded() && hasTextSites && (
            <>
              <Col>
                <SectionHeadline mt="xlarge">Site</SectionHeadline>
              </Col>

              <RiskCards source="web" />

              <Col>
                <IndividualTermsTable source="web" />
              </Col>
            </>
          )}

          {(hasPublications || hasTextSites) && mainInfoLoaded() && individual && (
            <>
              <SocialTabs individual={individual} subjectType={subjectType} />
              <TermModal />
            </>
          )}
        </Row>
      </Container>

      {/* Footer */}
      <Footer />
    </ContentWrapper>
  )
}

export default withRouter(Individual)
