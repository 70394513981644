/* Helpers */
import { call, put, takeLatest, select } from 'redux-saga/effects'
import cookie from 'react-cookies'
import TagManager from 'react-gtm-module'
import { initAmplitude } from '@zeta/helpers'

/* Constants */
import {
  SIGN_IN_REQUESTED,
  SIGN_IN_SUCCEEDED,
  SIGN_IN_FAILED,
  UPDATE_USER_INFO_REQUESTED,
  UPDATE_USER_INFO_SUCCEEDED
} from './action-types'

import {
  authCookie,
  amplitudeConfig,
  gtmId,
  satismeterConfig
} from '../../../constants/config'

import { axiosInstance, signIn, updateUserInfo } from '../../../api'
import Crypto from 'crypto-es'

/* Workers */
function* signInWorker({ email, password, history, initialPath }) {
  try {
    const key = Crypto.enc.Hex.parse(process.env.REACT_APP_CRYPTO_KEY)
    const iv = Crypto.enc.Base64.parse(process.env.REACT_APP_CRYPTO_IV)

    const crypted = Crypto.AES.encrypt(password, key, {
      iv: iv,
      mode: Crypto.mode.CBC
    })

    const payload = yield call(signIn, {
      email: email,
      password: crypted.toString()
    })

    /* Formata os dados ("userData") p/ serem salvos na store e no cookie */
    const userData = {
      ...payload.data,
      token: payload.headers.authorization,
      permission: payload.data.permission,
      id: payload.data.id
    }

    /* Envia "userData" para a store */
    yield put({ type: SIGN_IN_SUCCEEDED, userData })

    /* Salva o cookie com "userData" */
    cookie.save(authCookie, userData, {
      path: '/',
      expires: new Date(new Date().setMinutes(new Date().getMinutes() + 240)) // cookie expira em 240 minutos
    })

    if (userData.email !== 'devops@refinariadedados.com.br') {
      /* Inicializa o Amplitude caso a chave exista */
      if (amplitudeConfig.key) {
        initAmplitude({
          key: amplitudeConfig.key,
          userId: userData.email,
          userProperties: {
            Cliente: amplitudeConfig.company,
            'Permissão do usuário': userData.permission
          }
        })
      }

      /* Inicializa o GTM caso a chave exista */
      if (gtmId) {
        TagManager.initialize({ gtmId })
      }
    }

    /* Inicializa o Satismeter caso a chave exista */
    if (satismeterConfig.writeKey) {
      ;(function () {
        window.satismeter =
          window.satismeter ||
          function () {
            ;(window.satismeter.q = window.satismeter.q || []).push(arguments)
          }
        window.satismeter.l = 1 * new Date()
        var script = document.createElement('script')
        var parent = document.getElementsByTagName('script')[0].parentNode
        script.async = 1
        script.src = 'https://app.satismeter.com/satismeter.js'
        parent.appendChild(script)
      })()

      window.satismeter({
        writeKey: satismeterConfig.writeKey,
        userId: userData.email
      })
    }

    /* Salva o token na instance do Axios */
    axiosInstance.defaults.headers.common['Authorization'] =
      payload.headers.authorization

    /* Redireciona para o path inicial */
    history.push(initialPath)
  } catch (error) {
    /* Envia o erro para a store */
    yield put({ type: SIGN_IN_FAILED, error })

    /* Remove o cookie salvo previamente (caso exista) */
    cookie.remove(authCookie, { path: '/' })
  }
}

function* updateUserInfoWorker({ firstName, lastName }) {
  try {
    const users = yield select((state) => state.data.users)
    const responsables = yield select(
      (state) => state.data.dynamicFiltersItems.responsable
    )

    const activeUserName = yield select(
      (state) => `${state.user.first_name} ${state.user.last_name}`
    )

    const newName = `${firstName} ${lastName}`
    const updatedUsers = users
      .filter((user) => user !== activeUserName)
      .concat(newName)

    const updatedResponsables = responsables.find(
      (responsable) => responsable.dbValue === activeUserName
    )
      ? responsables
          .filter((responsable) => responsable.dbValue !== activeUserName)
          .concat({ visibleValue: newName, dbValue: newName })
      : responsables

    yield call(updateUserInfo, { firstName, lastName })

    const summarises = yield select((state) => state.data.summarises)

    yield put({
      type: UPDATE_USER_INFO_SUCCEEDED,
      firstName,
      lastName,
      users: updatedUsers,
      responsables: updatedResponsables,
      summarises: {
        people: summarises.people.map((summarise) =>
          summarise.responsable === activeUserName
            ? { ...summarise, responsable: newName }
            : summarise
        ),
        company: summarises.company.map((summarise) =>
          summarise.responsable === activeUserName
            ? { ...summarise, responsable: newName }
            : summarise
        )
      }
    })

    const authCookiePayload = cookie.load(authCookie)

    cookie.save(
      authCookie,
      {
        ...authCookiePayload,
        first_name: firstName,
        last_name: lastName
      },
      {
        path: '/',
        expires: new Date(new Date().setMinutes(new Date().getMinutes() + 240)) // cookie expira em 240 minutos
      }
    )
  } catch (error) {
    console.log(error)
  }
}

/* Watchers */
export function* signInWatcher() {
  yield takeLatest(SIGN_IN_REQUESTED, signInWorker)
}

export function* updateUserInfoWatcher() {
  yield takeLatest(UPDATE_USER_INFO_REQUESTED, updateUserInfoWorker)
}
