export const BOARD_REQUESTED = 'BOARD_REQUESTED'
export const BOARD_SUCCEEDED = 'BOARD_SUCCEEDED'
export const SET_BOARD_INFO = 'SET_BOARD_INFO'

export const CHART_REQUESTED = 'CHART_REQUESTED'
export const CHART_SUCCEEDED = 'CHART_SUCCEEDED'

export const MAIN_DATA_REQUESTED = 'MAIN_DATA_REQUESTED'
export const MAIN_DATA_SUCCEEDED = 'MAIN_DATA_SUCCEEDED'

export const ARCHIVED_REQUESTED = 'ARCHIVED_REQUESTED'
export const ARCHIVED_SUCCEEDED = 'ARCHIVED_SUCCEEDED'

export const SUBJECT_ARCHIVED = 'SUBJECT_ARCHIVED'
export const SUBJECT_UNARCHIVED = 'SUBJECT_UNARCHIVED'
export const SUBJECT_UPDATED = 'SUBJECT_UPDATED'

export const SET_FILTERS = 'SET_FILTERS'

export const SET_LIST_PAGE = 'SET_LIST_PAGE'
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY'

export const SET_SAVE_REPORT_MODAL = 'SET_SAVE_REPORT_MODAL'

export const PATCH_TERM_RISK_REQUESTED = 'PATCH_TERM_RISK_REQUESTED'
export const PATCH_TERM_RISK_SUCCEEDED = 'PATCH_TERM_RISK_SUCCEEDED'
export const SHELVE_TERM_REQUESTED = 'SHELVE_TERM_REQUESTED'
export const SHELVE_TERM_SUCCEEDED = 'SHELVE_TERM_SUCCEEDED'

export const SET_ACTIVE_UNIT = 'SET_ACTIVE_UNIT'

export const RESET_PROPS = 'RESET_PROPS'
export const RESET_STATE = 'RESET_STATE'
