import { combineReducers } from 'redux'

import user from '@zeta/user/src/store/reducers/user'
import data from './data'
import individual from './individual'

export default combineReducers({
  user,
  data,
  individual
})
