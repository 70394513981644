import styled from 'styled-components'

export const Option = styled.button.attrs(() => ({
  type: 'button'
}))`
  font-size: 0.875rem;
  border: 1px solid ${({ isActive, theme: { colors } }) =>
    isActive ? colors.brand.primary.medium : colors.surface.primary.dark};
  background-color: ${({ isActive, theme: { colors } }) =>
    isActive ? colors.brand.primary.lightness : 'transparent'};
  padding: ${({ theme: { spacing } }) => `0 ${spacing.small}`};
  height: ${({ theme: { spacing } }) => spacing.xlarge};
  margin-bottom: ${({ theme: { spacing } }) => spacing.small};
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-right: ${({ theme: { spacing } }) => spacing.small};
  }
`
