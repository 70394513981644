import styled from 'styled-components'

import { Icon } from '@zeta/ui/src'

export const Wrapper = styled.div`
  margin-bottom: ${({ theme: { spacing } }) => spacing.xlarge};
`

export const Table = styled.table`
  width: 100%;
  min-width: 850px;
  border-collapse: collapse;
  background-color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};

  thead th {
    padding: ${({ theme: { spacing } }) => spacing.medium};
    text-align: left;
    font-size: 0.75rem;
    font-weight: 400;
    color: ${({ theme: { colors } }) => colors.surface.primary.darkness};
  }

  tbody td {
    padding: ${({ theme: { spacing } }) =>
    `${spacing.small} ${spacing.medium}`};
    font-size: 0.875rem;
  }

  tr {
    border-bottom: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  }
`

export const SocialsIcons = styled.div`
  display: flex;
  align-items: center;
`

export const SocialIcon = styled(Icon)`
  &:not(:last-child) {
    margin-right: ${({ theme: { spacing } }) => spacing.medium};
  }

  > svg {
    width: 12px;
    height: 12px;
    fill: ${({ theme: { colors } }) => colors.brand.primary.medium};
  }
`

export const ExpandButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  background-color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  border-top: 0;
  padding: ${({ theme: { spacing } }) => spacing.small};
  width: 100%;
  outline: 0;
`
