/* Constants */
import {
  PUBLICATIONS_REQUESTED,
  SET_HIT_TO_CLASSIFY,
  PATCH_OBSERVATION_REQUESTED,
  SET_VIEW_FILTERED_PUBLICATIONS,
  RESET_INDIVIDUAL
} from './action-types'

export const getAndSetPublications = ({
  subjectId, subjectType
}) => ({
  type: PUBLICATIONS_REQUESTED,
  reqParams: { subjectId },
  subjectType
})

export const setHitToClassify = hitToClassify => ({
  type: SET_HIT_TO_CLASSIFY,
  hitToClassify
})

export const editObservation = ({
  observationId, status, notes, social
}) => ({
  type: PATCH_OBSERVATION_REQUESTED,
  reqParams: { observationId, status, notes },
  social
})

export const setViewFilteredPublications = bool => ({
  type: SET_VIEW_FILTERED_PUBLICATIONS,
  bool
})

export const resetIndividual = () => ({
  type: RESET_INDIVIDUAL
})
