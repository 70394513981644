/* React */
import { useState } from 'react'
import PropTypes from 'prop-types'

/* Helpers */
import { omit } from 'ramda'

export const setFilterValue = (values, setValues) => (filterName, value) => {
  setValues({
    ...values,
    [filterName]: value
  })
}

export const removeFilterValue = (values, setValues) => (filterName) => {
  setValues(omit([filterName], values))
}

const Filter = ({ initialValues, render }) => {
  const [values, setValues] = useState(initialValues || {})

  return render({
    values,
    setValues,
    setFilterValue: setFilterValue(values, setValues),
    removeFilterValue: removeFilterValue(values, setValues)
  })
}

Filter.propTypes = {
  initialValues: PropTypes.object,
  render: PropTypes.func.isRequired
}

export default Filter
