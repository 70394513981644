import styled from 'styled-components'

import { defaultProps, defaultPropsPropTypes } from '@zeta/ui/src'

export const Wrapper = styled.div`
  background-color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  border-radius: ${({ theme: { radius } }) => radius.xsmall};
  margin-bottom: ${({ theme: { radius } }) => radius.xlarge};

  ${defaultProps}
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid
    ${({ theme: { colors } }) => colors.surface.primary.dark};
  padding: ${({ theme: { spacing } }) => spacing.medium};
`

export const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  margin: ${({ theme: { spacing } }) =>
    `${spacing.small} 0 ${spacing.medium} 0`};

  thead th,
  tbody td {
    padding: ${({ theme: { spacing } }) =>
    `${spacing.small} ${spacing.medium}`};
  }

  thead {
    th {
      font-size: 0.875rem;

      &:first-child {
        text-align: left;
      }

      &:last-child {
        text-align: right;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background-color: ${({ risk, theme: { colors } }) =>
    colors.risk[risk].lightness};
      }

      td:first-child {
        font-size: 0.875rem;
      }

      td:last-child {
        text-align: right;
        font-weight: 700;
        color: ${({ risk, theme: { colors } }) => colors.risk[risk].medium};
      }
    }
  }
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
