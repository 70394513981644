/* React */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

/* Components */
import { Formik, Form, Field } from 'formik'

import {
  Modal,
  ModalContent,
  ModalActions,
  FieldGroup,
  DropdownSelect,
  Button,
  LoadingSpinner,
  CustomText,
  Icon
} from '@zeta/ui/src'

/* Helpers */
import { logAmplitudeEvent } from '@zeta/helpers'

/* Action Creators */
import { setHitToClassify, editObservation } from 'store/reducers/individual/action-creators'

const TermModal = () => {
  const dispatch = useDispatch()

  const hitToClassify = useSelector(state => state.individual.hitToClassify)
  const isPatchingObservation = useSelector(state => state.individual.isPatchingObservation)

  return hitToClassify && (
    <Modal
      isVisible={hitToClassify && true}
      closeFn={() => dispatch(setHitToClassify(null)) }
      title='Procedência da menção'
    >
      <span data-testid="termModal">
        <Formik
          initialValues={{
            status: hitToClassify.observation.status,
            notes: hitToClassify.observation.notes || ''
          }}
          onSubmit={values => {
            dispatch(
              editObservation({
                observationId: hitToClassify.observation.id,
                status: values.status,
                notes: values.notes || '',
                social: hitToClassify.social
              })
            )

            logAmplitudeEvent({
              event: 'Avaliar um termo',
              eventProperties: {
                'Local': 'Pág. de perfil > Card de publicação > Modal "Procedência do termo"',
                'Termo': hitToClassify.word.term,
                'Base': hitToClassify.observation.subject_type,
                'Fonte': hitToClassify.observation.social_type,
                'Procedência': values.status >= 2 && values.status <= 5 ? 2 : values.status
              }
            })
          }}
        >
          {props => {
            const { values, setFieldValue, handleSubmit } = props

            return (
              <Form onSubmit={handleSubmit}>
                <ModalContent>
                  <CustomText size='0.875rem' mb='large' data-testid="termToClassify">
                    <strong>Termo a ser classificado:</strong> {hitToClassify.word.term}
                  </CustomText>

                  {/* Procedência */}
                  <DropdownSelect
                    items={[
                      {
                        text: 'Não classificado',
                        fn: () => setFieldValue('status', 0)
                      },
                      {
                        text: 'Inconclusivo',
                        fn: () => setFieldValue('status', 7)
                      },
                      {
                        text: 'Improcedente',
                        fn: () => setFieldValue('status', 1)
                      },
                      {
                        text: 'Investigação',
                        fn: () => setFieldValue('status', 6)
                      },
                      {
                        text: 'Procedente',
                        fn: () => setFieldValue('status', 2)
                      }
                    ]}
                    activeIndex={
                      (() => {
                        if (values.status === 0) return 0
                        if (values.status === 7) return 1
                        if (values.status === 1) return 2
                        if (values.status === 6) return 3
                        if (values.status >= 2 && values.status <= 5) return 4
                      })()
                    }
                    label='O risco desse termo é procedente?'
                    displayText='Não classificado'
                    mb='large'
                    data-testid='statusDropdownSelect'
                  />

                  {/* Ação */}
                  {values.status >= 2 && values.status <= 5 &&
                    <Field
                      inputElement='radio'
                      type='radio'
                      name='status'
                      label='Ação:'
                      options={[
                        { label: 'Nenhuma ação', value: 2 },
                        { label: 'Advertência', value: 3 },
                        { label: 'Multa', value: 4 },
                        { label: 'Orientação', value: 5 }
                      ]}
                      component={FieldGroup}
                      data-testid='actionRadioField'
                    />}

                  {/* Observações */}
                  <Field
                    inputElement='textarea'
                    name='notes'
                    label='Observação:'
                    component={FieldGroup}
                    data-testid='notesTextarea'
                  />
                </ModalContent>

                <ModalActions>
                  {/* Cancelar */}
                  <Button
                    red
                    onClick={() => dispatch(setHitToClassify(null))}
                    isDisabled={isPatchingObservation}
                    data-testid='cancelButton'
                  >
                    <Icon icon='close-outline' />
                    <span>Cancelar</span>
                  </Button>

                  {/* Confirmar */}
                  <Button
                    type='submit'
                    isDisabled={isPatchingObservation}
                    data-testid='submitObservationButton'
                  >
                    <Icon icon='save-outline' />
                    <span>Confirmar</span>
                  </Button>

                  {/* Loading */}
                  {isPatchingObservation &&
                    <LoadingSpinner size='xlarge' ml='small' data-testid='loadingSpinner' />}
                </ModalActions>
              </Form>
            )
          }}
        </Formik>
      </span>
    </Modal>
  )
}

export default TermModal
