/* React */
import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

/* Components */
import { Container, Row, Col } from 'styled-bootstrap-grid'

import {
  GroupBarChart,
  LineChart,
  Tabs,
  ScrollAnchor,
  theme
} from '@zeta/ui/src'

import Header from './Header'
import DictionaryGraphCard from './DictionaryGraphCard'
import DictionaryTermsTable from './DictionaryTermsTable'

import Menu from 'components/sidebar/Menu'
import ContentWrapper from 'components/ContentWrapper'
import Footer from 'components/Footer'

/* Helpers */
import { getSubjectType } from 'helpers'

/* Constants */
const chartsMargin = { top: 24, right: 12, left: -12, bottom: 0 }

export const Dictionary = ({
  /* router */
  computedMatch
}) => {
  const { dictionaryId } = computedMatch.params

  const subjectType = getSubjectType(computedMatch.url)

  const [activeTab, setActiveTab] = useState(1)
  const [reload, setReload] = useState(false)

  return (
    <>
      <Menu />

      <ContentWrapper>
        <Container>
          <Row>
            <Col>
              <Header dictionaryId={dictionaryId} reload={reload} />
            </Col>

            <Col>
              <DictionaryGraphCard
                headline="VOLUMETRIA POR PROCEDÊNCIA (POR DATA DE CLASSIFICAÇÃO)"
                dictionaryId={dictionaryId}
                reqParameter="risk"
                graph={(data) => (
                  <GroupBarChart
                    data={data}
                    colors={[
                      theme.colors.risk[3].light,
                      theme.colors.risk[2].light,
                      theme.colors.risk[1].light
                    ]}
                    margin={chartsMargin}
                    barGap={0}
                  />
                )}
                id="graphCard1"
                mb="large"
              />
            </Col>

            <Col>
              <DictionaryGraphCard
                headline="PROCEDÊNCIAS POR PERÍODO (POR DATA DE CLASSIFICAÇÃO)"
                dictionaryId={dictionaryId}
                reqParameter="date"
                graph={(data) => (
                  <LineChart
                    data={data}
                    colors={[
                      theme.colors.brand.secondary.extra,
                      theme.colors.feedback.green.darkness,
                      theme.colors.brand.primary.light,
                      theme.colors.risk[2].darkness,
                      theme.colors.brand.primary.darkness
                    ]}
                    margin={chartsMargin}
                  />
                )}
                id="graphCard2"
                mb="large"
              />
            </Col>

            <Col>
              <DictionaryGraphCard
                headline="VOLUMETRIA POR PROCEDÊNCIA (POR DATA DE PUBLICAÇÃO)"
                dictionaryId={dictionaryId}
                reqParameter="risk"
                graph={(data) => (
                  <GroupBarChart
                    data={data}
                    colors={[
                      theme.colors.risk[3].light,
                      theme.colors.risk[2].light,
                      theme.colors.risk[1].light
                    ]}
                    margin={chartsMargin}
                    barGap={0}
                  />
                )}
                id="graphCard3"
                isDatePost
                mb="large"
              />
            </Col>

            <Col>
              <DictionaryGraphCard
                headline="PROCEDÊNCIAS POR PERÍODO (POR DATA DE PUBLICAÇÃO)"
                dictionaryId={dictionaryId}
                reqParameter="date"
                graph={(data) => (
                  <LineChart
                    data={data}
                    colors={[
                      theme.colors.brand.secondary.extra,
                      theme.colors.feedback.green.darkness,
                      theme.colors.brand.primary.light,
                      theme.colors.risk[2].darkness,
                      theme.colors.brand.primary.darkness
                    ]}
                    margin={chartsMargin}
                  />
                )}
                id="graphCard4"
                isDatePost
              />
            </Col>

            <Col>
              <Tabs
                items={[
                  {
                    text: 'Termos ativos',
                    onClickFn: () => setActiveTab(1),
                    isActive: activeTab === 1
                    // count
                  },
                  {
                    text: 'Termos arquivados',
                    onClickFn: () => setActiveTab(2),
                    isActive: activeTab === 2
                    // count
                  }
                ]}
                mt="largest"
                mb="xlarge"
              />

              <ScrollAnchor name="dictionaryTable" />

              {activeTab === 1 && (
                <DictionaryTermsTable
                  dictionaryId={dictionaryId}
                  reload={reload}
                  setReload={setReload}
                  subjectType={subjectType}
                />
              )}

              {activeTab === 2 && (
                <DictionaryTermsTable
                  dictionaryId={dictionaryId}
                  archived
                  reload={reload}
                  setReload={setReload}
                  subjectType={subjectType}
                />
              )}
            </Col>
          </Row>
        </Container>

        {/* Footer */}
        <Footer />
      </ContentWrapper>
    </>
  )
}

Dictionary.propTypes = {
  computedMatch: PropTypes.object.isRequired
}

export default withRouter(Dictionary)
