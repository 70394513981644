import { uniq } from 'ramda'

const helpers = {
  /**
   *
   * Filtra os subjects a serem listados a partir do searchQuery (filtra por name ou document)
   * @param {Array[Object]} subjects
   * @param {String} searchQuery
   * @return {Object}
   *
   */
  filterSubjectsBySearchQuery: (subjects, searchQuery) =>
    subjects.filter(
      (subject) =>
        subject.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        subject.document
          .replace(/\./g, '')
          .replace(/-/g, '')
          .includes(searchQuery.replace(/\./g, '').replace(/-/g, ''))
    ),

  /**
   *
   * Filtra os subjects a serem listados
   * @param {Array[Object]} observations
   * @param {Array[Object]} subjects
   * @return {Array[Object]}
   *
   */
  filterSubjectsToList: (observations, subjects) =>
    uniq(observations.map((observation) => observation.subject_id))
      .map((id) => subjects.find((subject) => subject.id === id))
      .sort((a, b) => b.total - a.total)
}

export const { filterSubjectsBySearchQuery, filterSubjectsToList } = helpers

export default helpers
