import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../../defaultProps'

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  .flatpickr-input {
    height: ${({ theme: { spacing } }) => spacing.large};
    line-height: ${({ theme: { spacing } }) => spacing.large};
    border-radius: ${({ theme: { radius } }) => radius.smallest};
    padding-left: ${({ theme: { spacing } }) => spacing.xsmall};
    padding-right: ${({ theme: { spacing } }) => spacing.xsmall};
  }

  ${defaultProps}
`

export const InputGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme: { spacing } }) => spacing.small};

  &:first-child:not(:only-child) {
    margin-right: ${({ theme: { spacing } }) => spacing.medium};
  }
`

export const Label = styled.div`
  font-size: 0.75rem;
  color: ${({ theme: { colors } }) => colors.brand.tertiary.light};
  margin-right: ${({ theme: { spacing } }) => spacing.small};
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
