/* Constants */
import theme from '../../theme/theme'

const defaultColors = [
  theme.colors.brand.primary.dark,
  theme.colors.brand.secondary.dark,
  theme.colors.feedback.green.darkness,
  theme.colors.yellow[300],
  theme.colors.brand.tertiary.dark,
  theme.colors.brand.primary.light,
  theme.colors.brand.secondary.light,
  theme.colors.feedback.green.medium,
  theme.colors.yellow[200],
  theme.colors.brand.tertiary.lightness
]

/* Retorna a cor da barra */
export const getColor = (i, colors = defaultColors) => colors[i % colors.length]

/* Retorna a largura do gráfico */
// export const getChartWidth = (chartWidth) => {
//   if (chartWidth === 'sm') return 435
//   if (chartWidth === 'md') return 938
// }
