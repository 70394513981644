/* React */
import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

/* Components */
import {
  SearchForm,
  ButtonAlt,
  Icon,
  HorizontalScroll,
  Table,
  Pagination,
  FlexAlignCenter,
  theme
} from '@zeta/ui/src'

import RiskBadge from 'components/RiskBadge'

import AddTermModal from './AddTermModal'

/* Styled */
import { Wrapper, Header, Footer } from './styled'

/* Helpers */
// import domtoimage from 'dom-to-image'
import { getTerms, getDictionaryBlob } from 'api'

const DictionaryTermsTable = ({
  /* router */
  history,
  /* props */
  dictionaryId,
  archived,
  reload,
  setReload,
  subjectType
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const [rows, setRows] = useState([])
  const [totalItems, setTotalItems] = useState([])

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(25)

  const [searchValue, setSearchValue] = useState('')
  const [findWord, setFindWord] = useState(null)

  const [orderBy, setOrderBy] = useState(null)
  const initialDscOrder = {
    term: null,
    risk: null,
    mentions: null,
    proceeding: null,
    unfounded: null,
    investigation: null,
    inconclusive: null,
    unclassified: null
  }
  const [dscOrder, setDscOrder] = useState(initialDscOrder)

  const toggleOrdenation = (orderBy) => {
    setOrderBy(orderBy)
    setDscOrder({
      ...initialDscOrder,
      [orderBy]: dscOrder[orderBy] ? null : true
    })
  }

  const [isAddTermModalVisible, setIsAddTermModalVisible] = useState(false)

  useEffect(() => {
    let mounted = true

    setIsLoading(true)

    getTerms({
      dictionaryId,
      params: {
        page,
        perPage,
        archived,
        findWord,
        orderBy,
        dscOrder: orderBy ? dscOrder[orderBy] : null
      }
    }).then((res) => {
      if (mounted) {
        setRows(
          res.data.result.map((term) => ({
            cells: [
              term.term,
              <RiskBadge risk={term.risk} />,
              term.mentions,
              term.proceeding,
              term.unfounded,
              term.investigation,
              term.inconclusive,
              term.unclassified
            ],
            onClickFn: () =>
              history.push(
                `/dicionario/${subjectType}/${dictionaryId}/${term.id}`
              )
          }))
        )
        setTotalItems(res.data.total_listed)
        setIsLoading(false)
      }
    })

    return () => {
      mounted = false
    }
  }, [page, perPage, findWord, orderBy, dscOrder, reload])

  return (
    <Wrapper id="dictionaryTable">
      <Header>
        <FlexAlignCenter mr="medium" mb="medium">
          <SearchForm
            onSubmit={() => {
              setPage(1)
              setFindWord(searchValue)
            }}
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            placeholder="Pesquise por termo"
            bg={theme.colors.surface.primary.lightness}
            override={`
              @media (min-width: 768px) {
                width: 400px;
                max-width: 100%;
              }
            `}
            mr="medium"
            isDisabled={isLoading}
          />

          {findWord && (
            <ButtonAlt
              onClick={() => {
                setPage(1)
                setSearchValue('')
                setFindWord(null)
              }}
            >
              <Icon icon="close-outline" />
              <span>Limpar busca</span>
            </ButtonAlt>
          )}
        </FlexAlignCenter>

        <FlexAlignCenter mr="medium" mb="medium">
          <ButtonAlt
            onClick={() => {
              getDictionaryBlob({ dictionaryId, archived }).then((res) => {
                const downloadUrl = window.URL.createObjectURL(res.data)
                const tempLink = document.createElement('a')
                tempLink.href = downloadUrl
                tempLink.setAttribute(
                  'download',
                  `export-dicionario-${dictionaryId}.csv`
                )
                tempLink.click()
              })
            }}
            // onClick={async () => {
            //   const dataUrl = await domtoimage.toJpeg(document.getElementById('dictionaryTable'))
            //   const link = document.createElement('a')
            //   link.download = `dicionario-${dictionaryId}.jpeg`
            //   link.href = dataUrl
            //   link.click()
            // }}
            isSquare
          >
            <Icon icon="download-outline" />
          </ButtonAlt>

          <ButtonAlt onClick={() => setIsAddTermModalVisible(true)} ml="medium">
            <Icon icon="plus-circle-outline" />
            <span>Adicionar termo</span>
          </ButtonAlt>
        </FlexAlignCenter>
      </Header>

      <HorizontalScroll>
        <Table
          headers={[
            {
              text: 'Termo',
              onClickFn: () => toggleOrdenation('term')
            },
            {
              text: 'Risco',
              onClickFn: () => toggleOrdenation('risk')
            },
            {
              text: 'Menções',
              onClickFn: () => toggleOrdenation('mentions')
            },
            {
              text: 'Procedentes',
              onClickFn: () => toggleOrdenation('proceeding')
            },
            {
              text: 'Improcedentes',
              onClickFn: () => toggleOrdenation('unfounded')
            },
            {
              text: 'Investigação',
              onClickFn: () => toggleOrdenation('investigation')
            },
            {
              text: 'Inconclusivo',
              onClickFn: () => toggleOrdenation('inconclusive')
            },
            {
              text: 'Não Class.',
              onClickFn: () => toggleOrdenation('unclassified')
            }
          ]}
          rows={rows}
          isLoadingRows={isLoading}
          override={`
            th {
              font-size: 0.7rem;
            }
          `}
        />
      </HorizontalScroll>

      {!isLoading && totalItems > 0 && (
        <Footer>
          <Pagination
            page={page}
            setPageFn={setPage}
            perPage={perPage}
            setPerPageFn={setPerPage}
            perPageItems={[10, 25, 50, 100]}
            totalItems={totalItems}
            scrollAnchor="dictionaryTable"
          />
        </Footer>
      )}

      <AddTermModal
        isAddTermModalVisible={isAddTermModalVisible}
        setIsAddTermModalVisible={setIsAddTermModalVisible}
        dictionaryId={dictionaryId}
        reload={reload}
        setReload={setReload}
      />
    </Wrapper>
  )
}

DictionaryTermsTable.propTypes = {
  history: PropTypes.object.isRequired,
  dictionaryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  archived: PropTypes.bool,
  reload: PropTypes.bool,
  setReload: PropTypes.func
}

export default withRouter(DictionaryTermsTable)
