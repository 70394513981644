/* React */
import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

/* Components */
import { Col } from 'styled-bootstrap-grid'
import RiskCard from 'components/RiskCard'

/* Helpers */
import { splitHitsByRisk, formatRiskCard, filterHits } from '../helpers'

/* Selectors */
const hitsByRiskSelector = createSelector(
  (state) => state.individual.mergedHits,
  (state) => state.individual.biosMergedHits,
  (state) => state.individual.textSitesMergedHits,
  (state) => state.individual.viewFilteredPublications,
  (state) => state.data.filters,
  (_, source) => source,
  (
    mergedHits,
    biosMergedHits,
    textSitesMergedHits,
    viewFilteredPublications,
    filters,
    source
  ) =>
    splitHitsByRisk(
      source === 'social'
        ? filterHits(
            [...mergedHits, ...biosMergedHits],
            viewFilteredPublications,
            filters
          )
        : filterHits(textSitesMergedHits, viewFilteredPublications, filters)
    )
)

const RiskCards = ({ source }) => {
  const hitsByRisk = useSelector((state) => hitsByRiskSelector(state, source))

  const RiskCardGroup = ({ risk }) => (
    <Col md={4}>
      <RiskCard
        risk={risk}
        totalPostsLabel={source === 'social' ? 'publicações' : 'textos'}
        {...formatRiskCard(hitsByRisk[risk])}
      />
    </Col>
  )

  return (
    <>
      <RiskCardGroup risk={3} />

      <RiskCardGroup risk={2} />

      <RiskCardGroup risk={1} />
    </>
  )
}

export default RiskCards
