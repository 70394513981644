import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../../defaultProps'

const HorizontalScroll = styled.div`
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;

  ${defaultProps}
`

HorizontalScroll.propTypes = {
  ...defaultPropsPropTypes
}

export default HorizontalScroll
