/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Styled */
import { Wrapper } from './styled'

const Chip = ({ children, ...rest }) => <Wrapper {...rest}>{children}</Wrapper>

Chip.propTypes = {
  bg: PropTypes.string,
  color: PropTypes.string
}

export default Chip
