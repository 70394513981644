/* React */
import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* Components */
import ClickAwayListener from 'react-click-away-listener'
import CustomText from '../helpers/CustomText'
import Icon from '../Icon'
import CustomScrollbars from '../CustomScrollbars'

/* Styled */
import { Wrapper, Display, Options, Option } from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'

/* Constants */
import theme from '../../theme/theme'

const DropdownSelect = ({
  items,
  activeIndex,
  label,
  displayText,
  displayWidth,
  isDisabled,
  maxItemsVisible,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [activeItem, setActiveItem] = useState(
    activeIndex !== null ? items[activeIndex] : null
  )

  return (
    <ClickAwayListener onClickAway={() => setIsOpen(false)} mouseEvent='mousedown'>
      <Wrapper displayWidth={displayWidth} isDisabled={isDisabled} {...rest}>
        <div>
          {label && <CustomText size='0.875rem' weight='700' mb='xsmall'>{label}</CustomText>}

          <Display
            displayWidth={displayWidth}
            type='button'
            onClick={() => setIsOpen(!isOpen)}
            disabled={isDisabled}
            data-testid='dropdownSelectToggleBtn'
          >
            <CustomText size='0.75rem' data-testid='dropdownSelectActiveItem'>
              {activeItem ? activeItem.text : displayText}
            </CustomText>

            <Icon
              icon={isOpen ? 'arrow-ios-upward-outline' : 'arrow-ios-downward-outline'}
              size='medium'
              fill={theme.colors.brand.primary.medium}
            />
          </Display>
        </div>

        {isOpen && (
          <Options
            displayWidth={displayWidth}
            data-testid='dropdownSelectOptions'
          >
            <CustomScrollbars style={{ height: maxItemsVisible * 40 || items.length * 40 }}>
              {items.map((item) => (
                <Option
                  key={uuidv4()}
                  type='button'
                  onClick={() => {
                    item.fn()
                    setActiveItem(item)
                    setIsOpen(false)
                  }}
                  disabled={item.isDisabled}
                  data-testid='dropdownSelectOption'
                >
                  {item.text}
                </Option>
              ))}
            </CustomScrollbars>
          </Options>
        )}
      </Wrapper>
    </ClickAwayListener>
  )
}

DropdownSelect.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      fn: PropTypes.func.isRequired
    })
  ),
  activeIndex: PropTypes.number,
  label: PropTypes.string,
  displayText: PropTypes.string.isRequired,
  displayWidth: PropTypes.number,
  isDisabled: PropTypes.bool,
  maxItemsVisible: PropTypes.number
}

export default DropdownSelect
