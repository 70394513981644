export const visibleKeys = {
  total_risk_1__INTEGER: 'Quantidade de menções de baixo risco',
  total_risk_2__INTEGER: 'Quantidade de menções de médio risco',
  total_risk_3__INTEGER: 'Quantidade de menções de alto risco',
  total_risks_facebook__INTEGER: 'Quantidade de menções de risco em Facebook',
  total_risks_linkedin__INTEGER: 'Quantidade de menções de risco em Linkedin',
  total_risks_instagram__INTEGER: 'Quantidade de menções de risco em Instagram',
  total_risks_twitter__INTEGER: 'Quantidade de menções de risco em Twitter',
  total_risks_site__INTEGER: 'Quantidade de menções de risco no site',
  count_connections_facebook__INTEGER: 'Quantidade de conexões no Facebook',
  count_connections_linkedin__INTEGER: 'Quantidade de conexões no Linkedin',
  count_connections_instagram__INTEGER: 'Quantidade de conexões no Instagram',
  count_connections_twitter__INTEGER: 'Quantidade de conexões no Twitter',
  office__STRING: 'Escritório',
  all_words__STRING: 'Palavra chave',

  getVisibleKey: (key) => visibleKeys[key]
}

export const displayNameKey = {
  people: 'input_name__STRING',
  company: 'office__STRING'
}

export const dataSetTabActiveIndex = {
  people: 0,
  company: 1
}

export const visibleObservationStatus = {
  0: 'Não classificado',
  1: 'Improcedente',
  2: 'Procedente',
  3: 'Advertência',
  4: 'Multa',
  5: 'Orientação',
  6: 'Investigação',
  7: 'Inconclusivo'
}

export const visibleBiosStatus = {
  0: 'Não analisado',
  1: 'Solicitação de ajuste',
  2: 'Aprovado'
}
