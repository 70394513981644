/* React */
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'

/* Components */
import {
  SectionHeadline,
  CustomText,
  Chip,
  Button,
  Icon,
  theme
} from '@zeta/ui/src'

/* Parts */
import SidebarHeader from 'components/sidebar/SidebarHeader'
import DeleteBoardModal from 'components/sidebar/DeleteBoardModal'
import EditBoardModal from 'components/sidebar/EditBoardModal'

/* Styled */
import { Wrapper, ButtonCard } from './styled'

/* Helpers */
import { getSubjectType } from 'helpers'

/* Action Creators */
import {
  setBoardInfo,
  setSaveBoardModal
} from 'store/reducers/data/action-creators'

const SidebarContent = ({
  /* router */
  history,
  /* props */
  activeTab,
  setActiveTab
}) => {
  const dispatch = useDispatch()

  const userId = useSelector((state) => state.user.id)
  const isLoadingMainData = useSelector((state) => state.data.isLoadingMainData)
  const board = useSelector((state) => state.data.board)
  const filters = useSelector((state) => state.data.filters)

  const isBoard = () => board.type === 'board'
  const isOwner = () => board.user_id === userId || board.user.id === userId // VERIFICAR (na resposta da edição de um "board", não volta o objeto "user", e sim uma prop "user_id")

  const subjectType = getSubjectType(history.location.pathname)

  const [isEditBoardModalOpen, setIsEditBoardModalOpen] = useState(false)
  const [isDeleteBoardModalOpen, setIsDeleteBoardModalOpen] = useState(false)

  return (
    <>
      <Wrapper>
        {!isLoadingMainData && (
          <>
            <SidebarHeader
              subjectType={subjectType}
              boardStatus={isBoard() && board.config_body.status}
              boardId={isBoard() ? board.id : null}
              boardName={isBoard() ? board.name : 'Painel sem nome'}
              deleteFn={
                isBoard() && isOwner()
                  ? () => setIsDeleteBoardModalOpen(true)
                  : null
              }
              editFn={
                isBoard() && isOwner()
                  ? () => setIsEditBoardModalOpen(true)
                  : null
              }
              saveFn={
                isBoard() && !isOwner()
                  ? null
                  : () => {
                      dispatch(
                        setSaveBoardModal({
                          isOpen: true,
                          proceedFn: (res) => {
                            dispatch(
                              setBoardInfo({
                                payload: { ...res.data, type: 'board' }
                              })
                            )
                            history.push(
                              `/painel/${res.data.id}/people/dashboard`
                            )
                          },
                          enableProceedWithoutSaving: false
                        })
                      )
                    }
              }
            />

            {
              /* Filtros */
              // minorStatsSucceeded &&
              <>
                <SectionHeadline mt={'xlarge'}>
                  <Icon
                    icon={'options-2-outline'}
                    size="medium"
                    fill={theme.colors.brand.primary.medium}
                  />
                  Filtros
                </SectionHeadline>

                <ButtonCard withBorder>
                  <div>
                    <CustomText size={'0.75rem'} weight={'700'} mb={'smallest'}>
                      Todos os filtros
                    </CustomText>
                    <Chip bg={theme.colors.feedback.red.dark}>
                      filtros aplicados: {Object.keys(filters).length}
                    </Chip>
                  </div>

                  <Button
                    small
                    onClick={() =>
                      activeTab === 'filters'
                        ? setActiveTab(null)
                        : setActiveTab('filters')
                    }
                  >
                    {activeTab === 'filters' ? (
                      <Icon icon={'close-outline'} />
                    ) : (
                      <Icon icon={'arrow-ios-forward-outline'} />
                    )}
                  </Button>
                </ButtonCard>
              </>
            }
          </>
        )}
      </Wrapper>

      {/* Delete Modal */}
      <DeleteBoardModal
        isModalOpen={isDeleteBoardModalOpen}
        setIsModalOpen={setIsDeleteBoardModalOpen}
      />

      {/* Edit Modal */}
      <EditBoardModal
        isModalOpen={isEditBoardModalOpen}
        setIsModalOpen={setIsEditBoardModalOpen}
        proceedFn={(res) => dispatch(setBoardInfo({ payload: res.data }))}
      />
    </>
  )
}

export default withRouter(SidebarContent)
