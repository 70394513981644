/* React */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

/* Components */
import { Formik, Form, Field } from 'formik'

import {
  Modal,
  ModalContent,
  ModalActions,
  FieldGroup,
  Button,
  Icon
} from '@zeta/ui/src'

/* Helpers */
import { formatDate } from '@zeta/helpers'
import { classifySubjectBios, classifySubjectExperience } from 'api'
import { update } from 'ramda'

/* Constants */
import { visibleBiosStatus } from 'constants/global'

/* Action Creators */
import { updateSubject } from 'store/reducers/data/action-creators'

const ClassifyModal = ({
  contentToClassify,
  setContentToClassify,
  subjectBios,
  setSubjectBios,
  subjectId,
  subjectType,
  activeUnit
}) => {
  const [isClassifying, setIsClassifying] = useState(false)

  const dispatch = useDispatch()

  const isBios = contentToClassify.bios
  const social =
    isBios && contentToClassify.bios.social_network_type.toLowerCase()

  return (
    <Modal isVisible={true} closeFn={() => setContentToClassify(null)}>
      <Formik
        initialValues={{
          status: isBios
            ? contentToClassify.bios.status
            : contentToClassify.experience.status
        }}
        onSubmit={(values) => {
          setIsClassifying(true)

          if (isBios) {
            return classifySubjectBios({
              biosId: contentToClassify.bios.id,
              status: values.status
            }).then((res) => {
              setContentToClassify(null)

              setSubjectBios({
                ...subjectBios,
                [social]: update(
                  subjectBios[social].findIndex((item) => item.bios),
                  { bios: res.data },
                  subjectBios[social]
                )
              })

              dispatch(
                updateSubject({
                  subjectId,
                  subjectType,
                  activeUnit,
                  values: { [`${social}_bios_status`]: Number(values.status) }
                })
              )
            })
          }

          classifySubjectExperience({
            experienceId: contentToClassify.experience.id,
            status: values.status
          }).then((res) => {
            setContentToClassify(null)

            setSubjectBios({
              ...subjectBios,
              linkedin: update(
                subjectBios['linkedin'].findIndex((item) => item.experience),
                { experience: res.data },
                subjectBios['linkedin']
              )
            })

            dispatch(
              updateSubject({
                subjectId,
                subjectType,
                activeUnit,
                values: {
                  [`linkedin_experience_status`]: Number(values.status)
                }
              })
            )
          })
        }}
      >
        {(props) => {
          const { handleSubmit } = props

          return (
            <Form onSubmit={handleSubmit}>
              <ModalContent>
                <Field
                  inputElement="radio"
                  type="radio"
                  name="status"
                  label={`Classificar ${
                    isBios
                      ? `Bios do ${contentToClassify.bios.social_network_type}`
                      : 'Experiência do Linkedin'
                  } coletada em ${formatDate(
                    isBios
                      ? contentToClassify.bios.collected_at
                      : contentToClassify.experience.collected_at
                  )}`}
                  options={[
                    { label: visibleBiosStatus[0], value: 0 },
                    { label: visibleBiosStatus[1], value: 1 },
                    { label: visibleBiosStatus[2], value: 2 }
                  ]}
                  component={FieldGroup}
                />
              </ModalContent>

              <ModalActions>
                {/* Cancelar */}
                <Button
                  red
                  onClick={() => setContentToClassify(null)}
                  isDisabled={isClassifying}
                >
                  <Icon icon="close-outline" />
                  <span>Cancelar</span>
                </Button>

                {/* Confirmar */}
                <Button type="submit" isDisabled={isClassifying}>
                  <Icon icon="save-outline" />
                  <span>Confirmar</span>
                </Button>
              </ModalActions>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default ClassifyModal
