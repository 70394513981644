/* React */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'

/* Components */
import { Container, Row, Col } from 'styled-bootstrap-grid'

import {
  Divider,
  DividerVertical,
  Button,
  CustomText,
  FlexSpaceBetween,
  Hide767
} from '@zeta/ui/src'

import Filter from 'components/filters/Filter'

/* Parts */
import Content from './Content'

/* Styled */
import { Tab, ButtonsWrapper, TabButton, TabContent } from '../styled'

/* Helpers */
import { logAmplitudeEvent } from '@zeta/helpers'

/* Action Creators */
import { setFilters } from 'store/reducers/data/action-creators'

const Filters = ({
  closeTabAndGoToTop
}) => {
  const dispatch = useDispatch()

  const filters = useSelector(state => state.data.filters)

  return (
    <Tab>
      <Filter
        initialValues={filters}
        render={filterProps => {
          const { values } = filterProps

          return (
            <>
              <Container fluid>
                <Row>
                  <Col>
                    <FlexSpaceBetween>
                      <CustomText
                        size='1.5rem'
                        weight='700'
                        override='@media (max-width: 767px) { display: none; }'
                      >
                        Todos os filtros
                      </CustomText>

                      <ButtonsWrapper>
                        <TabButton type={'button'} cancel onClick={closeTabAndGoToTop}>
                          Cancelar
                        </TabButton>

                        <Hide767>
                          <DividerVertical override='margin: 0; height: 40px;' />
                        </Hide767>

                        <TabButton type={'button'} onClick={() => {
                          closeTabAndGoToTop()
                          dispatch(setFilters({}))
                        }}>
                          Limpar filtros
                        </TabButton>

                        <Button
                          onClick={() => {
                            closeTabAndGoToTop()
                            dispatch(setFilters(values))

                            Object.keys(values).forEach(key => {
                              logAmplitudeEvent({
                                event: 'Filtro aplicado',
                                eventProperties: {
                                  'Variável': key,
                                  'Valor': values[key]
                                }
                              })
                            })
                          }}
                        >
                          Aplicar filtros
                        </Button>
                      </ButtonsWrapper>
                    </FlexSpaceBetween>

                    <Divider my='medium' fullWidth />
                  </Col>
                </Row>
              </Container>

              <TabContent>
                <Content filterProps={filterProps} />
              </TabContent>
            </>
          )
        }}
      />
    </Tab>
  )
}

export default withRouter(Filters)
