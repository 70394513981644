export const SIGN_IN_REQUESTED = 'SIGN_IN_REQUESTED'
export const SIGN_IN_SUCCEEDED = 'SIGN_IN_SUCCEEDED'
export const SIGN_IN_FAILED = 'SIGN_IN_FAILED'

export const SIGN_OUT = 'SIGN_OUT'

export const UPDATE_USER_INFO_REQUESTED = 'UPDATE_USER_INFO_REQUESTED'
export const UPDATE_USER_INFO_SUCCEEDED = 'UPDATE_USER_INFO_SUCCEEDED'

export const SET_USERS = 'SET_USERS'
