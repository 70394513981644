/**
 *
 * Métodos relacionados a formatação de valores.
 *
 */
const formatValue = {
  /**
   *
   * Converte datas para formato dd/mm/aaaa
   * @param {String} dateString
   * @return {String}
   *
  */
  formatDate: dateString => {
    if (dateString === 'NA' || !dateString) return 'NA'

    const monthNumber = {
      /* pt */
      'Jan': '01',
      'Fev': '02',
      'Mar': '03',
      'Abr': '04',
      'Mai': '05',
      'Jun': '06',
      'Jul': '07',
      'Ago': '08',
      'Set': '09',
      'Out': '10',
      'Nov': '11',
      'Dez': '12',
      /* en */
      'Jan': '01',
      'Feb': '02',
      'Mar': '03',
      'Apr': '04',
      'May': '05',
      'Jun': '06',
      'Jul': '07',
      'Aug': '08',
      'Sep': '09',
      'Oct': '10',
      'Nov': '11',
      'Dec': '12',
    }

    if (/^\w{3}, \d{2} \w{3} \d{4}/.test(dateString)) {
      const splitted = dateString.split(' ')
      return `${splitted[1]}/${monthNumber[splitted[2]]}/${splitted[3]}`
    }

    if (/\d{4}-\d{2}-\d{2}/.test(dateString)) {
      const splitted = dateString.slice(0, 10).split('-')
      return `${splitted[2]}/${splitted[1]}/${splitted[0]}`
    }

    return dateString
  }
}

export const {
  formatDate
} = formatValue

export default formatValue
