/* React */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

/* Components */
import {
  CustomBlock,
  CustomText,
  TextWithIcon,
  Icon,
  theme
} from '@zeta/ui/src'

/* Styled */
import { Wrapper, Thumbnail, RiskItem, Totals, Icons } from './styled'

/* Constants */
import { features } from 'constants/config'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'

const visibleRisk = {
  1: 'Baixo',
  2: 'Médio',
  3: 'Alto'
}

export const getInfos = (subjectType, subject) => {
  if (subjectType === 'people') {
    return (
      <>
        {subject.registration_data.office && (
          <TextWithIcon mb="small">
            <Icon icon="briefcase-outline" />

            <span>
              <strong>Escritório:</strong> {subject.registration_data.office}
            </span>
          </TextWithIcon>
        )}

        {features.actingArea && subject.registration_data.acting_area && (
          <TextWithIcon mb="small">
            <Icon icon="briefcase-outline" />

            <span>
              <strong>Frente de atuação:</strong>{' '}
              {subject.registration_data.acting_area}
            </span>
          </TextWithIcon>
        )}

        {features.occupationArea && subject.registration_data.area && (
          <TextWithIcon mb="small">
            <Icon icon="briefcase-outline" />

            <span>
              <strong>Área de ocupação:</strong>{' '}
              {subject.registration_data.area}
            </span>
          </TextWithIcon>
        )}

        <TextWithIcon>
          <Icon icon="person-outline" />

          <span>
            <strong>CPF:</strong> {subject.document}
          </span>
        </TextWithIcon>
      </>
    )
  }

  if (subjectType === 'company') {
    return (
      <>
        <TextWithIcon>
          <Icon icon="clipboard-outline" />

          <span>
            <strong>CNPJ:</strong> {subject.document}
          </span>
        </TextWithIcon>
      </>
    )
  }
}

const ListItem = ({
  profile,
  risks,
  riskSiteTotal,
  infos: Infos,
  isFlat,
  isTermPage,
  ...rest
}) => {
  return (
    <Wrapper isFlat={isFlat} {...rest}>
      <CustomBlock
        override={`
          display: flex;
          align-items: center;

          @media (max-width: 575px) {
            flex-direction: column;
          }

          @media (max-width: 991px) {
            margin-bottom: ${theme.spacing.large}
          }
        `}
      >
        <Thumbnail src={profile.thumbnail} />

        <CustomBlock
          override={`
            @media (max-width: 575px) {
              margin-top: ${theme.spacing.medium};
              width: 100%;
            }
          `}
        >
          <CustomText weight="700" mb="small">
            {profile.name}
          </CustomText>

          <Infos />
        </CustomBlock>
      </CustomBlock>

      <CustomBlock
        override={`
          display: flex;

          @media (max-width: 575px) {
            flex-direction: column;
            align-items: flex-start;
          }

          @media (min-width: 992px) {
            justify-content: flex-end;
            margin-left: ${theme.spacing.medium};
          }
        `}
      >
        {Object.keys(risks)
          .reverse()
          .map((risk) => (
            <RiskItem risk={risk} key={uuidv4()}>
              <CustomBlock
                override={`
                display: flex;
                align-items: center;
              `}
              >
                <Totals risk={risk}>{risks[risk].total}</Totals>

                <CustomBlock
                  override={`
                    width: calc(100% - 56px);
                    white-space: nowrap;
                  `}
                >
                  <CustomText
                    size="0.875rem"
                    color={theme.colors.risk[risk].medium}
                  >
                    {isTermPage ? (
                      <>
                        Menções <br />
                        do termo
                      </>
                    ) : (
                      'Menções de'
                    )}
                  </CustomText>

                  {!isTermPage && (
                    <CustomText
                      weight="700"
                      color={theme.colors.risk[risk].medium}
                    >
                      {visibleRisk[risk]} risco
                    </CustomText>
                  )}
                </CustomBlock>
              </CustomBlock>

              {risks[risk].total > 0 && (
                <Fragment>
                  <Icons risk={risk}>
                    {risks[risk].facebook > 0 && (
                      <Icon icon="fa-facebook" ml="small" />
                    )}
                    {risks[risk].linkedin > 0 && (
                      <Icon icon="fa-linkedin" ml="small" />
                    )}
                    {risks[risk].instagram > 0 && (
                      <Icon icon="fa-instagram" ml="small" />
                    )}
                    {risks[risk].twitter > 0 && (
                      <Icon icon="fa-twitter" ml="small" />
                    )}
                    {risks[risk].youtube > 0 && (
                      <Icon icon="fa-youtube" ml="small" />
                    )}
                    {risks[risk].site > 0 && <Icon icon="globe" ml="small" />}
                  </Icons>
                </Fragment>
              )}
            </RiskItem>
          ))}

        {riskSiteTotal !== null && (
          <RiskItem risk="site" key={uuidv4()}>
            <CustomBlock
              override={`
                display: flex;
                align-items: center;
              `}
            >
              <Totals risk="site">{riskSiteTotal}</Totals>

              <CustomBlock
                override={`
                  width: calc(100% - 56px);
                  white-space: nowrap;
                `}
              >
                <CustomText
                  size="0.875rem"
                  color={theme.colors.risk.site.medium}
                >
                  Menções{' '}
                  {isTermPage && (
                    <>
                      <br />
                      do termo
                    </>
                  )}
                </CustomText>

                {!isTermPage && (
                  <CustomText
                    weight="700"
                    color={theme.colors.risk.site.medium}
                  >
                    no site
                  </CustomText>
                )}
              </CustomBlock>
            </CustomBlock>
          </RiskItem>
        )}
      </CustomBlock>
    </Wrapper>
  )
}

ListItem.propTypes = {
  profile: PropTypes.shape({
    name: PropTypes.string.isRequired,
    thumbnail: PropTypes.string.isRequired
  }),
  risks: PropTypes.shape({
    1: PropTypes.shape({
      total: PropTypes.number.isRequired,
      facebook: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      twitter: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      linkedin: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      instagram: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
    }),
    2: PropTypes.shape({
      total: PropTypes.number.isRequired,
      facebook: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      twitter: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      linkedin: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      instagram: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
    }),
    3: PropTypes.shape({
      total: PropTypes.number.isRequired,
      facebook: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      twitter: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      linkedin: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
      instagram: PropTypes.oneOfType([PropTypes.number, PropTypes.bool])
    })
  }),
  riskSiteTotal: PropTypes.number,
  infos: PropTypes.func.isRequired,
  isFlat: PropTypes.bool,
  isTermPage: PropTypes.bool,
  onClick: PropTypes.func.isRequired
}

ListItem.defaultProps = {
  riskSiteTotal: null
}

export default ListItem
