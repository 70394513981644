import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme: { colors } }) => colors.brand.primary.medium};
  border-radius: ${({ theme: { spacing } }) => spacing.xsmall};
  background-color: ${({ theme: { colors } }) => colors.surface.primary.light};
  padding: ${({ theme: { spacing } }) => spacing.small};

  svg {
    margin-right: ${({ theme: { spacing } }) => spacing.small};
    transform: translateY(-1px);
  }

  ${defaultProps}
`

export const Text = styled.div`
  font-size: 0.75rem;
  line-height: 1;
  color: ${({ theme: { colors } }) => colors.brand.primary.medium};
  width: calc(100% - 24px);
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
