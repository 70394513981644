/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Recharts */
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts'

import CustomTooltip from './CustomTooltip'

/* Styled */
import { Wrapper } from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'

const StackedVerticalBarChart = ({
  data,
  chartWidth,
  chartHeight,
  tickCount,
  margin,
  barSize,
  colors,
  orientation,
  allowDecimals,
  ...rest
}) => {
  return (
    <Wrapper {...rest}>
      <ResponsiveContainer width={chartWidth || '100%'} height={chartHeight}>
        <BarChart layout='vertical' data={data} margin={margin} {...rest}>
          <Tooltip content={<CustomTooltip />} />

          <CartesianGrid horizontal={false} />

          <XAxis
            type='number'
            tickCount={tickCount}
            orientation={orientation || 'bottom'}
            allowDecimals={allowDecimals}
          />
          <YAxis type='category' dataKey='name' />

          {data &&
            data[0] &&
            Object.keys(data[0])
              .filter((key) => key !== 'name')
              .map((key, i) => (
                <Bar
                  dataKey={key}
                  barSize={barSize}
                  fill={colors[i]}
                  isAnimationActive={false}
                  stackId='a'
                  key={uuidv4()}
                />
              ))}
        </BarChart>
      </ResponsiveContainer>
    </Wrapper>
  )
}

StackedVerticalBarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  chartWidth: PropTypes.string.isRequired,
  chartHeight: PropTypes.number,
  tickCount: PropTypes.number,
  margin: PropTypes.object,
  barSize: PropTypes.number,
  colors: PropTypes.array.isRequired
}

StackedVerticalBarChart.defaultProps = {
  // data: [
  //   { name: 'Item 1', quantidade: 500, percentual: 30 },
  //   { name: 'Item 2', quantidade: 100, percentual: 10 },
  //   { name: 'Item 3', quantidade: 200, percentual: 20 },
  //   { name: 'Item 4', quantidade: 800, percentual: 50 },
  //   { name: 'Item 5', quantidade: 50, percentual: 38 }
  // ],
  chartHeight: 500,
  margin: {
    left: 20,
    right: 20
  },
  barSize: 20
}

export default StackedVerticalBarChart
