import styled from 'styled-components'

export const User = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacing.small} 0`};

  &:not(:first-child) {
    border-top: 1px solid ${({ theme }) => theme.colors.surface.primary.dark};
  }

  > div {
    width: calc(100% - 40px);
  }
`

export const UserName = styled.div`
  font-size: 0.875rem;
  margin-bottom: ${({ theme }) => theme.spacing.smallest};
`

export const UserEmail = styled.div`
  font-size: 0.875rem;
  margin-bottom: ${({ theme }) => theme.spacing.smallest};
  font-weight: 700;
`

export const UserStatus = styled.div`
  font-size: 0.875rem;
`

export const RemoveBtn = styled.button.attrs(() => ({
  type: 'button'
}))`
  width: ${({ theme }) => theme.spacing.medium};
  height: ${({ theme }) => theme.spacing.medium};
  border: 2px solid ${({ theme }) => theme.colors.feedback.red.medium};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`
