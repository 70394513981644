/* Helpers */
import { uniq, equals } from 'ramda'
import globalHelpers from 'helpers'

import { defaultAvatar } from '@zeta/ui/src'

const helpers = {
  /**
   *
   * Verifica se o perfil possui a rede social especificada e verifica se profile_image possui o regex de url,
   * indicando a existência (ou não) do thumbnail, e retorna essa url (profile_image) caso a verificação seja positiva, do contrário retorna null
   * @param {Object} social objeto da rede social do subject
   * @return {String | null}
   *
   */
  getPicture: (social) =>
    social[0] && /^http/.test(social[0].profile_image)
      ? social[0].profile_image
      : null,

  /**
   *
   * Retorna os subjects vinculados a um escritório formatados para exibição no componente EmployeesTable
   * @param {Array[Number]} ids
   * @param {Array[Object]} subjects
   * @return {Array[Object]}
   *
   */
  formatEmployees: (ids, subjects) =>
    ids
      .map((id) => {
        const subject = subjects.find((subject) => subject.id === id)

        return subject
          ? {
              thumbnail:
                globalHelpers.getPicture(
                  subject.registration_data.linkedin_image
                ) ||
                globalHelpers.getPicture(
                  subject.registration_data.linkedin_image
                ) ||
                defaultAvatar,
              name: subject.name,
              risks: {
                1: subject['1'] ? subject['1'].total : 0,
                2: subject['2'] ? subject['2'].total : 0,
                3: subject['3'] ? subject['3'].total : 0
              },
              id: subject.id
            }
          : null
      })
      .filter((subject) => subject)
      .sort(
        (a, b) =>
          b.risks['1'] +
          b.risks['2'] +
          b.risks['3'] -
          (a.risks['1'] + a.risks['2'] + a.risks['3'])
      ),

  /**
   *
   * Concatena em um único array todas as publicações de todas as redes sociais
   * @param {Array} publications
   * @return {Array}
   *
   */
  mergePublications: (publications) =>
    Object.keys(publications).reduce(
      (acc, social) => acc.concat(publications[social]),
      []
    ),

  /**
   *
   * Concatena em um único array todos os hits (menções) a partir de um array de publicações
   * @param {Array} publications
   * @return {Array}
   *
   */
  mergeHits: (publications) =>
    publications.reduce(
      (acc, publication) =>
        acc.concat(
          publication.hit.map((item) => ({
            ...item,
            url: publication.publication?.url
          }))
        ),
      []
    ),

  /**
   *
   * Retorna um objeto dividindo os hits (menções) por seus respectivos riscos
   * @param {Array} hits
   * @return {Object} { '1': [...], '2': [...], '3': [...] }
   *
   */
  splitHitsByRisk: (hits) =>
    hits.reduce(
      (acc, hit) => ({
        ...acc,
        [hit.word.risk]: acc[hit.word.risk]
          ? acc[hit.word.risk].concat(hit)
          : [].concat(hit)
      }),
      {}
    ),

  /**
   *
   * Filtra um array de hits, retornando apenas os hits do status especificado
   * @param {Array} hits array de hits de um risco específico
   * @param {Number} status 0 a 6
   * @return {Array}
   *
   */
  filterHitsByStatus: (hits, status) =>
    hits.filter((hit) => hit.observation.status == status),

  /**
   *
   * Formata o objeto que será passado para o componente RiskCard
   * @param {Array} hits array de hits de um risco específico
   * @return {Array}
   *
   */
  formatRiskCard: (hits = []) => ({
    totalPosts: uniq(hits.map((hit) => hit.observation.observable_id)).length,
    totalHits: hits.length,
    totalHitsStatus0: helpers.filterHitsByStatus(hits, 0).length,
    totalHitsStatus1: helpers.filterHitsByStatus(hits, 1).length,
    totalHitsStatus2: helpers.filterHitsByStatus(hits, 2).length,
    totalHitsStatus3: helpers.filterHitsByStatus(hits, 3).length,
    totalHitsStatus4: helpers.filterHitsByStatus(hits, 4).length,
    totalHitsStatus5: helpers.filterHitsByStatus(hits, 5).length,
    totalHitsStatus6: helpers.filterHitsByStatus(hits, 6).length,
    totalHitsStatus7: helpers.filterHitsByStatus(hits, 7).length
  }),

  /**
   *
   * Retorna um array com os termos únicos a partir de um array de hits (menções)
   * @param {Array} hits
   * @return {Array}
   *
   */
  getUniqTerms: (hits) =>
    uniq(hits.map((hit) => ({ term: hit.word.term, risk: hit.word.risk }))),

  /**
   *
   * Retorna um array com a formatação das rows para o componente IndividualTermsTable
   * @param {Array[String]} uniqTerms
   * @param {Array[Object]} hits
   * @return {Array[Object]}
   *
   */
  formatSocialRanking: (uniqTerms, hits) =>
    uniqTerms
      .map((term) => ({
        term: term.term,
        risk: term.risk,
        socials: {
          facebook: hits.some(
            (hit) =>
              hit.word.term === term.term &&
              hit.observation.social_type === 'Facebook'
          ),
          linkedin: hits.some(
            (hit) =>
              hit.word.term === term.term &&
              hit.observation.social_type === 'Linkedin'
          ),
          instagram: hits.some(
            (hit) =>
              hit.word.term === term.term &&
              hit.observation.social_type === 'Instagram'
          ),
          twitter: hits.some(
            (hit) =>
              hit.word.term === term.term &&
              hit.observation.social_type === 'Twitter'
          ),
          youtube: hits.some(
            (hit) =>
              hit.word.term === term.term &&
              hit.observation.social_type === 'Youtube'
          )
        },
        total: hits.filter((hit) => hit.word.term === term.term).length
      }))
      .sort((a, b) => b.total - a.total),

  /**
   *
   * Ordena as publicações por data
   * @param {Array[Object]} publications
   * @return {Array[Object]}
   *
   */
  sortPublicationsByDate: (publications) => {
    const notNA = publications.filter(
      (publication) => publication.publication.date_post !== 'NA'
    )
    const NA = publications.filter(
      (publication) => publication.publication.date_post === 'NA'
    )

    return notNA
      .sort(
        (a, b) =>
          new Date(b.publication.date_post) - new Date(a.publication.date_post)
      )
      .concat(NA)
  },

  /**
   *
   * Ordena as bios por data
   * @param {Array[Object]} bios
   * @return {Array[Object]}
   *
   */
  sortBiosByDate: (bios) => {
    if (bios.length === 0) return bios

    const prop = bios[0].bios ? 'bios' : 'experience'

    return bios.sort(
      (a, b) => new Date(b[prop].collected_at) - new Date(a[prop].collected_at)
    )
  },

  /**
   *
   * Verifica se value é uma data, e em caso positivo, retorna apenas os 10 primeiros caracteres (aaaa-mm-dd)
   * @param {String|Number} value
   * @return {String|Number}
   *
   */
  sliceIfDate: (value) =>
    /\d{4}-\d{2}-\d{2}/.test(value) ? value.slice(0, 10) : value,

  /**
   *
   * Filtra as publicações do subject
   * @param {Array[Object]} publications
   * @param {Boolean} viewFilteredPublications
   * @param {Object} filters
   * @return {Array[Object]}
   *
   */
  filterPublications: (publications, viewFilteredPublications, filters) => {
    if (!filters || equals(filters, {}) || !viewFilteredPublications) {
      return publications
    }

    const filteredProps = Object.keys(filters)

    return publications
      .filter((publication) =>
        filteredProps
          .map((prop) =>
            Array.isArray(filters[prop])
              ? filters[prop].some((filterValue) =>
                  publication.hit.some(
                    (hit) => hit.observation[prop] === filterValue
                  )
                )
              : (filters[prop].start
                  ? publication.hit.some(
                      (hit) =>
                        helpers.sliceIfDate(hit.observation[prop]) >=
                        filters[prop].start
                    )
                  : true) &&
                (filters[prop].end
                  ? publication.hit.some(
                      (hit) =>
                        helpers.sliceIfDate(hit.observation[prop]) <=
                        filters[prop].end
                    )
                  : true)
          )
          .every((exp) => exp)
      )
      .reduce((acc, curPub) => {
        const updatedCurPub = {
          ...curPub,
          hit: curPub.hit.filter((hit) =>
            filteredProps
              .map((prop) =>
                Array.isArray(filters[prop])
                  ? filters[prop].some(
                      (filterValue) => filterValue === hit.observation[prop]
                    )
                  : (filters[prop].start
                      ? helpers.sliceIfDate(hit.observation[prop]) >=
                        filters[prop].start
                      : true) &&
                    (filters[prop].end
                      ? helpers.sliceIfDate(hit.observation[prop]) <=
                        filters[prop].end
                      : true)
              )
              .every((exp) => exp)
          )
        }

        return acc.concat(updatedCurPub)
      }, [])
  },

  /**
   *
   * Filtra os hits do subject
   * @param {Array[Object]} hits
   * @param {Boolean} viewFilteredPublications
   * @param {Object} filters
   * @return {Array[Object]}
   *
   */
  filterHits: (hits, viewFilteredPublications, filters) => {
    if (!filters || equals(filters, {}) || !viewFilteredPublications)
      return hits

    const filteredProps = Object.keys(filters)

    return hits.filter((hit) =>
      filteredProps
        .map((prop) =>
          Array.isArray(filters[prop])
            ? filters[prop].some(
                (filterValue) => filterValue === hit.observation[prop]
              )
            : (filters[prop].start
                ? helpers.sliceIfDate(hit.observation[prop]) >=
                  filters[prop].start
                : true) &&
              (filters[prop].end
                ? helpers.sliceIfDate(hit.observation[prop]) <=
                  filters[prop].end
                : true)
        )
        .every((exp) => exp)
    )
  }
}

export const {
  getPicture,
  formatEmployees,
  mergePublications,
  mergeHits,
  splitHitsByRisk,
  filterHitsByStatus,
  formatRiskCard,
  getUniqTerms,
  formatSocialRanking,
  sortPublicationsByDate,
  sortBiosByDate,
  sliceIfDate,
  filterPublications,
  filterHits
} = helpers

export default helpers
