/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Styled */
import { Wrapper, Background, Progress, Percentage, Text } from './styled'

const ProgressChart = ({
  percentage,
  hidePercent,
  text,
  fill,
  sqSize,
  strokeWidth,
  numberDisplay,
  ...rest
}) => {
  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (sqSize - strokeWidth) / 2
  // Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${sqSize} ${sqSize}`
  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2
  // Scale 100% coverage overlay with the actual percent
  const dashOffset = dashArray - (dashArray * percentage) / 100

  return (
    <Wrapper {...rest}>
      <svg width={sqSize} height={sqSize} viewBox={viewBox}>
        <Background
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
        />

        <Progress
          cx={sqSize / 2}
          cy={sqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          // Start progress marker at 12 O'Clock
          transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset
          }}
          fill={fill}
        />

        <Percentage
          x='50%'
          y='50%'
          dy={text ? '-.2em' : '4px'}
          textAnchor='middle'
          fill={fill}
        >
          {numberDisplay || percentage}
          {!hidePercent && '%'}
        </Percentage>

        {text && (
          <Text x='50%' y='50%' dy='.8em' textAnchor='middle'>
            {text}
          </Text>
        )}
      </svg>
    </Wrapper>
  )
}

ProgressChart.propTypes = {
  percentage: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired
  ]).isRequired,
  hidePercent: PropTypes.bool,
  text: PropTypes.string,
  fill: PropTypes.string,
  sqSize: PropTypes.number,
  strokeWidth: PropTypes.number
}

ProgressChart.defaultProps = {
  sqSize: 130,
  strokeWidth: 15
}

export default ProgressChart
