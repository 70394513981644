/* React */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

/* Components */
import { Row, Col } from 'styled-bootstrap-grid'

import {
  CustomBlock,
  CustomText
} from '@zeta/ui/src'

import FilterTabs from 'components/filters/FilterTabs'
import Selection from 'components/filters/Selection'

/* Parts */
import SelectedFilters from './SelectedFilters'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'

/* Constants */
import { visibleLabels } from 'constants/filters'

const OccupationAreaFilter = ({
  ...props
}) => {
  const dynamicFiltersItems = useSelector(state => state.data.dynamicFiltersItems)

  const [activeView, setActiveView] = useState('A')

  const letters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']

  return (
    <CustomBlock mt='xlarge'>
      <Row>
        <Col>
          <CustomText size='0.75rem' mb='medium'>{visibleLabels['occupation_area']}</CustomText>

          <SelectedFilters />

          <FilterTabs
            items={
              [...letters, 'Número'].map(letter => (
                {
                  text: letter,
                  onClickFn: () => setActiveView(letter),
                  isActive: activeView === letter
                }
              ))
            }
          />

          {letters.map(letter => activeView === letter
            ? <Selection
              display={'column'}
              columnWidth={'25%'}
              selectionType={'checkbox'}
              name={'occupation_area'}
              items={dynamicFiltersItems['occupation_area'].filter(item => item.visibleValue.charAt(0).toLowerCase() === letter.toLowerCase())}
              mt={'large'}
              key={uuidv4()}
              {...props}
            />
            : null
          )}

          {activeView === 'Número' &&
            <Selection
              display={'column'}
              columnWidth={'25%'}
              selectionType={'checkbox'}
              name={'occupation_area'}
              items={dynamicFiltersItems['occupation_area'].filter(item => !isNaN(Number(item.visibleValue.charAt(0))))}
              mt={'large'}
              {...props}
            />}
        </Col>
      </Row>
    </CustomBlock>
  )
}

export default OccupationAreaFilter
