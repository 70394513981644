/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Styled */
import { Wrapper } from './styled'

const Button = ({ type, isDisabled, children, ...rest }) => (
  <Wrapper type={type || 'button'} disabled={isDisabled} {...rest}>
    {children}
  </Wrapper>
)

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  outlined: PropTypes.bool,
  red: PropTypes.bool,
  green: PropTypes.bool,
  small: PropTypes.bool,
  large: PropTypes.bool,
  fullWidth: PropTypes.bool
}

export default Button
