import styled from 'styled-components'

import { Col } from 'styled-bootstrap-grid'

export const CardCol = styled(Col)`
  @media screen and (min-width: 768px) {
    margin-right: 60px;
  }
  @media screen and (min-width: 992px) {
    margin-right: 80px;
  }
  @media screen and (min-width: 1200px) {
    margin-right: 95px;
  }
`

export const Label = styled.label`
  display: block;
  margin-bottom: ${({ theme }) => theme.spacing.xsmall};
  font-size: 0.875rem;
  font-weight: 700;
`

export const Input = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid ${({ theme }) => theme.colors.surface.primary.dark};
  border-radius: ${({ theme: { radius } }) => radius.smallest};
  padding: 0 ${({ theme }) => theme.spacing.small};
  font-size: 0.875rem;

  &:focus {
    border-color: ${({ theme }) => theme.colors.brand.primary.medium};
    outline: 0;
  }

  &:disabled {
    background-color: ${({ theme }) => theme.colors.surface.primary.lightness};
    color: ${({ theme }) => theme.colors.type.medium};
  }
`

export const TabButton = styled.button.attrs(() => ({
  type: 'button'
}))`
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.brand.tertiary.lightness};

  ${({ isActive, theme }) => isActive && `color: ${theme.colors.brand.primary.medium};`}

  &:hover {
    color: ${({ theme }) => theme.colors.brand.primary.medium};
    transition: color .3s;
  }

  &:not(:first-child) {
    margin-left: ${({ theme }) => theme.spacing.medium};
  }
`
