import styled from 'styled-components'

export const ExpandButton = styled.button`
  font-size: 0.75rem;
  color: ${({ theme: { colors } }) => colors.brand.tertiary.lightness};
  border-bottom: 1px solid
    ${({ theme: { colors } }) => colors.surface.primary.dark};
  padding: ${({ theme: { spacing } }) => `${spacing.small} 0`};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  outline: 0;
`

export const ItemCheckbox = styled.div`
  width: ${({ theme: { spacing } }) => spacing.medium};
  height: ${({ theme: { spacing } }) => spacing.medium};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  border-radius: ${({ theme: { radius } }) => radius.smallest};
`

export const ItemLabel = styled.div`
  font-size: 0.75rem;
  color: ${({ theme: { colors } }) => colors.brand.tertiary.lightness};
  width: calc(100% - 24px);
  margin-left: ${({ theme: { spacing } }) => spacing.small};
`

export const Item = styled.button`
  display: flex;
  align-items: center;
  outline: 0;
  text-align: left;

  &:not(:last-child) {
    margin-bottom: ${({ theme: { spacing } }) => spacing.small};
  }

  ${ItemCheckbox} {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  ${({ selected, theme: { colors } }) =>
    selected &&
    `
    ${ItemCheckbox} {
      background-color: ${colors.brand.primary.dark};
      border-color: ${colors.brand.primary.dark};

      svg {
        fill: ${colors.surface.secondary.lightness};
      }
    }

    ${ItemLabel} {
      color: ${colors.brand.tertiary.dark};
    }

    ${CloudItem} {
      background-color: ${colors.brand.primary.dark};
      border-color: ${colors.brand.primary.dark};
      color: ${colors.surface.secondary.lightness};
    }
  `}

  ${({ display, columnWidth, theme: { spacing } }) =>
    display === 'column' &&
    `
    @media (min-width: 576px) {
      display: inline-flex;
      width: 50%;
      padding-right: ${spacing.medium};
    }

    @media (min-width: 768px) {
      width: ${columnWidth};
    }
  `}

  ${({ display, theme: { spacing } }) =>
    display === 'cloud' &&
    `
    display: inline-block;

    &:not(:last-child) {
      margin-right: ${spacing.small};
    }
  `}
`

export const CloudItem = styled.div`
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  border-radius: ${({ theme: { radius } }) => radius.small};
  font-size: 0.75rem;
  color: ${({ theme: { colors } }) => colors.brand.tertiary.lightness};
  padding: ${({ theme: { spacing } }) => `${spacing.small} ${spacing.medium}`};
`
