import React from 'react'

import { Route } from 'react-router-dom'

import SignIn from '../SignIn'
import ForgotPassword from '../ForgotPassword'
import SetPassword from '../SetPassword'
import SignOut from '../SignOut'
import PrivateRoute from '../PrivateRoute'
import UserConfigurations from '../UserConfigurations'

import { paths } from '../../constants/config'

const Routes = ({ initialPath, UserPageWrapper, history }) => [
  <Route
    exact
    path={paths.signIn}
    render={(props) => (
      <SignIn history={history} initialPath={initialPath} {...props} />
    )}
    key={paths.signIn}
  />,
  <ForgotPassword
    exact
    path={paths.forgotPassword}
    history={history}
    key={paths.forgotPassword}
  />,
  <SetPassword
    exact
    path={`${paths.setPassword}/:token`}
    history={history}
    key={paths.setPassword}
  />,
  <SignOut exact path={paths.signOut} to={paths.signIn} key={paths.signOut} />,
  <PrivateRoute
    exact
    path={paths.userConfigurations}
    permissions={['admin', 'standard']}
    key={paths.userConfigurations}
  >
    <UserPageWrapper>
      <UserConfigurations />
    </UserPageWrapper>
  </PrivateRoute>
]

export default Routes
