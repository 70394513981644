/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import { Icon, theme } from '@zeta/ui/src'

/* Styled */
import { Wrapper, Item, Text } from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'

const FilterTabs = ({ items, ...rest }) => {
  return (
    <Wrapper {...rest}>
      {items.map((item) => (
        <Item
          type='button'
          onClick={item.onClickFn}
          isActive={item.isActive}
          key={uuidv4()}
        >
          <Text isActive={item.isActive}>{item.text}</Text>

          <Icon
            icon='arrow-ios-downward-outline'
            size='medium'
            fill={
              item.isActive
                ? theme.colors.surface.secondary.lightness
                : theme.colors.brand.primary.medium
            }
          />
        </Item>
      ))}
    </Wrapper>
  )
}

FilterTabs.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClickFn: PropTypes.func.isRequired,
      isActive: PropTypes.bool.isRequired
    })
  )
}

export default FilterTabs
