/* React */
import React, { useState, useEffect } from 'react'

/* Components */
import {
  Card,
  Tabs,
  Icon,
  CustomBlock,
  CustomText,
  Chip,
  FlexCentered,
  LoadingSpinner,
  theme
} from '@zeta/ui/src'

import { StatusButton } from '../SocialTabs/TermsTable/styled'

import ClassifyModal from './ClassifyModal'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'
import { formatDate } from '@zeta/helpers'
import { getSubjectBios } from 'api'

/* Constants */
import { visibleBiosStatus } from 'constants/global'

const Bios = ({ subjectId, subjectType, activeUnit }) => {
  const [subjectBios, setSubjectBios] = useState(null)
  const [isLoadingBios, setIsLoadingBios] = useState(false)
  const [activeSocialsTab, setActiveSocialsTab] = useState(null)
  const [contentToClassify, setContentToClassify] = useState(null)

  useEffect(() => {
    setIsLoadingBios(true)

    getSubjectBios({ subjectId }).then((res) => {
      setSubjectBios(res.data)
      setActiveSocialsTab(
        Object.keys(res.data).filter((social) => res.data[social].length > 0)[0]
      )
      setIsLoadingBios(false)
    })
  }, [])

  const findBios = () => subjectBios[activeSocialsTab].find((item) => item.bios)
  const findExperience = () =>
    subjectBios[activeSocialsTab].find((item) => item.experience)

  return (
    <>
      <Card>
        {subjectBios && activeSocialsTab && (
          <>
            {Object.keys(subjectBios).some(
              (social) => subjectBios[social].length > 0
            ) && (
              <Tabs
                items={Object.keys(subjectBios)
                  .filter((social) => subjectBios[social].length > 0)
                  .map((social) => ({
                    icon: <Icon icon={`fa-${social}`} />,
                    text: social.charAt(0).toUpperCase() + social.slice(1),
                    onClickFn: () => setActiveSocialsTab(social),
                    isActive: activeSocialsTab === social
                  }))}
              />
            )}

            {/* Bios */}
            {subjectBios[activeSocialsTab].length > 0 && findBios() && (
              <CustomBlock mt="large">
                {/* Headline Bios */}
                <CustomText size="0.875rem" weight="700" mb="xsmall">
                  Bio{' '}
                  <span style={{ fontWeight: '400' }}>
                    (coletado em: {formatDate(findBios().bios.collected_at)})
                  </span>
                </CustomText>

                {/* Conteúdo Bios */}
                <CustomBlock
                  override="div { font-size: 0.875rem; }"
                  mb="xsmall"
                >
                  {activeSocialsTab === 'linkedin' ? (
                    <>
                      {findBios().bios.content.title && (
                        <CustomText
                          color={theme.colors.brand.tertiary.lightness}
                          weight="700"
                        >
                          {findBios().bios.content.title}
                        </CustomText>
                      )}

                      {findBios().bios.content.about &&
                      findBios().bios.content.about.split('\n').length > 1 ? (
                        findBios()
                          .bios.content.about.split('\n')
                          .map((item) => (
                            <CustomText key={uuidv4()}>{item}</CustomText>
                          ))
                      ) : (
                        <CustomText>{findBios().bios.content.about}</CustomText>
                      )}
                    </>
                  ) : (
                    <CustomText>
                      {findBios().bios.content &&
                      findBios().bios.content.split('\n').length > 1 ? (
                        findBios()
                          .bios.content.split('\n')
                          .map((item) => (
                            <CustomText key={uuidv4()}>{item}</CustomText>
                          ))
                      ) : (
                        <CustomText>{findBios().bios.content}</CustomText>
                      )}
                    </CustomText>
                  )}
                </CustomBlock>

                {/* Status Bios */}
                {findBios().bios.status === 0 ? (
                  <StatusButton
                    type="button"
                    onClick={() => setContentToClassify(findBios())}
                  >
                    <Icon
                      icon="edit-outline"
                      fill={theme.colors.brand.primary.medium}
                      size="medium"
                      mr="xsmall"
                    />
                    <span
                      style={{
                        fontSize: '0.875rem',
                        transform: 'translateY(1px)'
                      }}
                    >
                      Não analisado
                    </span>
                  </StatusButton>
                ) : (
                  <Chip bg={theme.colors.brand.secondary.medium}>
                    <button
                      type="button"
                      onClick={() => setContentToClassify(findBios())}
                    >
                      <Icon
                        icon="edit-outline"
                        fill={theme.colors.surface.secondary.lightness}
                        size="medium"
                        mr="xsmall"
                      />
                    </button>
                    <span style={{ fontSize: '0.75rem' }}>
                      {visibleBiosStatus[findBios().bios.status]}
                    </span>
                  </Chip>
                )}
              </CustomBlock>
            )}

            {/* Experiência */}
            {subjectBios[activeSocialsTab].length > 0 && findExperience() && (
              <CustomBlock mt="large">
                {/* Headline Experiência */}
                <CustomText size="0.875rem" weight="700" mb="xsmall">
                  Experiência{' '}
                  <span style={{ fontWeight: '400' }}>
                    (coletado em:{' '}
                    {formatDate(findExperience().experience.collected_at)})
                  </span>
                </CustomText>

                {/* Conteúdo Experiência */}
                <CustomBlock>
                  {findExperience().experience.content.experiences.map(
                    (experience, i) => (
                      <CustomBlock
                        mt={i === 0 ? '0' : 'medium'}
                        mb={
                          i ===
                          findExperience().experience.content.experiences
                            .length -
                            1
                            ? 'xsmall'
                            : '0'
                        }
                        override="div { font-size: 0.875rem; }"
                        key={uuidv4()}
                      >
                        {experience.role && (
                          <CustomText
                            color={theme.colors.brand.tertiary.lightness}
                            weight="700"
                          >
                            {experience.role}
                          </CustomText>
                        )}
                        {experience.company_name && (
                          <CustomText>{experience.company_name}</CustomText>
                        )}
                        {experience.description &&
                        experience.description.split('\n').length > 1 ? (
                          experience.description
                            .split('\n')
                            .map((item) => (
                              <CustomText key={uuidv4()}>{item}</CustomText>
                            ))
                        ) : (
                          <CustomText>{experience.description}</CustomText>
                        )}
                        {experience.time_of_work && (
                          <CustomText>{experience.time_of_work}</CustomText>
                        )}
                      </CustomBlock>
                    )
                  )}
                </CustomBlock>

                {/* Status Experiência */}
                {findExperience().experience.status === 0 ? (
                  <StatusButton
                    type="button"
                    onClick={() => setContentToClassify(findExperience())}
                  >
                    <Icon
                      icon="edit-outline"
                      fill={theme.colors.brand.primary.medium}
                      size="medium"
                      mr="xsmall"
                    />
                    <span
                      style={{
                        fontSize: '0.875rem',
                        transform: 'translateY(1px)'
                      }}
                    >
                      Não analisado
                    </span>
                  </StatusButton>
                ) : (
                  <Chip bg={theme.colors.brand.secondary.medium}>
                    <button
                      type="button"
                      onClick={() => setContentToClassify(findExperience())}
                    >
                      <Icon
                        icon="edit-outline"
                        fill={theme.colors.surface.secondary.lightness}
                        size="medium"
                        mr="xsmall"
                      />
                    </button>
                    <span style={{ fontSize: '0.75rem' }}>
                      {visibleBiosStatus[findExperience().experience.status]}
                    </span>
                  </Chip>
                )}
              </CustomBlock>
            )}
          </>
        )}

        {isLoadingBios && (
          <FlexCentered>
            <LoadingSpinner size="40" my="large" />
          </FlexCentered>
        )}

        {/* Sem Resultados */}
        {subjectBios &&
          Object.keys(subjectBios).every(
            (social) => subjectBios[social].length === 0
          ) && (
            <CustomText size="0.875rem">Nenhum dado a ser exibido.</CustomText>
          )}
      </Card>

      {/* Modal de Classificação */}
      {contentToClassify && (
        <ClassifyModal
          contentToClassify={contentToClassify}
          setContentToClassify={setContentToClassify}
          subjectBios={subjectBios}
          setSubjectBios={setSubjectBios}
          subjectId={subjectId}
          subjectType={subjectType}
          activeUnit={activeUnit}
        />
      )}
    </>
  )
}

export default Bios
