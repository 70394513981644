/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Styled */
import { Wrapper, Display } from './styled'

const SelectedFilter = ({ value }) => (
  <Wrapper>
    <Display>
      {value}
    </Display>
  </Wrapper>
)

SelectedFilter.propTypes = {
  value: PropTypes.string.isRequired
}

export default SelectedFilter
