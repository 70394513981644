import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.div`
  ${defaultProps}
`

export const ExpandButton = styled.button`
  background-color: ${({ theme: { colors } }) =>
    colors.brand.primary.lightness};
  color: ${({ theme: { colors } }) => colors.brand.tertiary.light};
  padding: ${({ theme: { spacing } }) => `${spacing.small} ${spacing.medium}`};
  display: flex;
  justify-content: space-between;
  width: 100%;
  text-align: left;
`

export const Title = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
  width: calc(100% - ${({ theme: { spacing } }) => spacing.large});
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
