/* React */
import React, { useState } from 'react'

/* Components */
import {
  Modal,
  ModalContent,
  ModalActions,
  Button,
  LoadingSpinner,
  Icon,
  CustomText,
  CustomBlock
} from '@zeta/ui/src'

import { Label, Input } from '../../styled'

/* Helpers */
import { createUser } from '../../../api'
import { validateEmail } from '../../../helpers'

const AddModal = ({ setModal, users, setUsers }) => {
  const [isRequesting, setIsRequesting] = useState(false)

  const [fields, setFields] = useState({
    firstName: '',
    lastName: '',
    email: ''
  })

  const handleCreateUser = () => {
    setIsRequesting(true)

    const { email, firstName, lastName } = fields

    createUser({
      email: email.trim(),
      firstName: firstName.trim(),
      lastName: lastName.trim()
    }).then((res) => {
      setIsRequesting(false)
      setModal(null)
      setUsers(users.concat(res.data))
    })
  }

  return (
    <Modal
      title="Adicionar usuário"
      isVisible={true}
      closeFn={() => setModal(null)}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleCreateUser()
        }}
      >
        <ModalContent>
          <CustomText size="0.875rem" mb="medium">
            Preencha o nome e o e-mail do novo usuário. Um link será enviado
            para este endereço de e-mail para que o usuário faça a configuração
            de sua senha.
          </CustomText>

          <CustomBlock my="medium">
            <Label for="firstName">Nome</Label>
            <Input
              id="firstName"
              type="text"
              value={fields.firstName}
              onChange={(e) =>
                setFields({ ...fields, firstName: e.target.value })
              }
            />
          </CustomBlock>

          <CustomBlock mb="medium">
            <Label for="lastName">Sobrenome</Label>
            <Input
              id="lastName"
              type="text"
              value={fields.lastName}
              onChange={(e) =>
                setFields({ ...fields, lastName: e.target.value })
              }
            />
          </CustomBlock>

          <CustomBlock>
            <Label for="email">E-mail</Label>
            <Input
              id="email"
              type="text"
              value={fields.email}
              onChange={(e) => setFields({ ...fields, email: e.target.value })}
            />
          </CustomBlock>
        </ModalContent>

        <ModalActions>
          <Button onClick={() => setModal(null)} red>
            <Icon icon="close-outline" />
            <span>Cancelar</span>
          </Button>

          <Button
            type="submit"
            isDisabled={
              !fields.firstName ||
              !fields.lastName ||
              !validateEmail(fields.email) ||
              (users && users.find((user) => user.email === fields.email)) ||
              isRequesting
            }
          >
            <Icon icon="checkmark-outline" />
            <span>Adicionar usuário</span>
          </Button>

          {isRequesting && <LoadingSpinner size="xlarge" ml="small" />}
        </ModalActions>
      </form>
    </Modal>
  )
}

export default AddModal
