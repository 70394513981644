import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.div`
  position: relative;

  ${({ displayWidth }) => displayWidth && 'display: inline-block;'};

  ${({ isDisabled }) => isDisabled && 'opacity: 0.5;'};

  ${defaultProps}
`

export const Display = styled.button`
  width: ${({ displayWidth }) => (displayWidth ? `${displayWidth}px` : '100%')};
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme: { colors } }) =>
    colors.surface.secondary.lightness};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  padding: 0 ${({ theme: { spacing } }) => spacing.small};

  ${({ disabled }) => disabled && 'cursor: not-allowed !important;'}
`

export const Options = styled.div`
  width: ${({ displayWidth }) => (displayWidth ? `${displayWidth}px` : '100%')};
  position: absolute;
  z-index: 1;
  right: 0;
  background-color: ${({ theme: { colors } }) =>
    colors.surface.secondary.lightness};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  border-top: 0;
`

export const Option = styled.button`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  padding: ${({ theme: { spacing } }) => `0 ${spacing.small}`};
  outline: 0;
  text-align: left;
  font-size: 0.75rem;

  &:hover:not([disabled]) {
    background-color: ${({ theme: { colors } }) =>
      colors.brand.primary.lightness};
    transition: all 0.3s;
  }

  &[disabled] {
    cursor: not-allowed;
    color: ${({ theme: { colors } }) => colors.surface.primary.dark};
  }
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
