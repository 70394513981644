import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

// import Chip from '../Chip'

export const Wrapper = styled.div`
  ${defaultProps}
`

export const Header = styled.button.attrs(() => ({
  type: 'button'
}))`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;

  @media (min-width: 768px) {
    height: 40px;
  }

  ${({ noBorder, theme: { colors, spacing } }) =>
    !noBorder &&
    `
      background-color: ${colors.surface.secondary.lightness};
      border: 1px solid ${colors.surface.primary.dark};
      padding-left: ${spacing.medium};

      @media (max-width: 767px) {
        padding-top: 8px;
        padding-bottom: 8px;
        padding-right: 8px;
      }
    `}
`

// export const TitleWrapper = styled.div`
//   display: flex;
//   align-items: center;

//   ${({ noBorder, theme: { spacing } }) =>
//     !noBorder &&
//     `
//       width: calc(100% - ${spacing.medium} - 40px);
//     `}

//   ${Chip} {
//     &:first-child {
//       margin-right: ${({ theme: { spacing } }) => spacing.small};
//     }
//     &:last-child {
//       margin-left: ${({ theme: { spacing } }) => spacing.small};
//     }
//   }
// `

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: calc(100% - 40px);
  padding-right: ${({ theme: { spacing } }) => spacing.medium};
  /* font-size: 0.75rem; */

  /* ${({ noBorder }) => noBorder && 'font-weight: 700;'} */
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background-color: ${({ theme: { colors } }) => colors.brand.primary.medium};
`

export const Content = styled.div`
  ${({ noBorder, theme: { colors } }) =>
    !noBorder &&
    `
      border: 1px solid ${colors.surface.primary.dark};
      border-top: 0;
      background-color: ${colors.surface.secondary.lightness};
    `}
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
