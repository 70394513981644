/* React */
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

/* Components */
import CustomText from '../helpers/CustomText'
import Divider from '../Divider'

/* Styled */
import { Wrapper } from './styled'

const Card = ({ children, title, ...rest }) => (
  <Wrapper {...rest}>
    {title && (
      <Fragment>
        <CustomText weight='700'>{title}</CustomText>
        <Divider my='medium' fullWidth />
      </Fragment>
    )}

    {children}
  </Wrapper>
)

Card.propTypes = {
  title: PropTypes.string
}

export default Card
