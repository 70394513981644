/* React */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

/* Components */
import { Redirect } from 'react-router-dom'

import { Container, Row } from 'styled-bootstrap-grid'
import { Formik, Field, Form } from 'formik'

import {
  Card,
  Logo,
  LoadingSpinner,
  Button,
  FlexCentered,
  CustomText,
  Icon
} from '@zeta/ui/src'

import AuthWrapper from '../AuthWrapper'
import FieldGroup from '../FieldGroup'
import { ErrorMessage } from '../FieldGroup/styled'

/* Styled */
import { CardCol } from '../styled'

/* Constants */
import { paths } from '../../constants/config'

/* Helpers */
import { validateEmail } from '../../helpers'

/* Action Creators */
import { signIn } from '../../store/reducers/user/action-creators'

const SignIn = ({ history, initialPath }) => {
  const dispatch = useDispatch()

  const token = useSelector((state) => state.user.token)
  const isLoading = useSelector((state) => state.user.isLoading)
  const error = useSelector((state) => state.user.error)

  return token ? (
    <Redirect to={paths.initial} />
  ) : (
    <AuthWrapper>
      <Container>
        <Row alignItems={'center'} justifyContent={'end'}>
          <CardCol md={6} lg={4}>
            <Card>
              <FlexCentered mb={'medium'}>
                <Logo size={'120'} />
              </FlexCentered>

              <Formik
                initialValues={{ email: '', password: '' }}
                onSubmit={(values) =>
                  dispatch(
                    signIn({
                      ...values,
                      history,
                      initialPath
                    })
                  )
                }
              >
                {({ values, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <Field
                      type="email"
                      name="email"
                      label="e-mail"
                      placeholder="nome@empresa.com.br"
                      component={FieldGroup}
                      icon={() => <Icon icon={'email-outline'} />}
                      data-testid="emailInput"
                    />
                    <Field
                      type="password"
                      name="password"
                      label="senha"
                      placeholder="••••••••"
                      component={FieldGroup}
                      icon={() => <Icon icon={'unlock-outline'} />}
                      data-testid="passwordInput"
                    />

                    {/* Erro */}
                    {error && error.response.status === 401 && !isLoading && (
                      <FlexCentered mb="small">
                        <ErrorMessage>Acesso não autorizado.</ErrorMessage>
                      </FlexCentered>
                    )}

                    {/* Loading */}
                    {isLoading && (
                      <FlexCentered>
                        <LoadingSpinner size={'40'} />
                      </FlexCentered>
                    )}

                    {/* Entrar */}
                    <FlexCentered>
                      <Button
                        type="submit"
                        disabled={
                          isLoading ||
                          !values.email ||
                          !values.password ||
                          !validateEmail(values.email)
                        }
                        data-testid="signInBtn"
                      >
                        Entrar
                      </Button>
                    </FlexCentered>
                  </Form>
                )}
              </Formik>

              <FlexCentered mt="medium">
                <CustomText
                  as="button"
                  type="button"
                  onClick={() => history.push(paths.forgotPassword)}
                  size="0.875rem"
                  override="text-decoration: underline;"
                >
                  esqueci minha senha
                </CustomText>
              </FlexCentered>
            </Card>
          </CardCol>
        </Row>
      </Container>
    </AuthWrapper>
  )
}

export default SignIn
