import styled from 'styled-components'
// import PropTypes from 'prop-types'

import defaultProps, { defaultPropsPropTypes } from '../../defaultProps'

export const Flex = styled.div`
  display: flex;

  ${defaultProps}
`

export const FlexCentered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  ${defaultProps}
`

export const FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;

  ${({ wrap }) => wrap && 'flex-wrap: wrap;'}

  ${defaultProps}
`

export const FlexAlignEnd = styled.div`
  display: flex;
  align-items: flex-end;

  ${defaultProps}
`

export const FlexJustifyEnd = styled.div`
  display: flex;
  justify-content: flex-end;

  ${defaultProps}
`

export const FlexSpaceBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  ${defaultProps}
`

// export const Group = styled.div`
//   display: flex;
//   align-items: center;
//   flex-wrap: wrap;

//   > * {
//     margin-bottom: ${({ itemSpacing, theme: { spacing } }) =>
//     itemSpacing ? itemSpacing + 'px' : spacing.medium};

//     ${({ noItemSpacingBottom }) => noItemSpacingBottom && 'margin-bottom: 0;'}

//     &:not(:last-child) {
//       margin-right: ${({ itemSpacing, theme: { spacing } }) =>
//     itemSpacing ? itemSpacing + 'px' : spacing.medium};
//     }
//   }

//   ${defaultProps}
// `

Flex.propTypes = {
  ...defaultPropsPropTypes
}

FlexCentered.propTypes = {
  ...defaultPropsPropTypes
}

FlexAlignEnd.propTypes = {
  ...defaultPropsPropTypes
}

FlexJustifyEnd.propTypes = {
  ...defaultPropsPropTypes
}

FlexSpaceBetween.propTypes = {
  ...defaultPropsPropTypes
}

// Group.propTypes = {
//   itemSpacing: PropTypes.number,
//   noItemSpacingBottom: PropTypes.bool,
//   ...defaultPropsPropTypes
// }

export default Flex
