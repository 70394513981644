/* React */
import React from 'react'

/* Components */
import { SVGAuthGraphism } from '@zeta/ui/src'

/* Styled */
import { Wrapper, AuthGraphismWrapper } from './styled'

const AuthWrapper = ({ children }) => (
  <Wrapper>
    <AuthGraphismWrapper>
      <SVGAuthGraphism />
    </AuthGraphismWrapper>

    {children}
  </Wrapper>
)

export default AuthWrapper
