/* React */
import React from 'react'

/* Components */
import { Row, Col } from 'styled-bootstrap-grid'

import { CustomText, CustomBlock } from '@zeta/ui/src'

import Selection from 'components/filters/Selection'
import DateRange from 'components/filters/DateRange'

/* Constants */
import { selectionOptions, visibleLabels } from 'constants/filters'

const RiskFilters = ({ ...props }) => {
  return (
    <CustomBlock mt="medium">
      <Row>
        <Col lg={4}>
          <Selection
            display="group"
            selectionType="checkbox"
            label={visibleLabels['social_type']}
            name="social_type"
            items={selectionOptions['social_type']}
            {...props}
            mb="xlarge"
          />
        </Col>

        <Col lg={4}>
          <Selection
            display="group"
            selectionType="checkbox"
            label={visibleLabels['observable_type']}
            name="observable_type"
            items={selectionOptions['observable_type']}
            {...props}
            mb="xlarge"
          />
        </Col>

        <Col lg={4}>
          <Selection
            display="group"
            selectionType="checkbox"
            label={visibleLabels['risk']}
            name="risk"
            items={selectionOptions['risk']}
            {...props}
            mb="xlarge"
          />
        </Col>

        <Col lg={4}>
          <Selection
            display="group"
            selectionType="checkbox"
            label={visibleLabels['status']}
            name="status"
            items={selectionOptions['status']}
            {...props}
            mb="xlarge"
          />
        </Col>

        <Col>
          <CustomText size="0.75rem" mb="medium">
            {visibleLabels['date_post']}
          </CustomText>

          <DateRange name="date_post" {...props} />
        </Col>

        <Col>
          <CustomText size="0.75rem" mb="medium" mt="xlarge">
            {visibleLabels['created_at']}
          </CustomText>

          <DateRange name="created_at" {...props} />
        </Col>
      </Row>
    </CustomBlock>
  )
}

export default RiskFilters
