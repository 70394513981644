/* React */
import React, { useState } from 'react'

/* Components */
import { Formik, Form, Field } from 'formik'

import {
  Modal,
  ModalContent,
  ModalActions,
  FieldGroup,
  Button,
  LoadingSpinner,
  Warning,
  Icon,
  CustomText
} from '@zeta/ui/src'

/* Styled */
import { CheckboxWrapper, CheckboxSelect, CheckboxLabel } from './styled'

/* Helpers */
import { path } from 'ramda'
import { logAmplitudeEvent } from '@zeta/helpers'
import { reportMistakes } from 'api'

const Checkbox = ({ isSelected, onClickFn, label }) => {
  return (
    <CheckboxWrapper isSelected={isSelected} onClick={onClickFn}>
      <CheckboxSelect>
        <Icon icon="checkmark-outline" size={12} fill="transparent" />
      </CheckboxSelect>

      <CheckboxLabel>{label}</CheckboxLabel>
    </CheckboxWrapper>
  )
}

const CustomLabel = ({ children }) => (
  <CustomText size="0.75rem" mb="small">
    {children}
  </CustomText>
)

const ReportErrorModal = ({
  isErrorModalVisible,
  setIsErrorModalVisible,
  mistake,
  setMistake,
  subjectId,
  subjectType
}) => {
  const [isReportingError, setIsReportingError] = useState(false)
  const [modalMsg, setModalMsg] = useState(null)
  const [modalTimeout, setModalTimeout] = useState(null)
  const clearModalTimeout = () => clearTimeout(modalTimeout)

  const submitFn = (values) => {
    setIsReportingError(true)

    reportMistakes({
      subjectId,
      mistake: values,
      mistakeId: mistake ? mistake.id : null
    }).then((res) => {
      setIsReportingError(false)
      setModalMsg('Erro reportado com sucesso.')

      const possibleMistakes = [
        'facebook',
        'individual',
        'instagram',
        'linkedin',
        'other',
        'site',
        'twitter'
      ]
      const mistakes = possibleMistakes.filter(
        (possibleMistake) => values[possibleMistake]
      )

      logAmplitudeEvent({
        event: '[Erros] Reportar erro',
        eventProperties: {
          Erros: mistake
            ? mistakes.filter((mistakesItem) => !mistake[mistakesItem])
            : mistakes,
          Local: 'Pág. de "perfil" > modal "Reportar erro"'
        }
      })

      setMistake(res.data)

      setModalTimeout(
        setTimeout(() => {
          setModalMsg(null)
          setIsErrorModalVisible(false)
        }, 3000)
      )
    })
  }

  return (
    <Modal
      isVisible={isErrorModalVisible}
      closeFn={() => {
        clearModalTimeout()
        setModalMsg(null)
        setIsErrorModalVisible(false)
      }}
      title={'Reportar erro'}
    >
      <Formik
        initialValues={{
          facebook: path(['facebook'], mistake) || false,
          notes_facebook: path(['notes_facebook'], mistake) || '',
          linkedin: path(['linkedin'], mistake) || false,
          notes_linkedin: path(['notes_linkedin'], mistake) || '',
          instagram: path(['instagram'], mistake) || false,
          notes_instagram: path(['notes_instagram'], mistake) || '',
          twitter: path(['twitter'], mistake) || false,
          notes_twitter: path(['notes_twitter'], mistake) || '',
          site: path(['site'], mistake) || false,
          notes_site: path(['notes_site'], mistake) || '',
          other: path(['other'], mistake) || false,
          notes_other: path(['notes_other'], mistake) || ''
          // individual: '',
          // notes_individual: ''
        }}
        onSubmit={(values) => submitFn(values)}
      >
        {(props) => {
          const { values, setFieldValue, handleSubmit } = props

          const handleCheckboxClick = (booleanKey, notesKey) => {
            if (values[booleanKey]) {
              setFieldValue(booleanKey, false)
              setFieldValue(notesKey, '')
              return
            }
            setFieldValue(booleanKey, true)
          }

          return (
            <Form onSubmit={handleSubmit}>
              <ModalContent>
                {/* Facebook */}
                <Checkbox
                  label="Facebook incorreto"
                  isSelected={values.facebook}
                  onClickFn={() =>
                    handleCheckboxClick('facebook', 'notes_facebook')
                  }
                />

                {values.facebook && (
                  <>
                    <CustomLabel>URL correta (opcional):</CustomLabel>
                    <Field
                      inputElement="input"
                      name="notes_facebook"
                      component={FieldGroup}
                    />
                  </>
                )}

                {/* Linkedin */}
                <Checkbox
                  label="Linkedin incorreto"
                  isSelected={values.linkedin}
                  onClickFn={() =>
                    handleCheckboxClick('linkedin', 'notes_linkedin')
                  }
                />

                {values.linkedin && (
                  <>
                    <CustomLabel>URL correta (opcional):</CustomLabel>
                    <Field
                      inputElement="input"
                      name="notes_linkedin"
                      component={FieldGroup}
                    />
                  </>
                )}

                {/* Instagram */}
                <Checkbox
                  label="Instagram incorreto"
                  isSelected={values.instagram}
                  onClickFn={() =>
                    handleCheckboxClick('instagram', 'notes_instagram')
                  }
                />

                {values.instagram && (
                  <>
                    <CustomLabel>URL correta (opcional):</CustomLabel>
                    <Field
                      inputElement="input"
                      name="notes_instagram"
                      component={FieldGroup}
                    />
                  </>
                )}

                {/* Twitter */}
                <Checkbox
                  label="Twitter incorreto"
                  isSelected={values.twitter}
                  onClickFn={() =>
                    handleCheckboxClick('twitter', 'notes_twitter')
                  }
                />

                {values.twitter && (
                  <>
                    <CustomLabel>URL correta (opcional):</CustomLabel>
                    <Field
                      inputElement="input"
                      name="notes_twitter"
                      component={FieldGroup}
                    />
                  </>
                )}

                {/* Site */}
                {subjectType === 'company' && (
                  <>
                    <Checkbox
                      label="Site incorreto"
                      isSelected={values.site}
                      onClickFn={() =>
                        handleCheckboxClick('site', 'notes_site')
                      }
                    />

                    {values.site && (
                      <>
                        <CustomLabel>URL correta (opcional):</CustomLabel>
                        <Field
                          inputElement="input"
                          name="notes_site"
                          component={FieldGroup}
                        />
                      </>
                    )}
                  </>
                )}

                {/* Outros */}
                <Checkbox
                  label="Outros"
                  isSelected={values.other}
                  onClickFn={() => handleCheckboxClick('other', 'notes_other')}
                />

                {values.other && (
                  <>
                    <CustomLabel>Descrição do erro:</CustomLabel>
                    <Field
                      inputElement="textarea"
                      name="notes_other"
                      component={FieldGroup}
                    />
                  </>
                )}
              </ModalContent>

              <ModalActions>
                <Button red onClick={() => setIsErrorModalVisible(false)}>
                  <Icon icon="close-outline" />
                  <span>Cancelar</span>
                </Button>

                <Button
                  type="submit"
                  isDisabled={isReportingError || modalMsg !== null}
                >
                  <Icon icon="save-outline" />
                  <span>Confirmar</span>
                </Button>

                {isReportingError && (
                  <LoadingSpinner size={'xlarge'} ml={'small'} />
                )}

                {modalMsg && (
                  <Warning override={'width: 100%;'} mt={'medium'}>
                    {modalMsg}
                  </Warning>
                )}
              </ModalActions>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default ReportErrorModal
