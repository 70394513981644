/* React */
import React from 'react'

/* Redux */
import { Provider } from 'react-redux'
import store from 'store'

/* Styles */
import { GridThemeProvider, BaseCSS } from 'styled-bootstrap-grid'
import { Theme, grid } from '@zeta/ui/src'

/* Router */
import { Router, Redirect, Switch } from 'react-router-dom'
import { createBrowserHistory } from 'history'

/* Components */
import UserRoutes from '@zeta/user/src/components/Routes'
import UserPageWrapper from 'components/UserPageWrapper'

import PrivateRoute from '@zeta/user/src/components/PrivateRoute'

import MainDataLoader from 'components/MainDataLoader'

import BoardsList from 'components/pages/BoardsList'

import DictionariesList from 'components/pages/DictionariesList'
import Dictionary from 'components/pages/Dictionary'
import DictionaryTermPage from 'components/pages/DictionaryTermPage'

import BoardWrapper from 'components/BoardWrapper'
import Dashboard from 'components/pages/Dashboard'
import List from 'components/pages/List'
import Individual from 'components/pages/Individual'

import ScrollToTop from 'components/routeHelpers/ScrollToTop'

/* Styled */
import { Wrapper } from './styled'

/* Constants */
import { paths, subjectTypes } from 'constants/config'

export const history = createBrowserHistory()

const privateRouteDefaultProps = {
  initialPath: paths.initial,
  permissions: ['admin', 'standard']
}

const BoardWrapperRoute = (props) => (
  <PrivateRoute
    component={BoardWrapper}
    {...privateRouteDefaultProps}
    {...props}
  />
)

const App = () => (
  <Theme>
    <GridThemeProvider gridTheme={grid}>
      <Provider store={store}>
        <Router history={history}>
          <MainDataLoader>
            <Wrapper>
              <Switch>
                {UserRoutes({
                  initialPath: paths.initial,
                  UserPageWrapper,
                  history
                })}

                {/* Lista de paineis */}
                <PrivateRoute
                  exact
                  path={'/paineis'}
                  component={BoardsList}
                  {...privateRouteDefaultProps}
                />

                <PrivateRoute
                  exact
                  path={'/dicionarios'}
                  component={DictionariesList}
                  {...privateRouteDefaultProps}
                />
                {/* Dicionário */}
                <PrivateRoute
                  exact
                  path={'/dicionario/:subjectType/:dictionaryId'}
                  component={Dictionary}
                  {...privateRouteDefaultProps}
                />
                {/* Termo do dicionário */}
                <PrivateRoute
                  exact
                  path={'/dicionario/:subjectType/:dictionaryId/:termId'}
                  component={DictionaryTermPage}
                  {...privateRouteDefaultProps}
                />

                {/* Análise Geral */}
                {subjectTypes.people && (
                  <BoardWrapperRoute
                    exact
                    path={'/painel/:boardId/people/dashboard'}
                    content={Dashboard}
                  />
                )}
                {subjectTypes.people && (
                  <BoardWrapperRoute
                    exact
                    path={'/dataset/people/dashboard'}
                    content={Dashboard}
                  />
                )}
                {subjectTypes.company && (
                  <BoardWrapperRoute
                    exact
                    path={'/painel/:boardId/company/dashboard'}
                    content={Dashboard}
                  />
                )}
                {subjectTypes.company && (
                  <BoardWrapperRoute
                    exact
                    path={'/dataset/company/dashboard'}
                    content={Dashboard}
                  />
                )}

                {/* Listagem */}
                {subjectTypes.people && (
                  <BoardWrapperRoute
                    exact
                    path={'/painel/:boardId/people/individual'}
                    content={List}
                  />
                )}
                {subjectTypes.people && (
                  <BoardWrapperRoute
                    exact
                    path={'/dataset/people/individual'}
                    content={List}
                  />
                )}
                {subjectTypes.company && (
                  <BoardWrapperRoute
                    exact
                    path={'/painel/:boardId/company/individual'}
                    content={List}
                  />
                )}
                {subjectTypes.company && (
                  <BoardWrapperRoute
                    exact
                    path={'/dataset/company/individual'}
                    content={List}
                  />
                )}

                {/* Individual */}
                {subjectTypes.people && (
                  <BoardWrapperRoute
                    exact
                    path={'/painel/:boardId/people/individual/:individualID'}
                    content={Individual}
                  />
                )}
                {subjectTypes.people && (
                  <BoardWrapperRoute
                    exact
                    path={'/dataset/people/individual/:individualID'}
                    content={Individual}
                  />
                )}
                {subjectTypes.company && (
                  <BoardWrapperRoute
                    exact
                    path={'/painel/:boardId/company/individual/:individualID'}
                    content={Individual}
                  />
                )}
                {subjectTypes.company && (
                  <BoardWrapperRoute
                    exact
                    path={'/dataset/company/individual/:individualID'}
                    content={Individual}
                  />
                )}

                {/* Redirecionamento padrão (caso nenhuma rota acima dê match) */}
                <Redirect to={paths.initial} />
              </Switch>
            </Wrapper>
          </MainDataLoader>

          <ScrollToTop />
        </Router>
        <BaseCSS />
      </Provider>
    </GridThemeProvider>
  </Theme>
)

export default App
