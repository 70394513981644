/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Styled */
import { Wrapper, Display } from './styled'

const SelectedFilter = ({ name, value }) => (
  <Wrapper>
    <Display>
      {name}: <strong>{value}</strong>
    </Display>
  </Wrapper>
)

SelectedFilter.propTypes = {
  name: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired
  ]),
  value: PropTypes.string.isRequired
}

export default SelectedFilter
