/* React */
import React from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

/* Components */
import { Col } from 'styled-bootstrap-grid'
import RiskCard from 'components/RiskCard'
import TermsRankingTable from '../TermsRankingTable'

/* Helpers */
import { path } from 'ramda'
import {
  filterObservationsBySource,
  splitObservationsByRisk,
  formatRiskCard,
  formatTermsRankingTable
} from '../helpers'
import { filterObservations } from 'helpers'

/* Selectors */
const observationsByRiskSelector = createSelector(
  (state) => state.data.observations,
  (state) => state.data.filters,
  (state) => state.data.subjects,
  (state) => state.data.activeUnit,
  (_, subjectType) => subjectType,
  (_, __, source) => source,
  (observations, filters, subjects, activeUnit, subjectType, source) =>
    splitObservationsByRisk(
      filterObservations(
        filterObservationsBySource(
          path([activeUnit, subjectType], observations) || [],
          source
        ),
        filters,
        path([activeUnit, subjectType], subjects) || []
      )
    )
)

const RiskCards = ({ subjectType, source }) => {
  const observationsByRisk = useSelector((state) =>
    observationsByRiskSelector(state, subjectType, source)
  )
  const terms = useSelector((state) => state.data.terms)

  const RiskCardGroup = ({ risk }) => (
    <Col md={4}>
      <RiskCard
        risk={risk}
        totalListedLabel={'perfis'}
        {...formatRiskCard(observationsByRisk[risk])}
      />

      {observationsByRisk[risk] && (
        <TermsRankingTable
          risk={risk}
          terms={formatTermsRankingTable(observationsByRisk[risk], terms)}
        />
      )}
    </Col>
  )

  return (
    <>
      <RiskCardGroup risk={3} />

      <RiskCardGroup risk={2} />

      <RiskCardGroup risk={1} />
    </>
  )
}

export default RiskCards
