import styled from 'styled-components'

import { Icon } from '@zeta/ui/src'

export const Info = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  margin-bottom: ${({ theme: { spacing } }) => spacing.small};

  > a {
    color: ${({ theme: { colors } }) => colors.brand.primary.medium};

    &:hover {
      color: ${({ theme: { colors } }) => colors.brand.primary.dark};
      transition: all .3s;
    }
  }
`

export const InfoIcon = styled(Icon)`
  > svg {
    width: ${({ theme: { spacing } }) => spacing.medium};
    height: ${({ theme: { spacing } }) => spacing.medium};
    margin-right: ${({ theme: { spacing } }) => spacing.small};
    fill: ${({ theme: { colors } }) => colors.brand.tertiary.medium};
    transform: translateY(-1px);
  }
`

export const Message = styled.div`
  font-size: 0.75rem;
  color: ${({ theme: { colors } }) => colors.surface.primary.darkness};
  padding: ${({ theme: { spacing } }) => `${spacing.large} ${spacing.xlarge}`};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  width: 100%;
  text-align: center;
`
