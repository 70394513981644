/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import SVGLogo from '../svg/SVGLogo'

/* Styled */
import { Wrapper } from './styled'

const Logo = (props) => {
  return (
    <Wrapper {...props}>
      <SVGLogo />
    </Wrapper>
  )
}

Logo.propTypes = {
  size: PropTypes.oneOf([PropTypes.string, PropTypes.number]).isRequired
}

export default Logo
