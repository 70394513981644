import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../../defaultProps'

export const Wrapper = styled.div`
  margin-bottom: ${({ theme: { spacing } }) => spacing.large};
  font-size: 0.75rem;

  ${defaultProps}
`

export const Label = styled.label`
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
`

export const Legend = styled.div`
  color: ${({ theme: { colors } }) => colors.brand.tertiary.lightness};
  font-size: 0.75rem;
  font-weight: 400;
`

export const Prefix = styled.div`
  color: ${({ theme: { colors } }) => colors.brand.tertiary.lightness};
  font-size: 0.75rem;
  margin-right: ${({ theme: { spacing } }) => spacing.small};
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
