/* React */
import React from 'react'

/* Styles */
import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { Normalize } from 'styled-normalize'
import theme from '../../theme/theme'

const GlobalStyle = createGlobalStyle`
  :root {
    font-size: 16px;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
  }

  body {
    font-family: 'Hind', sans-serif;
    font-size: 1rem;
    color: ${({ theme: { colors } }) => colors.type.medium};
    background-color: ${({ theme: { colors } }) =>
      colors.surface.primary.lightness};
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
    padding: 0;
    line-height: 1.2;
  }

  button {
    cursor: pointer;
    border: none;
    background: none;
    color: ${({ theme: { colors } }) => colors.brand.tertiary.light};
    outline: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  /* ReactTooltip */
  .__react_component_tooltip {
    max-width: 288px;
  }

  /* Flatpickr */
  .flatpickr-input,
  .flatpickr-input + .form-control.input {
    width: 80px;
    height: 40px;
    line-height: 40px;
    font-size: 0.75rem;
    border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
    padding-left: ${({ theme: { spacing } }) => spacing.small};
    padding-right: ${({ theme: { spacing } }) => spacing.small};
  }

  .flatpickr-months .flatpickr-month,
  .flatpickr-current-month .flatpickr-monthDropdown-months,
  .flatpickr-weekdays,
  .flatpickr-weekday,
  .flatpickr-day.selected {
    background: ${({ theme: { colors } }) =>
      colors.brand.primary.medium} !important;
  }

  .flatpickr-day.selected {
    border-color: ${({ theme: { colors } }) =>
      colors.brand.primary.medium} !important;
  }

  .flatpickr-current-month {
    font-size: 1rem;
  }

  span.flatpickr-weekday {
    color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
    font-weight: 400;
  }

  .flatpickr-months .flatpickr-prev-month:hover svg,
  .flatpickr-months .flatpickr-next-month:hover svg {
    fill: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  }
`

const Theme = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      {children}
      <Normalize />
      <GlobalStyle />
    </ThemeProvider>
  )
}

export default Theme
