/* React */
import React from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

/* Components */
import { Container, Row, Col } from 'styled-bootstrap-grid'

import {
  Tabs,
  TabsAlt,
  Divider,
  ScrollAnchor,
  FlexCentered,
  LoadingSpinner
} from '@zeta/ui/src'

import ContentWrapper from 'components/ContentWrapper'
import Footer from 'components/Footer'

/* Parts */
import ActiveUnitHandler from 'components/ActiveUnitHandler'
import ListSearch from './ListSearch'
import ListItems from './ListItems'

/* Constants */
import { subjectTypes } from 'constants/config'

/* Helpers */
import { getPathPrefix } from '@zeta/helpers'
import { getDataSetTabsItems } from 'helpers'

const List = ({
  /* router */
  history,
  computedMatch,
  /* props */
  subjectType
}) => {
  const isLoadingArchived = useSelector((state) => state.data.isLoadingArchived)

  const routerProps = {
    history,
    computedMatch
  }

  return (
    <ContentWrapper withSidebar>
      <Container fluid>
        <Row>
          <Col>
            <Tabs
              items={[
                {
                  text: 'Análise de menções',
                  onClickFn: () =>
                    history.push(
                      `${getPathPrefix(computedMatch)}/${subjectType}/dashboard`
                    )
                },
                {
                  text: 'Perfis',
                  onClickFn: () =>
                    history.push(
                      `${getPathPrefix(
                        computedMatch
                      )}/${subjectType}/individual`
                    ),
                  isActive: true
                }
              ]}
            />

            <TabsAlt
              items={getDataSetTabsItems({
                subjectTypes,
                history,
                computedMatch,
                screenPath: 'individual'
              })}
              mb="xlarge"
            />

            <ActiveUnitHandler subjectType={subjectType} />
          </Col>
        </Row>

        {isLoadingArchived && (
          <Row>
            <Col>
              <FlexCentered>
                <LoadingSpinner size="40" my="large" />
              </FlexCentered>
            </Col>
          </Row>
        )}

        {!isLoadingArchived && (
          <>
            <Row alignItems={'end'}>
              <Col lg={6}>
                <ListSearch subjectType={subjectType} />
              </Col>
            </Row>

            <Row>
              <Col>
                <Divider />

                <ScrollAnchor name="listItems" />
                <ListItems subjectType={subjectType} {...routerProps} />
              </Col>
            </Row>
          </>
        )}
      </Container>

      {/* Footer */}
      <Footer />
    </ContentWrapper>
  )
}

export default withRouter(List)
