/* React */
import React from 'react'

const SVGAuthGraphism = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='781'
      height='1018'
      viewBox='0 0 781 1018'
    >
      <defs>
        <path id='a' d='M0 1023.862h1051.915V.815H0z' />
      </defs>
      <g fill='none' fillRule='evenodd'>
        <path fill='#027CA0' d='M472.88 480h302v-4h-302z' />
        <path fill='#634061' d='M252.88 356h52v-7h-52z' />
        <path fill='#389CF2' d='M367.88 300h115v-15h-115z' />
        <path fill='#027CA0' d='M170.88 301h302v-4h-302z' />
        <path fill='#389CF2' d='M-112.12 311h115v-15h-115z' />
        <path fill='#634061' d='M118.88 345h7v-52h-7z' />
        <path
          fill='#027CA0'
          d='M271.88 337h19v-2h-19zM364.88 374h19v-2h-19zM351.88 385h19v-2h-19zM364.88 398h19v-2h-19z'
        />
        <path
          fill='#FB5439'
          d='M371.87 319l.01 18.997-1.99.003-.01-18.997zM339.87 335l.01 18.997-1.989.003-.01-18.997zM328.87 335l.01 18.997-1.99.003-.01-18.997z'
        />
        <path fill='#FFF' d='M313.88 496h2v-19h-2z' />
        <path fill='#027CA0' d='M300.88 483h2v-19h-2z' />
        <path fill='#FFF' d='M288.88 496h2v-19h-2z' />
        <path
          fill='#FB5439'
          d='M368.88 483.99l-18.996.01-.003-1.99 18.997-.01z'
        />
        <g transform='translate(-271.12 -6)'>
          <path
            fill='#FB5439'
            d='M409.544 456.602l-.015 15.179a1.52 1.52 0 0 0 1.516 1.52 1.52 1.52 0 0 0 1.518-1.517l.013-15.18c0-.839-.677-1.52-1.515-1.52-.837 0-1.517.678-1.517 1.518M397.91 470.591l-.017 18.218 3.034.003.015-18.218z'
          />
          <mask id='b' fill='#fff'>
            <use xlinkHref='#a' />
          </mask>
          <path
            fill='#FB5439'
            d='M377.141 307.779h3.031V289.56h-3.03zM664.424 422.562h3.032v-97.66h-3.032zM567.015 358.633h3.032v-97.66h-3.032z'
            mask='url(#b)'
          />
          <path
            fill='#027CA0'
            d='M332.187 316.46h301.187v-3.442H332.187z'
            mask='url(#b)'
          />
          <path
            fill='#B3B9D1'
            d='M359.779 370.478h109.19V336.88h-109.19zM258.679 556.69h109.189v-33.6H258.679zM440.837 488.055h33.565v-109.3h-33.565z'
            mask='url(#b)'
          />
          <path
            fill='#027CA0'
            d='M431.634 510.37H732.82v-3.44H431.634zM307.457 544.649h301.187v-3.443H307.457z'
            mask='url(#b)'
          />
          <path
            fill='#389CF2'
            d='M501.625 477.147h16.177V361.775h-16.177z'
            mask='url(#b)'
          />
          <path
            fill='#634061'
            d='M523.98 392.338h52.573v-6.072H523.98zM523.98 376.768h52.573v-6.072H523.98zM393.352 465.102h52.573v-6.073h-52.573zM315.295 465.102h52.573v-6.073h-52.573zM611.941 330.937l-52.574.046-.004-6.073 52.572-.046zM315.295 498.697h52.573v-6.072h-52.573z'
            mask='url(#b)'
          />
          <path
            fill='#027CA0'
            d='M310.922 292.598h18.199v-3.035h-18.2zM310.922 305.147h18.199v-3.035h-18.2zM310.922 330.245h18.199v-3.036h-18.2zM558.355 404.685h18.198v-3.035h-18.198zM558.355 417.234h18.198v-3.036h-18.198zM558.355 429.783h18.198v-3.034h-18.198zM390.928 410.818h3.032v-18.216h-3.032zM406.497 394.12v15.179a1.517 1.517 0 0 1-3.033 0v-15.18a1.517 1.517 0 1 1 3.033 0M419.033 394.12v15.179a1.517 1.517 0 1 1-3.032 0v-15.18a1.517 1.517 0 1 1 3.032 0'
            mask='url(#b)'
          />
          <path
            fill='#FB5439'
            d='M273.81 566.134h3.032v-18.218h-3.032zM489.5 445.268h3.032v-97.662H489.5zM660.028 604.079h3.031v-97.662h-3.03zM526.117 588.72h3.031V491.06h-3.031zM593.898 448.57h97.564v-3.036h-97.564zM348.978 488.026h3.033v-97.66h-3.033zM381.688 350.696v94.625a1.516 1.516 0 0 1-3.031 0v-94.625a1.516 1.516 0 1 1 3.031 0'
            mask='url(#b)'
          />
          <path
            fill='#FFF'
            d='M121.584 364.407H458.05v-7.692H121.584zM288.307 728.25h7.684V391.447h-7.684z'
            mask='url(#b)'
          />
          <path
            fill='#634061'
            d='M315.295 448.305h52.573v-6.073h-52.573zM406.278 349.637h6.066v-52.625h-6.066zM587.179 610.274h6.066v-52.625h-6.066zM570.399 590.929h6.066v-52.625H570.4zM533.548 429.936h8.767v-14.748h-8.767zM501.625 340.489h14.733v-8.776h-14.733zM591.682 410.425h14.734v-8.777h-14.734zM289.276 341.957h8.768V327.21h-8.768z'
            mask='url(#b)'
          />
          <path
            fill='#FB5439'
            d='M539.353 456.693h3.033v-18.217h-3.033z'
            mask='url(#b)'
          />
          <path
            fill='#FFF'
            d='M527.632 473.769H864.1v-7.692H527.632zM523.98 433.407h3.033v-18.218h-3.033z'
            mask='url(#b)'
          />
          <path
            fill='#389CF2'
            d='M408.7 622.302h16.176V506.93h-16.177z'
            mask='url(#b)'
          />
          <path
            fill='#FFF'
            d='M402.859 532.655h336.468v-7.692H402.86z'
            mask='url(#b)'
          />
          <path
            fill='#634061'
            d='M616.08 544.649h8.767V529.9h-8.768z'
            mask='url(#b)'
          />
          <path
            fill='#027CA0'
            d='M558.357 441.918h301.187v-3.442H558.357z'
            mask='url(#b)'
          />
          <path
            fill='#FFF'
            d='M593.741 379.647h18.2v-3.036h-18.2z'
            mask='url(#b)'
          />
          <path
            fill='#634061'
            d='M410.665 433.406c0-5.791-4.69-10.485-10.475-10.485-5.784 0-10.474 4.694-10.474 10.485 0 5.791 4.69 10.485 10.474 10.485s10.475-4.694 10.475-10.485M695.865 334.583c0-5.792-4.69-10.485-10.474-10.485-5.786 0-10.475 4.693-10.475 10.485 0 5.791 4.69 10.485 10.475 10.485 5.784 0 10.474-4.694 10.474-10.485'
            mask='url(#b)'
          />
          <path
            fill='#389CF2'
            d='M189.221 427.875h115.257v-16.193H189.22z'
            mask='url(#b)'
          />
          <path
            fill='#634061'
            d='M463.449 498.697h6.066v-52.625h-6.066zM315.295 481.9h52.573v-6.072h-52.573z'
            mask='url(#b)'
          />
          <path
            stroke='#B3B9D1'
            strokeLinejoin='bevel'
            strokeWidth='.785'
            d='M962.827 1023.311L687.787 24.712l363.611 816.611z'
            mask='url(#b)'
          />
          <path
            stroke='#B3B9D1'
            strokeLinejoin='bevel'
            strokeWidth='.785'
            d='M636.507 323.358L1051.4 841.324 687.788 24.71zM501.319 692.001l550.08 149.322-88.57 181.988z'
            mask='url(#b)'
          />
          <path
            stroke='#B3B9D1'
            strokeLinejoin='bevel'
            strokeWidth='.785'
            d='M603.875 482.014L501.32 692l461.508 331.31zM636.507 323.358l-32.631 158.656L137.708 1.38z'
            mask='url(#b)'
          />
          <path
            stroke='#B3B9D1'
            strokeLinejoin='bevel'
            strokeWidth='.785'
            d='M687.787 24.712L137.707 1.379l498.8 321.98zM501.319 692.001l102.556-209.987L137.708 1.38z'
            mask='url(#b)'
          />
          <path
            stroke='#B3B9D1'
            strokeLinejoin='bevel'
            strokeWidth='.785'
            d='M501.319 692.001L49.134 346.69 137.708 1.38z'
            mask='url(#b)'
          />
          <path
            stroke='#B3B9D1'
            strokeLinejoin='bevel'
            strokeWidth='.785'
            d='M636.507 323.358L49.134 346.69l554.742 135.324zM501.319 692.001L387.108 887.987 49.134 346.69z'
            mask='url(#b)'
          />
          <path
            stroke='#B3B9D1'
            strokeLinejoin='bevel'
            strokeWidth='.785'
            d='M962.827 1023.311l-575.72-135.324 114.21-195.986z'
            mask='url(#b)'
          />
        </g>
      </g>
    </svg>
  )
}

export default SVGAuthGraphism
