/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Components */
import ReactTooltip from 'react-tooltip'

import {
  Card,
  CustomBlock,
  CustomText,
  Divider,
  FlexCentered
} from '@zeta/ui/src'

/* Embeds */
import { TwitterTweetEmbed } from 'react-twitter-embed'
import { FacebookProvider, EmbeddedPost } from 'react-facebook'
// import InstagramEmbed from 'react-instagram-embed'

/* Parts */
import TermsTable from '../TermsTable'

/* Styled */
import { Info, InfoIcon, Message } from './styled'

/* Constants */
// import { clientAccessToken } from 'constants/config'

/* Helpers */
import { formatDate } from '@zeta/helpers'

const PostCard = ({ publication, social }) => {
  const {
    date_post,
    date_article,
    date_collected,
    url,
    content
  } = publication.publication

  return (
    <Card mb="large">
      <Info>
        <InfoIcon icon="calendar-outline" />

        <CustomText
          size="0.875rem"
          weight="700"
          mr={date_post === 'NA' || date_article === 'NA' ? 'xsmall' : null}
        >
          {formatDate(date_post || date_article || date_collected)}
        </CustomText>

        {(date_post === 'NA' ||
          date_article === 'NA' ||
          date_collected === 'NA') && (
          <span data-testid="dateTooltip">
            <FlexCentered
              data-tip="Acesse a postagem para visualizar a data de publicação."
              data-for="dateTooltip"
            >
              <InfoIcon icon="info-outline" />
            </FlexCentered>
            <ReactTooltip id="dateTooltip" />
          </span>
        )}
      </Info>

      <Info>
        <InfoIcon icon="link-2-outline" />
        <a href={url} target="_blank" rel="noopener noreferrer">
          acessar {social === 'sites' ? 'página' : 'publicação'}
        </a>
      </Info>

      <Divider />

      {/* TABELA DE TERMOS */}
      <TermsTable publication={publication} social={social} />

      {/* EMBED */}
      <CustomBlock
        override={`
          ${social !== 'sites' && 'height: 320px; overflow: auto;'}

          ${
            (social === 'linkedin' || social === 'linkedinArticles') &&
            (!content.embed || content.embed === 'NA')
              ? 'display: flex; align-items: center;'
              : ''
          }

          ${social === 'instagram' ? 'display: flex; align-items: center;' : ''}

          iframe.instagram-media {
            max-width: 99% !important;
          }
        `}
        mt={'medium'}
      >
        <>
          {social === 'facebook' && (
            <span data-testid="facebookEmbed">
              <FacebookProvider appId="386490029135896">
                <EmbeddedPost href={url} width="auto" />
              </FacebookProvider>
            </span>
          )}

          {social === 'linkedin' || social === 'linkedinArticles' ? (
            content.embed && content.embed !== 'NA' ? (
              <span data-testid="linkedinEmbed">
                <iframe
                  src={content.embed}
                  height="300"
                  width="100%"
                  frameBorder="0"
                ></iframe>
              </span>
            ) : (
              <Message data-testid="message">
                Não é possível exibir essa publicação. Clique no link acima para
                acessar.
              </Message>
            )
          ) : null}

          {social === 'instagram' && (
            // <span data-testid='instagramEmbed'>
            //   <InstagramEmbed url={url} clientAccessToken={clientAccessToken} />
            // </span>}
            <Message data-testid="message">
              Não é possível exibir essa publicação. Clique no link acima para
              acessar.
            </Message>
          )}

          {social === 'twitter' && (
            <span data-testid="twitterEmbed">
              <TwitterTweetEmbed
                tweetId={url.split('/')[url.split('/').length - 1]}
              />
            </span>
          )}

          {social === 'youtube' && (
            <iframe
              width="560"
              height="315"
              src={url.replace('watch?v=', 'embed/')}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{
                aspectRatio: '16 / 9',
                width: '100%'
              }}
            ></iframe>
          )}

          {social === 'sites' && (
            <>
              <Divider />
              <CustomText size="0.875rem">[...] {content} [...]</CustomText>
            </>
          )}
        </>
      </CustomBlock>
    </Card>
  )
}

PostCard.propTypes = {
  publication: PropTypes.object.isRequired,
  social: PropTypes.string.isRequired
}

export default PostCard
