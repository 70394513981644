import styled from 'styled-components'

const ListNav = styled.div`
  margin: ${({ theme: { spacing } }) => spacing.medium} 0;
  display: flex;
  align-items: center;
  justify-content: center;

  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 ${({ theme: { spacing } }) => spacing.small};
    font-size: 0.75rem;

    svg {
      width: ${({ theme: { spacing } }) => spacing.large};
      height: ${({ theme: { spacing } }) => spacing.large};
      fill: ${({ theme: { colors } }) => colors.brand.primary.medium};
    }
  }

  > div {
    margin: 0 ${({ theme: { spacing } }) => spacing.small};
    font-size: 0.875rem;
  }
`

export default ListNav
