import styled from 'styled-components'
import PropTypes from 'prop-types'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size, theme: { spacing } }) => spacing[size] || size + 'px'};
  height: ${({ size, theme: { spacing } }) => spacing[size] || size + 'px'};
  border-radius: 50%;
  background-color: ${({ bg }) => bg};
  transform: translateY(-2px);

  svg {
    width: ${({ iconSize }) => iconSize};
    height: ${({ iconSize }) => iconSize};
    fill: ${({ fill }) => fill};
  }

  ${defaultProps}
`

IconWrapper.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  // iconSize: PropTypes.string.isRequired,
  bg: PropTypes.string.isRequired,
  // fill: PropTypes.string.isRequired,
  ...defaultPropsPropTypes
}

export default IconWrapper
