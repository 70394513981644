import styled from 'styled-components'

import { defaultProps, defaultPropsPropTypes } from '@zeta/ui/src'

export const Wrapper = styled.button.attrs(() => ({
  type: 'button'
}))`
  background-color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  color: ${({ theme: { colors } }) => colors.brand.tertiary.light};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  border-radius: ${({ theme: { radius } }) => radius.xsmall};
  padding: ${({ theme: { spacing } }) => spacing.medium};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
  }

  ${({ isFlat }) =>
    isFlat &&
    `
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  `}

  &:not(:first-child) {
    margin-top: ${({ theme: { spacing } }) => spacing.small};
  }

  &:hover {
      border-color: ${({ theme: { colors } }) => colors.brand.primary.medium};
      transition: all .3s;
    }
  }

  ${defaultProps}
`

export const Thumbnail = styled.img`
  width: ${({ theme: { spacing } }) => spacing.largest};
  height: ${({ theme: { spacing } }) => spacing.largest};
  border-radius: 50%;

  @media (min-width: 576px) {
    margin-right: ${({ theme: { spacing } }) => spacing.medium};
  }
`

export const RiskItem = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  border: 1px solid ${({ risk, theme: { colors } }) => colors.risk[risk].medium};
  border-radius: ${({ theme: { radius } }) => radius.xsmall};
  padding: ${({ theme: { spacing } }) => spacing.small};

  @media (max-width: 575px) {
    &:not(:last-child) {
      margin-bottom: ${({ theme: { spacing } }) => spacing.medium};
    }
  }

  @media (min-width: 576px) {
    &:not(:first-child) {
      margin-left: ${({ theme: { spacing } }) => spacing.large};
    }
  }
`

export const Totals = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: ${({ risk, theme: { colors } }) => colors.risk[risk].medium};
  color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  font-size: 1.25rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme: { spacing } }) => spacing.small};
`

export const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: ${({ theme: { spacing } }) => spacing.small};

  svg {
    width: 12px;
    height: 12px;
    fill: ${({ risk, theme: { colors } }) => colors.risk[risk].medium};
  }
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
