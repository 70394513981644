/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Styled */
import { Wrapper, Item } from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'

const DropdownBtns = ({ items, secondary, ...rest }) => {
  return (
    <Wrapper {...rest}>
      {items.map((item) => (
        <Item
          as={item.asDiv ? 'div' : 'button'}
          type={item.asDiv ? null : 'button'}
          onClick={item.onClickFn}
          secondary={secondary}
          key={uuidv4()}
        >
          {item.text}
        </Item>
      ))}
    </Wrapper>
  )
}

DropdownBtns.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      onClickFn: PropTypes.func,
      asDiv: PropTypes.bool
    })
  ),
  secondary: PropTypes.bool
}

export default DropdownBtns
