import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  z-index: 2;
  left: 0;
  bottom: 0;
  height: ${({ theme: { spacing } }) => spacing.largest};
  width: 100vw;
  background-color: ${({ theme: { colors } }) => colors.brand.tertiary.dark};

  @media (min-width: 1200px) {
    top: 0;
    height: 100vh;
    width: 88px;
  }
`

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: ${({ theme: { spacing } }) => spacing.largest};
  background-color: ${({ theme: { colors } }) => colors.brand.primary.medium};

  @media (max-width: 1199px) {
    display: none;
  }

  > svg {
    fill: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
    width: ${({ theme: { spacing } }) => spacing.xlarge};
    height: ${({ theme: { spacing } }) => spacing.xlarge};
  }
`

export const NavWrapper = styled.nav`
  display: flex;
  justify-content: center;

  @media (min-width: 1200px) {
    flex-direction: column;
    height: calc(100% - ${({ theme: { spacing } }) => spacing.largest});
    padding-bottom: ${({ theme: { spacing } }) => spacing.largest};
  }
`

export const ItemWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: ${({ theme: { spacing } }) => spacing.largest};
  position: relative;
  flex-direction: column;

  @media (max-width: 1199px) {
    border-bottom: 4px solid ${({ theme: { colors } }) => colors.brand.tertiary.dark};
    padding-top: 4px;
  }

  @media (min-width: 1200px) {
    border-left: 4px solid ${({ theme: { colors } }) => colors.brand.tertiary.dark};
  }

  svg {
    fill: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
    width: ${({ theme: { spacing } }) => spacing.large};
    height: ${({ theme: { spacing } }) => spacing.large};
  }

  p {
    color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};;
    font-size: 0.625rem;
  }

  &:hover {
    border-color: ${({ theme: { colors } }) => colors.brand.primary.medium};
    transition: all .3s;

    svg {
      fill: ${({ theme: { colors } }) => colors.brand.primary.medium};
      transition: all .3s;
    }

    p {
      color: ${({ theme: { colors } }) => colors.brand.primary.medium};
      transition: all .3s;
    }
  }
`

export const MobileLinkWrapper = styled.div`
  @media (min-width: 1200px) {
    display: none;
  }
`

export const LogoutLinkWrapper = styled.div`
  @media (min-width: 1200px) {
    position: absolute;
    left: 0;
    bottom: 0;
  }
`
