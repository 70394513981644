import { call, put, takeLatest, all } from 'redux-saga/effects'

/* Types */
import {
  BOARD_REQUESTED,
  BOARD_SUCCEEDED,
  MAIN_DATA_REQUESTED,
  MAIN_DATA_SUCCEEDED,
  ARCHIVED_REQUESTED,
  ARCHIVED_SUCCEEDED,
  PATCH_TERM_RISK_REQUESTED,
  PATCH_TERM_RISK_SUCCEEDED,
  SHELVE_TERM_REQUESTED,
  SHELVE_TERM_SUCCEEDED,
  CHART_SUCCEEDED,
  CHART_REQUESTED
} from './action-types'

/* Constants */
import { dynamicFilters } from 'constants/filters'

/* Helpers */
import { uniq } from 'ramda'
import { formatObservationsArray, filterSubjectsBySubjectType } from 'helpers'
import {
  getBoard,
  queryObservations,
  getSubjects,
  getDictionaries,
  getTerms,
  queryArchivedObservations,
  getArchivedSubjects,
  editTerm,
  getChart
} from 'api'

/* Workers */
function* getAndSetBoardWorker(action) {
  try {
    const { boardType, params } = action
    const { boardId } = params

    const payload = yield call(getBoard, { boardId })

    yield put({ type: BOARD_SUCCEEDED, boardType, payload: payload.data })
  } catch (error) {
    console.log(error)
  }
}

function* getAndSetMainDataWorker() {
  try {
    const [obsPayload, subjectsPayload, dictionariesPayload] = yield all([
      call(queryObservations, { query: {} }),
      call(getSubjects),
      call(getDictionaries)
    ])

    const dictionariesTermsPayload = yield all(
      dictionariesPayload.data.map((dictionary) =>
        call(getTerms, {
          dictionaryId: dictionary.id,
          params: { perPage: 99999 }
        })
      )
    )

    const observations = {
      people: formatObservationsArray(obsPayload.data, 'private_person'),
      company: formatObservationsArray(obsPayload.data, 'legal_entity')
    }

    yield put({
      type: MAIN_DATA_SUCCEEDED,
      observations: {
        people: observations.people,
        company: observations.company
      },
      subjects: {
        people: filterSubjectsBySubjectType(
          subjectsPayload.data.result,
          'private_person'
        ),
        company: filterSubjectsBySubjectType(
          subjectsPayload.data.result,
          'legal_entity'
        )
      },
      terms: dictionariesTermsPayload
        .reduce((acc, res) => acc.concat(res.data.result), [])
        .map((term) => ({ term: term.term, id: term.id })),
      dynamicFiltersItems: dynamicFilters
        ? dynamicFilters.reduce((acc, filterKey) => {
            return {
              ...acc,
              [filterKey]: uniq(
                obsPayload.data.map((observation) => observation[filterKey])
              )
                .filter((value) => value)
                .map((value) => ({ visibleValue: value, dbValue: value }))
            }
          }, {})
        : null
    })
  } catch (error) {
    console.log(error)
  }
}

function* getAndSetArchivedWorker() {
  try {
    const [obsPayload, subjectsPayload] = yield all([
      call(queryArchivedObservations, { query: {} }),
      call(getArchivedSubjects)
    ])

    const observations = {
      people: formatObservationsArray(obsPayload.data, 'private_person'),
      company: formatObservationsArray(obsPayload.data, 'legal_entity')
    }

    const subjects = {
      people: filterSubjectsBySubjectType(
        subjectsPayload.data.result,
        'private_person'
      ),
      company: filterSubjectsBySubjectType(
        subjectsPayload.data.result,
        'legal_entity'
      )
    }

    yield put({
      type: ARCHIVED_SUCCEEDED,
      payload: {
        observations: {
          people: observations.people,
          company: observations.company
        },
        subjects: {
          people: subjects.people,
          company: subjects.company
        }
      }
    })
  } catch (error) {
    console.log(error)
  }
}

function* editTermRiskWorker(action) {
  try {
    const { termId, risk } = action.reqParams

    const payload = yield call(editTerm, { termId, risk })

    yield put({ type: PATCH_TERM_RISK_SUCCEEDED, payload })
    yield put({ type: MAIN_DATA_REQUESTED })

    action.callback()
  } catch (error) {
    console.log(error)
  }
}

function* shelveTermWorker(action) {
  try {
    const { termId, archived } = action.reqParams

    const payload = yield call(editTerm, { termId, archived })

    yield put({ type: SHELVE_TERM_SUCCEEDED, payload })
    yield put({ type: MAIN_DATA_REQUESTED })

    action.callback()
  } catch (error) {
    console.log(error)
  }
}

function* getAndSetChartWorker() {
  try {
    const payload = yield call(getChart)

    yield put({ type: CHART_SUCCEEDED, payload: payload.data })
  } catch (error) {
    console.log(error)
  }
}

/* Watchers */
export function* getAndSetBoardWatcher() {
  yield takeLatest(BOARD_REQUESTED, getAndSetBoardWorker)
}

export function* getAndSetMainDataWatcher() {
  yield takeLatest(MAIN_DATA_REQUESTED, getAndSetMainDataWorker)
}

export function* getAndSetArchivedWatcher() {
  yield takeLatest(ARCHIVED_REQUESTED, getAndSetArchivedWorker)
}

export function* editTermRiskWatcher() {
  yield takeLatest(PATCH_TERM_RISK_REQUESTED, editTermRiskWorker)
}

export function* shelveTermWatcher() {
  yield takeLatest(SHELVE_TERM_REQUESTED, shelveTermWorker)
}

export function* getAndSetChartWatcher() {
  yield takeLatest(CHART_REQUESTED, getAndSetChartWorker)
}
