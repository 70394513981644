/* React */
import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* Components */
import Icon from '../Icon'
import theme from '../../theme/theme'

/* Styled */
import { Wrapper, ExpandButton, Title } from './styled'

const CollapseAlt = ({ startExpanded, title, children, ...rest }) => {
  const [isExpanded, setIsExpanded] = useState(startExpanded)

  return (
    <Wrapper {...rest}>
      <ExpandButton type='button' onClick={() => setIsExpanded(!isExpanded)}>
        <Title>{title}</Title>

        <Icon
          icon={isExpanded ? 'arrowhead-up-outline' : 'arrowhead-down-outline'}
          size='medium'
          fill={theme.colors.brand.primary.medium}
        />
      </ExpandButton>

      {isExpanded && children}
    </Wrapper>
  )
}

CollapseAlt.propTypes = {
  startExpanded: PropTypes.bool,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
}

export default CollapseAlt
