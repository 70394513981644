export default [
  ['2020-06-01', '2020-06-30'],
  ['2020-07-01', '2020-07-31'],
  ['2020-08-01', '2020-08-31'],
  ['2020-09-01', '2020-09-30'],
  ['2020-10-01', '2020-10-31'],
  ['2020-11-01', '2020-11-30'],
  ['2020-12-01', '2020-12-31'],
  ['2021-01-01', '2021-01-31'],
  ['2021-02-01', '2021-02-28'],
  ['2021-03-01', '2021-03-31'],
  ['2021-04-01', '2021-04-30'],
  ['2021-05-01', '2021-05-31'],
  ['2021-06-01', '2021-06-30'],
  ['2021-07-01', '2021-07-31'],
  ['2021-08-01', '2021-08-31'],
  ['2021-09-01', '2021-09-30'],
  ['2021-10-01', '2021-10-31'],
  ['2021-11-01', '2021-11-30'],
  ['2021-12-01', '2021-12-31'],
  ['2022-01-01', '2022-01-31'],
  ['2022-02-01', '2022-02-28'],
  ['2022-03-01', '2022-03-31'],
  ['2022-04-01', '2022-04-30'],
  ['2022-05-01', '2022-05-31'],
  ['2022-06-01', '2022-06-30'],
  ['2022-07-01', '2022-07-31'],
  ['2022-08-01', '2022-08-31'],
  ['2022-09-01', '2022-09-30'],
  ['2022-10-01', '2022-10-31']
]
