import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

const DividerVertical = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${({ theme: { colors } }) => colors.surface.primary.dark};
  margin: 0 ${({ theme: { spacing } }) => spacing.large};

  ${defaultProps}
`

DividerVertical.propTypes = {
  ...defaultPropsPropTypes
}

export default DividerVertical
