/* React */
import React from 'react'
import { useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'

/* Components */
import { Container, Row, Col } from 'styled-bootstrap-grid'

import {
  Tabs,
  TabsAlt,
  SectionHeadline,
  FlexCentered,
  LoadingSpinner
} from '@zeta/ui/src'

import ContentWrapper from 'components/ContentWrapper'
import Footer from 'components/Footer'

/* Parts */
import ActiveUnitHandler from 'components/ActiveUnitHandler'
import RiskCards from './RiskCards'
import SubjectsRanking from './SubjectsRanking'

/* Constants */
import { subjectTypes } from 'constants/config'

/* Helpers */
import { getPathPrefix } from '@zeta/helpers'
import { getDataSetTabsItems } from 'helpers'

const Dashboard = ({
  /* router */
  history,
  computedMatch,
  /* props */
  subjectType
}) => {
  const isLoadingArchived = useSelector((state) => state.data.isLoadingArchived)

  const routerProps = {
    history,
    computedMatch
  }

  return (
    <ContentWrapper withSidebar>
      <Container fluid>
        <Row>
          <Col>
            <Tabs
              items={[
                {
                  text: 'Análise de menções',
                  onClickFn: () =>
                    history.push(
                      `${getPathPrefix(computedMatch)}/${subjectType}/dashboard`
                    ),
                  isActive: true
                },
                {
                  text: 'Perfis',
                  onClickFn: () =>
                    history.push(
                      `${getPathPrefix(
                        computedMatch
                      )}/${subjectType}/individual`
                    )
                }
              ]}
            />

            <TabsAlt
              items={getDataSetTabsItems({
                subjectTypes,
                history,
                computedMatch,
                screenPath: 'dashboard'
              })}
              mb="xlarge"
            />

            <ActiveUnitHandler subjectType={subjectType} />
          </Col>

          {isLoadingArchived && (
            <Col>
              <FlexCentered>
                <LoadingSpinner size="40" my="large" />
              </FlexCentered>
            </Col>
          )}

          {!isLoadingArchived && (
            <>
              <Col>
                <SectionHeadline>Redes sociais</SectionHeadline>
              </Col>

              <RiskCards subjectType={subjectType} source="social" />

              {subjectType === 'company' && (
                <>
                  <Col>
                    <SectionHeadline>Sites</SectionHeadline>
                  </Col>

                  <RiskCards subjectType={subjectType} source="web" />
                </>
              )}

              <Col>
                <SubjectsRanking subjectType={subjectType} {...routerProps} />
              </Col>
            </>
          )}
        </Row>
      </Container>

      {/* Footer */}
      <Footer />
    </ContentWrapper>
  )
}

export default withRouter(Dashboard)
