/* React */
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

/* Components */
import {
  Button,
  CustomBlock,
  LoadingSpinner,
  FlexJustifyEnd
} from '@zeta/ui/src'

import { Label, Input } from '../../styled'

/* Action Creators */
import { updateUserInfo } from '../../../store/reducers/user/action-creators'

/* Helpers */
const getChangedFields = (initialFields, fields) =>
  Object.keys(initialFields).filter(
    (field) => fields[field] !== initialFields[field]
  )

export const Profile = () => {
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)

  const profileInitialFields = {
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email
  }

  const [profileFields, setProfileFields] = useState(profileInitialFields)

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        dispatch(
          updateUserInfo({
            firstName: profileFields.firstName.trim(),
            lastName: profileFields.lastName.trim()
          })
        )
      }}
    >
      <CustomBlock my="medium">
        <Label for="firstName">Nome</Label>
        <Input
          id="firstName"
          type="text"
          value={profileFields.firstName}
          onChange={(e) =>
            setProfileFields({ ...profileFields, firstName: e.target.value })
          }
        />
      </CustomBlock>

      <CustomBlock mb="medium">
        <Label for="lastName">Sobrenome</Label>
        <Input
          id="lastName"
          type="text"
          value={profileFields.lastName}
          onChange={(e) =>
            setProfileFields({ ...profileFields, lastName: e.target.value })
          }
        />
      </CustomBlock>

      <CustomBlock mb="xlarge">
        <Label for="email">E-mail</Label>
        <Input id="email" type="text" value={profileFields.email} disabled />
      </CustomBlock>

      <FlexJustifyEnd>
        <Button
          type="submit"
          isDisabled={
            getChangedFields(profileInitialFields, profileFields).length ===
              0 || user.isUpdatingUserInfo
          }
        >
          Salvar alterações
        </Button>

        {user.isUpdatingUserInfo && <LoadingSpinner size="xlarge" ml="small" />}
      </FlexJustifyEnd>
    </form>
  )
}

export default Profile
