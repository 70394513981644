/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Styled */
import { Wrapper, IconWrapper } from './styled'

/* Components */
import Icon from '../Icon'
import CustomBlock from '../helpers/CustomBlock'
import CustomText from '../helpers/CustomText'
import { FlexCentered } from '../helpers/Flex'

/* Constants */
import theme from '../../theme/theme'

const HeadlineCard = ({ headline, text, icon, children, ...props }) => {
  return (
    <Wrapper {...props}>
      <FlexCentered
        override={`
          @media (max-width: 575px) {
            flex-direction: column;
          }
        `}
      >
        <IconWrapper>
          <Icon
            icon={icon}
            size='medium'
            fill={theme.colors.surface.secondary.lightness}
          />
        </IconWrapper>

        <CustomBlock
          override={`
            @media (min-width: 576px) {
              width: calc(100% - 56px);
            }

            @media (max-width: 575px) {
              text-align: center;
              margin-top: ${theme.spacing.medium};
            }
          `}
        >
          <CustomText size='1.25rem' weight='700'>
            {headline}
          </CustomText>

          {text && (
            <CustomText size='0.875rem' mt='xsmall'>
              {text}
            </CustomText>
          )}
        </CustomBlock>
      </FlexCentered>

      <FlexCentered
        override={`
          @media (min-width: 576px) {
            > * {
              margin-left: ${theme.spacing.medium};
            }
          }

          @media (max-width: 575px) {
            flex-direction: column;

            > * {
              margin-top: ${theme.spacing.medium};
            }
          }
        `}
      >
        {children}
      </FlexCentered>
    </Wrapper>
  )
}

HeadlineCard.propTypes = {
  headline: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.string.isRequired
}

export default HeadlineCard
