/* React */
import React from 'react'

/* Components */
import { Row, Col } from 'styled-bootstrap-grid'

import {
  CustomBlock
} from '@zeta/ui/src'

import Selection from 'components/filters/Selection'

/* Constants */
import { selectionOptions, visibleLabels } from 'constants/filters'

const ActingAreaFilter = ({
  ...props
}) => {
  return (
    <CustomBlock mt='xlarge'>
      <Row>
        <Col lg={4}>
          <Selection
            display='group'
            selectionType='checkbox'
            label={visibleLabels['acting_area']}
            name='acting_area'
            items={selectionOptions['acting_area']}
            {...props}
            mb='xlarge'
          />
        </Col>
      </Row>
    </CustomBlock>
  )
}

export default ActingAreaFilter
