/* React */
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

/* Components */
import { Formik, Form, Field } from 'formik'

import {
  Modal,
  ModalContent,
  ModalActions,
  FieldGroup,
  Button,
  Icon,
  DropdownSelect
} from '@zeta/ui/src'

/* Helpers */
import { updateSubject } from 'api'

/* Action Creators */
import { updateSubject as updateSubjectActionCreator } from 'store/reducers/data/action-creators'

const OuvidoriaModal = ({
  individual,
  setIndividual,
  subjectType,
  activeUnit,
  setIsOuvidoriaModalOpen
}) => {
  const [isClassifying, setIsClassifying] = useState(false)

  const dispatch = useDispatch()

  return (
    <Modal
      title='Classificação de "Ouvidoria"'
      isVisible={true}
      closeFn={() => setIsOuvidoriaModalOpen(false)}
    >
      <Formik
        initialValues={{
          ouvidoria_status: individual.subject.ouvidoria_status,
          ouvidoria_notes: individual.subject.ouvidoria_notes
        }}
        onSubmit={(values) => {
          setIsClassifying(true)

          return updateSubject({
            subjectId: individual.subject.id,
            values: {
              ouvidoria_status: values.ouvidoria_status,
              ouvidoria_notes: values.ouvidoria_notes
            }
          }).then(() => {
            setIsOuvidoriaModalOpen(false)

            setIndividual({
              ...individual,
              subject: {
                ...individual.subject,
                ouvidoria_status: values.ouvidoria_status,
                ouvidoria_notes: values.ouvidoria_notes
              }
            })

            dispatch(
              updateSubjectActionCreator({
                subjectId: individual.subject.id,
                subjectType,
                activeUnit,
                values: {
                  ouvidoria_status: values.ouvidoria_status,
                  ouvidoria_notes: values.ouvidoria_notes
                }
              })
            )
          })
        }}
      >
        {(props) => {
          const { values, setFieldValue, handleSubmit } = props

          return (
            <Form onSubmit={handleSubmit}>
              <ModalContent>
                {/* Procedência */}
                <DropdownSelect
                  items={[
                    {
                      text: 'Não classificado',
                      fn: () => setFieldValue('ouvidoria_status', 0)
                    },
                    {
                      text: 'Inconclusivo',
                      fn: () => setFieldValue('ouvidoria_status', 7)
                    },
                    {
                      text: 'Improcedente',
                      fn: () => setFieldValue('ouvidoria_status', 1)
                    },
                    {
                      text: 'Investigação',
                      fn: () => setFieldValue('ouvidoria_status', 6)
                    },
                    {
                      text: 'Procedente',
                      fn: () => setFieldValue('ouvidoria_status', 2)
                    }
                  ]}
                  activeIndex={(() => {
                    if (values.ouvidoria_status === 0) return 0
                    if (values.ouvidoria_status === 7) return 1
                    if (values.ouvidoria_status === 1) return 2
                    if (values.ouvidoria_status === 6) return 3
                    if (
                      values.ouvidoria_status >= 2 &&
                      values.ouvidoria_status <= 5
                    )
                      return 4
                  })()}
                  label="Classificação:"
                  displayText="Não classificado"
                  mb="large"
                />

                {/* Ação */}
                {values.ouvidoria_status >= 2 && values.ouvidoria_status <= 5 && (
                  <Field
                    inputElement="radio"
                    type="radio"
                    name="ouvidoria_status"
                    label="Ação:"
                    options={[
                      { label: 'Nenhuma ação', value: 2 },
                      { label: 'Advertência', value: 3 },
                      { label: 'Multa', value: 4 },
                      { label: 'Orientação', value: 5 }
                    ]}
                    component={FieldGroup}
                  />
                )}

                {/* Observações */}
                <Field
                  inputElement="textarea"
                  name="ouvidoria_notes"
                  label="Observação:"
                  component={FieldGroup}
                />
              </ModalContent>

              <ModalActions>
                {/* Cancelar */}
                <Button
                  red
                  onClick={() => setIsOuvidoriaModalOpen(false)}
                  isDisabled={isClassifying}
                >
                  <Icon icon="close-outline" />
                  <span>Cancelar</span>
                </Button>

                {/* Confirmar */}
                <Button type="submit" isDisabled={isClassifying}>
                  <Icon icon="save-outline" />
                  <span>Confirmar</span>
                </Button>
              </ModalActions>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default OuvidoriaModal
