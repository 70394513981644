/* React */
import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

/* Components */
import { UnitSwitcher } from '@zeta/ui/src'

/* Action Creators */
import { setActiveUnit, getAndSetArchived } from 'store/reducers/data/action-creators'

const ActiveUnitHandler = () => {
  const dispatch = useDispatch()

  const activeUnit = useSelector(state => state.data.activeUnit)
  const archivedAlreadyRequested = useSelector(state => state.data.archivedAlreadyRequested)

  useEffect(() => {
    if (activeUnit === 'archived' && !archivedAlreadyRequested) {
      dispatch(getAndSetArchived())
    }
  }, [activeUnit])

  return (
    <UnitSwitcher
      items={[
        {
          key: 'active',
          text: 'Perfis ativos',
          onClickFn: () => dispatch(setActiveUnit('active'))
        },
        {
          key: 'archived',
          text: 'Perfis arquivados',
          onClickFn: () => dispatch(setActiveUnit('archived'))
        }
      ]}
      activeUnit={activeUnit}
      mb='xlarge'
    />
  )
}

export default ActiveUnitHandler
