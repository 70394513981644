/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Styled */
import { Wrapper } from './styled'

const ButtonAlt = ({ isSquare, children, ...rest }) => {
  return (
    <Wrapper isSquare={isSquare} {...rest}>
      {children}
    </Wrapper>
  )
}

ButtonAlt.propTypes = {
  isSquare: PropTypes.bool
}

export default ButtonAlt
