import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.div`
  display: flex;
  background-color: ${({ theme: { colors } }) =>
    colors.surface.secondary.lightness};
  font-size: 0.875rem;

  @media (max-width: 767px) {
    flex-direction: column;
  }

  ${defaultProps}
`

export const Text = styled.div`
  font-weight: 700;
  line-height: 1;
  color: ${({ isActive, theme: { colors } }) =>
    isActive
      ? `${colors.brand.tertiary.light}`
      : `${colors.brand.tertiary.lightness}`};
`

export const Item = styled.button`
  text-align: left;
  outline: 0;
  padding: ${({ theme: { spacing } }) => `${spacing.medium} ${spacing.small}`};

  ${({ isActive, theme: { colors } }) =>
    isActive &&
    `
    background-color: ${colors.brand.primary.lightness};
  `}
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
