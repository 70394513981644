/* Paths das rotas */
export const paths = {
  initial: process.env.REACT_APP_INITIAL_PATH
}

/* API */
export const apiDomain = process.env.REACT_APP_API_DOMAIN

/* Client Access Token (Instagram) */
export const clientAccessToken = process.env.REACT_APP_CLIENT_ACCESS_TOKEN

/* Subject Types */
export const subjectTypes = {
  people: process.env.REACT_APP_SUBJECT_TYPE_PEOPLE,
  company: process.env.REACT_APP_SUBJECT_TYPE_COMPANY
}

/* Features */
export const features = {
  // dictionary: process.env.REACT_APP_FEATURE_DICTIONARY,
  occupationArea: process.env.REACT_APP_FEATURE_OCCUPATION_AREA,
  actingArea: process.env.REACT_APP_FEATURE_ACTING_AREA,
  ouvidoria: process.env.REACT_APP_FEATURE_OUVIDORIA
}
