import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../../defaultProps'

export const Wrapper = styled.div`
  font-size: ${({ size }) => size};
  font-weight: ${({ weight }) => weight};
  ${({ color }) => color && `color: ${color};`}
  ${({ align }) => align && `text-align: ${align};`}

  ${defaultProps}
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
