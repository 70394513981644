/* React */
import React from 'react'
// import PropTypes from 'prop-types'

/* Components */
import Icon from '../Icon'

/* Styled */
import { Wrapper, Text } from './styled'

/* Constants */
import theme from '../../theme/theme'

const Alert = ({ children, ...rest }) => (
  <Wrapper {...rest}>
    <Icon
      icon='alert-triangle-outline'
      size='large'
      fill={theme.colors.feedback.red.darkness}
      mr='medium'
    />
    <Text>{children}</Text>
  </Wrapper>
)

Alert.propTypes = {}

export default Alert
