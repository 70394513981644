import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: ${({ theme: { spacing } }) => `0 -${spacing.small} ${spacing.medium} -${spacing.small}`};

  > * {
    margin: ${({ theme: { spacing } }) => `0 ${spacing.small} ${spacing.small} ${spacing.small}`};
  }
`
