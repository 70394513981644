import { includes, assocPath, path, equals, uniq } from 'ramda'
import { getPathPrefix } from '@zeta/helpers'

import { observationsFilterProps, subjectsFilterProps } from 'constants/filters'

const complianceHelpers = {
  /**
   *
   * Retorna o tipo do dataset (people ou company) baseado na url
   * @param {String} url url atual
   * @return {String} 'people' / 'company'
   *
   */
  getSubjectType: (url) => {
    if (/\/people/.test(url)) return 'people'
    if (/\/company/.test(url)) return 'company'
  },

  /**
   *
   * Verifica se o valor informado possui o regex de url, e em caso positivo, retorna a url
   * @param {Object} url
   * @return {String | null}
   *
   */
  getPicture: (url) => (url && /^http/.test(url) ? url : null),

  /**
   *
   * Retorna os itens das tabs de navegação entre subject types (pessoas / escritórios)
   * @param {Object} subjectTypes {people, company}
   * @param {Object} history
   * @param {String} screenPath 'dashboard' / 'individual' / ...
   * @return {Array[Object]}
   *
   */
  getDataSetTabsItems: ({
    subjectTypes,
    history,
    computedMatch,
    screenPath
  }) => {
    if (subjectTypes.people && subjectTypes.company) {
      return [
        {
          text: 'Pessoas',
          onClickFn: () =>
            history.push(
              `${getPathPrefix(computedMatch)}/people/${screenPath}`
            ),
          isActive: includes('people', computedMatch.path.split('/'))
        },
        {
          text: 'Escritórios',
          onClickFn: () =>
            history.push(
              `${getPathPrefix(computedMatch)}/company/${screenPath}`
            ),
          isActive: includes('company', computedMatch.path.split('/'))
        }
      ]
    }

    if (subjectTypes.people) {
      return [
        {
          text: 'Pessoas',
          onClickFn: () =>
            history.push(
              `${getPathPrefix(computedMatch)}/people/${screenPath}`
            ),
          isActive: includes('people', computedMatch.path.split('/'))
        }
      ]
    }

    if (subjectTypes.company) {
      return [
        {
          text: 'Escritórios',
          onClickFn: () =>
            history.push(
              `${getPathPrefix(computedMatch)}/company/${screenPath}`
            ),
          isActive: includes('company', computedMatch.path.split('/'))
        }
      ]
    }
  },

  /**
   *
   * Formata o array de observations que é salvo na store (filtra pelo subject type e exclui informações desnecessárias do objeto)
   * @param {Array[Object]} observations
   * @param {String} subjectType 'private_person' / 'legal_entity'
   * @return {Array[Object]}
   *
   */
  formatObservationsArray: (observations, subjectType) =>
    observations
      .filter((observation) => observation.subject_type === subjectType)
      .map((observation) => ({
        id: observation.id,
        status: observation.status,
        word_id: observation.word_id,
        observable_type: observation.observable_type,
        subject_id: observation.subject_id,
        subject_type: observation.subject_type,
        social_type: observation.social_type,
        risk: observation.risk,
        date_post: observation.date_post && observation.date_post.slice(0, 10),
        created_at:
          observation.created_at && observation.created_at.slice(0, 10),
        archived: observation.archived,
        occupation_area: observation.occupation_area,
        acting_area: observation.acting_area
      })),

  /**
   *
   * Filtra os subjects pelo subjectType
   * @param {Array[Object]} subjects
   * @param {String} subjectType 'private_person' / 'legal_entity'
   * @return {Array[Object]}
   *
   */
  filterSubjectsBySubjectType: (subjects, subjectType) =>
    subjects.filter((subject) => subject.subject_type === subjectType),

  /**
   *
   * Formata o array de subjects (calcula os hits totais, por risco e por rede social, e ordena por quem tem mais risco)
   * @param {Array[Object]} observations
   * @param {Array[Object]} subjects
   * @return {Array[Object]}
   *
   */
  formatSubjectsList: (observations, subjects) =>
    subjects
      .reduce((acc, subject) => {
        let newSubject

        const subjectObservations = observations.filter(
          (observation) => observation.subject_id === subject.id
        )

        if (subjectObservations.length > 0) {
          newSubject = subjectObservations.reduce((acc, observation) => {
            const socialCount = assocPath(
              [observation.risk, observation.social_type],
              (path([observation.risk, observation.social_type], acc) || 0) + 1,
              acc
            )
            const totalCount = assocPath(
              [observation.risk, 'total'],
              (path([observation.risk, 'total'], acc) || 0) + 1,
              socialCount
            )

            return { ...totalCount, total: (totalCount.total || 0) + 1 }
          }, subject)
        } else {
          newSubject = { ...subject, total: 0 }
        }

        return acc.concat(newSubject)
      }, [])
      .sort((a, b) => b.total - a.total),

  /**
   *
   * Filtra as observations baseado no objeto filters
   * @param {Array[Object]} observations
   * @param {Object} filters
   * @return {Array[Object]}
   *
   */
  filterObservations: (observations, filters, subjects) => {
    if (!filters || equals(filters, {})) return observations

    const subjFilteredProps = Object.keys(filters).filter((prop) =>
      subjectsFilterProps.some(
        (subjectFilterProp) => subjectFilterProp === prop
      )
    )

    const filteredSubjectsIds = subjects
      .filter((subject) =>
        subjFilteredProps
          .map((prop) =>
            Array.isArray(filters[prop])
              ? filters[prop].some(
                  (filterValue) => filterValue === subject[prop]
                )
              : (filters[prop].start
                  ? subject[prop] >= filters[prop].start
                  : true) &&
                (filters[prop].end ? subject[prop] <= filters[prop].end : true)
          )
          .every((exp) => exp)
      )
      .map((subject) => subject.id)

    const obsFilteredProps = Object.keys(filters).filter((prop) =>
      observationsFilterProps.some(
        (observationFilterProp) => observationFilterProp === prop
      )
    )

    return observations
      .filter((observation) =>
        filteredSubjectsIds.some((id) => id === observation.subject_id)
      )
      .filter((observation) =>
        obsFilteredProps
          .map((prop) =>
            Array.isArray(filters[prop])
              ? filters[prop].some(
                  (filterValue) => filterValue === observation[prop]
                )
              : (filters[prop].start
                  ? observation[prop] >= filters[prop].start
                  : true) &&
                (filters[prop].end
                  ? observation[prop] <= filters[prop].end
                  : true)
          )
          .every((exp) => exp)
      )
  },

  // TESTAR
  /**
   *
   * Retorna a quantidade de subjects (filtrados ou não)
   * @param {Object} filters
   * @param {Object} observations observations de um subject type específico
   * @param {Object} subjects subjects de um type específico
   * @return {Number}
   *
   */
  getSubjectsLength: (filters, observations, subjects) =>
    filters && !equals(filters, {})
      ? uniq(
          filterObservations(observations, filters, subjects).map(
            (observation) => observation.subject_id
          )
        ).length
      : subjects.length,

  /**
   *
   * Pagina um array
   * @param {Array} items
   * @param {Number} page
   * @param {Number} perPage
   * @return {Array}
   *
   */
  paginateItems: (items, page, perPage) =>
    items.slice(perPage * page - perPage, perPage * page)
}

export const {
  getSubjectType,
  getPicture,
  getDataSetTabsItems,
  formatObservationsArray,
  filterSubjectsBySubjectType,
  formatSubjectsList,
  filterObservations,
  getSubjectsLength,
  paginateItems
} = complianceHelpers

export default complianceHelpers
