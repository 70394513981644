/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Styled */
import { Wrapper } from './styled'

const TextWithIcon = ({ children, ...rest }) => {
  return <Wrapper {...rest}>{children}</Wrapper>
}

TextWithIcon.propTypes = {}

export default TextWithIcon
