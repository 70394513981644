import { update, path } from 'ramda'

export const getUpdatedObservations = (state, action) => {
  const subjectType =
    action.payload.data.subject_type === 'private_person' ? 'people' : 'company'
  const unit = action.payload.data.archived ? 'archived' : 'active'

  if (path(['observations', unit, subjectType], state)) {
    const i = state.observations[unit][subjectType].findIndex(
      (observation) => observation.id === action.observationId
    )
    const updatedObservations = update(
      i,
      action.payload.data,
      state.observations[unit][subjectType]
    )

    return {
      observations: {
        ...state.observations,
        [unit]: {
          ...state.observations[unit],
          [subjectType]: updatedObservations
        }
      }
    }
  }

  return {}
}

export const removeSubject = (subjects, subjectId) =>
  subjects.filter((subject) => subject.id !== subjectId)

export const includeSubject = (
  subjectsWhereWillBeIncluded = [],
  subjectsWhereWillBeRemoved,
  subjectId
) =>
  subjectsWhereWillBeIncluded
    .concat(
      subjectsWhereWillBeRemoved.find((subject) => subject.id === subjectId)
    )
    .sort((a, b) => b.total - a.total)

export const removeSubjectObservations = (observations, subjectId) =>
  observations.filter((observation) => observation.subject_id !== subjectId)

export const includeSubjectObservations = (
  observationsWhereWillBeIncluded = [],
  observationsWhereWillBeRemoved,
  subjectId
) =>
  observationsWhereWillBeIncluded.concat(
    observationsWhereWillBeRemoved.filter(
      (observation) => observation.subject_id === subjectId
    )
  )

// const removeRelates = (subjects, relatesIds) =>
//   subjects.filter(subject => relatesIds.every(id => id !== subject.id))

// const includeRelates = (subjectsWhereWillBeIncluded = [], subjectsWhereWillBeRemoved, relatesIds) =>
//   subjectsWhereWillBeIncluded.concat(subjectsWhereWillBeRemoved.filter(subject => relatesIds.some(id => id === subject.id))).sort((a, b) => b.total - a.total)

// const removeRelatesObservations = (observations, relatesIds) =>
//   observations.filter(observation => relatesIds.every(id => id !== observation.subject_id))

// const includeRelatesObservations = (observationsWhereWillBeIncluded = [], observationsWhereWillBeRemoved, relatesIds) =>
//   observationsWhereWillBeIncluded.concat(observationsWhereWillBeRemoved.filter(observation => relatesIds.some(id => id === observation.subject_id)))
