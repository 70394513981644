import styled from 'styled-components'

import { defaultProps, defaultPropsPropTypes } from '@zeta/ui/src'

export const Wrapper = styled.button`
  background-color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  padding: ${({ theme: { spacing } }) => spacing.medium};
  border-radius: ${({ theme: { radius } }) => radius.small};
  text-align: left;
  width: 100%;

  &:hover {
    border-color: ${({ theme: { colors } }) => colors.brand.primary.medium};
    transition: border-color 0.3s;
  }

  ${defaultProps}
`

export const Info = styled.div`
  display: flex;
  margin-bottom: ${({ theme: { spacing } }) => spacing.large};
`

export const IconWrapper = styled.div`
  width: ${({ theme: { spacing } }) => spacing.large};
  height: ${({ theme: { spacing } }) => spacing.large};
  border-radius: 50%;

  ${({ blue, theme: { colors } }) =>
    blue &&
    `
    background-color: ${colors.brand.primary.lightness};
    `}

  ${({ purple, theme: { colors } }) =>
    purple &&
    `
    background-color: ${colors.brand.secondary.lightness};
    `}

  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${({ theme: { spacing } }) => spacing.small};

  svg {
    width: ${({ theme: { spacing } }) => spacing.medium};
    height: ${({ theme: { spacing } }) => spacing.medium};

    ${({ blue, theme: { colors } }) =>
    blue &&
    `
      fill: ${colors.brand.primary.medium};
    `}

    ${({ purple, theme: { colors } }) =>
    purple &&
    `
      fill: ${colors.brand.secondary.medium};
    `}
  }
`

export const InfoTextWrapper = styled.div`
  width: calc(100% - ${({ theme: { spacing } }) => spacing.xlarge});
`

export const InfoLabel = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
`

export const InfoText = styled.div`
  font-size: 0.875rem;
  display: flex;
  align-items: center;

  /* > svg {
    width: ${({ theme: { spacing } }) => spacing.medium};
    height: ${({ theme: { spacing } }) => spacing.medium};
    fill: ${({ theme: { colors } }) => colors.brand.primary.medium};
    margin: 0 ${({ theme: { spacing } }) => spacing.medium};
  } */
`

export const DatasetGroup = styled.div`
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  border-radius: ${({ theme: { radius } }) => radius.small};
  padding: ${({ theme: { spacing } }) => spacing.medium};
  position: relative;

  &:not(:last-child) {
    margin-bottom: ${({ theme: { spacing } }) => spacing.large};
  }
`

export const DatasetGroupLabel = styled.div`
  font-size: 0.875rem;
  font-weight: 700;
  position: absolute;
  top: -7px;
  left: 12px;
  padding: 0 ${({ theme: { spacing } }) => spacing.xsmall};
  background-color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
`

export const DatasetGroupInfo = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
`

export const DatasetGroupInfoTextWrapper = styled.div`
  width: calc(100% - ${({ theme: { spacing } }) => spacing.xlarge});
`

export const DatasetGroupInfoTotal = styled.span`
  font-weight: 700;

  ${({ blue, theme: { colors } }) =>
    blue &&
    `
      color: ${colors.brand.primary.medium};
    `}

  ${({ purple, theme: { colors } }) =>
    purple &&
    `
      color: ${colors.brand.secondary.medium};
    `}
`

export const DatasetGroupInfoText = styled.span`
  font-size: 0.875rem;
`

export const Divider = styled.div`
  height: 1px;
  border: 1px solid ${({ theme: { colors } }) => colors.surface.primary.dark};
  margin-bottom: 12px;
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
