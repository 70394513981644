/* React */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

/* Components */
import {
  Icon,
  Modal,
  ModalContent,
  ModalActions,
  CustomText,
  Button,
  LoadingSpinner,
  FlexAlignCenter,
  theme
} from '@zeta/ui/src'

import RiskBadge from 'components/RiskBadge'

/* Action Creators */
import { editTermRisk } from 'store/reducers/data/action-creators'

const TermEditionModal = ({
  isTermEditionModalVisible,
  setIsTermEditionModalVisible,
  term,
  newTerm,
  setIsEditFormOpen,
  reload,
  setReload
}) => {
  const dispatch = useDispatch()

  const isPatchingTermRisk = useSelector(state => state.data.isPatchingTermRisk)

  return (
    <Modal
      isVisible={isTermEditionModalVisible}
      closeFn={() => setIsTermEditionModalVisible(false)}
      title='Editar termo'
      alert={<>A alteração só entrará em vigor na próxima coleta se for feita <strong>até o primeiro dia do mês</strong>.</>}
    >
      <ModalContent>
        <CustomText
          size='1.25rem'
          weight='700'
          mb='large'
        >
          Está certo disso?
        </CustomText>

        <CustomText
          size='0.875rem'
          mb='large'
        >
          Ao confirmar essa ação, esse termo será alterado no dicionário e atualizado nas próximas entregas de coletas.<br />
          As publicações já coletadas e classificadas como procedentes serão mantidas no nosso sistema e nas visualizações de volumetria.
        </CustomText>

        <CustomText
          size='0.875rem'
          weight='700'
          mb='large'
        >
          Alteração:
        </CustomText>

        <FlexAlignCenter
          override={`
            svg {
              width: ${theme.spacing.medium};
              height: ${theme.spacing.medium};

              @media (max-width: 767px) {
                margin: ${theme.spacing.medium} 0;
                transform: rotate(90deg);
              }
            }

            @media (max-width: 767px) {
              flex-direction: column;
              align-items: flex-start;
            }

            @media (min-width: 768px) {
              > * {
                margin: 0 ${theme.spacing.xsmall};

                &:first-child {
                  margin-left: 0;
                }

                &:last-child {
                  margin-right: 0;
                }
              }
            }
          `}
          mb='large'
        >
          <FlexAlignCenter>
            <CustomText size='0.875rem' mr='small'>
              {term.term}
            </CustomText>

            <RiskBadge risk={term.risk} />
          </FlexAlignCenter>

          <Icon icon='arrow-forward-outline' />

          <FlexAlignCenter>
            <CustomText size='0.875rem' mr='small'>
              {newTerm.term}
            </CustomText>

            <RiskBadge risk={newTerm.risk} />
          </FlexAlignCenter>
        </FlexAlignCenter>

        <CustomText
          size='0.875rem'
          weight='700'
        >
          Você está certo de que deseja editar esse termo?
        </CustomText>
      </ModalContent>

      <ModalActions>
        <Button
          onClick={() => setIsTermEditionModalVisible(false)}
          red
        >
          <Icon icon={'close-outline'} />
          <span>Cancelar</span>
        </Button>

        <Button
          onClick={
            () => dispatch(
              editTermRisk({
                termId: term.id,
                risk: newTerm.risk,
                callback: () => {
                  setIsEditFormOpen(false)
                  setReload(!reload)
                }
              })
            )
          }
          disabled={isPatchingTermRisk}
        >
          <Icon icon={'save-outline'} />
          <span>Salvar</span>
        </Button>

        {isPatchingTermRisk &&
          <LoadingSpinner size={'xlarge'} ml={'small'} />}
      </ModalActions>
    </Modal>
  )
}

TermEditionModal.propTypes = {
  isTermEditionModalVisible: PropTypes.bool.isRequired,
  setIsTermEditionModalVisible: PropTypes.func.isRequired,
  term: PropTypes.shape({
    term: PropTypes.string,
    risk: PropTypes.oneOf([1, 2, 3, '1', '2', '3']),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired,
  newTerm: PropTypes.shape({
    term: PropTypes.string,
    risk: PropTypes.oneOf([1, 2, 3, '1', '2', '3']),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }).isRequired,
  setIsEditFormOpen: PropTypes.func.isRequired,
  reload: PropTypes.bool,
  setReload: PropTypes.func
}

export default TermEditionModal
