/* React */
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'

/* Components */
import { Container, Row } from 'styled-bootstrap-grid'
import { Formik, Field, Form } from 'formik'

import {
  Card,
  Logo,
  LoadingSpinner,
  Button,
  FlexCentered,
  CustomText,
  Icon,
  theme
} from '@zeta/ui/src'

import AuthWrapper from '../AuthWrapper'
import FieldGroup from '../FieldGroup'
import { ErrorMessage, SuccessMessage } from '../FieldGroup/styled'

/* Styled */
import { CardCol } from '../styled'

/* Helpers */
import { paths } from '../../constants/config'
import { setPassword } from '../../api'
import {
  validatePassword,
  hasUpperCaseLetter,
  hasLowerCaseLetter,
  hasNumber,
  hasSpecialCharacter,
  hasMinLength,
  getValidationColor
} from '../../helpers'

/* Action Creators */
import { signOut } from '../../store/reducers/user/action-creators'

/* Constants */
const visibleError = {
  'Wrong token':
    ' Operação inválida. Entre em contato com o administrador da plataforma.',
  'Token expired':
    'Ativação de cadastro expirada. Solicite um novo link ao administrador da plataforma.'
}

const SetPassword = ({ history }) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(signOut())
  }, [])

  const [isRequesting, setIsRequesting] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(null)

  const handleSetPassword = (values) => {
    const { password, confirmPassword } = values

    setIsRequesting(true)

    setPassword({
      token: history.location.pathname
        .match(/token=\w+/g)[0]
        .replace(/token=/, ''),
      password,
      confirmPassword
    })
      .then(() => {
        setSuccess(true)
        setError(null)
        setIsRequesting(false)
      })
      .catch((error) => {
        setError(error.response)
        setIsRequesting(false)
      })
  }

  const isValid = (values) =>
    values.password && values.confirmPassword
      ? values.password === values.confirmPassword &&
        validatePassword(values.password)
      : false

  return (
    <AuthWrapper>
      <Container>
        <Row alignItems={'center'} justifyContent={'end'}>
          <CardCol md={6} lg={4}>
            <Card>
              <FlexCentered mb={'medium'}>
                <Logo size={'120'} />
              </FlexCentered>

              <Formik
                initialValues={{ password: '', confirmPassword: '' }}
                onSubmit={(values) => handleSetPassword(values)}
              >
                {({ values, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    {!success && (
                      <>
                        <CustomText size="0.75rem" mb="xsmall">
                          Sua senha deve conter:
                        </CustomText>
                        <CustomText
                          size="0.75rem"
                          mb="xsmall"
                          override={`color: ${getValidationColor(
                            hasUpperCaseLetter(values.password)
                          )};`}
                        >
                          &#9679; uma letra maiúscula
                        </CustomText>
                        <CustomText
                          size="0.75rem"
                          mb="xsmall"
                          override={`color: ${getValidationColor(
                            hasLowerCaseLetter(values.password)
                          )};`}
                        >
                          &#9679; uma letra minúscula
                        </CustomText>
                        <CustomText
                          size="0.75rem"
                          mb="xsmall"
                          override={`color: ${getValidationColor(
                            hasNumber(values.password)
                          )};`}
                        >
                          &#9679; um número
                        </CustomText>
                        <CustomText
                          size="0.75rem"
                          mb="xsmall"
                          override={`color: ${getValidationColor(
                            hasSpecialCharacter(values.password)
                          )};`}
                        >
                          &#9679; um caractere especial
                        </CustomText>
                        <CustomText
                          size="0.75rem"
                          mb="small"
                          override={`color: ${getValidationColor(
                            hasMinLength(values.password)
                          )};`}
                        >
                          &#9679; no mínimo 8 caracteres
                        </CustomText>

                        <Field
                          type="password"
                          name="password"
                          label="senha"
                          placeholder="••••••••"
                          component={FieldGroup}
                          icon={() => <Icon icon={'unlock-outline'} />}
                        />

                        <Field
                          type="password"
                          name="confirmPassword"
                          label="confirmar senha"
                          placeholder="••••••••"
                          component={FieldGroup}
                          icon={() => <Icon icon={'unlock-outline'} />}
                        />

                        {values.password !== '' &&
                        values.password !== values.confirmPassword ? (
                          <CustomText
                            size="0.75rem"
                            mb="small"
                            override={`color: ${theme.colors.feedback.red.darkness};`}
                          >
                            &#9679; as senhas não coincidem
                          </CustomText>
                        ) : null}
                      </>
                    )}

                    {/* Loading */}
                    {isRequesting && (
                      <FlexCentered>
                        <LoadingSpinner size={'40'} />
                      </FlexCentered>
                    )}

                    {/* Erro */}
                    {error && error.status !== 401 && !isRequesting && (
                      <FlexCentered mb="small">
                        <ErrorMessage>
                          {visibleError[error.data.msg]}
                        </ErrorMessage>
                      </FlexCentered>
                    )}

                    {/* Sucesso */}
                    {success && (
                      <FlexCentered mb="small">
                        <SuccessMessage>Sua senha foi definida.</SuccessMessage>
                      </FlexCentered>
                    )}

                    {/* Enviar */}
                    {!success && (
                      <FlexCentered>
                        <Button
                          type="submit"
                          disabled={isRequesting || !isValid(values)}
                        >
                          Definir senha
                        </Button>
                      </FlexCentered>
                    )}
                  </Form>
                )}
              </Formik>

              {success && (
                <FlexCentered mt="medium">
                  <CustomText
                    as="button"
                    type="button"
                    onClick={() => history.push(paths.signIn)}
                    size="0.875rem"
                    override="text-decoration: underline;"
                  >
                    ir para o login
                  </CustomText>
                </FlexCentered>
              )}
            </Card>
          </CardCol>
        </Row>
      </Container>
    </AuthWrapper>
  )
}

export default SetPassword
