/* React */
import React from 'react'

/* Components */
import { Col } from 'styled-bootstrap-grid'

import {
  Pagination,
  CustomText,
  CustomBlock,
  Divider,
  FlexCentered,
  TabsAlt,
  SectionHeadline,
  Card,
  FlexAlignCenter,
  ScrollAnchor,
  theme
} from '@zeta/ui/src'

import { InfoIcon } from '../PostCard/styled'

/* Parts */
import PostCard from '../PostCard'
import TermsTable from '../TermsTable'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'
import { formatDate } from '@zeta/helpers'
import { paginateItems } from 'helpers'
import { sortPublicationsByDate, sortBiosByDate } from '../../helpers'

const SocialTab = ({
  social,
  publications,
  filteredPublicationsBySocialNetwork,
  bios,
  individual,
  postsPage,
  setPostsPage,
  linkedin,
  linkedinArticles,
  activeSocialsTab,
  setActiveSocialsTab
}) => {
  const isLinkedinAndHavePosts = () => {
    if (
      activeSocialsTab !== 'linkedin' &&
      activeSocialsTab !== 'linkedinArticles'
    )
      return false
    if (
      filteredPublicationsBySocialNetwork.linkedin.length === 0 &&
      filteredPublicationsBySocialNetwork.linkedinArticles.length === 0
    )
      return false
    return true
  }

  return individual[social === 'linkedinArticles' ? 'linkedin' : social]
    .length === 0 ? (
    <Col>
      <CustomText size="0.875rem" mt="large" mb="xlarge">
        Perfil não encontrado na rede social.
      </CustomText>
    </Col>
  ) : (
    <>
      {bios && bios.length > 0 && (
        <>
          {bios.filter((bios) => bios.bios).length > 0 && (
            <>
              <Col>
                <SectionHeadline mt="large" mb="medium">
                  Bio
                </SectionHeadline>
              </Col>

              {sortBiosByDate(bios.filter((bios) => bios.bios)).map((bios) => (
                <Col md={6} key={uuidv4()}>
                  <Card mb="large">
                    <FlexAlignCenter>
                      <InfoIcon icon="calendar-outline" />
                      <CustomText size="0.875rem" weight="700">
                        Coletado em {formatDate(bios.bios.collected_at)}
                      </CustomText>
                    </FlexAlignCenter>

                    <Divider my="medium" />

                    <TermsTable publication={bios} social={social} />

                    <Divider my="medium" />

                    {activeSocialsTab === 'linkedin' ||
                    activeSocialsTab === 'linkedinArticles' ? (
                      <CustomBlock>
                        {bios.bios.content.title && (
                          <CustomText
                            color={theme.colors.brand.tertiary.lightness}
                            size="0.875rem"
                            weight="700"
                          >
                            {bios.bios.content.title}
                          </CustomText>
                        )}

                        {bios.bios.content.about &&
                        bios.bios.content.about.split('\n').length > 1 ? (
                          bios.bios.content.about.split('\n').map((item) => (
                            <CustomText size="0.875rem" key={uuidv4()}>
                              {item}
                            </CustomText>
                          ))
                        ) : (
                          <CustomText size="0.875rem">
                            {bios.bios.content.about}
                          </CustomText>
                        )}
                      </CustomBlock>
                    ) : (
                      <CustomBlock>
                        {bios.bios.content &&
                        bios.bios.content.split('\n').length > 1 ? (
                          bios.bios.content.split('\n').map((item) => (
                            <CustomText size="0.875rem" key={uuidv4()}>
                              {item}
                            </CustomText>
                          ))
                        ) : (
                          <CustomText size="0.875rem">
                            {bios.bios.content}
                          </CustomText>
                        )}
                      </CustomBlock>
                    )}
                  </Card>
                </Col>
              ))}
            </>
          )}

          {bios.filter((bios) => bios.experience).length > 0 && (
            <>
              <Col>
                <SectionHeadline mt="large" mb="medium">
                  Experiência
                </SectionHeadline>
              </Col>

              {sortBiosByDate(bios.filter((bios) => bios.experience)).map(
                (experienceGroup) => (
                  <Col md={6} key={uuidv4()}>
                    <Card mb="large">
                      <FlexAlignCenter>
                        <InfoIcon icon="calendar-outline" />
                        <CustomText size="0.875rem" weight="700">
                          Coletado em{' '}
                          {formatDate(experienceGroup.experience.collected_at)}
                        </CustomText>
                      </FlexAlignCenter>

                      <Divider my="medium" />

                      <TermsTable
                        publication={experienceGroup}
                        social={social}
                      />

                      <Divider my="medium" />

                      {experienceGroup.experience.content.experiences.map(
                        (experience, i) => (
                          <CustomBlock
                            mt={i === 0 ? '0' : 'medium'}
                            override="div { font-size: 0.875rem; }"
                            key={uuidv4()}
                          >
                            {experience.role && (
                              <CustomText
                                color={theme.colors.brand.tertiary.lightness}
                                weight="700"
                              >
                                {experience.role}
                              </CustomText>
                            )}
                            {experience.company_name && (
                              <CustomText>{experience.company_name}</CustomText>
                            )}
                            {experience.description &&
                            experience.description.split('\n').length > 1 ? (
                              experience.description
                                .split('\n')
                                .map((item) => (
                                  <CustomText key={uuidv4()}>{item}</CustomText>
                                ))
                            ) : (
                              <CustomText>{experience.description}</CustomText>
                            )}
                            {experience.time_of_work && (
                              <CustomText>{experience.time_of_work}</CustomText>
                            )}
                          </CustomBlock>
                        )
                      )}
                    </Card>
                  </Col>
                )
              )}
            </>
          )}
        </>
      )}

      {(publications.length > 0 || isLinkedinAndHavePosts()) && (
        <>
          <Col>
            <ScrollAnchor name={'socialTabsAnchor'}>
              <SectionHeadline mt="large" mb="medium">
                Publicações
              </SectionHeadline>
            </ScrollAnchor>
          </Col>

          {isLinkedinAndHavePosts() ? (
            <Col>
              <TabsAlt
                items={[
                  {
                    text: `Posts${
                      linkedin.length > 0 ? ` (${linkedin.length})` : ''
                    }`,
                    onClickFn: () => setActiveSocialsTab('linkedin'),
                    isActive: activeSocialsTab === 'linkedin'
                  },
                  {
                    text: `Artigos${
                      linkedinArticles.length > 0
                        ? ` (${linkedinArticles.length})`
                        : ''
                    }`,
                    onClickFn: () => setActiveSocialsTab('linkedinArticles'),
                    isActive: activeSocialsTab === 'linkedinArticles'
                  }
                ]}
                mb="xlarge"
              />
            </Col>
          ) : null}

          {paginateItems(
            sortPublicationsByDate(publications),
            postsPage[social],
            8
          ).map((publication) => (
            <Col md={6} key={uuidv4()}>
              <PostCard publication={publication} social={social} />
            </Col>
          ))}

          {publications.length > 0 && (
            <Col>
              <FlexCentered>
                <Pagination
                  page={postsPage[social]}
                  setPageFn={(page) =>
                    setPostsPage({ ...postsPage, [social]: page })
                  }
                  perPage={8}
                  totalItems={publications.length}
                  scrollAnchor="socialTabsAnchor"
                />
              </FlexCentered>
            </Col>
          )}
        </>
      )}
    </>
  )
}

export default SocialTab
