import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.div`
  background-color: ${({ theme: { colors } }) =>
    colors.surface.secondary.lightness};
  border: solid 1px ${({ theme: { colors } }) => colors.surface.primary.dark};
  display: inline-flex;
  flex-direction: column;
  position: absolute;
  z-index: 2;

  ${defaultProps}
`

export const Item = styled.button`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  ${({ as, theme: { spacing } }) =>
    as !== 'div' && `padding: 0 ${spacing.small};`}
  outline: 0;
  text-align: left;
  font-size: 0.75rem;
  white-space: nowrap;

  &:hover {
    background-color: ${({ secondary, theme: { colors } }) =>
      secondary ? colors.brand.primary.medium : colors.brand.primary.lightness};

    ${({ secondary, theme: { colors } }) =>
      secondary &&
      `
        color: ${colors.surface.secondary.lightness};

        svg {
          fill: ${colors.surface.secondary.lightness};
          transition: all 0.3s;
        }
      `};

    transition: all 0.3s;
  }
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
