import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  border: 0;
  border-radius: ${({ theme: { radius } }) => radius.smallest};
  padding: ${({ theme: { spacing } }) => `0 ${spacing.medium}`};
  height: 40px;
  white-space: nowrap;
  cursor: pointer;
  background-color: ${({ theme: { colors } }) => colors.brand.primary.medium};
  color: ${({ theme: { colors } }) => colors.surface.secondary.lightness};

  &:hover {
    box-shadow: ${({ theme: { boxShadow } }) => boxShadow.rb};
    transition: all .3s;
  }

  > span {
    &:first-child {
      margin-left: 0;
      margin-right: ${({ theme: { spacing } }) => spacing.small};
    }
    &:last-child {
      margin-left: ${({ theme: { spacing } }) => spacing.small};
      margin-right: 0;
    }
    &:only-child {
      margin-left: 0;
      margin-right: 0;
    }
  }

  svg {
    width: ${({ theme: { spacing } }) => spacing.medium};
    height: ${({ theme: { spacing } }) => spacing.medium};
    fill: ${({ theme: { colors } }) => colors.surface.secondary.lightness};
    transform: translateY(-1px);
  }

  ${({ outlined, theme: { colors } }) =>
    outlined &&
    `
      background-color: transparent;
      border: 1px solid ${colors.brand.primary.medium};
      color: ${colors.brand.primary.medium};

      &:hover {
        color: ${colors.brand.primary.dark};
        border-color: ${colors.brand.primary.dark};
        box-shadow: none;

        svg {
          fill: ${colors.brand.primary.dark};
        }
      }

      svg {
        fill: ${colors.brand.primary.medium};
        transition: all .3s;
      }
    `}

  ${({ red, theme: { colors } }) =>
    red &&
    `
      background-color: ${colors.feedback.red.dark};
    `}

  ${({ green, theme: { colors } }) =>
    green &&
    `
      background-color: ${colors.feedback.green.dark};
    `}

  ${({ small, theme: { spacing } }) =>
    small &&
    `
      padding: 0 ${spacing.small};
      height: ${spacing.xlarge};
      font-size: 0.75rem;

      svg {
        &:first-child {
          margin-left: 0;
          margin-right: ${spacing.xsmall};
        }
        &:last-child {
          margin-left: ${spacing.xsmall};
          margin-right: 0;
        }
        &:only-child {
          margin-left: 0;
          margin-right: 0;
        }
      }
    `}

  ${({ large, theme: { spacing } }) =>
    large &&
    `
      padding: 0 ${spacing.xlarge};
      height: 56px;
    `}

  ${({ fullWidth }) =>
    fullWidth &&
    `
      width: 100%;
    `}

  ${({ disabled, theme: { colors } }) =>
    disabled &&
    `
      background-color: ${colors.brand.tertiary.lightness};
      cursor: not-allowed;
      box-shadow: none !important;
      outline: 0;
    `}

  ${defaultProps}
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
