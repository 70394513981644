/* Constants */
import {
  BOARD_SUCCEEDED,
  SET_BOARD_INFO,
  SET_FILTERS,
  SET_SAVE_REPORT_MODAL,
  MAIN_DATA_REQUESTED,
  MAIN_DATA_SUCCEEDED,
  ARCHIVED_REQUESTED,
  ARCHIVED_SUCCEEDED,
  SUBJECT_ARCHIVED,
  SUBJECT_UNARCHIVED,
  SUBJECT_UPDATED,
  PATCH_TERM_RISK_REQUESTED,
  PATCH_TERM_RISK_SUCCEEDED,
  SHELVE_TERM_REQUESTED,
  SHELVE_TERM_SUCCEEDED,
  CHART_REQUESTED,
  CHART_SUCCEEDED,
  SET_LIST_PAGE,
  SET_SEARCH_QUERY,
  SET_ACTIVE_UNIT,
  RESET_PROPS,
  RESET_STATE
} from './action-types'

import { OBSERVATION_PATCHED } from 'store/reducers/individual/action-types'

/* Helpers */
import { path, update } from 'ramda'
import {
  getUpdatedObservations,
  removeSubject,
  includeSubject,
  removeSubjectObservations,
  includeSubjectObservations
} from './helpers'

export const initialState = {
  board: null,

  isLoadingChart: false,
  chart: null,

  isLoadingMainData: false,
  observations: null,
  subjects: null,
  terms: null,

  filters: {},
  dynamicFiltersItems: null,

  listPage: {
    active: {
      people: 1,
      company: 1
    },
    archived: {
      people: 1,
      company: 1
    }
  },

  searchQuery: {
    active: {
      people: '',
      company: ''
    },
    archived: {
      people: '',
      company: ''
    }
  },

  isPatchingTermRisk: false,
  isShelvingTerm: false,

  saveBoardModal: {
    isOpen: false,
    proceedFn: null,
    enableProceedWithoutSaving: false
  },

  activeUnit: 'active',

  isLoadingArchived: false,
  archivedAlreadyRequested: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CHART_REQUESTED: {
      return {
        ...state,
        isLoadingChart: true
      }
    }

    case CHART_SUCCEEDED: {
      return {
        ...state,
        chart: action.payload,
        isLoadingChart: false
      }
    }

    case MAIN_DATA_REQUESTED:
      return {
        ...state,
        isLoadingMainData: true
      }

    case MAIN_DATA_SUCCEEDED:
      return {
        ...state,
        isLoadingMainData: false,
        observations: {
          active: {
            people: action.observations.people,
            company: action.observations.company
          }
        },
        subjects: {
          active: {
            people: action.subjects.people,
            company: action.subjects.company
          }
        },
        terms: action.terms,
        dynamicFiltersItems: action.dynamicFiltersItems
      }

    case ARCHIVED_REQUESTED:
      return {
        ...state,
        isLoadingArchived: true
      }

    case ARCHIVED_SUCCEEDED:
      return {
        ...state,
        observations: {
          ...state.observations,
          archived: {
            people: action.payload.observations.people,
            company: action.payload.observations.company
          }
        },
        subjects: {
          ...state.subjects,
          archived: {
            people: action.payload.subjects.people,
            company: action.payload.subjects.company
          }
        },
        isLoadingArchived: false,
        archivedAlreadyRequested: true
      }

    case SUBJECT_ARCHIVED:
      return {
        ...state,
        observations: {
          active: {
            people:
              action.subjectType === 'people'
                ? removeSubjectObservations(
                    state.observations.active.people,
                    action.subjectId
                  )
                : state.observations.active.people,
            // : removeRelatesObservations(state.observations.active.people, action.relatesIds),
            company:
              action.subjectType === 'company'
                ? removeSubjectObservations(
                    state.observations.active.company,
                    action.subjectId
                  )
                : state.observations.active.company
          },
          archived: {
            people:
              action.subjectType === 'people'
                ? includeSubjectObservations(
                    path(['observations', 'archived', 'people'], state),
                    state.observations.active.people,
                    action.subjectId
                  )
                : path(['observations', 'archived', 'people'], state),
            // : includeRelatesObservations(path(['observations', 'archived', 'people'], state), state.observations.active.people, action.relatesIds),
            company:
              action.subjectType === 'company'
                ? includeSubjectObservations(
                    path(['observations', 'archived', 'company'], state),
                    state.observations.active.company,
                    action.subjectId
                  )
                : path(['observations', 'archived', 'company'], state)
          }
        },
        subjects: {
          active: {
            people:
              action.subjectType === 'people'
                ? removeSubject(state.subjects.active.people, action.subjectId)
                : state.subjects.active.people,
            // : removeRelates(state.subjects.active.people, action.relatesIds),
            company:
              action.subjectType === 'company'
                ? removeSubject(state.subjects.active.company, action.subjectId)
                : state.subjects.active.company
          },
          archived: {
            people:
              action.subjectType === 'people'
                ? includeSubject(
                    path(['subjects', 'archived', 'people'], state),
                    state.subjects.active.people,
                    action.subjectId
                  )
                : path(['subjects', 'archived', 'people'], state),
            // : includeRelates(path(['subjects', 'archived', 'people'], state), state.subjects.active.people, action.relatesIds),
            company:
              action.subjectType === 'company'
                ? includeSubject(
                    path(['subjects', 'archived', 'company'], state),
                    state.subjects.active.company,
                    action.subjectId
                  )
                : path(['subjects', 'archived', 'company'], state)
          }
        }
        // isLoadingArchived: action.subjectType === 'company' && !state.archivedAlreadyRequested && true
      }

    case SUBJECT_UNARCHIVED:
      return {
        ...state,
        observations: {
          active: {
            people:
              action.subjectType === 'people'
                ? includeSubjectObservations(
                    state.observations.active.people,
                    state.observations.archived.people,
                    action.subjectId
                  )
                : state.observations.active.people,
            // : includeRelatesObservations(state.observations.active.people, state.observations.archived.people, action.relatesIds),
            company:
              action.subjectType === 'company'
                ? includeSubjectObservations(
                    state.observations.active.company,
                    state.observations.archived.company,
                    action.subjectId
                  )
                : state.observations.active.company
          },
          archived: {
            people:
              action.subjectType === 'people'
                ? removeSubjectObservations(
                    state.observations.archived.people,
                    action.subjectId
                  )
                : state.observations.archived.people,
            // : removeRelatesObservations(state.observations.archived.people, action.relatesIds),
            company:
              action.subjectType === 'company'
                ? removeSubjectObservations(
                    state.observations.archived.company,
                    action.subjectId
                  )
                : state.observations.archived.company
          }
        },
        subjects: {
          active: {
            people:
              action.subjectType === 'people'
                ? includeSubject(
                    state.subjects.active.people,
                    state.subjects.archived.people,
                    action.subjectId
                  )
                : state.subjects.active.people,
            // : includeRelates(state.subjects.active.people, state.subjects.archived.people, action.relatesIds),
            company:
              action.subjectType === 'company'
                ? includeSubject(
                    state.subjects.active.company,
                    state.subjects.archived.company,
                    action.subjectId
                  )
                : state.subjects.active.company
          },
          archived: {
            people:
              action.subjectType === 'people'
                ? removeSubject(
                    state.subjects.archived.people,
                    action.subjectId
                  )
                : state.subjects.archived.people,
            // : removeRelates(state.subjects.archived.people, action.relatesIds),
            company:
              action.subjectType === 'company'
                ? removeSubject(
                    state.subjects.archived.company,
                    action.subjectId
                  )
                : state.subjects.archived.company
          }
        }
      }

    case SUBJECT_UPDATED:
      return {
        ...state,
        subjects: {
          ...state.subjects,
          [action.activeUnit]: {
            ...state.subjects[action.activeUnit],
            [action.subjectType]: update(
              state.subjects[action.activeUnit][action.subjectType].findIndex(
                (subject) => subject.id == action.subjectId
              ),
              {
                ...state.subjects[action.activeUnit][action.subjectType][
                  state.subjects[action.activeUnit][
                    action.subjectType
                  ].findIndex((subject) => subject.id == action.subjectId)
                ],
                ...action.values
              },
              state.subjects[action.activeUnit][action.subjectType]
            )
          }
        }
      }

    case BOARD_SUCCEEDED:
      return {
        ...state,
        board: {
          type: action.boardType,
          ...action.payload
        },
        filters:
          action.boardType === 'board' ? action.payload.config_body.filters : {}
      }

    case SET_BOARD_INFO:
      return {
        ...state,
        board: {
          ...state.board,
          ...action.payload
        }
      }

    case OBSERVATION_PATCHED:
      return {
        ...state,
        ...getUpdatedObservations(state, action)
      }

    case SET_FILTERS:
      return {
        ...state,
        filters: action.filters,
        listPage: initialState.listPage,
        searchQuery: initialState.searchQuery
      }

    case SET_LIST_PAGE:
      return {
        ...state,
        listPage: {
          ...state.listPage,
          [state.activeUnit]: {
            ...state.listPage[state.activeUnit],
            [action.subjectType]: action.page
          }
        }
      }

    case SET_SEARCH_QUERY:
      return {
        ...state,
        searchQuery: {
          ...state.searchQuery,
          [state.activeUnit]: {
            ...state.searchQuery[state.activeUnit],
            [action.subjectType]: action.searchQuery
          }
        },
        listPage: initialState.listPage
      }

    case SET_SAVE_REPORT_MODAL:
      return {
        ...state,
        saveBoardModal: {
          isOpen: action.isOpen,
          proceedFn: action.proceedFn,
          enableProceedWithoutSaving: action.enableProceedWithoutSaving
        }
      }

    case PATCH_TERM_RISK_REQUESTED: {
      return {
        ...state,
        isPatchingTermRisk: true
      }
    }

    case PATCH_TERM_RISK_SUCCEEDED: {
      return {
        ...state,
        isPatchingTermRisk: false
      }
    }

    case SHELVE_TERM_REQUESTED: {
      return {
        ...state,
        isShelvingTerm: true
      }
    }

    case SHELVE_TERM_SUCCEEDED: {
      return {
        ...state,
        isShelvingTerm: false
      }
    }

    case SET_ACTIVE_UNIT:
      return {
        ...state,
        activeUnit: action.activeUnit
      }

    case RESET_PROPS:
      return {
        ...state,
        ...action.props.reduce((acc, cur) => {
          return {
            ...acc,
            [cur]: initialState[cur]
          }
        }, {})
      }

    case RESET_STATE:
      return {
        ...initialState,
        chart: state.chart,
        isLoadingChart: state.isLoadingChart,
        isLoadingMainData: state.isLoadingMainData,
        observations: state.observations,
        subjects: state.subjects,
        terms: state.terms,
        dynamicFiltersItems: state.dynamicFiltersItems
      }

    default:
      return state
  }
}
