import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme: { spacing } }) => spacing.medium};
  border-radius: ${({ theme: { radius } }) => radius.small};
  background-color: ${({ theme: { colors } }) => colors.surface.primary.light};

  @media (max-width: 575px) {
    flex-direction: column;
  }

  ${defaultProps}
`

export const IconWrapper = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${({ theme: { colors } }) => colors.brand.primary.medium};
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 576px) {
    margin-right: ${({ theme: { spacing } }) => spacing.medium};
  }
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
