import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  padding: ${({ theme: { spacing } }) => spacing.large} 0;
  background-color: ${({ theme: { colors } }) => colors.brand.tertiary.dark};

  &:before {
    content: "";
    position: absolute;
    background-color: ${({ theme: { colors } }) => colors.brand.primary.medium};

    @media screen and (max-width: 767px) {
      height: 40vh;
      bottom: 0;
      left: 0;
      width: 100%;
    }

    @media screen and (min-width: 768px) {
      height: 100%;
      top: 0;
      right: 0;
      width: calc((100vw - 720px) / 2 + 240px);
    }

    @media screen and (min-width: 992px) {
      width: calc((100vw - 960px) / 2 + 240px);
    }

    @media screen and (min-width: 1200px) {
      width: calc((100vw - 1140px) / 2 + 285px);
    }
  }
`

export const AuthGraphismWrapper = styled.div`
  @media screen and (max-width: 767px) {
    display: none;
  }

  @media screen and (min-width: 768px) {
    position: absolute;
    top: 0;
    left: 0;

    > svg {
      width: 50vw;
      height: 90vh;
    }
  }
`
