import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../../defaultProps'

/* Props

1. behavior (+ style)
- hasError
- isValid
- disabled

+ defaultProps

*/

export const Wrapper = styled.div`
  margin-bottom: ${({ theme: { spacing } }) => spacing.large};
  font-size: 0.75rem;

  ${defaultProps}
`

export const Label = styled.label`
  display: block;
  font-size: 0.875rem;
  font-weight: 700;
`

export const Legend = styled.div`
  color: ${({ theme: { colors } }) => colors.brand.tertiary.lightness};
  font-size: 0.75rem;
  font-weight: 400;
`

export const InputWrapper = styled.div`
  display: flex;
  position: relative;

  ${defaultProps}

  /* ${({ type }) => type === 'file' && 'display: none;'} */

  /* ${({ horizontalDisplay, theme: { spacing } }) =>
    horizontalDisplay &&
    `
    display: flex;

    ${RadioItem} {
      margin-bottom: 0;

      &:not(:last-child) {
        margin-right: ${spacing.large};
      }
    }
  `} */

  /* position: relative; */

  > svg {
    width: ${({ theme: { spacing } }) => spacing.large};
    height: ${({ theme: { spacing } }) => spacing.large};
    /* ${({ hasError, theme: { colors } }) =>
      hasError && `fill: ${colors.feedback.red.dark};`}
    ${({ isValid, theme: { colors } }) =>
      isValid && `fill: ${colors.feedback.green.dark};`} */
    position: absolute;
    right: ${({ theme: { spacing } }) => spacing.small};
    top: calc(50% - 1px);
    transform: translateY(-50%);
  }
`

export const inputStyles = ({ disabled, theme: { colors } }) => `
  width: 100%;
  border: 1px solid ${colors.surface.primary.dark};
  background-color: ${colors.surface.secondary.lightness};

  &:focus {
    outline: 0;
    border-color: ${colors.brand.primary.light};
  }
`

// ${disabled && `
//   background-color: ${colors.surface.primary.light};
// `}

export const Input = styled.input`
  height: 40px;
  line-height: 40px;
  padding: ${({ theme: { spacing } }) => `0 ${spacing.small}`};

  ${({ disabled, theme: { colors } }) =>
    inputStyles({ disabled, theme: { colors } })}

  ${({ withIcon, theme: { spacing } }) =>
    withIcon &&
    `
    padding-right: calc(${spacing.small} + ${spacing.medium});
  `}
`

export const Textarea = styled.textarea`
  height: 100px;
  padding: ${({ theme: { spacing } }) => spacing.small};
  /* padding-right: ${({ theme: { spacing } }) => spacing.xlarge}; */

  ${({ disabled, theme: { colors } }) =>
    inputStyles({ disabled, theme: { colors } })}
`

export const RadioItem = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme: { spacing } }) => spacing.small};
  /* margin-bottom: ${({ theme: { spacing } }) => spacing.small}; */
  background-color: ${({ isChecked, theme: { colors } }) =>
    isChecked
      ? colors.surface.primary.light
      : colors.surface.secondary.lightness};
  border: 1px solid ${({ isChecked, theme: { colors } }) =>
    isChecked ? colors.brand.primary.medium : colors.surface.primary.dark};

  ${({ isChecked, theme: { colors } }) =>
    isChecked &&
    `
    color: ${colors.brand.primary.medium};
  `}

  @media (max-width: 767px) {
    &:not(:last-child) {
      margin-bottom: ${({ theme: { spacing } }) => spacing.xsmall};
    }
  }

  @media (min-width: 768px) {
    margin-bottom: ${({ theme: { spacing } }) => spacing.small};

    &:not(:last-child) {
      margin-right: ${({ theme: { spacing } }) => spacing.medium};
    }
  }
`

export const Radio = styled.input.attrs(() => ({
  type: 'radio'
}))`
  margin-right: ${({ theme: { spacing } }) => spacing.xsmall};
`

export const RadioLabel = styled.label`
  line-height: 1;
`

export const ErrorMessage = styled.div`
  color: ${({ theme: { colors } }) => colors.feedback.red.dark};
  margin-top: ${({ theme: { spacing } }) => spacing.xsmall};
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
