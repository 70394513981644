import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../../defaultProps'

export const Wrapper = styled.div`
  ${defaultProps}
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
