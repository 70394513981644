/* React */
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

/* Components */
import {
  Icon,
  Modal,
  ModalContent,
  ModalActions,
  CustomText,
  Button,
  LoadingSpinner
} from '@zeta/ui/src'

/* Action Creators */
import { shelveTerm } from 'store/reducers/data/action-creators'

const ShelveTermModal = ({
  isShelveTermModalVisible,
  setIsShelveTermModalVisible,
  termId,
  archived,
  reload,
  setReload
}) => {
  const dispatch = useDispatch()

  const isShelvingTerm = useSelector(state => state.data.isShelvingTerm)

  return (
    <Modal
      isVisible={isShelveTermModalVisible}
      closeFn={() => setIsShelveTermModalVisible(false)}
      title={archived ? 'Desarquivar termo' : 'Arquivar termo'}
      alert={<>A alteração só entrará em vigor na próxima coleta se for feita <strong>até o primeiro dia do mês</strong>.</>}
    >
      <ModalContent>
        <CustomText
          size='1.25rem'
          weight='700'
          mb='large'
        >
          Está certo disso?
        </CustomText>

        <CustomText
          size='0.875rem'
          mb='large'
        >
          {archived &&
            <>
            Ao confirmar essa ação, esse termo voltará ao dicionário das próximas coletas.<br />
            Publicações com menções desse termo referente ao período em que a palavra estava arquivada <strong>não serão contempladas</strong>, os resultados são referentes apenas aos períodos em que a coleta do termo estava ativada.
            </>}

          {!archived &&
            <>
              Ao confirmar essa ação, esse termo não estará mais no dicionário das próximas coletas.<br />
              As publicações já coletadas serão mantidas no nosso sistema e nas visualizações de volumetria.
            </>}
        </CustomText>

        <CustomText
          size='0.875rem'
          weight='700'
        >
          Você está certo de que deseja {archived ? 'desarquivar' : 'arquivar'} esse termo?
        </CustomText>
      </ModalContent>

      <ModalActions>
        <Button
          type={'button'}
          onClick={() => setIsShelveTermModalVisible(false)}
          red
        >
          <Icon icon={'close-outline'} />
          <span>Cancelar</span>
        </Button>

        <Button
          type={'button'}
          onClick={
            () => dispatch(
              shelveTerm({
                termId,
                archived: !archived,
                callback: () => {
                  setIsShelveTermModalVisible(false)
                  setReload(!reload)
                }
              })
            )
          }
          disabled={isShelvingTerm}
        >
          {archived
            ? <Icon icon={'file-add-outline'} />
            : <Icon icon={'file-remove-outline'} />}
          <span>{archived ? 'Desarquivar' : 'Arquivar'}</span>
        </Button>

        {isShelvingTerm &&
          <LoadingSpinner size={'xlarge'} ml={'small'} />}
      </ModalActions>
    </Modal>
  )
}

ShelveTermModal.propTypes = {
  isShelveTermModalVisible: PropTypes.bool.isRequired,
  setIsShelveTermModalVisible: PropTypes.func.isRequired,
  termId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  archived: PropTypes.bool,
  reload: PropTypes.bool,
  setReload: PropTypes.func,
  isShelvingTerm: PropTypes.bool.isRequired,
  shelveTerm: PropTypes.func.isRequired
}

export default ShelveTermModal
