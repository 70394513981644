/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Styled */
import { Wrapper } from './styled'

const ButtonAction = ({ children, ...rest }) => {
  return <Wrapper {...rest}>{children}</Wrapper>
}

ButtonAction.propTypes = {
  bg: PropTypes.string
}

export default ButtonAction
