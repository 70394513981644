import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;

  ${defaultProps}
`

export const UnitSwitcherButton = styled.button.attrs(() => ({
  type: 'button'
}))`
  font-size: 0.875rem;
  color: ${({ isActive, theme: { colors } }) =>
    isActive
      ? colors.surface.secondary.lightness
      : colors.brand.primary.medium};
  ${({ isActive, theme: { colors } }) =>
    isActive && `background-color: ${colors.brand.primary.medium}`};
  border: 1px solid ${({ theme: { colors } }) => colors.brand.primary.medium};

  &:first-child {
    border-radius: ${({ theme: { radius } }) =>
      `${radius.medium} 0 0 ${radius.medium}`};
    padding: ${({ theme: { spacing } }) =>
      `${spacing.xsmall} ${spacing.small} ${spacing.xsmall} ${spacing.medium}`};
  }
  &:last-child {
    border-radius: ${({ theme: { radius } }) =>
      `0 ${radius.medium} ${radius.medium} 0`};
    padding: ${({ theme: { spacing } }) =>
      `${spacing.xsmall} ${spacing.medium} ${spacing.xsmall} ${spacing.small}`};
  }
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
