import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  font-size: 0.75rem;

  ${defaultProps}
`

export const PaginationCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: ${({ theme: { colors } }) => colors.brand.primary.medium};
  margin: 0 ${({ theme: { spacing } }) => spacing.medium};
  position: relative;

  @media (max-width: 767px) {
    width: 100%;
    margin: ${({ theme: { spacing } }) =>
      `0 ${spacing.xsmall} ${spacing.small} 0`};
  }
`

export const Pages = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > * {
    margin: 0 ${({ theme: { spacing } }) => spacing.xsmall};
  }
`

export const ActivePage = styled.div`
  background-color: ${({ theme: { colors } }) =>
    colors.brand.primary.lightness};
  border: 1px solid ${({ theme: { colors } }) => colors.brand.primary.medium};
  padding: ${({ theme: { spacing } }) => `${spacing.xsmall} ${spacing.small}`};
  font-weight: 700;
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
