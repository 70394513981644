/* React */
import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* Components */
import Icon from '../Icon'
import LoadingSpinner from '../LoadingSpinner'
import { FlexCentered } from '../helpers/Flex'

/* Styled */
import {
  Wrapper,
  HeaderRow,
  HeaderCell,
  HeaderCellBtn,
  BodyRow,
  BodyCell
} from './styled'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'

/* Constants */
import theme from '../../theme/theme'

const Table = ({
  headers,
  rows,
  isLoadingRows,
  initialActiveColumn,
  activeColumnOrder,
  initialOrder,
  noWrap,
  stickyHeader,
  stickyFirstCell,
  ...rest
}) => {
  const [activeColumn, setActiveColumn] = useState(initialActiveColumn)

  const [order, setOrder] = useState(
    headers.reduce(
      (acc, cur, i) =>
        cur.onClickFn ? { ...acc, [i]: initialActiveColumn === i && activeColumnOrder ? activeColumnOrder : initialOrder || 'ASC' } : acc,
      {}
    )
  )

  const toggleOrder = (i) => {
    setOrder(
      Object.keys(order).reduce(
        (acc, cur) =>
          i === Number(cur)
            ? { ...acc, [cur]: order[cur] === 'ASC' ? 'DESC' : 'ASC' }
            : { ...acc, [cur]: initialOrder || 'ASC' },
        {}
      )
    )
  }

  return (
    <Wrapper {...rest}>
      <thead>
        <HeaderRow>
          {headers &&
            headers.map((header, i) =>
              header.onClickFn ? (
                <HeaderCell stickyHeader={stickyHeader} stickyFirstCell={stickyFirstCell} key={uuidv4()}>
                  <HeaderCellBtn
                    type='button'
                    onClick={() => {
                      setActiveColumn(i)
                      toggleOrder(i)

                      header.onClickFn()
                    }}
                    isActive={activeColumn === i}
                  >
                    {header.text}

                    {order[i] === 'ASC' && (
                      <Icon
                        icon='arrow-ios-upward-outline'
                        size='medium'
                        fill={theme.colors.brand.primary.medium}
                        ml='medium'
                      />
                    )}
                    {order[i] === 'DESC' && (
                      <Icon
                        icon='arrow-ios-downward-outline'
                        size='medium'
                        fill={theme.colors.brand.primary.medium}
                        ml='medium'
                      />
                    )}
                  </HeaderCellBtn>
                </HeaderCell>
              ) : (
                <HeaderCell stickyHeader={stickyHeader} stickyFirstCell={stickyFirstCell} key={uuidv4()}>{header.text}</HeaderCell>
              )
            )}
        </HeaderRow>
      </thead>

      <tbody>
        {!isLoadingRows &&
          rows &&
          rows.map((row) => (
            <BodyRow key={uuidv4()} onClick={row.onClickFn}>
              {row.cells.map((cell, i) => (
                <BodyCell
                  key={uuidv4()}
                  isActive={activeColumn === i}
                  noWrap={noWrap}
                  stickyFirstCell={stickyFirstCell}
                >
                  {cell}
                </BodyCell>
              ))}
            </BodyRow>
          ))}

        {isLoadingRows && (
          <BodyRow>
            <BodyCell colSpan={headers.length}>
              <FlexCentered>
                <LoadingSpinner size='40' />
              </FlexCentered>
            </BodyCell>
          </BodyRow>
        )}
      </tbody>
    </Wrapper>
  )
}

Table.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      onClickFn: PropTypes.func
    })
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      cells: PropTypes.array.isRequired,
      onClickFn: PropTypes.func
    })
  ),
  isLoadingRows: PropTypes.bool,
  initialActiveColumn: PropTypes.number,
  initialOrder: PropTypes.oneOf(['ASC', 'DESC']),
  noWrap: PropTypes.bool,
  stickyHeader: PropTypes.bool,
  stickyFirstCell: PropTypes.bool
}

export default Table
