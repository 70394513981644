/* React */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

/* Components */
import { Formik, Form, Field } from 'formik'

import {
  Modal,
  ModalContent,
  ModalActions,
  FieldGroup,
  DropdownSelect,
  Button,
  Warning,
  LoadingSpinner,
  Icon
} from '@zeta/ui/src'

/* Helpers */
import { logAmplitudeEvent } from '@zeta/helpers'
import { subjectTypes } from 'constants/config'
import { getSubjectsLength, filterObservations } from 'helpers'
import { saveBoard, createBoard } from 'api'

const SaveBoardModal = ({
  isModalOpen,
  closeModal,
  isBoard,
  enableProceedWithoutSaving,
  proceedFn
}) => {
  const board = useSelector((state) => state.data.board)
  const filters = useSelector((state) => state.data.filters)
  const observations = useSelector((state) => state.data.observations)
  const subjects = useSelector((state) => state.data.subjects)

  const [isSubmitting, setIsSubmitting] = useState(false)

  const submitThen = (res) => {
    setIsSubmitting(false)
    closeModal()

    logAmplitudeEvent({
      event: '[Paineis] Salvar painel',
      eventProperties: {
        Status: isBoard() ? 'Painel existente' : 'Painel novo',
        Local: 'Modal'
      }
    })

    proceedFn(res)
  }

  const submitFn = (values) => {
    setIsSubmitting(true)

    const configBody = {
      filters,
      status: values.status,
      totals: {
        people: subjectTypes.people
          ? {
              totalListed: getSubjectsLength(
                filters,
                observations.active.people,
                subjects.active.people
              ),
              totalHits: filterObservations(
                observations.active.people,
                filters,
                subjects.active.people
              ).length
            }
          : null,
        company: subjectTypes.company
          ? {
              totalListed: getSubjectsLength(
                filters,
                observations.active.company,
                subjects.active.company
              ),
              totalHits: filterObservations(
                observations.active.company,
                filters,
                subjects.active.company
              ).length
            }
          : null
      }
    }

    isBoard()
      ? saveBoard({
          boardId: board.id,
          name: values.name,
          configBody
        }).then((res) => submitThen(res))
      : createBoard({
          name: values.name,
          configBody
        }).then((res) => submitThen(res))
  }

  return (
    <Modal
      title={'Salvar painel'}
      isVisible={isModalOpen}
      closeFn={() => closeModal()}
    >
      <Formik
        initialValues={
          isBoard()
            ? { name: board.name, status: board.config_body.status || 1 }
            : { name: '', status: 1 }
        }
        onSubmit={(values) => submitFn(values)}
      >
        {(props) => {
          const { values, setFieldValue, handleSubmit } = props

          return (
            <Form onSubmit={handleSubmit}>
              <ModalContent>
                {!isBoard() && (
                  <Warning mb={'large'}>
                    Os painéis criados ficarão visíveis para todos os membros do
                    time.
                  </Warning>
                )}

                <Field
                  inputElement={'input'}
                  type={'text'}
                  name={'name'}
                  label={'Nome do painel'}
                  placeholder={'Digite o nome do seu painel'}
                  component={FieldGroup}
                  mb={'large'}
                />

                <DropdownSelect
                  items={[
                    {
                      text: 'Em investigação',
                      fn: () => setFieldValue('status', 1)
                    },
                    {
                      text: 'Finalizado',
                      fn: () => setFieldValue('status', 2)
                    }
                  ]}
                  activeIndex={values.status - 1}
                  label={'Status do painel'}
                  displayText={'Em investigação'}
                />
              </ModalContent>

              <ModalActions>
                {enableProceedWithoutSaving && (
                  <Button type={'button'} onClick={proceedFn} red>
                    <Icon icon={'close-outline'} />
                    <span>Não salvar</span>
                  </Button>
                )}

                <Button type="submit" disabled={isSubmitting || !values.name}>
                  <Icon icon={'save-outline'} />
                  <span>Salvar painel</span>
                </Button>

                {isSubmitting && (
                  <LoadingSpinner size={'xlarge'} ml={'small'} />
                )}
              </ModalActions>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

export default SaveBoardModal
