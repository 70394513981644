/* Constants */
import { features } from 'constants/config'
import { visibleBiosStatus } from 'constants/global'

/* Opções dos filtros SELECTION */
const bios_experience_status = [
  { visibleValue: visibleBiosStatus[0], dbValue: 0 },
  { visibleValue: visibleBiosStatus[1], dbValue: 1 },
  { visibleValue: visibleBiosStatus[2], dbValue: 2 }
]

export const selectionOptions = {
  social_type: [
    { visibleValue: 'Facebook', dbValue: 'Facebook' },
    { visibleValue: 'Linkedin', dbValue: 'Linkedin' },
    { visibleValue: 'Instagram', dbValue: 'Instagram' },
    { visibleValue: 'Twitter', dbValue: 'Twitter' },
    { visibleValue: 'Youtube', dbValue: 'Youtube' },
    { visibleValue: 'Site (apenas escritórios)', dbValue: 'Site' }
  ],
  observable_type: [
    { visibleValue: 'Post', dbValue: 'Publication' },
    { visibleValue: 'Artigo', dbValue: 'Article' },
    { visibleValue: 'Bio', dbValue: 'Bio' },
    { visibleValue: 'Experiência', dbValue: 'Experience' }
  ],
  risk: [
    { visibleValue: 'Baixo risco', dbValue: 1 },
    { visibleValue: 'Médio risco', dbValue: 2 },
    { visibleValue: 'Alto risco', dbValue: 3 }
  ],
  status: [
    { visibleValue: 'Não classificado', dbValue: 0 },
    { visibleValue: 'Investigação', dbValue: 6 },
    { visibleValue: 'Improcedente', dbValue: 1 },
    { visibleValue: 'Inconclusivo', dbValue: 7 },
    { visibleValue: 'Procedente (nenhuma ação)', dbValue: 2 },
    { visibleValue: 'Procedente (advertência)', dbValue: 3 },
    { visibleValue: 'Procedente (multa)', dbValue: 4 },
    { visibleValue: 'Procedente (orientação)', dbValue: 5 }
  ],
  acting_area: [
    { visibleValue: 'Gov. Assessores', dbValue: 'Gov. Assessores' },
    { visibleValue: 'Control Room', dbValue: 'Control Room' },
    { visibleValue: 'Trade Surveillance', dbValue: 'Trade Surveillance' }
  ],
  linkedin_bios_status: bios_experience_status,
  linkedin_experience_status: bios_experience_status,
  instagram_bios_status: bios_experience_status,
  facebook_bios_status: bios_experience_status,
  twitter_bios_status: bios_experience_status,
  youtube_bios_status: bios_experience_status,
  ouvidoria: [
    { visibleValue: 'Sim', dbValue: true },
    { visibleValue: 'Não', dbValue: false }
  ],
  ouvidoria_status: [
    { visibleValue: 'Não classificado', dbValue: 0 },
    { visibleValue: 'Investigação', dbValue: 6 },
    { visibleValue: 'Improcedente', dbValue: 1 },
    { visibleValue: 'Inconclusivo', dbValue: 7 },
    { visibleValue: 'Procedente (nenhuma ação)', dbValue: 2 },
    { visibleValue: 'Procedente (advertência)', dbValue: 3 },
    { visibleValue: 'Procedente (multa)', dbValue: 4 },
    { visibleValue: 'Procedente (orientação)', dbValue: 5 }
  ]
}

export const visibleLabels = {
  social_type: 'Menções de risco em',
  observable_type: 'Menções de risco em',
  risk: 'Menções com grau de risco de',
  status: 'Menções classificadas como',
  date_post: 'Menções de risco publicadas em',
  created_at: 'Menções de risco coletadas em',
  acting_area: 'Frente de atuação',
  occupation_area: 'Área de ocupação',
  linkedin_bios_status: 'Status da bios do Linkedin',
  linkedin_experience_status: 'Status da experiência do Linkedin',
  instagram_bios_status: 'Status da bios do Instagram',
  facebook_bios_status: 'Status da bios do Facebook',
  twitter_bios_status: 'Status da bios do Twitter',
  youtube_bios_status: 'Status da bios do Youtube',
  ouvidoria: 'Possui o termo "Ouvidoria" no site (apenas escritórios)',
  ouvidoria_status:
    'Classificação do termo "Ouvidoria" no site (apenas escritórios)'
}

export const dynamicFilters = features.occupationArea
  ? ['occupation_area']
  : null

export const observationsFilterProps = [
  'social_type',
  'observable_type',
  'risk',
  'status',
  'date_post',
  'created_at',
  'acting_area',
  'occupation_area'
]

export const subjectsFilterProps = [
  'linkedin_bios_status',
  'linkedin_experience_status',
  'instagram_bios_status',
  'facebook_bios_status',
  'twitter_bios_status',
  'youtube_bios_status',
  'ouvidoria',
  'ouvidoria_status'
]
