import { createStore, applyMiddleware } from 'redux'
// import { composeWithDevTools } from 'redux-devtools-extension'
import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects'

import reducers from './reducers'

import { getInitialAuthData } from '@zeta/user/src/api'

import {
  signInWatcher,
  updateUserInfoWatcher
} from '@zeta/user/src/store/reducers/user/sagas'
import {
  getAndSetBoardWatcher,
  getAndSetMainDataWatcher,
  getAndSetArchivedWatcher,
  editTermRiskWatcher,
  shelveTermWatcher,
  getAndSetChartWatcher
} from 'store/reducers/data/sagas'
import {
  getAndSetPublicationsWatcher,
  editObservationWatcher
} from 'store/reducers/individual/sagas'

const sagaMiddleware = createSagaMiddleware()

const middlewares = [sagaMiddleware]

if (process.env.NODE_ENV === 'development') {
  const { createLogger } = require('redux-logger')
  middlewares.push(createLogger({ diff: true }))
}

const store = createStore(
  reducers,
  getInitialAuthData(),
  applyMiddleware(...middlewares)
  // process.env.NODE_ENV === 'production' ? applyMiddleware(...middlewares) : composeWithDevTools(applyMiddleware(...middlewares))
)

function* rootSaga() {
  yield all([
    // user
    signInWatcher(),
    updateUserInfoWatcher(),
    // data
    getAndSetBoardWatcher(),
    getAndSetMainDataWatcher(),
    getAndSetArchivedWatcher(),
    editTermRiskWatcher(),
    shelveTermWatcher(),
    getAndSetChartWatcher(),
    // individual
    getAndSetPublicationsWatcher(),
    editObservationWatcher()
  ])
}

sagaMiddleware.run(rootSaga)

export default store
