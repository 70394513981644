import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

const SectionHeadline = styled.div`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
  color: ${({ theme: { colors } }) => colors.brand.tertiary.light};
  margin-bottom: ${({ theme: { spacing } }) => spacing.medium};

  svg {
    margin-right: ${({ theme: { spacing } }) => spacing.small};
    transform: translateY(-1px);
  }

  ${defaultProps}
`

SectionHeadline.propTypes = {
  ...defaultPropsPropTypes
}

export default SectionHeadline
