import styled from 'styled-components'

import defaultProps, { defaultPropsPropTypes } from '../defaultProps'

export const Wrapper = styled.div`
  display: flex;
  border-bottom: 1px solid
    ${({ theme: { colors } }) => colors.surface.primary.dark};

  ${({ secondary, theme: { colors, spacing } }) =>
    secondary &&
    `
    border-bottom: none;
    background-color: ${colors.surface.secondary.lightness};
    width: calc(100% + ${spacing.xlarge});
    margin-left: -${spacing.medium};
    padding: 0 ${spacing.medium};
    font-size: 0.875rem;
  `}

  &:last-child {
    margin-bottom: ${({ theme: { spacing } }) => spacing.large};
  }

  ${defaultProps}
`

export const Item = styled.div`
  font-weight: 700;
  cursor: pointer;

  ${({ secondary, active, theme: { colors, spacing } }) =>
    !secondary &&
    `
    border-bottom: 2px solid;
    border-bottom-color: ${
      active ? colors.brand.primary.medium : 'transparent'
    };
    padding: ${spacing.small} 0;
    color: ${active ? colors.brand.primary.medium : colors.brand.tertiary.medium};

    &:not(:last-child) {
      margin-right: ${spacing.xlarge};
    }

    &:hover {
      color: ${colors.brand.primary.medium};
      border-bottom-color: ${colors.brand.primary.medium};
      transition: all 0.3s;
    }
  `}

  ${({ secondary, active, theme: { colors, spacing } }) =>
    secondary &&
    `
    padding: ${spacing.medium} ${spacing.small};

    ${
      active &&
      `
      background-color: ${colors.brand.primary.lightness};
    `
    }
  `}
`

Wrapper.propTypes = {
  ...defaultPropsPropTypes
}
