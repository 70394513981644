/* React */
import React, { useState } from 'react'
import PropTypes from 'prop-types'

/* Components */
import { Formik, Form, Field } from 'formik'

import {
  Icon,
  Modal,
  ModalContent,
  ModalActions,
  FieldGroup,
  DropdownSelect,
  Button,
  LoadingSpinner,
  Warning
} from '@zeta/ui/src'

/* Helpers */
import { createTerm } from 'api'

const AddTermModal = ({
  /* props */
  isAddTermModalVisible,
  setIsAddTermModalVisible,
  dictionaryId,
  reload,
  setReload
}) => {
  const [isAddingTerm, setIsAddingTerm] = useState(false)
  const [warning, setWarning] = useState(false)

  const closeModal = () => {
    setIsAddTermModalVisible(false)
    setIsAddingTerm(false)
    setWarning(false)
  }

  const addTerm = (values) => {
    setIsAddingTerm(true)

    createTerm({
      dictionaryId,
      term: values.term,
      risk: values.risk
    })
      .then(() => {
        closeModal()
        setReload(!reload)
      })
      .catch((error) => {
        if (
          error.response &&
          error.response.data &&
          error.response.data.term &&
          error.response.data.term[0] === 'has already been taken'
        ) {
          setIsAddingTerm(false)
          setWarning('Termo já existente no dicionário.')
        }
      })
  }

  return (
    <Modal
      isVisible={isAddTermModalVisible}
      closeFn={closeModal}
      title="Adicionar termo"
      alert={
        <>
          O termo só entrará em vigor na próxima coleta se for adicionado{' '}
          <strong>até o primeiro dia do mês</strong>.
        </>
      }
    >
      <Formik
        initialValues={{ term: '', risk: '' }}
        onSubmit={(values) => addTerm(values)}
      >
        {(props) => {
          const { values, setFieldValue, handleSubmit } = props

          return (
            <Form onSubmit={handleSubmit}>
              <ModalContent>
                <Field
                  inputElement={'input'}
                  type={'text'}
                  name={'term'}
                  label={'Termo:'}
                  placeholder={'Digite o termo'}
                  component={FieldGroup}
                  mb={'large'}
                />

                <DropdownSelect
                  items={[
                    {
                      text: 'Baixo risco',
                      fn: () => setFieldValue('risk', 1)
                    },
                    {
                      text: 'Médio risco',
                      fn: () => setFieldValue('risk', 2)
                    },
                    {
                      text: 'Alto risco',
                      fn: () => setFieldValue('risk', 3)
                    }
                  ]}
                  activeIndex={values.status - 1}
                  label={'Grau de risco:'}
                  displayText={'Selecione o risco'}
                />
              </ModalContent>

              <ModalActions>
                <Button type={'button'} onClick={closeModal} red>
                  <Icon icon={'close-outline'} />
                  <span>Cancelar</span>
                </Button>

                <Button
                  type="submit"
                  disabled={isAddingTerm || !values.term || !values.risk}
                >
                  <Icon icon={'plus-circle-outline'} />
                  <span>Adicionar</span>
                </Button>

                {isAddingTerm && (
                  <LoadingSpinner size={'xlarge'} ml={'small'} />
                )}

                {warning && (
                  <Warning override={'width: 100%;'} mt="medium">
                    {warning}
                  </Warning>
                )}
              </ModalActions>
            </Form>
          )
        }}
      </Formik>
    </Modal>
  )
}

AddTermModal.propTypes = {
  isAddTermModalVisible: PropTypes.bool.isRequired,
  setIsAddTermModalVisible: PropTypes.func.isRequired,
  dictionaryId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  reload: PropTypes.bool,
  setReload: PropTypes.func
}

export default AddTermModal
