import { theme } from '@zeta/ui/src'

export const hasUpperCaseLetter = pw => /[A-Z]/.test(pw)
export const hasLowerCaseLetter = pw => /[a-z]/.test(pw)
export const hasNumber = pw => /[0-9]/.test(pw)
export const hasSpecialCharacter = pw => /[^A-Za-z0-9]/.test(pw)
export const hasMinLength = pw => pw.length >= 8

export const validatePassword = pw =>
  hasUpperCaseLetter(pw) &&
  hasLowerCaseLetter(pw) &&
  hasNumber(pw) &&
  hasSpecialCharacter(pw) &&
  hasMinLength(pw)

export const getValidationColor = validationFn => validationFn ? theme.colors.feedback.green.darkness : theme.colors.feedback.red.darkness

export const validateEmail = email => /^([a-z0-9_\.\+-]+)@([\da-z\.-]+)\.([a-z\.]{2,6})$/.test(String(email).toLowerCase())
