/* React */
import React from 'react'

/* Parts */
import RiskFilters from './RiskFilters'
import ActingAreaFilter from './ActingAreaFilter'
import OccupationAreaFilter from './OccupationAreaFilter'
import BiosFilters from './BiosFilters'
import OuvidoriaFilters from './OuvidoriaFilters'

/* Constants */
import { features } from 'constants/config'

const Content = ({
  /* props */
  filterProps
}) => {
  return (
    <>
      <RiskFilters {...filterProps} />

      <BiosFilters {...filterProps} />

      {features.ouvidoria && <OuvidoriaFilters {...filterProps} />}

      {features.actingArea && <ActingAreaFilter {...filterProps} />}

      {features.occupationArea && <OccupationAreaFilter {...filterProps} />}
    </>
  )
}

export default Content
