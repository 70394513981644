/* React */
import React from 'react'
import PropTypes from 'prop-types'

/* Recharts */
import {
  AreaChart as RechartsAreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from 'recharts'

import CustomTooltip from './CustomTooltip'

/* Styled */
import { Wrapper } from './styled'

/* Constants */
import theme from '../../theme/theme'

const AreaChart = ({
  data,
  chartWidth,
  chartHeight,
  tickCount,
  margin,
  allowDecimals,
  ...rest
}) => {
  return (
    <Wrapper {...rest}>
      <ResponsiveContainer width={chartWidth || '100%'} height={chartHeight}>
        <RechartsAreaChart data={data} margin={margin} {...rest}>
          <Tooltip content={<CustomTooltip />} />

          <CartesianGrid />

          <XAxis type='category' dataKey='name' />
          <YAxis
            type='number'
            tickCount={tickCount}
            allowDecimals={allowDecimals}
          />

          <Area
            dataKey='quantidade'
            stroke={theme.colors.brand.primary.dark}
            fill={theme.colors.brand.primary.light}
            isAnimationActive={false}
          />
        </RechartsAreaChart>
      </ResponsiveContainer>
    </Wrapper>
  )
}

AreaChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  chartWidth: PropTypes.number,
  chartHeight: PropTypes.number,
  tickCount: PropTypes.number,
  margin: PropTypes.object
}

AreaChart.defaultProps = {
  // data: [
  //   { name: 'Item 1', quantidade: 500, percentual: 15 },
  //   { name: 'Item 2', quantidade: 100, percentual: 20 },
  //   { name: 'Item 3', quantidade: 200, percentual: 40 },
  //   { name: 'Item 4', quantidade: 100, percentual: 15 },
  //   { name: 'Item 5', quantidade: 800, percentual: 20 },
  //   { name: 'Item 6', quantidade: 500, percentual: 40 }
  // ],
  chartHeight: 250
}

export default AreaChart
