/* React */
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

/* Components */
import { Col } from 'styled-bootstrap-grid'

import { Tabs, Icon } from '@zeta/ui/src'

/* Parts */
import SocialTab from './SocialTab'

/* Helpers */
import { v4 as uuidv4 } from 'uuid'
import { filterPublications } from '../helpers'

/* Selectors */
const createSocialSelector = (social) =>
  createSelector(
    (state) => state.individual.publicationsBySocialNetwork,
    (state) => state.individual.viewFilteredPublications,
    (state) => state.data.filters,
    (publicationsBySocialNetwork, viewFilteredPublications, filters) =>
      filterPublications(
        publicationsBySocialNetwork[social],
        viewFilteredPublications,
        filters
      )
  )

const facebookSelector = createSocialSelector('facebook')
const linkedinSelector = createSocialSelector('linkedin')
const instagramSelector = createSocialSelector('instagram')
const twitterSelector = createSocialSelector('twitter')
const youtubeSelector = createSocialSelector('youtube')
const linkedinArticlesSelector = createSocialSelector('linkedinArticles')
const siteSelector = createSelector(
  (state) => state.individual.textSites,
  (state) => state.individual.viewFilteredPublications,
  (state) => state.data.filters,
  (textSites, viewFilteredPublications, filters) =>
    textSites &&
    filterPublications(textSites, viewFilteredPublications, filters)
)

const createBiosSelector = (social) =>
  createSelector(
    (state) => state.individual.biosBySocialNetwork,
    (state) => state.individual.viewFilteredPublications,
    (state) => state.data.filters,
    (biosBySocialNetwork, viewFilteredPublications, filters) =>
      filterPublications(
        biosBySocialNetwork[social],
        viewFilteredPublications,
        filters
      )
  )

const facebookBiosSelector = createBiosSelector('facebook')
const linkedinBiosSelector = createBiosSelector('linkedin')
const instagramBiosSelector = createBiosSelector('instagram')
const twitterBiosSelector = createBiosSelector('twitter')
const youtubeBiosSelector = createBiosSelector('youtube')

const SocialTabs = ({ individual, subjectType }) => {
  const [postsPage, setPostsPage] = useState({
    facebook: 1,
    linkedin: 1,
    linkedinArticles: 1,
    instagram: 1,
    twitter: 1,
    youtube: 1,
    sites: 1
  })

  const filteredPublicationsBySocialNetwork = {
    facebook: useSelector(facebookSelector),
    linkedin: useSelector(linkedinSelector),
    instagram: useSelector(instagramSelector),
    twitter: useSelector(twitterSelector),
    youtube: useSelector(youtubeSelector),
    linkedinArticles: useSelector(linkedinArticlesSelector)
  }

  const filteredTextSites = useSelector(siteSelector)

  const {
    facebook,
    linkedin,
    instagram,
    twitter,
    youtube,
    linkedinArticles
  } = filteredPublicationsBySocialNetwork

  const filteredBiosBySocialNetwork = {
    facebook: useSelector(facebookBiosSelector),
    linkedin: useSelector(linkedinBiosSelector),
    instagram: useSelector(instagramBiosSelector),
    twitter: useSelector(twitterBiosSelector),
    youtube: useSelector(youtubeBiosSelector)
  }

  const mergedPubsAndBiosBySocialNetwork = {
    facebook: [...facebook, ...filteredBiosBySocialNetwork.facebook],
    linkedin: [
      ...linkedin,
      ...linkedinArticles,
      ...filteredBiosBySocialNetwork.linkedin
    ],
    instagram: [...instagram, ...filteredBiosBySocialNetwork.instagram],
    twitter: [...twitter, ...filteredBiosBySocialNetwork.twitter],
    youtube: [...youtube, ...filteredBiosBySocialNetwork.youtube]
  }

  const [activeSocialsTab, setActiveSocialsTab] = useState(
    Object.keys(mergedPubsAndBiosBySocialNetwork).filter(
      (social) => mergedPubsAndBiosBySocialNetwork[social].length > 0
    )[0]
  )

  return (
    <>
      <Col>
        <Tabs
          items={[
            mergedPubsAndBiosBySocialNetwork.facebook.length > 0 && {
              icon: <Icon icon="fa-facebook" />,
              text: 'Facebook',
              count: mergedPubsAndBiosBySocialNetwork.facebook.length,
              onClickFn: () => setActiveSocialsTab('facebook'),
              isActive: activeSocialsTab === 'facebook'
            },
            mergedPubsAndBiosBySocialNetwork.linkedin.length > 0 && {
              icon: <Icon icon="fa-linkedin" />,
              text: 'Linkedin',
              count: mergedPubsAndBiosBySocialNetwork.linkedin.length,
              onClickFn: () => setActiveSocialsTab('linkedin'),
              isActive:
                activeSocialsTab === 'linkedin' ||
                activeSocialsTab === 'linkedinArticles'
            },
            mergedPubsAndBiosBySocialNetwork.instagram.length > 0 && {
              icon: <Icon icon="fa-instagram" />,
              text: 'Instagram',
              count: mergedPubsAndBiosBySocialNetwork.instagram.length,
              onClickFn: () => setActiveSocialsTab('instagram'),
              isActive: activeSocialsTab === 'instagram'
            },
            mergedPubsAndBiosBySocialNetwork.twitter.length > 0 && {
              icon: <Icon icon="fa-twitter" />,
              text: 'Twitter',
              count: mergedPubsAndBiosBySocialNetwork.twitter.length,
              onClickFn: () => setActiveSocialsTab('twitter'),
              isActive: activeSocialsTab === 'twitter'
            },
            mergedPubsAndBiosBySocialNetwork.youtube.length > 0 && {
              icon: <Icon icon="fa-youtube" />,
              text: 'Youtube',
              count: mergedPubsAndBiosBySocialNetwork.youtube.length,
              onClickFn: () => setActiveSocialsTab('youtube'),
              isActive: activeSocialsTab === 'youtube'
            },
            subjectType === 'company' &&
              filteredTextSites.length > 0 && {
                icon: <Icon icon="globe" />,
                text: 'Site',
                count:
                  filteredTextSites.length === 0
                    ? false
                    : filteredTextSites.length,
                onClickFn: () => setActiveSocialsTab('sites'),
                isActive: activeSocialsTab === 'sites'
              }
          ]}
        />
      </Col>

      {Object.keys(filteredPublicationsBySocialNetwork).map(
        (social) =>
          activeSocialsTab === social && (
            <SocialTab
              social={social}
              publications={filteredPublicationsBySocialNetwork[social]}
              filteredPublicationsBySocialNetwork={
                filteredPublicationsBySocialNetwork
              }
              bios={
                social === 'linkedinArticles'
                  ? filteredBiosBySocialNetwork['linkedin']
                  : filteredBiosBySocialNetwork[social]
              }
              individual={individual}
              postsPage={postsPage}
              setPostsPage={setPostsPage}
              linkedin={linkedin}
              linkedinArticles={linkedinArticles}
              activeSocialsTab={activeSocialsTab}
              setActiveSocialsTab={setActiveSocialsTab}
              key={uuidv4()}
            />
          )
      )}

      {activeSocialsTab === 'sites' && (
        <SocialTab
          social="sites"
          publications={filteredTextSites}
          individual={individual}
          postsPage={postsPage}
          setPostsPage={setPostsPage}
        />
      )}
    </>
  )
}

export default SocialTabs
